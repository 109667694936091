<template>
    <div class="o-card-option d-flex">
        <div class="o-card-option__icon d-flex justify-center">
            <v-img
                width="100"
                contain
                :src="srcImg"
            ></v-img>
        </div>
        <div>
            <h1 class="text-body-supertitle o-card-option__title-animate-o">{{title}}</h1>
            <h1 class="text-body-subtitle o-card-option__subtitle-animate-o">{{subtitle}}</h1>
            <MBtnNormalComp
                v-intersect="onIntersect"
                color="primary"
                class="mt-2"
                :depressed="true"
                :to="to"
                @click="$emit('click')"
            >
                {{nameBtn}}
            </MBtnNormalComp>
        </div>
    </div>
</template>
<script>
import { MBtnNormalComp } from '../../components/molecules'
export default {
    components: {
        MBtnNormalComp
    },
    props: {
        title: String,
        subtitle: String,
        nameBtn: String,
        to: [String, Object],
        srcImg: String,
        index: Number
    },
    methods: {
        onIntersect (entries, observer) {
            if (!this.activateAnimation && entries[0].isIntersecting && window.innerWidth > 600) {
                this.activateAnimation = true
                var effectTitle =  document.getElementsByClassName('o-card-option__title-animate-o')
                effectTitle[this.index].style.opacity = 1

                var effectIcon =  document.getElementsByClassName('o-card-option__subtitle-animate-o')
                effectIcon[this.index].style.opacity = 1
            }
        },
        noCellAnimation() {
            var effectTitle =  document.getElementsByClassName('o-card-option__title-animate-o')
            effectTitle[this.index].style.opacity = 1

            var effectIcon =  document.getElementsByClassName('o-card-option__subtitle-animate-o')
            effectIcon[this.index].style.opacity = 1
        }
    },
    mounted() {
        if (window.innerWidth <= 600) { this.noCellAnimation() }
    }
}
</script>