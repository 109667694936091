<template>
    <div>
        <div class="user__header pa-2">
            <div class="d-flex justify-center align-center">
                <v-app-bar-nav-icon class="mr-2 user__icon-menu" @click="m_menuComp_changeStateMenu()"></v-app-bar-nav-icon>
                <h1 class="text-body-private-title">DOCUMENTOS</h1>
            </div>
            <v-divider class="mb-2 mt-1 user__line-mobile"></v-divider>
            <v-spacer></v-spacer>
            <div class="d-flex align-center">
                <MSelectComp
                    v-if="tab==0"
                    v-model="searchOption"
                    :items="itemsOptionsPublic"
                    style="width:220px"
                    itemValue="value"
                    itemText="title"
                    :hideDetails="true"
                    @change="ac_changeStateGallery"
                />
                <OBtnHeaderToolTipComp
                    v-if="tab==0"
                    smsTooltip="Nuevo documento"
                    icon="mdi-plus"
                    class="ml-1"
                    @click="tab=1"
                />
                <OBtnHeaderToolTipComp
                    v-if="tab==1"
                    class="ml-1"
                    smsTooltip="Volver a la lista"
                    icon="mdi-database"
                    @click="ac_backListGallery()"
                />
            </div>
        </div>
        <MLineHeaderComp />
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <div class="d-flex pa-2">
                    <h1 class="text-body-private-title mr-1">Total Documentos:</h1>
                    <h1 class="text-body-private-subtitle">{{total}}</h1>
                </div>
                <v-divider></v-divider>
                <OLoaderDataComp
                    class="mt-2"
                    v-if="dialogLoaderData"
                    smsLoaderData="Obteniendo Fotos"
                />
                <MNoDataComp
                    v-if="!dialogLoaderData && listDocuments.length==0"
                    smsNotData="SIN DOCUMENTOS"
                    class="ma-2"
                />
                <div class="d-flex justify-center flex-wrap">
                    <SCardDocumentComp
                        class="ma-2"
                        v-for="(document, index) in listDocuments"
                        :key="index"
                        :document="document"
                        @clickedit="ac_showFormEditDoc(document)"
                        @clickdelete="ac_confirmDeleteDocument(document)"
                        @clickpublic="ac_confirmChangeState(document)"
                        @clickdownload="ac_downloadDocument(document)"
                        @clickbig="ac_showImgBig(document)"
                    />
                </div>
                <SPaginationComp
                    class="mt-2"
                    v-if="!dialogLoaderData && listDocuments.length>0"
                    :condicionGo="page < last_page"
                    :condicionBack="page > 1"
                    :titlePagination="'Pag. '+page+' de '+last_page"
                    @clickback="ac_changePage(false)"
                    @clickgo="ac_changePage(true)"
                />
            </v-tab-item>
            <v-tab-item>
                <div class="d-flex justify-center pa-2 pt-0 pb-3">
                    <v-form
                        v-on:submit.prevent="ac_confirmAction()"
                        ref="formdocument"
                        v-model="valid"
                        lazy-validation
                        class="user--border user--radius pa-3 mt-3"
                        style="width:460px"
                    >
                        <h1 class="text-body-private-title text-center mb-2">
                            {{indexDocument==-1 ? 'NUEVO DOCUMENTO' : 'EDITAR DOCUMENTO'}}</h1>
                        <div class="d-flex justify-center" v-if="indexDocument==-1">
                            <OFileDocumentComp
                                ref="bodydocument"
                                :sizePdf="4194304"
                                textSize="(Máximo: 4Mb)"
                                :errorPhoto="errorPhoto"
                                @onChangeFile="ac_changeFile"
                            />
                        </div>
                        <MTextFieldComp
                            class="mt-3"
                            v-model="dataDocument.title"
                            :clearable="true"
                            label="Título"
                            :dense="true"
                            :counter="200"
                            :rules="[v => !this.m_rule_emptyField(v) || 'El dato es requerido',
                                v => (v+'').length<=200 || 'El título no debe exeder los 200 dígitos']"
                        />
                        <MTextAreaComp
                            class="mt-3"
                            v-model="dataDocument.description"
                            :clearable="true"
                            label="Descripción"
                            :dense="true"
                            :counter="200"
                            height="120"
                            :rules="[
                                v => !this.m_rule_emptyField(v) || 'El dato es requerido',
                                v => (v+'').length<=1000 || 'La descripción no debe exeder los 200 dígitos']"
                        />
                        <MTextFieldComp
                            v-if="indexDocument==-1"
                            class="mt-3"
                            v-model="dataDocument.link"
                            :clearable="true"
                            label="Link PDF"
                            :dense="true"
                            :counter="150"
                            :rules="[v => (v+'').length<=150 || 'El link no debe exeder los 150 dígitos']"
                        />
                        <div class="text-center">
                            <MBtnNormalComp
                                color="primary"
                                :outlined="true"
                                @click="ac_confirmAction()"
                            >GUARDAR</MBtnNormalComp>
                        </div>
                    </v-form>
                </div>
            </v-tab-item>
        </v-tabs-items>
        <OModalPdfComp
            :src="srcPDF"
            :dialog="dialogPDF"
            :title="titlePDF"
            @clickclose="dialogPDF=false"
        />
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_closeSmsError()"
        />
        <OSmsConfirmComp
            :dialog="dialogConfirm"
            :smsConfirm="smsConfirm"
            @clickno="ac_closeSmsError()"
            @clickyes="ac_perfomOperation()"
        />
        <OSmsActionComp
            :frameCorrect="frameCorrect"
            :smsAction="smsAction"
            :timeout="timeSucess"
            :colorMessage="colorMessage"
            @click="m_dataGeneral_frameTimeReset()"
        />
        <OLoaderOperationComp
            :dialog="dialogLoaderOperation"
            smsLoaderOperation="Realizando Operación"
        />
    </div>
</template>
<script>
import {
    MTextFieldComp,
    MLineHeaderComp,
    MNoDataComp,
    MBtnNormalComp,
    MTextAreaComp,
    MSelectComp
} from '../../components/molecules'
import {
    OBtnHeaderToolTipComp,
    OSmsErrorComp,
    OSmsConfirmComp,
    OSmsActionComp,
    OLoaderOperationComp,
    OLoaderDataComp,
    OBigImageComp,
    ODialogHeaderComp,
    OFileDocumentComp,
    OModalPdfComp
} from '../../components/organisms'
import {
    SCardDirectoryComp,
    SCardDocumentComp,
    SPaginationComp
} from '../../components/species'
import {
    dataGeneralComponetsMixin,
    responseServerMixin,
    rulesMixin,
    userMixin,
    menuCompMixing,
    scrollMixin
} from '../../mixin'
import {
    Document,
    Person
} from '../../models'
export default {
    mixins: [
        dataGeneralComponetsMixin,
        responseServerMixin,
        rulesMixin,
        userMixin,
        menuCompMixing,
        scrollMixin
    ],
    components: {
        MTextFieldComp,
        OBtnHeaderToolTipComp,
        MLineHeaderComp,
        SCardDirectoryComp,
        OSmsErrorComp,
        OSmsConfirmComp,
        OSmsActionComp,
        OLoaderOperationComp,
        OLoaderDataComp,
        MNoDataComp,
        MBtnNormalComp,
        MTextAreaComp,
        MSelectComp,
        SCardDocumentComp,
        SPaginationComp,
        OBigImageComp,
        ODialogHeaderComp,
        OFileDocumentComp,
        OModalPdfComp
    },
    data: () => ({
        valid: true,
        viewTable: true,
        searchOption: 2,
        dataDocument: new Document(),
        indexDocument: -1,
        idDocument: 0,
        listDocuments: [],
        page: 0,
        total: 0,
        last_page: 0,
        isDelete: false,
        tab: 0,
        errorPhoto: false,
        //haveImage: false,
        itemsOptionsPublic: [
            { title: 'Todos', value: 2 },
            { title: 'Publicados', value: 1 },
            { title: 'No Publicados', value: 0 }
        ],
        isChangeState: false,
        srcPDF: '',
        dialogPDF: false,
        titlePDF: ''
    }),
    methods: {
        processError(error) {
            this.dialogLoaderOperation = false
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_action(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        ac_closeSmsError() {
            if (this.noAccess) {
                localStorage.removeItem('abis')
                this.$router.replace('/login')
            } else {
                this.isChangeState = false
                this.indexGallery = -1
                this.dialogError = false
                this.dialogConfirm = false
                this.isDelete = false
            }
        },
        getListDocuments(page, option_public) {
            this.listGallery = []
            this.dialogLoaderData = true
            Document.getListDocuments(page, option_public).then(response => {
                this.listDocuments = response.data
                this.page = response.page
                this.last_page = response.last_page
                this.total = response.total
                this.dialogLoaderData = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_changeFile(data) {
            if (data.is_big) {
                this.dataDocument.route_file = null
                this.errorPhoto = true
                this.showEvent(['Error de archivo',
                    'El tamaño no debe exceder los 4MB'], 'error')
            } else {
                this.dataDocument.route_file = data.file
                if (data.file !== null) this.errorPhoto = false
                else this.errorPhoto = true
            }
        },
        validateForm() {
            if (!this.$refs.formdocument.validate()) return false
            return true
        },
        smsErrorForm(smsError) {
            this.smsError = smsError
            this.dialogError = true
            this.actionError = true
        },
        ac_confirmAction() {
            if (this.validateForm()) {
                if (this.dataDocument.route_file === null && this.m_rule_emptyField(this.dataDocument.link)) {
                    this.errorPhoto = true
                    const smsError = [`Debe ingresar un documento porfavor`]
                    this.smsErrorForm(smsError)
                } else {
                    var velocity = this.m_scroll_velocity(window.scrollY)
                    this.m_scroll_animation(velocity)
                    setTimeout(() => {
                        if (this.indexDocument !== -1) this.smsConfirm = ['¿Está seguro de editar el documento?']
                        else {
                            if (!this.m_rule_emptyField(this.dataDocument.link)) {
                                this.dataDocument.with_link = 1
                                this.dataDocument.route_file = null
                                this.dataDocument.name_file = null
                                this.errorPhoto = false
                                this.$refs.bodydocument.resetData()
                            } else {
                                this.dataDocument.with_link = 0
                                this.dataDocument.link = null
                            }
                            this.smsConfirm = ['¿Está seguro de registrar el documento?']
                        }
                        this.dialogConfirm = true
                    }, velocity)
                }
            } else this.smsErrorForm(['Corrija los errores con rojo porfavor'])
        },
        showEvent(message, colorSms) {
            this.colorMessage = colorSms
            this.smsAction = message
            this.frameCorrect = true
            this.timeSucess = setTimeout(() => {
                this.frameCorrect = false
            }, 3000)
        },
        deleteDocument() {
            Document.deleteDocument({id: this.idDocument}).then(() => {
                this.listDocuments.splice(this.indexDocument, 1)
                this.indexDocument = -1
                this.idDocument = 0
                this.isDelete = false
                this.dialogLoaderOperation = false
                this.showEvent(['Operación exitosa',
                    'se eliminó el documento'], 'success')
            }).catch(error => {
                this.processError(error)
            })
        },
        addDocument() {
            this.dataDocument.name_file = new Date().getTime()
            Document.addDocument(this.$objectToFD(this.dataDocument)).then(response => {
                this.listDocuments.unshift(response)
                this.ac_backListGallery()
                this.dialogLoaderOperation = false
                this.showEvent(['Operación exitosa',
                    'se registró el documento'], 'success')
            }).catch(error => {
                this.processError(error)
            })
        },
        updatePhoto() {
            Document.updateDocument(this.dataDocument).then(response => {
                Object.assign(this.listDocuments[this.indexDocument], response)
                this.ac_backListGallery()
                this.dialogLoaderOperation = false
                this.showEvent(['Operación exitosa',
                    'se editó el documento'], 'success')
            }).catch(error => {
                this.processError(error)
            })
        },
        changeStateDocument() {
            Document.changeState({id: this.idDocument}).then(response => {
                this.listDocuments[this.indexDocument].public = response
                this.isChangeState = false
                this.indexDocument = -1
                this.dialogLoaderOperation = false
                this.showEvent(['Operación exitosa',
                    'se cambio el estado del documento'], 'success')
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_perfomOperation() {
            this.dialogConfirm = false
            this.dialogLoaderOperation = true
            if (this.isChangeState) {
                this.changeStateDocument()
            } else {
                if (this.isDelete) this.deleteDocument()
                else {
                    if (this.indexDocument !== -1) this.updatePhoto()
                    else this.addDocument()
                }
            }
        },
        ac_backListGallery() {
            this.tab = 0
            setTimeout(() => {
                if (this.indexDocument === -1) this.$refs.bodydocument.resetData()
                else this.indexDocument = -1
                this.dataDocument = new Document()
                this.$refs.formdocument.resetValidation()
                this.errorPhoto = false
            }, 200)  
        },
        ac_showFormEditDoc(document) {
            this.indexDocument = this.listDocuments.indexOf(document)
            this.dataDocument = Object.assign({}, document)
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            setTimeout(() => {
                this.tab = 1
            }, velocity)
        },
        ac_confirmDeleteDocument(document) {
            this.isDelete = true
            this.indexDocument = this.listDocuments.indexOf(document)
            this.idDocument = document.id
            this.smsConfirm = ['¿Está seguro de eliminar el documento?']
            this.dialogConfirm = true
        },
        ac_changePage(state) {
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            if (state) { ++this.page }
            else { --this.page }
            setTimeout(() => {
                this.getListDocuments(this.page, this.searchOption)
            }, velocity)
        },
        ac_changeStateGallery(e) {
            this.getListDocuments(1, e)
        },
        ac_confirmChangeState(document) {
            this.idDocument = document.id
            this.indexDocument = this.listDocuments.indexOf(document)
            this.isChangeState = true
            const smsState = document.public == 0 ? 'PUBLICAR el documento' : 'QUITAR LA PUBLICACIÓN del documento'
            this.smsConfirm = [`¿Está seguro de ${smsState}?`]
            this.dialogConfirm = true
        },
        ac_downloadDocument(document) {
            if (document.link != null) window.open(document.link)
            else Person.showPdfMobile(document.route_file)
        },
        ac_showImgBig(document) {
            if (document.link != null) {
                window.open(document.link)
            } else {
                this.srcPDF = ''
                this.titlePDF = document.title
                this.srcPDF = Person.showPdfPc(document.route_file)
                setTimeout(() => {
                    this.dialogPDF = true
                }, 200)
            }
        }
    },
    mounted() {
        if (window.innerWidth <= 600) {
            this.viewTable = false
            this.mobile = true
        }
        if (this.m_user_saveToken()) {
            this.getListDocuments(1, 2)
        } else this.$router.replace('/login')
    }
}
</script>