<template>
    <div class="card-person card-person--normal-size animation__card--opacity">
        <text-highlight
            class="text-body-private-title"
            :queries="searchPhone"
        >
            {{phone.name}}
        </text-highlight>
        <div class="d-flex">
            <h1 class="text-body-private-title mr-1">Celular:</h1>
            <h1 class="text-body-private-subtitle">{{phone.number}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-private-title mr-1">Cargo:</h1>
            <h1 class="text-body-private-subtitle">{{phone.profession}}</h1>
        </div>
        <v-divider></v-divider>
        <div class="d-flex justify-center mt-2">
            <OBtnOptionToolTipComp
                smsTooltip="Editar Celular"
                icon="mdi-pencil"
                class="mr-1"
                @click="$emit('clickedit')"
            />
            <OBtnOptionToolTipComp
                smsTooltip="Eliminar Celular"
                icon="mdi-trash-can"
                class="mr-1"
                @click="$emit('clickdelete')"
            />
        </div>
    </div>
</template>
<script>
import { OBtnOptionToolTipComp } from '../../components/organisms'
import TextHighlight from 'vue-text-highlight'
export default {
    props: {
        phone: Object,
        searchPhone: String
    },
    components: {
        OBtnOptionToolTipComp,
        TextHighlight
    }
}
</script>