<template>
    <div>
        <div class="user__header pa-2">
            <div class="d-flex justify-center align-center">
                <v-app-bar-nav-icon class="mr-2 user__icon-menu" @click="m_menuComp_changeStateMenu()"></v-app-bar-nav-icon>
                <h1 class="text-body-private-title">AFILIADOS</h1>
            </div>
            <v-divider class="mb-2 mt-1 user__line-mobile"></v-divider>
            <v-spacer></v-spacer>
            <div class="d-flex align-center">
                <OSearchComp
                    v-if="tab==0"
                    :dense="true"
                    :hideDetails="true"
                    label="Buscar"
                    :singleLine="true"
                    v-model="search"
                    @search="ac_searchPerson()"
                />
                <OBtnHeaderToolTipComp
                    v-if="tab==0"
                    class="ml-1"
                    :smsTooltip="viewTable ? 'Cambiar a vista por cards' : 'Cambiar a vista por tabla'"
                    :icon=" viewTable ? 'mdi-table-eye' : 'mdi-table-eye-off'"
                    @click="viewTable = !viewTable"
                />
                <OBtnHeaderToolTipComp
                    v-if="tab==1"
                    smsTooltip="Volver a la lista de afiliados"
                    icon="mdi-database"
                    @click="ac_backListAffiliate()"
                />
            </div>
        </div>
        <MLineHeaderComp />
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <div class="d-flex pa-2">
                    <h1 class="text-body-private-title mr-1">N° de Afiliados:</h1>
                    <h1 class="text-body-private-subtitle">{{total}}</h1>
                </div>
                <v-divider></v-divider>
                <OLoaderDataComp
                    class="mt-2"
                    v-if="dialogLoaderData"
                    smsLoaderData="Obteniendo Afiliados"
                />
                <MNoDataComp
                    v-if="!dialogLoaderData && listPerson.length==0 && !viewTable"
                    smsNotData="SIN AFILIADOS"
                    class="ma-2"
                />
                <div class="d-flex justify-center mt-3" v-if="viewTable">
                    <STableAffiliationsComp
                        v-if="!dialogLoaderData"
                        :listPerson="listPerson"
                        :searchPerson="searchPersonFilter"
                        @clickdetail="ac_showDetailPerson"
                        @clickupdate="ac_showFormUpdate"
                        @clickcv="ac_confirmChangeCv"
                        @clickcategory="ac_showDialogCategory"
                        @clickchangecode="ac_changeCodePerson"
                    />
                </div>
                <div class="d-flex justify-center flex-wrap" v-else>
                    <SCardPersonComp
                        v-for="(person, index) in listPerson"
                        :key="index"
                        class="ma-2"
                        :person="person"
                        :searchPerson="searchPersonFilter"
                        @clickdetail="ac_showDetailPerson(person)"
                        @clickupdate="ac_showFormUpdate(person)"
                        @clickcv="ac_confirmChangeCv(person)"
                        @clickcategory="ac_showDialogCategory(person)"
                        @clickchangecode="ac_changeCodePerson(person)"
                    />
                </div>
                <SPaginationComp
                    class="mt-2"
                    v-if="!dialogLoaderData && listPerson.length>0"
                    :condicionGo="page < last_page"
                    :condicionBack="page > 1"
                    :titlePagination="'Pag. '+page+' de '+last_page"
                    @clickback="ac_changePage(false)"
                    @clickgo="ac_changePage(true)"
                />
            </v-tab-item>
            <v-tab-item>
                <div class="d-flex justify-center pa-2 pt-0 pb-3">
                    <v-form
                        v-on:submit.prevent="ac_confirmUpdateAffiliate()"
                        ref="formaffiliate"
                        v-model="valid"
                        lazy-validation
                        class="user--border user--radius pa-3 mt-3"
                        style="width:500px"
                    >
                        <h1 class="text-body-private-title text-center mb-2">FORMULARIO AFILIADO</h1>
                        <MTextFieldComp
                            class="mb-2"
                            v-model="dataPerson.full_name"
                            :clearable="true"
                            label="Nombre"
                            :dense="true"
                            :counter="50"
                            :rules="[
                                v => !m_rule_emptyField(v) || 'El dato es requerido',
                                v => (v + '').length <= 50 || 'El nombre no debe exeder los 50 dígitos']"
                        />
                        <MTextFieldComp
                            class="mb-2"
                            v-model="dataPerson.last_name"
                            :clearable="true"
                            label="Apellidos"
                            :dense="true"
                            :counter="50"
                            :rules="[
                                v => !m_rule_emptyField(v) || 'El dato es requerido',
                                v => (v + '').length <= 50 || 'Los apellidos no deben exeder los 50 dígitos']"
                        />
                        <MSelectComp
                            label="Género (*)"
                            :items="itemsGender"
                            class="mt-2"
                            v-model="dataPerson.gender"
                            itemText="title"
                            itemValue="value"
                            :rules="[v => !m_rule_emptyField(v) || 'El dato es requerido']"
                        />
                        <MTextFieldComp
                            class="mb-2"
                            v-model="dataPerson.ci"
                            :clearable="true"
                            label="CI"
                            :dense="true"
                            :counter="20"
                            :rules="[v => !m_rule_emptyField(v) || 'El dato es requerido',
                                v => (v + '').length <= 20 || 'El CI no deben exceder los 20 dígitos'
                            ]"
                        />
                        <MSelectComp
                            label="Expedido en (*)"
                            :items="itemsDepartments"
                            :dense="true"
                            class="mt-2"
                            v-model="dataPerson.issued_in"
                            itemText="title"
                            itemValue="value"
                            :rules="[v => !m_rule_emptyField(v) || 'El dato es requerido']"
                        />
                        <ODateOneComp
                            label="Fecha de Nacimiento"
                            v-model="dateOfBith"
                            :dense="true"
                            class="mt-2"
                            @change="ac_electionDateExpense"
                            :rules="[v => !m_rule_emptyField(v) || 'El dato es requerido']"
                        />
                        <MTextFieldComp
                            label="Celular"
                            v-model="dataPerson.phone"
                            :dense="true"
                            class="mt-2"
                            :counter="8"
                            :rules="[v => !m_rule_emptyField(v) || 'El dato es requerido',
                                v => m_rule_numberInteger(v) || 'El celular solo debe contener números',
                                v => (v + '').length == 8 || 'El celular debe contar con 8 dígitos'
                            ]"
                        />
                        <MTextFieldComp
                            label="Dirección"
                            v-model="dataPerson.address"
                            :counter=50
                            :dense="true"
                            class="mt-2"
                            :rules="[v => !m_rule_emptyField(v) || 'El dato es requerido',
                                v => (v + '').length <= 50 || 'La dirección debe exceder los 50 dígitos'
                            ]"
                        />
                        <div class="text-center">
                            <MBtnNormalComp
                                color="primary"
                                :outlined="true"
                                @click="ac_confirmUpdateAffiliate()"
                            >ACTUALIZAR AFILIADO</MBtnNormalComp>
                        </div>
                    </v-form>
                </div>
            </v-tab-item>
        </v-tabs-items>
        <v-dialog
            persistent
            width="420px"
            v-model="dialogDetail"
        >
            <v-card class="ma-0 pa-0">
                <ODialogHeaderComp
                    :title="person.full_name+' '+person.last_name"
                    @clickclose="dialogDetail=false"
                />
                <OInfoAffiliateComp
                    :person="person"
                />
            </v-card>
        </v-dialog>
        <v-dialog
            persistent
            width="420px"
            v-model="dialogCategory"
        >
            <ODialogHeaderComp
                title="Cambiar Categoría"
                @clickclose="ac_closeDialogCategories()"
            />
            <v-card class="ma-0 pa-2">
                <div
                    v-for="(category, index) in listCategoryFilter"
                    :key="index"
                    class="user__person-election animation__card--opacity ma-2 ml-0 mr-0"
                    @click="ac_electionCategory(category)"
                >
                    <h1 class="text-body-subtitle">{{category.title}}</h1>
                </div>
            </v-card>
        </v-dialog>
        <v-dialog
            persistent
            width="420px"
            v-model="dialogNewCode"
        >
            <ODialogHeaderComp
                title="Nuevo Código"
                @clickclose="dialogNewCode=false"
            />
            <v-card class="ma-0 pa-2" v-if="dataChangeCode.name!=undefined">
                <h1 class="text-body-private-title">{{dataChangeCode.name}}</h1>
                <v-divider class="mt-1 mb-1"></v-divider>
                <h1 style="font-size:46px" class="text-center text-body-private-title">{{dataChangeCode.code}}</h1>
            </v-card>
        </v-dialog>

        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_closeSmsError()"
        />
        <OSmsConfirmComp
            :dialog="dialogConfirm"
            :smsConfirm="smsConfirm"
            @clickno="ac_closeSmsError()"
            @clickyes="ac_perfomOperation()"
        />
        <OLoaderOperationComp
            :dialog="dialogLoaderOperation"
            smsLoaderOperation="Realizando Operación"
        />
        <OSmsActionComp
            :frameCorrect="frameCorrect"
            :smsAction="smsAction"
            :timeout="timeSucess"
            @click="m_dataGeneral_frameTimeReset()"
        />
    </div>
</template>
<script>
import moment from 'moment'
import {
    MLineHeaderComp,
    MNoDataComp,
    MTextFieldComp,
    MBtnNormalComp,
    MSelectComp
} from '../../components/molecules'
import {
    OLoaderDataComp,
    OSmsErrorComp,
    OSearchComp,
    OSmsActionComp,
    ODialogHeaderComp,
    OBtnHeaderToolTipComp,
    ODateOneComp,
    OSmsConfirmComp,
    OLoaderOperationComp,
    OInfoAffiliateComp
} from '../../components/organisms'
import {
    SPaginationComp,
    SCardPersonComp,
    STableAffiliationsComp
} from '../../components/species'
import { Person } from '../../models'
import jsPDF from 'jspdf' 
import {
    dataGeneralComponetsMixin,
    responseServerMixin,
    rulesMixin,
    datesMixin,
    userMixin,
    menuCompMixing,
    personMixin,
    scrollMixin,
    generateCodeMixin
} from '../../mixin'
export default {
    mixins: [
        dataGeneralComponetsMixin,
        responseServerMixin,
        rulesMixin,
        datesMixin,
        userMixin,
        menuCompMixing,
        personMixin,
        scrollMixin,
        generateCodeMixin
    ],
    components: {
        MLineHeaderComp,
        SCardPersonComp,
        OLoaderDataComp,
        OSmsErrorComp,
        OSearchComp,
        OSmsActionComp,
        SPaginationComp,
        ODialogHeaderComp,
        MNoDataComp,
        STableAffiliationsComp,
        OBtnHeaderToolTipComp,
        MTextFieldComp,
        MBtnNormalComp,
        MSelectComp,
        ODateOneComp,
        OSmsConfirmComp,
        OLoaderOperationComp,
        OInfoAffiliateComp
    },
    data: () => ({
        tab: 0,
        listPerson: [],
        dataPerson: new Person(),
        page: 0,
        last_page: 0,
        search: '',
        dialogDetail: false,
        person: {},
        viewTable: true,
        valid: true,
        dateOfBith: '',
        indexPerson: -1,
        idPerson: 0,
        total: 0,
        isChangeCv: false,
        isChangeCategory: false,
        dialogCategory: false,
        listCategory: [
            {title: 'Activos', value: 'A'},
            {title: 'Transeúntes', value: 'B'},
            {title: 'Adherentes', value: 'C'},
            {title: 'Universitario', value: 'U'}
        ],
        dataChangeCategory: {},
        dataChangeCode: {},
        isChangeCode: false,
        dialogNewCode: false
    }),
    computed: {
        searchPersonFilter: function() {
            if (this.m_rule_emptyField(this.search)) return ''
            else return this.search
        },
        listCategoryFilter: function() {
            if (this.person.category!=undefined) {
                return this.listCategory.filter(category => {
                    return category.value !== this.person.category
                })
            }
            return this.listCategory
        }
    },
    methods: {
        processError(error) {
            this.dialogLoaderOperation = false
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_action(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        getPersons(page, search) {
            this.listPerson = []
            this.dialogLoaderData = true
            Person.getPersons(page, search).then(response => {
                this.listPerson = response.data
                this.last_page = response.last_page
                this.page = response.page
                this.total = response.total
                this.dialogLoaderData = false
            }).catch(error => {
                this.processError(error)
            })
        },
        showEvent(message) {
            this.smsAction = message
            this.frameCorrect = true
            this.timeSucess = setTimeout(() => {
                this.frameCorrect = false
            }, 3000)
        },
        ac_searchPerson() {
            if (this.search===null) {
                this.search = ''
                this.getPersons(1, '')
            } else this.getPersons(1, this.search)
        },
        ac_changePage(state) {
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            if (state) { ++this.page }
            else { --this.page }
            setTimeout(() => {
                this.getPersons(this.page, this.search)
            }, velocity)
        },
        ac_showDetailPerson(person) {
            this.person = person
            this.dialogDetail = true
        },
        ac_getEdad() {
            var currentDate = moment(this.m_date_systemDate(false))
            var date_of_birth = moment(this.person.date_of_birth)
            if (this.person.full_name == undefined) return ''
            else {
                return currentDate.diff(date_of_birth, 'year') + ' Años'
            }
        },
        ac_electionDateExpense(e) {
            this.dateOfBith = this.m_date_formatDate(e)
            this.dataPerson.date_of_birth = e
        },
        ac_showFormUpdate(person) {
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            this.indexPerson = this.listPerson.indexOf(person)
            this.dataPerson = Object.assign({}, person)
            this.dateOfBith = this.m_date_formatDate(this.dataPerson.date_of_birth)
            setTimeout(() => {
                this.tab = 1
            }, velocity)
        },
        validateDataAffiliate() {
            if (!this.$refs.formaffiliate.validate()) return false
            return true
        },
        ac_closeSmsError() {
            if (this.noAccess) {
                localStorage.removeItem('abis')
                this.$router.replace('/login')
            } else {
                this.idPerson = 0
                this.isChangeCv = false
                this.dialogError = false
                this.dialogConfirm = false
                this.isChangeCode = false
                if (this.isChangeCategory) this.dialogCategory = true
            }
        },
        ac_confirmUpdateAffiliate() {
            if (this.validateDataAffiliate()) {
                var velocity = this.m_scroll_velocity(window.scrollY)
                this.m_scroll_animation(velocity)
                this.smsConfirm = ['¿Está seguro de actualizar los datos de:', `${this.dataPerson.full_name}?`]
                setTimeout(() => {
                    this.dialogConfirm = true
                }, velocity)
            } else {
                this.smsError = ['Corrija los errores marcados con rojo porfavor']
                this.dialogError = true
                this.actionError = true
            }
        },
        ac_backListAffiliate() {
            this.dataPerson = new Person()
            this.tab = 0
        },
        showEvent(message) {
            this.smsAction = message
            this.frameCorrect = true
            this.timeSucess = setTimeout(() => {
                this.frameCorrect = false
            }, 3000)
        },
        updatePerson() {
            Person.updatePerson(this.dataPerson).then(response => {
                Object.assign(this.listPerson[this.indexPerson], response)
                this.dialogLoaderOperation = false
                this.showEvent(['Operación Exitosa',
                        `Se actualizó los datos`])
                this.tab = 0
            }).catch(error => {
                this.processError(error)
            })
        },
        changeStateCv() {
            Person.resetStateCv({id: this.idPerson}).then(() => {
                this.listPerson[this.indexPerson].admin_public = 0
                this.isChangeCv = false
                this.dialogLoaderOperation = false
                this.indexPerson = -1
                this.idPerson = 0
                this.showEvent(['Operación Exitosa',
                        `Se habilitó la actualización de CV`])
            }).catch(error => {
                this.processError(error)
            })
        },
        changeCategory() {
            Person.changeCategory(this.dataChangeCategory).then(() => {
                this.listPerson[this.indexPerson].category = this.dataChangeCategory.category
                this.listPerson[this.indexPerson].identifier = this.dataChangeCategory.identifier
                this.dialogLoaderOperation = false
                this.isChangeCategory = false
                this.showEvent(['Operación Exitosa',
                        `Se actualizó la categoría`])
            }).catch(error => {
                this.processError(error)
            })
        },
        downloadNewPdf() {
            const title_pdf = this.dataChangeCode.name.replace(/ /g, "_")+'.pdf'
            const interested = 'Afiliado: '+this.dataChangeCode.name
            const ci = 'CI: '+this.dataChangeCode.ci+' '+this.dataChangeCode.issued_in+'.'
            const identifier = 'N° Registro SIB Ch.: '+this.dataChangeCode.identifier
            const code = 'Código: '+this.dataChangeCode.code
            const doc = new jsPDF('p','mm','letter');
            doc.setFontSize(16)
            doc.text("CAMBIO DE CÓDIGO", 19, 20)
            var img = new Image();
            img.src = '/imgs/logo/logo.png';
            doc.addImage(img, 'JPEG', 140, 10, 60, 50);
            doc.setFontSize(13)
            doc.text(interested, 19, 35)
            doc.text(ci, 19, 45)
            doc.text(identifier, 19, 55)
            doc.text(code, 19, 65)
            doc.save(title_pdf);
        },
        changeCodeAffiliate() {
            Person.changeCode({id: this.dataChangeCode.id, code: this.dataChangeCode.code_encript}).then(() => {
                this.downloadNewPdf()
                this.dialogLoaderOperation = false
                this.dialogNewCode = true
                this.showEvent(['Operación Exitosa',
                        `Se actualizó el código`])
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_perfomOperation() {
            this.dialogConfirm = false
            this.dialogLoaderOperation = true
            if (this.isChangeCode) {
                this.changeCodeAffiliate()
            } else {
                if (this.isChangeCategory) this.changeCategory()
                else {
                    if (this.isChangeCv) this.changeStateCv()
                    else this.updatePerson()
                }
            }
        },
        ac_confirmChangeCv(person) {
            this.idPerson = person.id
            this.isChangeCv = true
            this.indexPerson = this.listPerson.indexOf(person)
            this.smsConfirm = [`¿Está seguro de permitir a:
                ${person.full_name}, actualizar su CV?`]
            this.dialogConfirm = true
        },
        ac_showDialogCategory(person) {
            this.isChangeCategory = true
            this.person = person
            this.indexPerson = this.listPerson.indexOf(person)
            this.dialogCategory = true
        },
        ac_closeDialogCategories() {
            this.isChangeCategory = false
            this.dialogCategory = false
        },
        ac_electionCategory(category) {
            const categoryOld = this.person.category
            const newIdentifier = this.person.identifier.replace(categoryOld, category.value)
            this.dataChangeCategory = {
                id: this.person.id,
                category: category.value,
                identifier: newIdentifier
            }
            this.dialogCategory = false
            this.smsConfirm = ['¿Está seguro(a) de cambiar la categoría de:',
                `${this.person.full_name} a ${category.title}?`]
            this.dialogConfirm = true
        },
        ac_changeCodePerson(person) {
            this.isChangeCode = true
            var letters = ['b','c','d','e','f','g','h','j','m','n','p','r','s','t','u','v','w','x','y','z','1','2','3','4','5','6','7','8','9']
            var code_random = ''
            for(var i = 0; i < 4; i++) {
                code_random += letters[Math.floor(Math.random() * letters.length)]+''
            }
            this.dataChangeCode = {
                id: person.id,
                code_encript: this.m_code_encript(code_random),
                code: code_random,
                name: person.full_name+' '+person.last_name,
                ci: person.ci,
                issued_in: person.issued_in,
                identifier: person.identifier
            }
            this.smsConfirm = [`¿Está seguro de cambiar el código de acceso de: ${person.full_name} ${person.last_name}?`]
            this.dialogConfirm = true
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.viewTable = false
        if (this.m_user_saveToken()) {
            this.getPersons(1, '')
        } else this.$router.replace('/login')
    }
}
</script>