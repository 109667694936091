<template>
    <div class="card-person card-person--normal-size animation__card--opacity">
        <text-highlight
            class="text-body-private-title"
            :queries="searchUser"
        >
            {{user.name}}
        </text-highlight>
        <!--<h1 class="text-body-private-title">{{user.name}}</h1>-->
        <v-divider></v-divider>
        <div class="d-flex">
            <h1 class="text-body-private-title mr-1">CI:</h1>
            <h1 class="text-body-private-subtitle">
                {{`${user.ci} ${user.issued_in}`}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-private-title mr-1">Username:</h1>
            <h1 class="text-body-private-subtitle">
                {{`${user.username}`}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-private-title mr-1">Categoría:</h1>
            <h1 class="text-body-private-subtitle">
                {{m_user_nameCategory(user.category)}}</h1>
        </div>
        <div class="d-flex align-center">
            <h1 class="text-body-private-title mr-1">Estado:</h1>
            <h1 class="text-body-private-subtitle mr-1">
                {{user.enabled==0 ? 'Inactivo' : 'Activo'}}</h1>
            <div :class="m_user_stateOrnament(user.enabled)"></div>
        </div>
        <div class="d-flex">
            <h1 class="text-body-private-title mr-1">Fecha Registro:</h1>
            <h1 class="text-body-private-subtitle">
                {{user.date}}</h1>
        </div>
        <v-divider></v-divider>
        <div class="d-flex justify-center mt-2">
            <OBtnOptionToolTipComp
                smsTooltip="Cambiar contraseña"
                icon="mdi-account-lock-open"
                class="mr-1"
                @click="$emit('clickcredentials')"
            />
            <OBtnOptionToolTipComp
                :smsTooltip="user.enabled==0 ? 'Habilitar Usuario' : 'Inhabilitar Usuario'"
                :icon="user.enabled==0 ? 'mdi-eye' : 'mdi-eye-off'"
                @click="$emit('clickstate')"
            />
            <OBtnOptionToolTipComp
                smsTooltip="Cambiar categoría"
                icon="mdi-pencil"
                class="ml-1"
                @click="$emit('clickupdate', user)"
            />
        </div>
    </div>
</template>
<script>
import { OBtnOptionToolTipComp } from '../../components/organisms'
import TextHighlight from 'vue-text-highlight'
import { userMixin } from '../../mixin'
export default {
    mixins: [ userMixin ],
    props: {
        user: Object,
        searchUser: String
    },
    components: {
        OBtnOptionToolTipComp,
        TextHighlight
    }
}
</script>