<template>
    <div>
        <div class="about__background">
            <v-img
                :src="imgBackground"
                gradient="to top right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)"
                width="100%"
                height="100%"
            >
                <OCardAnimationLogoComp />
            </v-img>
        </div>
        <div class="d-flex justify-center space-top-two mt-2">
            <h1 class="text-center text-body-big">REQUISITOS PARA AFILIACIÓN</h1>
        </div>
        <div class="d-flex justify-center mt-4">
            <div class="home__first membership__request">
                <div>
                    <div class="membership__request-img">
                        <v-img
                            src="/imgs/public/certificate/qr_bank.jpg"
                            width="100%"
                            height="100%"
                        ></v-img>
                    </div>
                    <div class="membership__request-title-qr">
                        <h1 class="text-body-private-title-inverse text-center">Código QR cuenta ABIS Ch.</h1>
                    </div>
                </div>
                <div class="membership__request-info">
                    <div
                        class="membership__request-fill mb-2"
                        v-for="(request, index) in listRequest"
                        :key="index"
                    >
                        <div>
                            <div class="membership__request-fill-number d-flex justify-center align-center">
                                <h1 class="text-body-big-inverse">{{(index+1)}}</h1>
                            </div>
                        </div>
                        <div class="membership__request-fill-text d-flex align-center">
                            <h1 class="text-body-subtitle">
                                {{request.title}}
                            </h1>
                        </div>
                        <v-divider vertical class="membership__divider-info ma-2"></v-divider>
                        <div class="membership__request-fill-icon d-flex align-center">
                            <v-icon color="#2196F3" class="membership__request--icon">
                                {{request.icon}}
                            </v-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="space-top">
            <div class="d-flex justify-center space-top space-bottom" style="background: #17191b">
                <div class="home__first">
                    <h1 class="text-body-big-inverse text-center">RECOMENDACIONES PARA EL PROCESO DE AFILIACIÓN</h1>
                    <v-divider dark class="mt-2 mb-2"></v-divider>
                    <div class="membership__recomendations">
                        <div
                            class="d-flex mt-1"
                            v-for="(recommendation, index) in listRecommendations"
                            :key="index"
                        >
                            <div class="mt-1 membership__ornament-circle-inverse"></div>
                            <h1 class="ml-2 text-body-subtitle-inverse">
                                {{recommendation}}
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-center space-top">
            <div class="home__first membership__qualities-title">
                <h1 class="text-body-big">BENEFICIOS DE AFILIACIÓN</h1>
                <div class="home__third-ornament-line mb-2 mt-2"></div>
            </div>
        </div>
        <div class="d-flex justify-center flex-wrap membership__qualities">
            <OCardQualitieComp
                v-for="(qualitie, index) in listQualities"
                :key="index"
                :index="index"
                :qualitie="qualitie"
            />
        </div>
        <div class="d-flex justify-center space-top">
            <div class="home__first membership__qualities-title">
                <h1 class="text-body-big">TIPOS DE AFILIACIÓN</h1>
                <div class="home__third-ornament-line mb-2 mt-2"></div>
            </div>
        </div>
        <div class="d-flex justify-center flex-wrap membership__pillars">
            <OCardPillarComp
                v-for="(pillar, index) in listPillars"
                :key="index"
                :index="index"
                :pillar="pillar"
            />
        </div>
        <div class="d-flex justify-center space-top-two space-bottom">
            <div class="membership__options">
                <OCardOptionComp
                    title="AFILIATE"
                    nameBtn="LLENAR FORMULARIO"
                    subtitle="Forma parte de nuestro equipo de trabajo cooperativo"
                    :index="0"
                    srcImg="/imgs/public/icons/icon_2_b.png"
                    @click="ac_goFormAffiliate()"
                />
                <OCardOptionComp
                    title="TRÁMITE"
                    nameBtn="VERIFICAR TRÁMITE"
                    :index="1"
                    srcImg="/imgs/public/icons/icon_5_b.png"
                    subtitle="Forma parte de nuestro equipo de trabajo cooperativo"
                    @click="ac_goVerifyProcess()"
                />
            </div>
        </div>
    </div>
</template>
<script>
import {
    OCardPillarComp,
    OCardQualitieComp,
    OCardOptionComp,
    OCardAnimationLogoComp
} from '../../components/organisms'
import { scrollMixin } from '../../mixin'
export default {
    metaInfo() {
        const title = `ABIS Ch. - Afiliación`
        return {
            title: title
        }
    },
    mixins: [ scrollMixin ],
    components: {
        OCardPillarComp,
        OCardQualitieComp,
        OCardOptionComp,
        OCardAnimationLogoComp
    },
    data: () => ({
        imgBackground: '/imgs/public/directory/membership.jpg',
        listRequest:[
            {
                title: `Carnet de identidad (imagen jpg o archivo pdf del anverso y reverso del documento
                    con límite máximo de peso de 2MB).`,
                icon: 'mdi-card-account-details-outline'
            },{
                title: `Título en provisión nacional (imagen jpg o archivo pdf del anverso y reverso del
                    documento con límite máximo de peso de 2MB).`,
                icon: 'mdi-badge-account-outline'
            },{
                title: `Título académico (imagen jpg o archivo pdf del anverso y reverso del documento con
                    límite máximo de peso de 2MB).`,
                icon: 'mdi-badge-account-outline'
            },{
                title: `Fotografía (4x4 cm) en formato jpg (límite máximo de peso de 2MB), en fondo azul marino.`,
                icon: 'mdi-account-tie-outline'
            },{
                title: `Comprobante del depósito bancario en formato jpg o archivo pdf (límite máximo de
                    peso de 2MB), por concepto de matrícula de afiliación a ABIS Chuquisaca.
                    El monto que debe ser depositado para la matrícula es de 200 Bs, a la cuenta de
                    Banco Unión N°: 10000044086672 a nombre del Ing. Arlid Morales Cueto (Presidente)
                    y la Ing. Gabriela Gardeazabal Torrez (Tesorera).`,
                icon: 'mdi-cash'
            },{
                title: `Una vez que usted cuente con los requisitos descritos anteriormente, debe proceder
                    con el llenado del formulario de afiliación.`,
                icon: 'mdi-file-sign'
            }
        ],
        listRecommendations: [
            `Todos los datos que ingrese deben ser revisados antes de enviar su formulario de solicitud.`,
            `Los documentos solicitados como requisitos de afiliación, deben ser de buena calidad
            de tal forma que la información sea legible para su revisión.`,
            `No salga de la página mientras no aparezca un mensaje de enviado.`,
            `Al finalizar el trámite descargue el certificado de afiliación en formato pdf, como
            respaldo para su persona.`
        ],
        listQualities: [
            {
                icon: "mdi-human-capacity-decrease",
                title: "DIRECTORIO DE AFILIADOS",
                subtitle: `Como socio de ABIS - CH, formará parte de una base de datos actualizada
                    de afiliados, instituciones gubernamentales, organizaciones nacionales e
                    internacionales vinculadas al sector de agua y saneamiento.`
            },{
                icon: "mdi-account-hard-hat",
                title: "BANCO DE OFERTAS LABORALES",
                subtitle: `Como socio de ABIS - CH, estará informado en tiempo real sobre diferentes
                    ofertas laborales relacionadas con el sector agua y medio ambiente, podrá ser
                    contactado para estas ofertas y otras actividades afines al rubro.`
            },{
                icon: "mdi-book-open-page-variant-outline",
                title: "OPORTUNIDADES ACADÉMICAS",
                subtitle: `Al ser parte de ABIS - CH, estará formando parte también de ABIS
                    a nivel nacional, AIDIS a nivel internacional y podrá optar por opciones
                    de becas a cursos de especialización en el área.`
            }
        ],
        listPillars: [
            {
                title: "ACTIVOS",
                subtitle: `Los profesionales a nivel licenciatura o técnico superior: Ingenieros sanitarios o
                    Ingenieros civiles con mención en ingeniería sanitaria y/o hidráulica o dedicados
                    con su actividad a acciones inherentes a la ingeniería sanitaria y ambiental
                    y sus campos de aplicación; que estén registrados en la Sociedad de Ingenieros
                    de Bolivia - SIB.`,
                subtitle_2: ``,
                icon: "mdi-human-male-board-poll"
            },{
                title: "TRANSEÚNTES",
                subtitle: `Los socios con categoría ACTIVOS registrados en cualquier filial de ABIS,
                    cuyo lugar de residencia haya cambiado temporalmente a otro departamento,
                    donde pretende ejercer su actividad profesional.`,
                subtitle_2: `Pasado el tiempo máximo de
                    1 año en la categoría de TRANSEÚNTE, el socio debe inscribirse como socio ACTIVO
                    en la filial de ABIS respectiva.`,
                icon: "mdi-human-male-board"
            },{
                title: "ADHERENTES",
                subtitle: `Cualquier profesional de nivel licenciatura o técnico superior, cuyas actividades estén
                    relacionadas con la ingeniería sanitaria y ambiental o la enseñanza de las
                    disciplinas del saneamiento y control del medio ambiente.`,
                subtitle_2: ``,
                icon: "mdi-account-tie"
            }
        ],
    }),
    methods: {
        ac_goVerifyProcess() {
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            setTimeout(() => {
                this.$router.push({name: 'verify-process'})
            }, velocity)
        },
        ac_goFormAffiliate() {
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            setTimeout(() => {
                this.$router.push({name: 'form-affiliate'})
            }, velocity)
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.imgBackground = '/imgs/public/directory/membership_mobile.jpg'
    }
}
</script>