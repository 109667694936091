import axios from "axios"
import { apiUrls } from '../plugins/apiUrls'
export default class Other {
    constructor (
        profile = null,
        linkedin = null,
        facebook = null
    ) {
        this.profile = profile
        this.linkedin = linkedin
        this.facebook = facebook
    }
    static addOther(parameters) {
        return axios.post(
            apiUrls.OTHER + '/add',
            parameters
        ).then(response => {
            return response.data
        })
    }
    static updateOther(parameters) {
        return axios.post(
            apiUrls.OTHER + '/update',
            parameters
        ).then(response => {
            return response.data
        })
    }
}