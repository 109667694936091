<template>
    <div class="o-card-pillar-academic">
        <div class="d-flex justify-center">
            <div class="o-card-pillar-academic__icon d-flex justify-center align-center">
                <div class="o-card-pillar-academic__icon-container d-flex justify-center">
                    <v-icon class="o-card-pillar-academic__icon-animate-rotate" color="#ffffffb7" style="font-size:40px">{{pillar.icon}}</v-icon>
                </div>
            </div>
        </div>
        <div class="o-card-pillar-academic__title-animate-o">
            <h1 class="text-body-supertitle text-center mt-2">{{pillar.title}}</h1>
            <h1 class="text-body-subtitle mt-2" v-intersect="onIntersect">{{pillar.subtitle}}</h1>
            <h1 class="text-body-subtitle mt-2">{{pillar.subtitle_2}}</h1>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        pillar: {},
        index: Number
    },
    data: () => ({
        activateAnimation: false
    }),
    methods: {
        onIntersect (entries, observer) {
            if (!this.activateAnimation && entries[0].isIntersecting && window.innerWidth > 600) {
                this.activateAnimation = true
                var effectTitle =  document.getElementsByClassName('o-card-pillar-academic__title-animate-o')
                effectTitle[this.index].style.opacity = 1

                var effectIcon =  document.getElementsByClassName('o-card-pillar-academic__icon-animate-rotate')
                effectIcon[this.index].style.opacity = 1
                effectIcon[this.index].style.transform = 'rotate(0deg)'
            }
        },
        noCellAnimation() {
            var effectTitle =  document.getElementsByClassName('o-card-pillar-academic__title-animate-o')
            effectTitle[this.index].style.opacity = 1

            var effectIcon =  document.getElementsByClassName('o-card-pillar-academic__icon-animate-rotate')
            effectIcon[this.index].style.opacity = 1
            effectIcon[this.index].style.transform = 'rotate(0deg)'
        }
    },
    mounted() {
        if (window.innerWidth <= 600) { this.noCellAnimation() }
    }
}
</script>