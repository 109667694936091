<template>
    <v-textarea
        :label="label"
        :single-line="singleLine"
        :counter="counter"
        outlined
        :hide-details="hideDetails"
        :rules="rules"
        :value="value"
        :height="height"
        @input="(e)=>(this.$emit('input', e))"
        :clearable="true"
    ></v-textarea>
</template>
<script>
export default {
    model: {
        prop: 'value',
        event: 'input'
    },
    props: {
        label: String,
        singleLine: Boolean,
        hideDetails: Boolean,
        value: [Number, String],
        rules: Array,
        counter: Number,
        height: String
    }
}
</script>