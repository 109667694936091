import axios from 'axios'
import { apiUrls } from '../plugins/apiUrls'
import { userStore } from '../store/modules'
export default class Person{
    constructor(
        full_name = null,
        last_name = null,
        ci = null,
        issued_in = null,
        gender = null,
        date_of_birth = null,
        phone = null,
        address = null,
        code_sib = null,
        route_face = null,
        code_qr = null,
        generate_code = null,
        identifier = null,
        category = null,
        date_of_expiry = null
    ){
       this.full_name = full_name
       this.last_name = last_name
       this.ci = ci
       this.issued_in = issued_in
       this.gender = gender
       this.date_of_birth = date_of_birth
       this.phone = phone
       this.address = address
       this.code_sib = code_sib
       this.route_face = route_face
       this.code_qr = code_qr
       this.generate_code = generate_code
       this.identifier = identifier
       this.category = category
       this.date_of_expiry = date_of_expiry
    }
    static addAffiliate(parameters) {
        return axios.post(apiUrls.PERSON + '/add',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static addAffiliateUniversity(parameters) {
        return axios.post(apiUrls.PERSON + '/add-university',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static getPersons(page, search) {
        return axios.get(
            apiUrls.PERSON + '/list?page='+page+'&search='+search,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static getIntoPerson(credentials) {
        return axios.post(apiUrls.PERSON + '/get-into', credentials).then(response => {
            return response.data
        })
    }
    static updatePerson(parameters) {
        return axios.put(
            apiUrls.PERSON + '/update',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static getExpiryAffiliate(page, search) {
        return axios.get(
            apiUrls.PERSON + '/list-expired?page='+page+'&search='+search,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static getListDisabled(page, search) {
        return axios.get(
            apiUrls.PERSON + '/list-disabled?page='+page+'&search='+search,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static getDataPerson(code_qr) {
        return axios.get(
            apiUrls.PERSON + '/get-data-person?code='+code_qr
        ).then(response => {
            return response.data
        })
    }
    static getListCv(ci, code) {
        return axios.get(
            apiUrls.PERSON + '/data-cv?code='+code+'&ci='+ci
        ).then(response => {
            return response.data
        })
    }
    static showPdfPc(route_pdf) {
        const pdf = `${apiUrls.PERSON}/show-pdf?route_pdf=${route_pdf}`
        return pdf
    }
    static showPdfMobile(route_pdf) {
        window.open(`${apiUrls.PERSON}/show-pdf?route_pdf=${route_pdf}`, '_blank')
    }
    static changeState(parameters) {
        return axios.put(
            apiUrls.PERSON + '/change-state',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static downloadImage(route_face) {
        window.open(`${apiUrls.PERSON}/download-face?route_face=${route_face}`, '_blank')
    }
    static resetStateCv(parameters) {
        return axios.put(
            apiUrls.PERSON + '/reset-state-cv',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static changeCategory(parameters) {
        return axios.put(
            apiUrls.PERSON + '/change-category',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static getPersonsCv(page, search) {
        return axios.get(
            apiUrls.PERSON + '/list-cv?page='+page+'&search='+search,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static changeCode(parameters) {
        return axios.put(
            apiUrls.PERSON + '/change-code',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static getListAffiliates(page, search, order, type_order, option_search) {
        return axios.get(
            `${apiUrls.PERSON}/affiliates-list?page=${page}&search=${search}
            &order=${order}&type_order=${type_order}&option_search=${option_search}`
        ).then(response => {
            return response.data
        })
    }
    static getListTotal() {
        return axios.get(apiUrls.PERSON+'/list-total',
            { headers: userStore.state.header }).then(response => {
            return response.data
        })
    }
}