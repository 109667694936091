<template>
    <div class="card-person card-person--normal-size animation__card--opacity">
        <text-highlight
            class="text-body-private-subtitle"
            :queries="search"
        >
            {{process.person.full_name+' '+process.person.last_name}}
        </text-highlight>
        <v-divider></v-divider>
        <div class="d-flex">
            <h1 class="text-body-private-title mr-1">CI:</h1>
            <text-highlight
                class="text-body-private-subtitle"
                :queries="search"
            >
                {{process.person.ci}}
            </text-highlight>
        </div>
        <div class="d-flex">
            <h1 class="text-body-private-title mr-1">Inicio de Solicitud:</h1>
            <h1 class="text-body-private-subtitle">
                {{process.date}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-private-title mr-1">Estado:</h1>
            <h1 class="text-body-private-subtitle">
                {{process.state_process=='s' ? 'Enviado' : 'Observado'}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-private-title mr-1">Estado de Cuenta:</h1>
            <h1 class="text-body-private-subtitle">
                {{process.person.is_expired==0 ? 'Al día' : 'Con deuda'}}</h1>
        </div>
        <v-divider></v-divider>
        <div class="d-flex justify-center mt-2">
            <OBtnOptionToolTipComp
                smsTooltip="Eliminar solicitud"
                icon="mdi-trash-can"
                class="ma-1"
                @click="$emit('clickdelete')"
            />
            <OBtnOptionToolTipComp
                smsTooltip="Revisar Solicitud"
                icon="mdi-file-send"
                class="ma-1"
                @click="$emit('clickadd')"
            />
        </div>
    </div>
</template>
<script>
import { OBtnOptionToolTipComp } from '../../components/organisms'
import TextHighlight from 'vue-text-highlight'
export default {
    props: {
        process: Object,
        search: String
    },
    components: {
        OBtnOptionToolTipComp,
        TextHighlight
    }
}
</script>