<template>
    <div>
        <div class="user__header pa-2">
            <div class="d-flex justify-center align-center">
                <v-app-bar-nav-icon class="mr-2 user__icon-menu" @click="m_menuComp_changeStateMenu()"></v-app-bar-nav-icon>
                <h1 class="text-body-private-title">MI PERFIL</h1>
            </div>
        </div>
        <MLineHeaderComp />
        <div v-if="dataProfile.id!=undefined" class="pa-2 animation__card--opacity">
            <div class="d-flex">
                <h1 class="text-body-private-title mr-1">Nombre Completo:</h1>
                <h1 class="text-body-private-subtitle">{{dataProfile.person.full_name}}</h1>
            </div>
            <div class="d-flex">
                <h1 class="text-body-private-title mr-1">CI:</h1>
                <h1 class="text-body-private-subtitle">
                    {{dataProfile.person.ci+' '+dataProfile.person.issued_in}}</h1>
            </div>
            <div class="d-flex">
                <h1 class="text-body-private-title mr-1">N° Registro SIB Ch.:</h1>
                <h1 class="text-body-private-subtitle">
                    {{dataProfile.person.identifier}}</h1>
            </div>
            <div class="d-flex">
                <h1 class="text-body-private-title mr-1">Rol:</h1>
                <h1 class="text-body-private-subtitle">
                    {{m_user_nameCategory(dataProfile.category)}}</h1>
            </div>
            <div class="d-flex">
                <h1 class="text-body-private-title mr-1">Username:</h1>
                <h1 class="text-body-private-subtitle">
                    {{dataProfile.username}}</h1>
            </div>
            <MBtnNormalComp
                color="primary"
                :outlined="true"
                class="mt-2"
                @click="ac_openForm()"
            >Cambiar Credenciales</MBtnNormalComp>
        </div>
        <OLoaderDataComp
            class="mt-2"
            v-if="dialogLoaderData"
            smsLoaderData="Obteniendo Perfil"
        />
        <v-dialog
            v-model="dialogPassword"
            persistent
            width="450"
        >
            <ODialogHeaderComp
                title="Cambiar Contraseña"
                @clickclose="ac_closeFormNewPassword()"
            />
            <v-card class="ma-0 pa-2">
                <SFormPasswordComp
                    ref="containerformpass"
                    :isMyProfile="true"
                    :dataUser="dataUserChangePass"
                    @clickchange="ac_confirmChangePass"
                />
            </v-card>
        </v-dialog>

        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_closeSmsError()"
        />
        <OSmsConfirmComp
            :dialog="dialogConfirm"
            :smsConfirm="smsConfirm"
            @clickno="ac_closeSmsError()"
            @clickyes="ac_perfomOperation()"
        />
        <OLoaderOperationComp
            :dialog="dialogLoaderOperation"
            smsLoaderOperation="Realizando Operación"
        />
        <SmsCongratulationsComp
            :dialog="dialogRedirect"
            smsCongratulations="Sus credenciales fueron actualizados, presione en el botón aceptar
                para ser redirigido al login e ingresar con sus nuevas credenciales"
            title="Operación Correcta"
            btnSms="ACEPTAR"
            :outCancel="true"
            @click="ac_redirectLogin()"
        />
    </div>
</template>
<script>
import {
    MLineHeaderComp,
    MBtnNormalComp
} from '../../components/molecules'
import {
    OSmsErrorComp,
    OSmsConfirmComp,
    OSmsActionComp,
    OLoaderOperationComp,
    OLoaderDataComp,
    ODialogHeaderComp,
    SmsCongratulationsComp
} from '../../components/organisms'
import {
    SFormPasswordComp
} from '../../components/species'
import {
    dataGeneralComponetsMixin,
    rulesMixin,
    responseServerMixin,
    menuCompMixing,
    userMixin
} from '../../mixin'
import { User } from '../../models'
import md5 from 'js-md5'
export default {
    mixins: [
        dataGeneralComponetsMixin,
        rulesMixin,
        responseServerMixin,
        menuCompMixing,
        userMixin
    ],
    components: {
        OSmsErrorComp,
        OSmsConfirmComp,
        OSmsActionComp,
        OLoaderOperationComp,
        MLineHeaderComp,
        OLoaderDataComp,
        MBtnNormalComp,
        ODialogHeaderComp,
        SFormPasswordComp,
        SmsCongratulationsComp
    },
    data: () => ({
        dataProfile: {},
        dialogPassword: false,
        dataUserChangePass: new User(),
        dialogRedirect: false
    }),
    methods: {
        processError(error) {
            this.dialogLoaderOperation = false
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_action(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        ac_closeSmsError() {
            if (this.noAccess) {
                localStorage.removeItem('abis')
                this.$router.replace('/login')
            } else {
                this.dialogError = false
                this.dialogConfirm = false
                this.dialogPassword = true
            }
        },
        getMyData() {
            this.dialogLoaderData = true
            User.getData().then(response => {
                this.dataProfile = response
                this.dialogLoaderData = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_closeFormNewPassword() {
            this.dataUserChangePass = new User()
            this.$refs.containerformpass.resetForm()
            this.dialogPassword = false
        },
        ac_confirmChangePass(e) {
            this.dialogPassword = false
            if (e) {
                this.smsConfirm = ['¿Está seguro de cambiar su contraseña?']
                this.dialogConfirm = true
            } else {
                this.smsError = ['Corrija los datos marcados con rojo porfavor']
                this.dialogError = true
                this.actionError = true
            }
        },
        ac_openForm() {
            this.dataUserChangePass.username = this.dataProfile.username
            this.dialogPassword = true
        },
        ac_perfomOperation() {
            this.dialogConfirm = false
            this.dialogLoaderOperation = true
            const parameters = {
                username: this.dataUserChangePass.username,
                password: md5(this.dataUserChangePass.password),
                id: this.dataProfile.id
            }
            User.changeCredentials(parameters).then(() => {
                localStorage.removeItem('abis')
                this.dialogLoaderOperation = false
                this.dialogRedirect = true
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_redirectLogin() {
            this.$router.push('/login')
        }
    },
    mounted() {
        if (this.m_user_saveToken()) {
            this.getMyData()
        } else this.$router.replace('/login')
    }
}
</script>