<template>
    <div
        :class="isError ? 'o-photo o-photo__error' : 'o-photo o-photo__correct'"
        @click="$emit('clickPhoto')"
        :style="imageOnly ? '' : 'cursor:pointer'"
    >
        <div class="o-photo__picture d-flex align-center">
            <div
                class="d-flex align-center"
                style="background:white; width:100%; height:100%;"
            >
                <v-img
                    v-if="getTypeFile(srcPreviewImage)"
                    :src="srcPreviewImage|imgMiniature"
                    width="100%"
                    height="100%"
                    contain
                ></v-img>
                <v-img
                    v-else
                    src="/imgs/private/pdf_cargado.jpg"
                    width="100%"
                    height="100%"
                    contain
                ></v-img>
            </div>
        </div>
    </div>
</template>
<script>
import { imgMiniature } from '../../filters'
export default {
    filters: {
        imgMiniature
    },
    props: {
        srcPreviewImage: String,
        isError: Boolean,
        imageOnly: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        getTypeFile(routeFile) {
            const dataRoute = routeFile.split(".")
            if (dataRoute[1] != 'pdf') return true
            return false
        }
    }
}
</script>