<template>
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
    >
        <template v-slot:activator="{ on }">
            <v-text-field
                :dense="dense"
                outlined
                :value="value"
                @input="(e)=>(this.$emit('input', e))"
                :label="label"
                :rules="rules"
                :disabled="disabled"
                :hide-details="hideDetails"
                readonly
                v-on="on"
            ></v-text-field>
        </template>
        <v-date-picker
            color="#21409A"
            @change="$emit('change', date)"
            locale="es-mx"
            v-model="date"
            @input="menu = false"
        ></v-date-picker>
    </v-menu>
</template>
<script>
export default {
    model: {
        prop: 'value',
        event: 'input'
    },
    props: {
        value: [Number, String],
        rules: Array,
        hideDetails: Boolean,
        label: String,
        disabled: Boolean,
        dense: Boolean
    },
    data: () => ({
        menu: false,
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    })
}
</script>
