<template>
    <div class="pb-4">
        <div class="d-flex justify-center" style="height:100%">
            <div class="home__first form__contianer">
                <div style="width:100%">
                    <v-tabs-items style="background:transparent !important;" v-model="tab" :touchless="true">
                        <v-tab-item>
                            <div>
                                <h1 class="text-body-big text-center">CORRECIÓN DE FORMULARIO</h1>
                            </div>
                            <div class="d-flex justify-center pa-2">
                                <SFormMembershipComp
                                    v-if="dataInterested.id_process != undefined"
                                    :dataInterested="dataInterested.interested"
                                    :dataObservation="dataObservation"
                                    :firstProcess="false"
                                    :mobile="mobile"
                                    @onClickForm="onClickForm"
                                    @clickImageBig="ac_showBigImage"
                                    titleForm="FORMULARIO DE AFILIACIÓN"
                                    @eventError="ac_showErrorBigFile"
                                />
                            </div>
                        </v-tab-item>
                        <v-tab-item>
                            <div class="d-flex justify-center">
                                <h1 class="text-center text-body-big">ENVÍO EXITOSO</h1>
                            </div>
                            <div class="d-flex justify-center mt-2">
                                <div class="home__first verify-process">
                                    <div class="verify-process__container">
                                        <div class="d-flex pb-1">
                                            <v-icon color="#51e933" class="mr-1">mdi-check-circle</v-icon>
                                            <h1 class="text-body-subtitle">ABIS Chuquisaca recibió correctamente el envio de su formulario.</h1>
                                        </div>
                                        <div class="d-flex pb-1">
                                            <v-icon color="#51e933" class="mr-1">mdi-check-circle</v-icon>
                                            <h1 class="text-body-subtitle">
                                                Realice el seguimiento a su tramite de afiliación ingresando en "VERIFICAR AFILIACIÓN"
                                            </h1>
                                        </div>
                                        <div class="d-flex justify-center mt-2 pb-2">
                                            <MBtnNormalComp
                                                color="primary"
                                                :to="{name: 'verify-process'}"
                                            >
                                                <v-icon color="white" class="mr-1">mdi-clipboard-clock</v-icon>
                                                VERIFICAR AFILIACIÓN
                                            </MBtnNormalComp>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </v-tab-item>
                    </v-tabs-items>
                </div>
            </div>
        </div>
        <OModalImgComp
            :dialog="modalImg"
            :src="srcImg"
            :title="titleImgBig"
            :isServer="isServer"
            @clickclose="modalImg=false"
        />
        <OModalPdfComp
            :dialog="dialogPdfPreview"
            :src="srcPdf"
            :title="titleImgBig"
            @clickclose="dialogPdfPreview=false"
        />
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="true"
            @click="dialogError=false"
        />
        <OLoaderOperationComp
            :dialog="dialogLoaderOperation"
            smsLoaderOperation="Enviando Solicitud"
        />
        <OSmsConfirmComp
            :dialog="dialogConfirm"
            :smsConfirm="smsConfirm"
            @clickno="dialogConfirm=false"
            @clickyes="ac_perfomOperation()"
        />
        <OSmsActionComp
            :frameCorrect="frameCorrect"
            :smsAction="smsAction"
            :timeout="timeSucess"
            colorMessage="error"
            @click="m_dataGeneral_frameTimeReset()"
        />
    </div>
</template>
<script>
import { MBtnNormalComp } from '../../components/molecules'
import {
    OSmsErrorComp,
    OLoaderOperationComp,
    OSmsConfirmComp,
    OModalImgComp,
    OModalPdfComp,
    OSmsActionComp
} from '../../components/organisms'
import { SFormMembershipComp } from '../../components/species'
import {
    dataGeneralComponetsMixin,
    rulesMixin,
    responseServerMixin,
    generateCodeMixin,
    scrollMixin
} from '../../mixin'
import {
    Observation,
    Interested,
    Person
} from '../../models'
export default {
    mixins: [
        dataGeneralComponetsMixin,
        rulesMixin,
        responseServerMixin,
        generateCodeMixin,
        scrollMixin
    ],
    components: {
        OSmsErrorComp,
        OLoaderOperationComp,
        OSmsConfirmComp,
        SFormMembershipComp,
        OModalImgComp,
        MBtnNormalComp,
        OModalPdfComp,
        OSmsActionComp
    },
    data: () => ({
        dataInterested: {},
        dataObservation: new Observation(),
        mobile: false,
        tab: 0,
        modalImg: false,
        srcImg: '',
        dataChangeImgs: {},
        titleImgBig: '',
        dialogPdfPreview: false,
        srcPdf: '',
        isServer: true
    }),
    methods: {
        processError(error) {
            this.dialogLoaderOperation = false
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_action(error)
            this.dialogError = true
        },
        onClickForm(e) {
            if (e.validForm) {
                var velocity = this.m_scroll_velocity(window.scrollY)
                this.m_scroll_animation(velocity)
                setTimeout(() => {
                    this.dataChangeImgs = e.dataCorrectionImgs
                    this.smsConfirm = [`¿Está seguro de enviar los datos corregidos:`,
                        `${this.dataInterested.interested.full_name}?`]
                    this.dialogConfirm = true
                }, velocity)
            } else {
                this.dialogError = true
                this.smsError = ['Corrija todos los datos marcados con rojo porfavor']
            }
        },
        generateChangesImgs() {
            var parametersImgs = {
                route_face: this.dataChangeImgs.route_face !== null ? 1 : 0,
                route_payment: this.dataChangeImgs.route_payment !== null ? 1 : 0,
                route_identity: this.dataChangeImgs.route_identity !== null ? 1 : 0,
                route_academy_a: this.dataChangeImgs.route_academy_a !== null ? 1 : 0,
                route_academy_r: this.dataChangeImgs.route_academy_r !== null ? 1 : 0,
                route_national_a: this.dataChangeImgs.route_national_a !== null ? 1 : 0,
                route_national_r: this.dataChangeImgs.route_national_r !== null ? 1 : 0,
                route_identity_r: this.dataChangeImgs.route_identity_r !== null ? 1 : 0
            }
            return parametersImgs
        },
        ac_perfomOperation() {
            const sendCredentials = {
                'ci': this.dataInterested.credentials.ci,
                'code': this.m_code_encript(this.dataInterested.credentials.code)
            }
            this.dialogConfirm = false
            this.dialogLoaderOperation = true
            var sendData = Object.assign({}, this.dataInterested.interested)
            sendData.fkprocess = this.dataInterested.id_process
            sendData.validated_date = null
            sendData.number_procedure = new Date().getTime()
            var parameters = {
                interested: sendData,
                valid_imgs: this.generateChangesImgs(),
                changeImg: Object.assign({}, this.dataChangeImgs),
                credentials: sendCredentials
            }
            Interested.correctObservation(this.$objectToFD(parameters)).then(() => {
                this.dialogLoaderOperation = false
                this.tab = 1
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_showBigImage(parameters) {
            this.titleImgBig = parameters.title
            if (parameters.imgFilter) {
                this.isServer = true
                const dataRoute = parameters.value.split(".")
                if (dataRoute[1] != 'pdf') {
                    this.srcImg = parameters.value
                    this.modalImg = true
                } else {
                    if (this.mobile) {
                        Person.showPdfMobile(parameters.value)
                    } else {
                        this.srcPdf = ''
                        this.srcPdf = Person.showPdfPc(parameters.value)
                        setTimeout(() => {
                            this.dialogPdfPreview = true
                        }, 200)
                    }
                }
            } else {
                this.isServer = false
                var file = parameters.value
                let reader = new FileReader()
                reader.onload = (file) =>{
                    if (parameters.value.type == 'application/pdf') {
                        if (this.mobile) {
                            this.showEvent(['La vista previa del PDF, solo es', 'para computadora'])
                        } else {
                            this.srcPdf = file.target.result
                            this.dialogPdfPreview = true
                        }
                    } else {
                        this.srcImg = file.target.result
                        this.modalImg = true
                    }
                }
                reader.readAsDataURL(file)
            }
        },
        showEvent(message) {
            this.smsAction = message
            this.frameCorrect = true
            this.timeSucess = setTimeout(() => {
                this.frameCorrect = false
            }, 3000)
        },
        ac_showErrorBigFile(smsError) {
            this.m_dataGeneral_frameTimeReset()
            setTimeout(() => {
                this.showEvent(smsError)
            }, 200)
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.mobile = true
        if (this.$route.params !== undefined && this.$route.params.id_process !== undefined) {
            this.dataInterested = this.$route.params
            this.dataObservation = Object.assign({}, this.dataInterested.interested.list_observations[0])
        } else {
            this.$router.replace({name: 'verify-process'})
        }
    }
}
</script>