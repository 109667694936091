import axios from 'axios'
import { apiUrls } from '../plugins/apiUrls'
import { userStore } from '../store/modules'

export default class User {
    constructor (
        id = null,
        username = null,
        password = null,
        repitpassword = null,
        category = null,
        name_person = '----',
        fkperson = null
    ) {
        this.id = id
        this.username = username
        this.password = password
        this.repitpassword = repitpassword
        this.category = category
        this.name_person = name_person
        this.fkperson = fkperson
    }
    static login(account) {
        return axios.post(apiUrls.USER + '/login', account).then(
            response => {
                return response.data
            }
        )
    }
    static logout(header) {
        return axios.post(
            apiUrls.USER + '/logout', {},
            { headers: header }
        ).then(
            response => {
                return response.data
            }
        )
    }
    static getListUser(idUser) {
        return axios.get(
            apiUrls.USER + '/list?id=' + idUser,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static createUser(parameters) {
        return axios.post(
            apiUrls.USER + '/create',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static changeCredentials(parameters) {
        return axios.put(
            apiUrls.USER + '/change-credentials',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static changeStateUser(parameters) {
        return axios.patch(
            apiUrls.USER + '/change-state',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static getData() {
        return axios.get(
            apiUrls.USER + '/my-data',
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static changeCategory(parameters) {
        return axios.put(
            apiUrls.USER + '/update',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
}