<template>
    <div>
        <div class="d-flex justify-center">
            <div class="home__first form__contianer space-bottom-two">
                <div style="width:100%">
                    <h1 class="text-body-big text-center mb-3 pt-2">
                        {{tab==0 ? 'INGRESAR' : 'INFORMACIÓN'}}
                    </h1>
                    <v-tabs-items style="background:transparent !important;" v-model="tab" :touchless="true">
                        <v-tab-item>
                            <OLoaderDataComp
                                class="mt-2"
                                v-if="dialogLoaderData"
                                smsLoaderData="Obteniendo sus Datos"
                            />
                        </v-tab-item>
                        <v-tab-item>
                            <div :class="ac_getClassAffiliate()">
                                <h1 class="text-body-private-subtitle text-center" v-if="dataPerson.expired!=0">
                                    ABIS Chuquisaca, confirma que <b>{{dataPerson.full_name+' '+dataPerson.last_name}}</b>, con CI: <b>{{dataPerson.ci}} {{dataPerson.issued_in}}.</b>
                                    , forma parte de nuestro equipo. Si requiere verificar los datos del afiliado, porfavor verifique la información
                                    de la página con los datos del Carnet de Identidad del afiliado. GRACIAS
                                </h1>
                                <h1 class="text-body-private-subtitle text-center" v-else>
                                    <b>{{dataPerson.full_name+' '+dataPerson.last_name}}</b>, con CI: <b>{{dataPerson.ci}}</b> <b>{{dataPerson.issued_in}}.</b> <b>No
                                        tiene la aprobación de ABIS Chuquisaca</b>, para utilizar el certificado, carnet o la información de la web
                                        para usos personales; por la expiración de acreditación.
                                </h1>
                            </div>
                            <div class="afiliado mt-2">
                                <div class="d-flex justify-center afiliado__photo">
                                    <OPhotoReviewComp
                                        :srcPreviewImage="dataPerson.route_face"
                                        :isError="false"
                                        :imageOnly="true"
                                    />
                                </div>
                                <div class="afiliado__info">
                                    <div class="d-flex pb-1">
                                        <h1 class="text-body-private-title mr-1">Afiliado:</h1>
                                        <h1 class="text-body-private-subtitle">
                                            {{dataPerson.full_name+' '+dataPerson.last_name}}</h1>
                                    </div>
                                    <div class="d-flex pb-1">
                                        <h1 class="text-body-private-title mr-1">Género:</h1>
                                        <h1 class="text-body-private-subtitle">
                                            {{dataPerson.gender=='h' ? 'Masculino' : 'Femenino'}}</h1>
                                    </div>
                                    <div class="d-flex pb-1">
                                        <h1 class="text-body-private-title mr-1">CI:</h1>
                                        <h1 class="text-body-private-subtitle">
                                            {{dataPerson.ci+' '+dataPerson.issued_in}}</h1>
                                    </div>
                                    <div class="d-flex pb-1">
                                        <h1 class="text-body-private-title mr-1">RNI:</h1>
                                        <h1 class="text-body-private-subtitle">
                                            {{dataPerson.code_sib!=null ? dataPerson.code_sib : 'S/RNI'}}</h1>
                                    </div>
                                    <div class="d-flex pb-1">
                                        <h1 class="text-body-private-title mr-1">N° Reg. ABIS Ch.:</h1>
                                        <h1 class="text-body-private-subtitle">{{dataPerson.identifier}}</h1>
                                    </div>
                                    <div class="d-flex pb-1">
                                        <h1 class="text-body-private-title mr-1">Celular:</h1>
                                        <h1 class="text-body-private-subtitle">{{dataPerson.phone}}</h1>
                                    </div>
                                    <div class="d-flex pb-1">
                                        <h1 class="text-body-private-title mr-1">Dirección:</h1>
                                        <h1 class="text-body-private-subtitle">{{dataPerson.address}}</h1>
                                    </div>
                                </div>
                            </div>
                        </v-tab-item>
                        <v-tab-item>
                            <div class="pa-2 verify-process__smsError">
                                <h1 class="text-body-private-subtitle text-center">
                                    La persona que trata de buscar no forma parte de ABIS Chuquisaca.
                                </h1>
                            </div>
                            <div class="pa-2 verify-process__smsError">
                                <h1 class="text-body-private-subtitle text-center">
                                    Para asegurarse, realice el escaneo Qr del certificado y del carnet de afiliación.
                                </h1>
                            </div>
                            <div class="pa-2 verify-process__smsError">
                                <h1 class="text-body-private-subtitle text-center">
                                    Además puede localizar al afiliado, mediante la opcion de búsqueda; colocando el número 
                                    de carnet de identidad (CI) en el campo de búsqueda y verificar sus datos.
                                </h1>
                            </div>
                            <div class="pa-2 verify-process__smsError">
                                <h1 class="text-body-private-subtitle text-center">
                                    Si realizado todas las opciones no puede verificar los datos del afiliado; entonces,
                                    puede estar siendo engañado(a), por una persona que no es parte de ABIS Chuquisaca.
                                </h1>
                            </div>
                        </v-tab-item>
                    </v-tabs-items>
                </div>
            </div>
        </div>
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="true"
            @click="dialogError=false"
        />
    </div>
</template>
<script>
import {
    OSmsErrorComp,
    OLoaderDataComp,
    OPhotoReviewComp
} from '../../components/organisms'
import { responseServerMixin } from '../../mixin'
import { Person } from '../../models'
import { imgMiniature } from '../../filters'
export default {
    metaInfo() {
        const title = `ABIS Ch. - Afiliados`
        return {
            title: title
        }
    },
    filters: { imgMiniature },
    mixins: [ responseServerMixin ],
    components : {
        OSmsErrorComp,
        OLoaderDataComp,
        OPhotoReviewComp
    },
    data: () => ({
        dialogError: false,
        smsError: [],
        actionError: true,
        dialogLoaderData: false,
        dataPerson: {},
        tab: 0,
        titleNoExpired:''
    }),
    methods: {
        processError(error) {
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_action(error)
            this.dialogError = true
        },
        getDataPerson(code) {
            this.dialogLoaderData = true
            Person.getDataPerson(code).then(response => {
                if (response.exist === 1) {
                    this.tab = 1
                    this.dataPerson = response
                } else this.tab = 2
                this.dialogLoaderData = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_getClassAffiliate() {
            if (this.dataPerson.expired !== undefined) {
                return this.dataPerson.expired !== 1 ? 'pa-2 verify-process__smsError' : 'pa-2 verify-process__smsCorrect'
            } else return ''
        }
    },
    mounted() {
        this.getDataPerson(this.$route.params.code)
    }
}
</script>