<template>
    <div>
        <div class="d-flex justify-center">
            <div class="s-form-review__form">
                <div class="d-flex align-center pa-1">
                    <div class="mr-2 s-form-review__container-title" v-if="!mobile">
                        <h1 class="text-body-private-title text-right">Nombre:</h1>
                    </div>
                    <div :class="mobile ? 'd-flex mb-2' : 'd-flex'" style="width:100%">
                        <div :class="errorsForm.full_name ?
                            's-form-review__container-subtitle border-error'
                            : 's-form-review__container-subtitle border-correct'">
                            <h1
                                :class="mobile ? 'text-body-private-subtitle pt-1' : 'text-body-private-subtitle'"
                                style="line-height:20px"
                            >{{dataInterested.full_name}}</h1>
                            <p v-if="mobile"
                                :style="errorsForm.full_name ? 'color:#FF5252;' : ''"
                            >Nombre</p>
                        </div>
                        <OSmsObservationComp
                            v-if="existsPreviusProcess && ac_validPreviewObs(dataPreviousInterested.list_observations[0].full_name_obs)"
                            smsTooltip="Ver previa observación"
                            :smsObservation="dataPreviousInterested.list_observations[0].full_name_obs"
                            icon="mdi-eye"
                            colorbtn="green"
                        />
                        <OMenuObservationComp
                            v-if="isObservation"
                            :closeOnContentClick="menuClick.full_name"
                            v-model="dataObservation.full_name_obs"
                            :mobile="mobile"
                            title="Nombre"
                            icon="mdi-information"
                            colorIcon="primary"
                            smsTooltip="Ingresar Observación"
                            @clicksave="ac_saveObservation(1)"
                            @clickopen="ac_openObservation(1)"
                            @clickclose="ac_closeObservation(1)"
                        />
                        <OIconToolTipComp
                            v-if="isObservation"
                            :mobile="mobile"
                            icon="mdi-trash-can"
                            color="red"
                            smsTooltip="Quitar observación"
                            @click="ac_refreshData(1)"
                        />
                    </div>
                </div>
                <div class="d-flex align-center pa-1">
                    <div class="mr-2 s-form-review__container-title" v-if="!mobile">
                        <h1 class="text-body-private-title text-right">Apellidos:</h1>
                    </div>
                    <div :class="mobile ? 'd-flex mb-2' : 'd-flex'" style="width:100%">
                        <div :class="errorsForm.last_name ?
                            's-form-review__container-subtitle border-error'
                            : 's-form-review__container-subtitle border-correct'">
                            <h1
                                :class="mobile ? 'text-body-private-subtitle pt-1' : 'text-body-private-subtitle'"
                                style="line-height:20px"
                            >{{dataInterested.last_name}}</h1>
                            <p v-if="mobile"
                                :style="errorsForm.last_name ? 'color:#FF5252;' : ''"
                            >Apellidos</p>
                        </div>
                        <OSmsObservationComp
                            v-if="existsPreviusProcess && ac_validPreviewObs(dataPreviousInterested.list_observations[0].last_name_obs)"
                            smsTooltip="Ver previa observación"
                            :smsObservation="dataPreviousInterested.list_observations[0].last_name_obs"
                            icon="mdi-eye"
                            colorbtn="green"
                        />
                        <OMenuObservationComp
                            v-if="isObservation"
                            :closeOnContentClick="menuClick.last_name"
                            v-model="dataObservation.last_name_obs"
                            :mobile="mobile"
                            title="Apellidos"
                            icon="mdi-information"
                            colorIcon="primary"
                            smsTooltip="Ingresar Observación"
                            @clicksave="ac_saveObservation(19)"
                            @clickopen="ac_openObservation(19)"
                            @clickclose="ac_closeObservation(19)"
                        />
                        <OIconToolTipComp
                            v-if="isObservation"
                            :mobile="mobile"
                            icon="mdi-trash-can"
                            color="red"
                            smsTooltip="Quitar observación"
                            @click="ac_refreshData(19)"
                        />
                    </div>
                </div>
                <div class="d-flex align-center pa-1">
                    <div class="mr-2 s-form-review__container-title" v-if="!mobile">
                        <h1 class="text-body-private-title text-right">CI:</h1>
                    </div>
                    <div :class="mobile ? 'd-flex mb-2' : 'd-flex'" style="width:100%">
                        <div :class="errorsForm.ci ?
                            's-form-review__container-subtitle border-error'
                            : 's-form-review__container-subtitle border-correct'">
                            <h1
                                :class="mobile ? 'text-body-private-subtitle pt-1' : 'text-body-private-subtitle'"
                                style="line-height:20px"
                            >{{dataInterested.ci}}</h1>
                            <p v-if="mobile"
                                :style="errorsForm.ci ? 'color:#FF5252;' : ''"
                            >CI</p>
                        </div>
                        <OSmsObservationComp
                            v-if="existsPreviusProcess && ac_validPreviewObs(dataPreviousInterested.list_observations[0].ci_obs)"
                            smsTooltip="Ver previa observación"
                            :smsObservation="dataPreviousInterested.list_observations[0].ci_obs"
                            icon="mdi-eye"
                            colorbtn="green"
                        />
                        <OMenuObservationComp
                            v-if="isObservation"
                            :closeOnContentClick="menuClick.ci"
                            v-model="dataObservation.ci_obs"
                            :mobile="mobile"
                            title="Carnet de Identidad"
                            icon="mdi-information"
                            colorIcon="primary"
                            smsTooltip="Ingresar Observación"
                            @clicksave="ac_saveObservation(2)"
                            @clickopen="ac_openObservation(2)"
                            @clickclose="ac_closeObservation(2)"
                        />
                        <OIconToolTipComp
                            v-if="isObservation"
                            :mobile="mobile"
                            icon="mdi-trash-can"
                            color="red"
                            smsTooltip="Quitar observación"
                            @click="ac_refreshData(2)"
                        />
                    </div>
                </div>
                <div class="d-flex align-center pa-1">
                    <div class="mr-2 s-form-review__container-title" v-if="!mobile">
                        <h1 class="text-body-private-title text-right">Expedido en:</h1>
                    </div>
                    <div :class="mobile ? 'd-flex mb-2' : 'd-flex'" style="width:100%">
                        <div :class="errorsForm.issued_in ?
                            's-form-review__container-subtitle border-error'
                            : 's-form-review__container-subtitle border-correct'">
                            <h1
                                :class="mobile ? 'text-body-private-subtitle pt-1' : 'text-body-private-subtitle'"
                                style="line-height:20px"
                            >{{m_utilitie_departament(dataInterested.issued_in)}}</h1>
                            <p v-if="mobile"
                                :style="errorsForm.issued_in ? 'color:#FF5252;' : ''"
                            >Expedido en</p>
                        </div>
                        <OSmsObservationComp
                            v-if="existsPreviusProcess && ac_validPreviewObs(dataPreviousInterested.list_observations[0].issued_in_obs)"
                            smsTooltip="Ver previa observación"
                            :smsObservation="dataPreviousInterested.list_observations[0].issued_in_obs"
                            icon="mdi-eye"
                            colorbtn="green"
                        />
                        <OMenuObservationComp
                            v-if="isObservation"
                            :closeOnContentClick="menuClick.issued_in"
                            v-model="dataObservation.issued_in_obs"
                            :mobile="mobile"
                            title="Expedido en"
                            icon="mdi-information"
                            colorIcon="primary"
                            smsTooltip="Ingresar Observación"
                            @clicksave="ac_saveObservation(3)"
                            @clickopen="ac_openObservation(3)"
                            @clickclose="ac_closeObservation(3)"
                        />
                        <OIconToolTipComp
                            v-if="isObservation"
                            :mobile="mobile"
                            icon="mdi-trash-can"
                            color="red"
                            smsTooltip="Quitar observación"
                            @click="ac_refreshData(3)"
                        />
                    </div>
                </div>
                <div class="d-flex align-center pa-1">
                    <div class="mr-2 s-form-review__container-title" v-if="!mobile">
                        <h1 class="text-body-private-title text-right">Género:</h1>
                    </div>
                    <div :class="mobile ? 'd-flex mb-2' : 'd-flex'" style="width:100%">
                        <div :class="errorsForm.gender ?
                            's-form-review__container-subtitle border-error'
                            : 's-form-review__container-subtitle border-correct'">
                            <h1
                                :class="mobile ? 'text-body-private-subtitle pt-1' : 'text-body-private-subtitle'"
                                style="line-height:20px"
                            >{{dataInterested.gender=='h' ? 'Masculino' : dataInterested.gender == 'm' ?  'Femenino' : 'Otro'}}</h1>
                            <p v-if="mobile"
                                :style="errorsForm.gender ? 'color:#FF5252;' : ''"
                            >Género</p>
                        </div>
                        <OSmsObservationComp
                            v-if="existsPreviusProcess && ac_validPreviewObs(dataPreviousInterested.list_observations[0].gender_obs)"
                            smsTooltip="Ver previa observación"
                            :smsObservation="dataPreviousInterested.list_observations[0].gender_obs"
                            icon="mdi-eye"
                            colorbtn="green"
                        />
                        <OMenuObservationComp
                            v-if="isObservation"
                            :closeOnContentClick="menuClick.gender"
                            v-model="dataObservation.gender_obs"
                            :mobile="mobile"
                            title="Género"
                            icon="mdi-information"
                            colorIcon="primary"
                            smsTooltip="Ingresar Observación"
                            @clicksave="ac_saveObservation(4)"
                            @clickopen="ac_openObservation(4)"
                            @clickclose="ac_closeObservation(4)"
                        />
                        <OIconToolTipComp
                            v-if="isObservation"
                            :mobile="mobile"
                            icon="mdi-trash-can"
                            color="red"
                            smsTooltip="Quitar observación"
                            @click="ac_refreshData(4)"
                        />
                    </div>
                </div>
                <div class="d-flex align-center pa-1">
                    <div class="mr-2 s-form-review__container-title" v-if="!mobile">
                        <h1 class="text-body-private-title text-right">Fecha de Nacimiento:</h1>
                    </div>
                    <div :class="mobile ? 'd-flex mb-2' : 'd-flex'" style="width:100%">
                        <div :class="errorsForm.date_of_birth ?
                            's-form-review__container-subtitle border-error'
                            : 's-form-review__container-subtitle border-correct'">
                            <h1
                                :class="mobile ? 'text-body-private-subtitle pt-1' : 'text-body-private-subtitle'"
                                style="line-height:20px"
                            >{{m_date_formatDate(dataInterested.date_of_birth)}}</h1>
                            <p v-if="mobile"
                                :style="errorsForm.date_of_birth ? 'color:#FF5252;' : ''"
                            >Fecha de Nacimiento</p>
                        </div>
                        <OSmsObservationComp
                            v-if="existsPreviusProcess && ac_validPreviewObs(dataPreviousInterested.list_observations[0].date_of_birth_obs)"
                            smsTooltip="Ver previa observación"
                            :smsObservation="dataPreviousInterested.list_observations[0].date_of_birth_obs"
                            icon="mdi-eye"
                            colorbtn="green"
                        />
                        <OMenuObservationComp
                            v-if="isObservation"
                            :closeOnContentClick="menuClick.date_of_birth"
                            v-model="dataObservation.date_of_birth_obs"
                            :mobile="mobile"
                            title="Fecha de Nac."
                            icon="mdi-information"
                            colorIcon="primary"
                            smsTooltip="Ingresar Observación"
                            @clicksave="ac_saveObservation(5)"
                            @clickopen="ac_openObservation(5)"
                            @clickclose="ac_closeObservation(5)"
                        />
                        <OIconToolTipComp
                            v-if="isObservation"
                            :mobile="mobile"
                            icon="mdi-trash-can"
                            color="red"
                            smsTooltip="Quitar observación"
                            @click="ac_refreshData(5)"
                        />
                    </div>
                </div>
                <div class="d-flex align-center pa-1">
                    <div class="mr-2 s-form-review__container-title" v-if="!mobile">
                        <h1 class="text-body-private-title text-right">Celular:</h1>
                    </div>
                    <div :class="mobile ? 'd-flex mb-2' : 'd-flex'" style="width:100%">
                        <div :class="errorsForm.phone ?
                            's-form-review__container-subtitle border-error'
                            : 's-form-review__container-subtitle border-correct'">
                            <h1
                                :class="mobile ? 'text-body-private-subtitle pt-1' : 'text-body-private-subtitle'"
                                style="line-height:20px"
                            >{{dataInterested.phone}}</h1>
                            <p v-if="mobile"
                                :style="errorsForm.phone ? 'color:#FF5252;' : ''"
                            >Celular</p>
                        </div>
                        <OSmsObservationComp
                            v-if="existsPreviusProcess && ac_validPreviewObs(dataPreviousInterested.list_observations[0].phone_obs)"
                            smsTooltip="Ver previa observación"
                            :smsObservation="dataPreviousInterested.list_observations[0].phone_obs"
                            icon="mdi-eye"
                            colorbtn="green"
                        />
                        <OMenuObservationComp
                            v-if="isObservation"
                            :closeOnContentClick="menuClick.phone"
                            v-model="dataObservation.phone_obs"
                            :mobile="mobile"
                            title="Celular"
                            icon="mdi-information"
                            smsTooltip="Ingresar Observación"
                            colorIcon="primary"
                            @clicksave="ac_saveObservation(6)"
                            @clickopen="ac_openObservation(6)"
                            @clickclose="ac_closeObservation(6)"
                        />
                        <OIconToolTipComp
                            v-if="isObservation"
                            :mobile="mobile"
                            icon="mdi-trash-can"
                            color="red"
                            smsTooltip="Quitar observación"
                            @click="ac_refreshData(6)"
                        />
                    </div>
                </div>
                <div class="d-flex align-center pa-1">
                    <div class="mr-2 s-form-review__container-title" v-if="!mobile">
                        <h1 class="text-body-private-title text-right">Dirección:</h1>
                    </div>
                    <div :class="mobile ? 'd-flex mb-2' : 'd-flex'" style="width:100%">
                        <div :class="errorsForm.address ?
                            's-form-review__container-subtitle border-error'
                            : 's-form-review__container-subtitle border-correct'">
                            <h1
                                :class="mobile ? 'text-body-private-subtitle pt-1' : 'text-body-private-subtitle'"
                                style="line-height:20px"
                            >{{dataInterested.address}}</h1>
                            <p v-if="mobile"
                                :style="errorsForm.address ? 'color:#FF5252;' : ''"
                            >Dirección</p>
                        </div>
                        <OSmsObservationComp
                            v-if="existsPreviusProcess && ac_validPreviewObs(dataPreviousInterested.list_observations[0].address_obs)"
                            smsTooltip="Ver previa observación"
                            :smsObservation="dataPreviousInterested.list_observations[0].address_obs"
                            icon="mdi-eye"
                            colorbtn="green"
                        />
                        <OMenuObservationComp
                            v-if="isObservation"
                            :closeOnContentClick="menuClick.address"
                            v-model="dataObservation.address_obs"
                            :mobile="mobile"
                            title="Dirección"
                            icon="mdi-information"
                            colorIcon="primary"
                            smsTooltip="Ingresar Observación"
                            @clicksave="ac_saveObservation(7)"
                            @clickopen="ac_openObservation(7)"
                            @clickclose="ac_closeObservation(7)"
                        />
                        <OIconToolTipComp
                            v-if="isObservation"
                            :mobile="mobile"
                            icon="mdi-trash-can"
                            color="red"
                            smsTooltip="Quitar observación"
                            @click="ac_refreshData(7)"
                        />
                    </div>
                </div>
                <div class="d-flex align-center pa-1">
                    <div class="mr-2 s-form-review__container-title" v-if="!mobile">
                        <h1 class="text-body-private-title text-right">Correo Electrónico:</h1>
                    </div>
                    <div :class="mobile ? 'd-flex mb-2' : 'd-flex'" style="width:100%">
                        <div :class="errorsForm.email ?
                            's-form-review__container-subtitle border-error'
                            : 's-form-review__container-subtitle border-correct'">
                            <h1
                                :class="mobile ? 'text-body-private-subtitle pt-1' : 'text-body-private-subtitle'"
                                style="line-height:20px"
                            >{{dataInterested.email}}</h1>
                            <p v-if="mobile"
                                :style="errorsForm.email ? 'color:#FF5252;' : ''"
                            >Correo Electrónico</p>
                        </div>
                        <OSmsObservationComp
                            v-if="existsPreviusProcess && ac_validPreviewObs(dataPreviousInterested.list_observations[0].email_obs)"
                            smsTooltip="Ver previa observación"
                            :smsObservation="dataPreviousInterested.list_observations[0].email_obs"
                            icon="mdi-eye"
                            colorbtn="green"
                        />
                        <OMenuObservationComp
                            v-if="isObservation"
                            :closeOnContentClick="menuClick.email"
                            v-model="dataObservation.email_obs"
                            :mobile="mobile"
                            title="Correo Electrónico"
                            icon="mdi-information"
                            colorIcon="primary"
                            smsTooltip="Ingresar Observación"
                            @clicksave="ac_saveObservation(18)"
                            @clickopen="ac_openObservation(18)"
                            @clickclose="ac_closeObservation(18)"
                        />
                        <OIconToolTipComp
                            v-if="isObservation"
                            :mobile="mobile"
                            icon="mdi-trash-can"
                            color="red"
                            smsTooltip="Quitar observación"
                            @click="ac_refreshData(18)"
                        />
                    </div>
                </div>
                <div class="d-flex align-center pa-1">
                    <div class="mr-2 s-form-review__container-title" v-if="!mobile">
                        <h1 class="text-body-private-title text-right">N° de Registro SIB:</h1>
                    </div>
                    <div :class="mobile ? 'd-flex mb-2' : 'd-flex'" style="width:100%">
                        <div :class="errorsForm.code_sib ?
                            's-form-review__container-subtitle border-error'
                            : 's-form-review__container-subtitle border-correct'">
                            <h1
                                :class="mobile ? 'text-body-private-subtitle pt-1' : 'text-body-private-subtitle'"
                                style="line-height:20px"
                            >{{dataInterested.code_sib!=null ? dataInterested.code_sib : 'S/N'}}</h1>
                            <p v-if="mobile"
                                :style="errorsForm.code_sib ? 'color:#FF5252;' : ''"
                            >N° de Registro SIB:</p>
                        </div>
                        <OSmsObservationComp
                            v-if="existsPreviusProcess && ac_validPreviewObs(dataPreviousInterested.list_observations[0].code_sib_obs)"
                            smsTooltip="Ver previa observación"
                            :smsObservation="dataPreviousInterested.list_observations[0].code_sib_obs"
                            icon="mdi-eye"
                            colorbtn="green"
                        />
                        <OMenuObservationComp
                            v-if="isObservation"
                            :closeOnContentClick="menuClick.code_sib"
                            v-model="dataObservation.code_sib_obs"
                            :mobile="mobile"
                            title="N° de Registro SIB"
                            icon="mdi-information"
                            colorIcon="primary"
                            smsTooltip="Ingresar Observación"
                            @clicksave="ac_saveObservation(8)"
                            @clickopen="ac_openObservation(8)"
                            @clickclose="ac_closeObservation(8)"
                        />
                        <OIconToolTipComp
                            v-if="isObservation"
                            :mobile="mobile"
                            icon="mdi-trash-can"
                            color="red"
                            smsTooltip="Quitar observación"
                            @click="ac_refreshData(8)"
                        />
                    </div>
                </div>
                <div class="d-flex align-center pa-1">
                    <div class="mr-2 s-form-review__container-title" v-if="!mobile">
                        <h1 class="text-body-private-title text-right">Categoría:</h1>
                    </div>
                    <div :class="mobile ? 'd-flex mb-2' : 'd-flex'" style="width:100%">
                        <div :class="errorsForm.category ?
                            's-form-review__container-subtitle border-error'
                            : 's-form-review__container-subtitle border-correct'">
                            <h1
                                :class="mobile ? 'text-body-private-subtitle pt-1' : 'text-body-private-subtitle'"
                                style="line-height:20px"
                            >{{m_utilitie_category(dataInterested.category)}}</h1>
                            <p v-if="mobile"
                                :style="errorsForm.category ? 'color:#FF5252;' : ''"
                            >Categoría:</p>
                        </div>
                        <OSmsObservationComp
                            v-if="existsPreviusProcess && ac_validPreviewObs(dataPreviousInterested.list_observations[0].category_obs)"
                            smsTooltip="Ver previa observación"
                            :smsObservation="dataPreviousInterested.list_observations[0].category_obs"
                            icon="mdi-eye"
                            colorbtn="green"
                        />
                        <OMenuObservationComp
                            v-if="isObservation"
                            :closeOnContentClick="menuClick.category"
                            v-model="dataObservation.category_obs"
                            :mobile="mobile"
                            title="Categoría"
                            icon="mdi-information"
                            colorIcon="primary"
                            smsTooltip="Ingresar Observación"
                            @clicksave="ac_saveObservation(9)"
                            @clickopen="ac_openObservation(9)"
                            @clickclose="ac_closeObservation(9)"
                        />
                        <OIconToolTipComp
                            v-if="isObservation"
                            :mobile="mobile"
                            icon="mdi-trash-can"
                            color="red"
                            smsTooltip="Quitar observación"
                            @click="ac_refreshData(9)"
                        />
                    </div>
                </div>
            </div>
        </div>
    <!-- photograf -->
        <div style="width:780px" class="d-flex justify-center flex-wrap mt-2 pl-1 pr-1">
            <div class="ma-2">
                <h1 class="text-body-private-small text-center">FOTOGRAFÍA</h1>
                <OPhotoReviewComp
                    :srcPreviewImage="dataInterested.route_face"
                    class="ma-2 mt-0"
                    :isError="errorsForm.route_face"
                    @clickPhoto="$emit('clickPhoto', {route: dataInterested.route_face, title: 'Fotografía Personal'})"
                />
                <div class="d-flex justify-center">
                    <OSmsObservationComp
                        v-if="existsPreviusProcess && ac_validPreviewObs(dataPreviousInterested.list_observations[0].route_face_obs)"
                        smsTooltip="Ver previa observación"
                        :smsObservation="dataPreviousInterested.list_observations[0].route_face_obs"
                        icon="mdi-eye"
                        colorbtn="green"
                    />
                    <OMenuObservationComp
                        v-if="isObservation"
                        :closeOnContentClick="menuClick.route_face"
                        v-model="dataObservation.route_face_obs"
                        :mobile="mobile"
                        title="Foto personal"
                        icon="mdi-information"
                        colorIcon="primary"
                        smsTooltip="Ingresar Observación"
                        @clicksave="ac_saveObservation(10)"
                        @clickopen="ac_openObservation(10)"
                        @clickclose="ac_closeObservation(10)"
                    />
                    <OIconToolTipComp
                        v-if="isObservation"
                        :mobile="mobile"
                        icon="mdi-trash-can"
                        color="red"
                        class="mr-1"
                        smsTooltip="Quitar observación"
                        @click="ac_refreshData(10)"
                    />
                    <OIconToolTipComp
                        :mobile="mobile"
                        icon="mdi-printer"
                        color="green"
                        class="mr-1"
                        smsTooltip="Imprimir fotografía"
                        @click="$emit('clickprinter',
                            {route: dataInterested.route_face,
                            title: 'Fotografía personal',
                            interested: dataInterested.full_name+' '+dataInterested.last_name,
                            ci: dataInterested.ci+' '+dataInterested.issued_in
                        })"
                    />
                    <OIconToolTipComp
                        :mobile="mobile"
                        icon="mdi-download-circle"
                        color="green"
                        class="mr-1"
                        smsTooltip="Descargar archivo"
                        @click="$emit('clickdownload', dataInterested.route_face)"
                    />
                </div>
            </div>
            <div class="ma-2">
                <h1 class="text-body-private-small text-center">CI ANVERSO</h1>
                <OPhotoReviewComp
                    :srcPreviewImage="dataInterested.route_identity"
                    class="ma-2 mt-0"
                    :isError="errorsForm.route_identity"
                    @clickPhoto="$emit('clickPhoto', {route: dataInterested.route_identity, title: 'CI Anverso'})"
                />
                <div class="d-flex justify-center">
                    <OSmsObservationComp
                        v-if="existsPreviusProcess && ac_validPreviewObs(dataPreviousInterested.list_observations[0].route_identity_obs)"
                        smsTooltip="Ver previa observación"
                        :smsObservation="dataPreviousInterested.list_observations[0].route_identity_obs"
                        icon="mdi-eye"
                        colorbtn="green"
                    />
                    <OMenuObservationComp
                        v-if="isObservation"
                        :closeOnContentClick="menuClick.route_identity"
                        v-model="dataObservation.route_identity_obs"
                        :mobile="mobile"
                        title="Foto CI Anverso"
                        icon="mdi-information"
                        colorIcon="primary"
                        smsTooltip="Ingresar Observación"
                        @clicksave="ac_saveObservation(12)"
                        @clickopen="ac_openObservation(12)"
                        @clickclose="ac_closeObservation(12)"
                    />
                    <OIconToolTipComp
                        v-if="isObservation"
                        :mobile="mobile"
                        icon="mdi-trash-can"
                        color="red"
                        class="mr-1"
                        smsTooltip="Quitar observación"
                        @click="ac_refreshData(12)"
                    />
                    <OIconToolTipComp
                        v-if="ac_validTypeFile(dataInterested.route_identity)"
                        :mobile="mobile"
                        icon="mdi-printer"
                        color="green"
                        class="mr-1"
                        smsTooltip="Imprimir fotografía"
                        @click="$emit('clickprinter',
                            {route: dataInterested.route_identity,
                            title: 'CI Anverso',
                            interested: dataInterested.full_name+' '+dataInterested.last_name,
                            ci: dataInterested.ci+' '+dataInterested.issued_in
                        })"
                    />
                    <OIconToolTipComp
                        :mobile="mobile"
                        icon="mdi-download-circle"
                        color="green"
                        class="mr-1"
                        smsTooltip="Descargar archivo"
                        @click="$emit('clickdownload', dataInterested.route_identity)"
                    />
                </div>
            </div>
            <!--ADD CI REVERSE-->
            <div class="ma-2">
                <h1 class="text-body-private-small text-center">CI REVERSO</h1>
                <OPhotoReviewComp
                    :srcPreviewImage="dataInterested.route_identity_r"
                    class="ma-2 mt-0"
                    :isError="errorsForm.route_identity_r"
                    @clickPhoto="$emit('clickPhoto', {route: dataInterested.route_identity_r, title: 'CI Reverso'})"
                />
                <div class="d-flex justify-center">
                    <OSmsObservationComp
                        v-if="existsPreviusProcess && ac_validPreviewObs(dataPreviousInterested.list_observations[0].route_identity_r_obs)"
                        smsTooltip="Ver previa observación"
                        :smsObservation="dataPreviousInterested.list_observations[0].route_identity_r_obs"
                        icon="mdi-eye"
                        colorbtn="green"
                    />
                    <OMenuObservationComp
                        v-if="isObservation"
                        :closeOnContentClick="menuClick.route_identity_r"
                        v-model="dataObservation.route_identity_r_obs"
                        :mobile="mobile"
                        title="Foto CI Reverso"
                        icon="mdi-information"
                        colorIcon="primary"
                        smsTooltip="Ingresar Observación"
                        @clicksave="ac_saveObservation(17)"
                        @clickopen="ac_openObservation(17)"
                        @clickclose="ac_closeObservation(17)"
                    />
                    <OIconToolTipComp
                        v-if="isObservation"
                        :mobile="mobile"
                        icon="mdi-trash-can"
                        color="red"
                        smsTooltip="Quitar observación"
                        @click="ac_refreshData(17)"
                    />
                    <OIconToolTipComp
                        v-if="ac_validTypeFile(dataInterested.route_identity_r)"
                        :mobile="mobile"
                        icon="mdi-printer"
                        color="green"
                        class="mr-1"
                        smsTooltip="Imprimir fotografía"
                        @click="$emit('clickprinter',
                            {route: dataInterested.route_identity_r,
                            title: 'CI Reverso',
                            interested: dataInterested.full_name+' '+dataInterested.last_name,
                            ci: dataInterested.ci+' '+dataInterested.issued_in
                        })"
                    />
                    <OIconToolTipComp
                        :mobile="mobile"
                        icon="mdi-download-circle"
                        color="green"
                        class="mr-1"
                        smsTooltip="Descargar archivo"
                        @click="$emit('clickdownload', dataInterested.route_identity_r)"
                    />
                </div>
            </div>
            <!--|||||||||||-->
            <div class="ma-2">
                <h1 class="text-body-private-small text-center">COMPROBANTE DE PAGO</h1>
                <OPhotoReviewComp
                    :srcPreviewImage="dataInterested.route_payment"
                    class="ma-2 mt-0"
                    :isError="errorsForm.route_payment"
                    @clickPhoto="$emit('clickPhoto', {route: dataInterested.route_payment, title: 'Comprobante de Pago'})"
                />
                <div class="d-flex justify-center">
                    <OSmsObservationComp
                        v-if="existsPreviusProcess && ac_validPreviewObs(dataPreviousInterested.list_observations[0].route_payment_obs)"
                        smsTooltip="Ver previa observación"
                        :smsObservation="dataPreviousInterested.list_observations[0].route_payment_obs"
                        icon="mdi-eye"
                        colorbtn="green"
                    />
                    <OMenuObservationComp
                        v-if="isObservation"
                        :closeOnContentClick="menuClick.route_payment"
                        v-model="dataObservation.route_payment_obs"
                        :mobile="mobile"
                        title="Foto de Pago"
                        icon="mdi-information"
                        colorIcon="primary"
                        smsTooltip="Ingresar Observación"
                        @clicksave="ac_saveObservation(11)"
                        @clickopen="ac_openObservation(11)"
                        @clickclose="ac_closeObservation(11)"
                    />
                    <OIconToolTipComp
                        v-if="isObservation"
                        :mobile="mobile"
                        icon="mdi-trash-can"
                        color="red"
                        smsTooltip="Quitar observación"
                        @click="ac_refreshData(11)"
                    />
                    <OIconToolTipComp
                        v-if="ac_validTypeFile(dataInterested.route_payment)"
                        :mobile="mobile"
                        icon="mdi-printer"
                        color="green"
                        class="mr-1"
                        smsTooltip="Imprimir fotografía"
                        @click="$emit('clickprinter',
                            {route: dataInterested.route_payment,
                            title: 'Comprobante de Pago',
                            interested: dataInterested.full_name+' '+dataInterested.last_name,
                            ci: dataInterested.ci+' '+dataInterested.issued_in
                        })"
                    />
                    <OIconToolTipComp
                        :mobile="mobile"
                        icon="mdi-download-circle"
                        color="green"
                        class="mr-1"
                        smsTooltip="Descargar archivo"
                        @click="$emit('clickdownload', dataInterested.route_payment)"
                    />
                </div>
            </div>
            <div class="ma-2">
                <h1 class="text-body-private-small text-center">T. ACADÉMICO A.</h1>
                <OPhotoReviewComp
                    :srcPreviewImage="dataInterested.route_academy_a"
                    class="ma-2 mt-0"
                    :isError="errorsForm.route_academy_a"
                    @clickPhoto="$emit('clickPhoto',
                        {route: dataInterested.route_academy_a, title: 'T. Académico Anverso'})"
                />
                <div class="d-flex justify-center">
                    <OSmsObservationComp
                        v-if="existsPreviusProcess && ac_validPreviewObs(dataPreviousInterested.list_observations[0].route_academy_a_obs)"
                        smsTooltip="Ver previa observación"
                        :smsObservation="dataPreviousInterested.list_observations[0].route_academy_a_obs"
                        icon="mdi-eye"
                        colorbtn="green"
                    />
                    <OMenuObservationComp
                        v-if="isObservation"
                        :closeOnContentClick="menuClick.route_academy_a"
                        v-model="dataObservation.route_academy_a_obs"
                        :mobile="mobile"
                        title="Título Académico A"
                        icon="mdi-information"
                        colorIcon="primary"
                        smsTooltip="Ingresar Observación"
                        @clicksave="ac_saveObservation(13)"
                        @clickopen="ac_openObservation(13)"
                        @clickclose="ac_closeObservation(13)"
                    />
                    <OIconToolTipComp
                        v-if="isObservation"
                        :mobile="mobile"
                        icon="mdi-trash-can"
                        color="red"
                        smsTooltip="Quitar observación"
                        @click="ac_refreshData(13)"
                    />
                    <OIconToolTipComp
                        v-if="ac_validTypeFile(dataInterested.route_academy_a)"
                        :mobile="mobile"
                        icon="mdi-printer"
                        color="green"
                        class="mr-1"
                        smsTooltip="Imprimir fotografía"
                        @click="$emit('clickprinter',
                            {route: dataInterested.route_academy_a,
                            title: 'Título Académico Anverso',
                            interested: dataInterested.full_name+' '+dataInterested.last_name,
                            ci: dataInterested.ci+' '+dataInterested.issued_in
                        })"
                    />
                    <OIconToolTipComp
                        :mobile="mobile"
                        icon="mdi-download-circle"
                        color="green"
                        class="mr-1"
                        smsTooltip="Descargar archivo"
                        @click="$emit('clickdownload', dataInterested.route_academy_a)"
                    />
                </div>
            </div>
            <div class="ma-2">
                <h1 class="text-body-private-small text-center">T. ACADÉMICO R.</h1>
                <OPhotoReviewComp
                    :srcPreviewImage="dataInterested.route_academy_r"
                    class="ma-2 mt-0"
                    :isError="errorsForm.route_academy_r"
                    @clickPhoto="$emit('clickPhoto',
                        {route: dataInterested.route_academy_r, title: 'T. Académico Reverso'})"
                />
                <div class="d-flex justify-center">
                    <OSmsObservationComp
                        v-if="existsPreviusProcess && ac_validPreviewObs(dataPreviousInterested.list_observations[0].route_academy_r_obs)"
                        smsTooltip="Ver previa observación"
                        :smsObservation="dataPreviousInterested.list_observations[0].route_academy_r_obs"
                        icon="mdi-eye"
                        colorbtn="green"
                    />
                    <OMenuObservationComp
                        v-if="isObservation"
                        :closeOnContentClick="menuClick.route_academy_r"
                        v-model="dataObservation.route_academy_r_obs"
                        :mobile="mobile"
                        title="Título Académico R."
                        icon="mdi-information"
                        colorIcon="primary"
                        smsTooltip="Ingresar Observación"
                        @clicksave="ac_saveObservation(14)"
                        @clickopen="ac_openObservation(14)"
                        @clickclose="ac_closeObservation(14)"
                    />
                    <OIconToolTipComp
                        v-if="isObservation"
                        :mobile="mobile"
                        icon="mdi-trash-can"
                        color="red"
                        smsTooltip="Quitar observación"
                        @click="ac_refreshData(14)"
                    />
                    <OIconToolTipComp
                        v-if="ac_validTypeFile(dataInterested.route_academy_r)"
                        :mobile="mobile"
                        icon="mdi-printer"
                        color="green"
                        class="mr-1"
                        smsTooltip="Imprimir fotografía"
                        @click="$emit('clickprinter',
                            {route: dataInterested.route_academy_r,
                            title: 'Título Académico Reverso',
                            interested: dataInterested.full_name+' '+dataInterested.last_name,
                            ci: dataInterested.ci+' '+dataInterested.issued_in
                        })"
                    />
                    <OIconToolTipComp
                        :mobile="mobile"
                        icon="mdi-download-circle"
                        color="green"
                        class="mr-1"
                        smsTooltip="Descargar archivo"
                        @click="$emit('clickdownload', dataInterested.route_academy_r)"
                    />
                </div>
            </div>
            <!--revision de nuevas fotografias-->
            <div class="ma-2">
                <h1 class="text-body-private-small text-center">T. P. NACIONAL A.</h1>
                <OPhotoReviewComp
                    :srcPreviewImage="dataInterested.route_national_a"
                    class="ma-2 mt-0"
                    :isError="errorsForm.route_national_a"
                    @clickPhoto="$emit('clickPhoto',
                        {route: dataInterested.route_national_a, title: 'T. P. Nacional Anverso'})"
                />
                <div class="d-flex justify-center">
                    <OSmsObservationComp
                        v-if="existsPreviusProcess && ac_validPreviewObs(dataPreviousInterested.list_observations[0].route_national_a_obs)"
                        smsTooltip="Ver previa observación"
                        :smsObservation="dataPreviousInterested.list_observations[0].route_national_a_obs"
                        icon="mdi-eye"
                        colorbtn="green"
                    />
                    <OMenuObservationComp
                        v-if="isObservation"
                        :closeOnContentClick="menuClick.route_national_a"
                        v-model="dataObservation.route_national_a_obs"
                        :mobile="mobile"
                        title="Título P. Nacional A."
                        icon="mdi-information"
                        colorIcon="primary"
                        smsTooltip="Ingresar Observación"
                        @clicksave="ac_saveObservation(15)"
                        @clickopen="ac_openObservation(15)"
                        @clickclose="ac_closeObservation(15)"
                    />
                    <OIconToolTipComp
                        v-if="isObservation"
                        :mobile="mobile"
                        icon="mdi-trash-can"
                        color="red"
                        smsTooltip="Quitar observación"
                        @click="ac_refreshData(15)"
                    />
                    <OIconToolTipComp
                        v-if="ac_validTypeFile(dataInterested.route_national_a)"
                        :mobile="mobile"
                        icon="mdi-printer"
                        color="green"
                        class="mr-1"
                        smsTooltip="Imprimir fotografía"
                        @click="$emit('clickprinter',
                            {route: dataInterested.route_national_a,
                            title: 'Título Provisión Nacional Anverso',
                            interested: dataInterested.full_name+' '+dataInterested.last_name,
                            ci: dataInterested.ci+' '+dataInterested.issued_in
                        })"
                    />
                    <OIconToolTipComp
                        :mobile="mobile"
                        icon="mdi-download-circle"
                        color="green"
                        class="mr-1"
                        smsTooltip="Descargar archivo"
                        @click="$emit('clickdownload', dataInterested.route_national_a)"
                    />
                </div>
            </div>
            <div class="ma-2">
                <h1 class="text-body-private-small text-center">T. P. NACIONAL R.</h1>
                <OPhotoReviewComp
                    :srcPreviewImage="dataInterested.route_national_r"
                    class="ma-2 mt-0"
                    :isError="errorsForm.route_national_r"
                    @clickPhoto="$emit('clickPhoto',
                        {route: dataInterested.route_national_r, title: 'T. P. Nacional Reverso'})"
                />
                <div class="d-flex justify-center">
                    <OSmsObservationComp
                        v-if="existsPreviusProcess && ac_validPreviewObs(dataPreviousInterested.list_observations[0].route_national_r_obs)"
                        smsTooltip="Ver previa observación"
                        :smsObservation="dataPreviousInterested.list_observations[0].route_national_r_obs"
                        icon="mdi-eye"
                        colorbtn="green"
                    />
                    <OMenuObservationComp
                        v-if="isObservation"
                        :closeOnContentClick="menuClick.route_national_r"
                        v-model="dataObservation.route_national_r_obs"
                        :mobile="mobile"
                        title="Título P. Nacional R."
                        icon="mdi-information"
                        colorIcon="primary"
                        smsTooltip="Ingresar Observación"
                        @clicksave="ac_saveObservation(16)"
                        @clickopen="ac_openObservation(16)"
                        @clickclose="ac_closeObservation(16)"
                    />
                    <OIconToolTipComp
                        v-if="isObservation"
                        :mobile="mobile"
                        icon="mdi-trash-can"
                        color="red"
                        smsTooltip="Quitar observación"
                        @click="ac_refreshData(16)"
                    />
                    <OIconToolTipComp
                        v-if="ac_validTypeFile(dataInterested.route_national_r)"
                        :mobile="mobile"
                        icon="mdi-printer"
                        color="green"
                        class="mr-1"
                        smsTooltip="Imprimir fotografía"
                        @click="$emit('clickprinter',
                            {route: dataInterested.route_national_r,
                            title: 'Título Provisión Nacional Reverso',
                            interested: dataInterested.full_name+' '+dataInterested.last_name,
                            ci: dataInterested.ci+' '+dataInterested.issued_in
                        })"
                    />
                    <OIconToolTipComp
                        :mobile="mobile"
                        icon="mdi-download-circle"
                        color="green"
                        class="mr-1"
                        smsTooltip="Descargar archivo"
                        @click="$emit('clickdownload', dataInterested.route_national_r)"
                    />
                </div>
            </div>
        </div>
        <div class="text-center mt-4" v-if="isObservation">
            <MBtnNormalComp
                color="primary"
                :outlined="true"
                class="mr-1"
                :disabled="ac_activateObservation()"
                @click="ac_sortData()"
            >OBSERVADO</MBtnNormalComp>
            <MBtnNormalComp
                color="primary"
                :outlined="true"
                :disabled="ac_activateAffiliation()"
                @click="$emit('clickaffiliation')"
            >REGISTRAR AFILIADO</MBtnNormalComp>
        </div>
    </div>
</template>
<script>
import {
    MBtnNormalComp
} from '../../components/molecules'
import {
    OMenuObservationComp,
    OIconToolTipComp,
    OPhotoReviewComp,
    OSmsObservationComp
} from '../../components/organisms'
import {
    utilitiesMixin,
    rulesMixin,
    datesMixin
} from '../../mixin'
export default {
    props: {
        dataObservation: {},
        dataInterested: {},
        isObservation: Boolean,
        errorsForm: {},
        mobile: Boolean,
        existsPreviusProcess: Boolean,
        dataPreviousInterested: Object
    },
    components: {
        OMenuObservationComp,
        OIconToolTipComp,
        MBtnNormalComp,
        OPhotoReviewComp,
        OSmsObservationComp
    },
    mixins: [
        utilitiesMixin,
        rulesMixin,
        datesMixin
    ],
    data: () => ({
        menuClick: {
            full_name: false,
            last_name: false,
            ci: false,
            issued_in: false,
            gender: false,
            date_of_birth: false,
            phone: false,
            address: false,
            email: false,
            code_sib: false,
            category: false,
            route_face: false,
            route_payment: false,
            route_identity: false,
            route_identity_r: false,
            route_academy_a: false,
            route_academy_r: false,
            route_national_a: false,
            route_national_r: false
        }
    }),
    methods: {
        activateMenuClick(position) {
            switch(position) {
                case 1: 
                    this.menuClick.full_name = true
                    break
                case 2:
                    this.menuClick.ci = true
                    break
                case 3:
                    this.menuClick.issued_in = true
                    break
                case 4:
                    this.menuClick.gender = true
                    break
                case 5:
                    this.menuClick.date_of_birth = true
                    break
                case 6:
                    this.menuClick.phone = true
                    break
                case 7:
                    this.menuClick.address = true
                    break
                case 8:
                    this.menuClick.code_sib = true
                    break
                case 9:
                    this.menuClick.category = true
                    break
                case 10:
                    this.menuClick.route_face = true
                    break
                case 11:
                    this.menuClick.route_payment = true
                    break
                case 12:
                    this.menuClick.route_identity = true
                    break
                case 13:
                    this.menuClick.route_academy_a = true
                    break
                case 14:
                    this.menuClick.route_academy_r = true
                    break
                case 15:
                    this.menuClick.route_national_a = true
                    break
                case 16:
                    this.menuClick.route_national_r = true
                    break
                case 17:
                    this.menuClick.route_identity_r = true
                    break
                case 18:
                    this.menuClick.email = true
                    break
                case 19:
                    this.menuClick.last_name = true
                    break
            }
        },
        ac_saveObservation(position) {
            this.activateMenuClick(position)
            switch(position) {
                case 1:
                    if(!this.m_rule_emptyField(this.dataObservation.full_name_obs)) this.errorsForm.full_name = true
                    else this.dataObservation.full_name_obs = null
                    break
                case 2:
                    if(!this.m_rule_emptyField(this.dataObservation.ci_obs)) this.errorsForm.ci = true
                    else this.dataObservation.ci_obs = null
                    break
                case 3:
                    if(!this.m_rule_emptyField(this.dataObservation.issued_in_obs)) this.errorsForm.issued_in = true
                    else this.dataObservation.issued_in_obs = null
                    break
                case 4:
                    if(!this.m_rule_emptyField(this.dataObservation.gender_obs)) this.errorsForm.gender = true
                    else this.dataObservation.gender_obs = null
                    break
                case 5:
                    if(!this.m_rule_emptyField(this.dataObservation.date_of_birth_obs)) this.errorsForm.date_of_birth = true
                    else this.dataObservation.date_of_birth_obs = null
                    break
                case 6:
                    if(!this.m_rule_emptyField(this.dataObservation.phone_obs)) this.errorsForm.phone = true
                    else this.dataObservation.phone_obs = null
                    break
                case 7:
                    if(!this.m_rule_emptyField(this.dataObservation.address_obs)) this.errorsForm.address = true
                    else this.dataObservation.address_obs = null
                    break
                case 8:
                    if(!this.m_rule_emptyField(this.dataObservation.code_sib_obs)) this.errorsForm.code_sib = true
                    else this.dataObservation.code_sib_obs = null
                    break
                case 9:
                    if(!this.m_rule_emptyField(this.dataObservation.category_obs)) this.errorsForm.category = true
                    else this.dataObservation.category_obs = null
                    break
                case 10:
                    if(!this.m_rule_emptyField(this.dataObservation.route_face_obs)) this.errorsForm.route_face = true
                    else this.dataObservation.route_face_obs = null
                    break
                case 11:
                    if(!this.m_rule_emptyField(this.dataObservation.route_payment_obs)) this.errorsForm.route_payment = true
                    else this.dataObservation.route_payment_obs = null
                    break
                case 12:
                    if(!this.m_rule_emptyField(this.dataObservation.route_identity_obs)) this.errorsForm.route_identity = true
                    else this.dataObservation.route_identity_obs = null
                    break
                case 13:
                    if(!this.m_rule_emptyField(this.dataObservation.route_academy_a_obs)) this.errorsForm.route_academy_a = true
                    else this.dataObservation.route_academy_a_obs = null
                    break
                case 14:
                    if(!this.m_rule_emptyField(this.dataObservation.route_academy_r_obs)) this.errorsForm.route_academy_r = true
                    else this.dataObservation.route_academy_r_obs = null
                    break
                case 15:
                    if(!this.m_rule_emptyField(this.dataObservation.route_national_a_obs)) this.errorsForm.route_national_a = true
                    else this.dataObservation.route_national_a_obs = null
                    break
                case 16:
                    if(!this.m_rule_emptyField(this.dataObservation.route_national_r_obs)) this.errorsForm.route_national_r = true
                    else this.dataObservation.route_national_r_obs = null
                    break
                case 17:
                    if(!this.m_rule_emptyField(this.dataObservation.route_identity_r_obs)) this.errorsForm.route_identity_r = true
                    else this.dataObservation.route_identity_r_obs = null
                    break
                case 18:
                    if(!this.m_rule_emptyField(this.dataObservation.email_obs)) this.errorsForm.email = true
                    else this.dataObservation.email_obs = null
                    break
                case 19:
                    if(!this.m_rule_emptyField(this.dataObservation.last_name_obs)) this.errorsForm.last_name = true
                    else this.dataObservation.last_name_obs = null
                    break
            }
        },
        ac_openObservation(position) {
            switch(position) {
                case 1:
                    this.menuClick.full_name = false
                    break
                case 2:
                    this.menuClick.ci = false
                    break
                case 3:
                    this.menuClick.issued_in = false
                    break
                case 4:
                    this.menuClick.gender = false
                    break
                case 5:
                    this.menuClick.date_of_birth = false
                    break
                case 6:
                    this.menuClick.phone = false
                    break
                case 7:
                    this.menuClick.address = false
                    break
                case 8:
                    this.menuClick.code_sib = false
                    break
                case 9:
                    this.menuClick.category = false
                    break
                case 10:
                    this.menuClick.route_face = false
                    break
                case 11:
                    this.menuClick.route_payment = false
                    break
                case 12:
                    this.menuClick.route_identity = false
                    break
                case 13:
                    this.menuClick.route_academy_a = false
                    break
                case 14:
                    this.menuClick.route_academy_r = false
                    break
                case 15:
                    this.menuClick.route_national_a = false
                    break
                case 16:
                    this.menuClick.route_national_r = false
                    break
                case 17:
                    this.menuClick.route_identity_r = false
                    break
                case 18:
                    this.menuClick.email = false
                    break
                case 19:
                    this.menuClick.last_name = false
                    break
            }
        },
        ac_refreshData(position) {
            switch(position) {
                case 1: 
                    this.dataObservation.full_name_obs = null
                    this.errorsForm.full_name = false
                    break
                case 2:
                    this.dataObservation.ci_obs = null
                    this.errorsForm.ci = false
                    break
                case 3:
                    this.dataObservation.issued_in_obs = null
                    this.errorsForm.issued_in = false
                    break
                case 4:
                    this.dataObservation.gender_obs = null
                    this.errorsForm.gender = false
                    break
                case 5:
                    this.dataObservation.date_of_birth_obs = null
                    this.errorsForm.date_of_birth = false
                    break
                case 6:
                    this.dataObservation.phone_obs = null
                    this.errorsForm.phone = false
                    break
                case 7:
                    this.dataObservation.address_obs = null
                    this.errorsForm.address = false
                    break
                case 8:
                    this.dataObservation.code_sib_obs = null
                    this.errorsForm.code_sib = false
                    break
                case 9:
                    this.dataObservation.category_obs = null
                    this.errorsForm.category = false
                    break
                case 10:
                    this.dataObservation.route_face_obs = null
                    this.errorsForm.route_face = false
                    break
                case 11:
                    this.dataObservation.route_payment_obs = null
                    this.errorsForm.route_payment = false
                    break
                case 12:
                    this.dataObservation.route_identity_obs = null
                    this.errorsForm.route_identity = false
                    break
                case 13:
                    this.dataObservation.route_academy_a_obs = null
                    this.errorsForm.route_academy_a = false
                    break
                case 14:
                    this.dataObservation.route_academy_r_obs = null
                    this.errorsForm.route_academy_r = false
                    break
                case 15:
                    this.dataObservation.route_national_a_obs = null
                    this.errorsForm.route_national_a = false
                    break
                case 16:
                    this.dataObservation.route_national_r_obs = null
                    this.errorsForm.route_national_r = false
                    break
                case 17:
                    this.dataObservation.route_identity_r_obs = null
                    this.errorsForm.route_identity_r = false
                    break
                case 18:
                    this.dataObservation.email_obs = null
                    this.errorsForm.email = false
                    break
                case 19:
                    this.dataObservation.last_name_obs = null
                    this.errorsForm.last_name = false
                    break
            }
        },
        ac_closeObservation(position) {
            this.activateMenuClick(position)
        },
        ac_activateAffiliation() {
            if (this.errorsForm.full_name || this.errorsForm.ci || this.errorsForm.issued_in || this.errorsForm.gender
                || this.errorsForm.date_of_birth || this.errorsForm.phone || this.errorsForm.address
                || this.errorsForm.code_sib || this.errorsForm.category || this.errorsForm.route_face
                || this.errorsForm.route_payment || this.errorsForm.route_identity || this.errorsForm.route_academy_a
                || this.errorsForm.route_academy_r || this.errorsForm.route_national_a || this.errorsForm.route_national_r
                || this.errorsForm.route_identity_r || this.errorsForm.email || this.errorsForm.last_name)
                return true
            return false
        },
        ac_activateObservation() {
            if (!this.errorsForm.full_name && !this.errorsForm.ci && !this.errorsForm.issued_in && !this.errorsForm.gender
                && !this.errorsForm.date_of_birth && !this.errorsForm.phone && !this.errorsForm.address
                && !this.errorsForm.code_sib && !this.errorsForm.category && !this.errorsForm.route_face
                && !this.errorsForm.route_payment && !this.errorsForm.route_identity && !this.errorsForm.route_academy_a
                && !this.errorsForm.route_academy_r && !this.errorsForm.route_national_a && !this.errorsForm.route_national_r
                && !this.errorsForm.route_identity_r && !this.errorsForm.email && !this.errorsForm.last_name) return true
            return false
        },
        ac_sortData() {
            if (!this.errorsForm.full_name) this.dataObservation.full_name_obs = null
            if (!this.errorsForm.ci) this.dataObservation.ci_obs = null
            if (!this.errorsForm.gender) this.dataObservation.gender_obs = null
            if (!this.errorsForm.issued_in) this.dataObservation.issued_in_obs = null
            if (!this.errorsForm.date_of_birth) this.dataObservation.date_of_birth_obs = null
            if (!this.errorsForm.phone) this.dataObservation.phone_obs = null
            if (!this.errorsForm.address) this.dataObservation.address_obs = null
            if (!this.errorsForm.code_sib) this.dataObservation.code_sib_obs = null
            if (!this.errorsForm.category) this.dataObservation.category_obs = null
            if (!this.errorsForm.route_face) this.dataObservation.route_face_obs = null
            if (!this.errorsForm.route_payment) this.dataObservation.route_payment_obs = null
            if (!this.errorsForm.route_identity) this.dataObservation.route_identity_obs = null
            if (!this.errorsForm.route_academy_a) this.dataObservation.route_academy_a_obs = null
            if (!this.errorsForm.route_academy_r) this.dataObservation.route_academy_r_obs = null
            if (!this.errorsForm.route_national_a) this.dataObservation.route_national_a_obs = null
            if (!this.errorsForm.route_national_r) this.dataObservation.route_national_r_obs = null
            if (!this.errorsForm.route_identity_r) this.dataObservation.route_identity_r_obs = null
            if (!this.errorsForm.email) this.dataObservation.email_obs = null
            if (!this.errorsForm.last_name) this.dataObservation.last_name_obs = null
            this.$emit('clickObservation')
        },
        ac_validPreviewObs(observation) {
            if (observation !== null) return true
            return false
        },
        ac_validTypeFile(route) {
            const dataRoute = route.split(".")
            if (dataRoute[1] != 'pdf') return true
            return false
        }
    }
}
</script>
