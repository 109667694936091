var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"formchangepass",staticClass:"pt-2",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.ac_sendForm()}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.isMyProfile)?_c('MTextFieldComp',{staticClass:"mb-2",attrs:{"clearable":true,"label":"Username","dense":true,"counter":20,"rules":[function (v) { return !this$1.m_rule_emptyField(v) || 'El dato es requerido'; },
            function (v) { return (v+'').length<=20 || 'El username no debe exeder los 20 dígitos'; },
            function (v) { return (v+'').length>=6 || 'El username debe ser igual o mayor a 6 dígitos'; }]},on:{"click":function($event){return _vm.ac_sendForm()}},model:{value:(_vm.dataUser.username),callback:function ($$v) {_vm.$set(_vm.dataUser, "username", $$v)},expression:"dataUser.username"}}):_vm._e(),_c('MTextFieldComp',{staticClass:"mb-2",attrs:{"clearable":true,"label":"Nuevo Password","dense":true,"counter":20,"type":_vm.showPassword ? 'text' : 'password',"icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"rules":[function (v) { return !this$1.m_rule_emptyField(v) || 'El dato es requerido'; },
            function (v) { return !this$1.m_rule_withSpaces(v) || 'El password no debe contener espacios'; },
            function (v) { return (v+'').length<=20 || 'El password no debe exeder los 20 dígitos'; },
            function (v) { return (v+'').length>=5 || 'El password debe ser igual o mayor a 5 dígitos'; }]},on:{"clicksee":function($event){_vm.showPassword = !_vm.showPassword},"click":function($event){return _vm.ac_sendForm()}},model:{value:(_vm.dataUser.password),callback:function ($$v) {_vm.$set(_vm.dataUser, "password", $$v)},expression:"dataUser.password"}}),_c('MTextFieldComp',{staticClass:"mb-2",attrs:{"clearable":true,"label":"Repita Password","dense":true,"counter":20,"type":_vm.showPassword ? 'text' : 'password',"icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"rules":[function (v) { return !this$1.m_rule_emptyField(v) || 'El dato es requerido'; },
            function (v) { return !this$1.m_rule_withSpaces(v) || 'El password no debe contener espacios'; },
            function (v) { return (v+'').length<=20 || 'El password no debe exeder los 20 dígitos'; },
            function (v) { return (v+'').length>=5 || 'El password debe ser igual o mayor a 5 dígitos'; },
            function (v) { return !this$1.ac_passwordEquals(v) || 'Las contraseñas no coinciden'; }]},on:{"clicksee":function($event){_vm.showPassword = !_vm.showPassword},"click":function($event){return _vm.ac_sendForm()}},model:{value:(_vm.dataUser.repitpassword),callback:function ($$v) {_vm.$set(_vm.dataUser, "repitpassword", $$v)},expression:"dataUser.repitpassword"}}),_c('div',{staticClass:"text-center"},[_c('MBtnNormalComp',{attrs:{"color":"primary","outlined":true},on:{"click":function($event){return _vm.ac_sendForm()}}},[_vm._v("CAMBIAR")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }