<template>
    <div class="card-person card-person--big-size animation__card--opacity d-flex">
        <div class="mr-2">
            <div
                class="card-person__photo"
                style="cursor:pointer"
                @click="$emit('clickbig')"
            >
                <v-img
                    :src="publicity.route_img|imgMiniature"
                    width="100%"
                    height="100%"
                    style="border-radius:5px"
                    contain
                ></v-img>
            </div>
            <div style="width:120px" class="d-flex justify-center flex-wrap mt-1">
                <OBtnOptionToolTipComp
                    :smsTooltip="`Editar noticia`"
                    icon="mdi-pencil"
                    style="margin:2px"
                    @click="$emit('clickedit')"
                />
                <OBtnOptionToolTipComp
                    :smsTooltip="`Eliminar noticia`"
                    icon="mdi-trash-can"
                    style="margin:2px"
                    @click="$emit('clickdelete')"
                />
                <OBtnOptionToolTipComp
                    :smsTooltip="publicity.public==1 ? 'Dejar de publicar noticia' : 'Publicar noticia'"
                    :icon="publicity.public==1 ? 'mdi-eye' : 'mdi-eye-off'"
                    style="margin:2px"
                    @click="$emit('clickpublic')"
                />
                <OBtnOptionToolTipComp
                    smsTooltip="Información completa"
                    icon="mdi-format-list-bulleted"
                    style="margin:2px"
                    @click="$emit('clickdetail')"
                />
            </div>
        </div>
        <div>
            <h1 class="text-body-private-title">{{publicity.title}}</h1>
            <div class="d-flex align-center">
                <h1 class="text-body-private-title mr-1">Estado:</h1>
                <h1 class="text-body-private-subtitle mr-1">
                    {{publicity.public==0 ? 'No publicado' : 'Publicado'}}</h1>
                <div :class="m_user_stateOrnament(publicity.public)"></div>
            </div>
            <div class="d-flex align-center">
                <h1 class="text-body-private-title mr-1">Expiración:</h1>
                <h1 class="text-body-private-subtitle mr-1">
                    {{m_date_formatDate(publicity.expiration)}}</h1>
                <div :class="ac_getExpiration(publicity.expiration)"></div>
            </div>
            <div class="d-flex align-center">
                <h1 class="text-body-private-title mr-1">Fecha Reg.:</h1>
                <h1 class="text-body-private-subtitle">
                    {{publicity.date}}</h1>
            </div>
            <v-divider class="mt-1 mb-1"></v-divider>
            <h1 class="text-body-private-small">
                {{publicity.description!=null ? ac_getResumenDescription() : 'Sin Descripción'}}</h1>
        </div>
    </div>
</template>
<script>
import { imgMiniature } from '../../filters'
import { userMixin, datesMixin } from '../../mixin'
import {
    OBtnOptionToolTipComp
} from '../organisms'
export default {
    mixins: [ userMixin, datesMixin ],
    filters: { imgMiniature },
    components: {
        OBtnOptionToolTipComp
    },
    props: {
        publicity: {}
    },
    methods: {
        ac_getExpiration(expiration) {
            if (expiration >= this.m_date_systemDate(false)) return 'card-person__ornament card-person__ornament-correct'
            return 'card-person__ornament card-person__ornament-error'
        },
        ac_getResumenDescription() {
            var newDescription = ''
            if (this.publicity.description.length > 100) {
                for(var i=0; i<=100; i++) {
                    newDescription += this.publicity.description[i]
                }
                newDescription += '....'
                return newDescription
            } else return this.publicity.description
        }
    }
}
</script>