<template>
    <table class="s-table-review-procedure animation-opacity" border="0">
        <thead>
            <th>N°</th>
            <th>Fecha de Envío</th>
            <th>Hora de Envío</th>
            <th>Estado</th>
            <th>Fecha de Revisión</th>
            <th>Hora de Revisión</th>
            <th>Acciones</th>
        </thead>
        <tbody>
            <tr
                v-for="(interested, index) in listInterested"
                :key="index"
            >
                <td class="s-table-review-procedure__border-line">
                    {{(index+1)}}</td>
                <td class="s-table-review-procedure__border-line">{{interested.date}}</td>
                <td class="s-table-review-procedure__border-line">{{interested.hours}}</td>
                <td class="s-table-review-procedure__border-line">
                    {{interested.validated_date!=null ? 'Revisado' : 'Sin revisión'}}
                </td>
                <td class="s-table-review-procedure__border-line">
                    {{interested.validated_date!=null ? interested.validated_date : 'S/F'}}</td>
                <td class="s-table-review-procedure__border-line">
                    {{interested.validate_hours!=null ? interested.validate_hours : 'S/H'}}</td>
                <td>
                    <div class="d-flex justify-center">
                        <OBtnOptionToolTipComp
                            smsTooltip="Ver formulario"
                            icon="mdi-clipboard-list"
                            @click="$emit('clickshowform', interested)"
                        />
                    </div>
                </td>
            </tr>
            <tr v-if="listInterested.length==0" class="text-center">
                <td colspan="5">SIN DATOS</td>
            </tr>
        </tbody>
    </table>
</template>
<script>
import { OBtnOptionToolTipComp } from '../organisms'
export default {
    props: {
        listInterested: Array
    },
    components: {
        OBtnOptionToolTipComp
    }
}
</script>