<template>
    <v-dialog persistent :value="dialog" width="380">
        <v-card class="pa-0" color="#FAFAFA">
            <div class="d-flex justify-center primary pa-2">
                <h1 style="letter-spacing:1px;" class="text-body-private-title-inverse">CONFIRMACIÓN</h1>
            </div>
            <div class="mb-2 pt-3 d-flex justify-center">
                <v-icon x-large class="animation__icon--rotate" color="primary">mdi-information</v-icon>
            </div>
            <v-card-text class="pa-1 d-block">
                <h1
                    class="text-body-subtitle text-center"
                    v-for="(error, index) in smsConfirm" :key="index"
                >
                    {{error}}
                </h1>
            </v-card-text>
            <v-card-actions class="justify-center pb-3">
                <MBtnNormalComp
                    :outlined="true"
                    color="#ff0000b0"
                    @click="$emit('clickno')"
                >
                    NO
                </MBtnNormalComp>
                <MBtnNormalComp
                    :outlined="true"
                    color="#008000b6"
                    @click="$emit('clickyes')"
                >
                    SI
                </MBtnNormalComp>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { MBtnNormalComp } from '../molecules'
export default {
    components:{
        MBtnNormalComp
    },
    props: {
        dialog: Boolean,
        smsConfirm: Array
    }
}
</script>
