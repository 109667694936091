<template>
    <div class="app-public" style="height:100%;">
        <div class="d-flex justify-center">
            <OMenuComp
                :listMenu="listMenu"
                :numberNews="listNews.length"
                @clicknews="ac_showNotices()"
                @clickface="ac_openFacebook()"
            />
        </div>
        <OMenuMobile
            :listMenu="listMenu"
            :numberNews="listNews.length"
            @clicknews="ac_showNotices()"
            @clickface="ac_openFacebook()"
        />
        <router-view />
        <OFooterComp />
        <OButtonWhatsAppComp
            v-if="listPersonsWhatsapp.length>0"
            :listPersonsWhatsapp="listPersonsWhatsapp"
            @clicknumber="ac_openWhatsapp"
        />
        <div class="app-public__btn-scroll">
            <v-btn
                :ripple="false"
                fab
                small
                dark
                color="orange"
                @click="ac_upScroll()"
            >
                <v-icon>mdi-chevron-up</v-icon>            
            </v-btn>
        </div>
        <div class="notice__group" :style="styleNotices">
            <div class="notice__fill">
                <div class="d-flex align-center pa-1 pr-2 pl-2" style="background:#2ca59f">
                    <h1 class="text-body-subtitle-inverse">CERRAR</h1>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="white"
                        depressed
                        :ripple="false"
                        fab
                        x-small
                        @click="ac_closeNotices()"
                    >
                        <v-icon color="#ca0000">mdi-close</v-icon>
                    </v-btn>
                </div>
                <div
                    v-for="(newNotice, index) in listNews"
                    :key="index"
                >
                    <div class="pl-2 pr-2 pb-3 notice__fill-information" @click="ac_goNews(newNotice)">
                        <h1 class="text-body-new-title" v-if="newNotice.type_publicity=='s'">ACTIVIDAD SOCIAL</h1>
                        <h1 class="text-body-new-title" v-if="newNotice.type_publicity=='l'">OPORTUNIDAD LABORAL</h1>
                        <h1 class="text-body-new-title" v-if="newNotice.type_publicity=='a'">OPORTUNIDAD ACADÉMICA</h1>
                        <h1 class="text-body-new-title">{{newNotice.title}}</h1>
                        <h1 class="text-body-new-subtitle">{{newNotice.subtitle}}</h1>
                        <div class="d-flex mt-1">
                            <div class="notice__fill-img mr-1">
                                <v-img
                                    :src="newNotice.route_img|imgMiniature"
                                    width="100%"
                                    height="100%"
                                    contain
                                ></v-img>
                            </div>
                            <div class="d-flex align-center">
                            <h1 class="text-body-new-subtitle">
                                {{ac_getResumenDescription(newNotice.description)}}
                            </h1>
                            </div>
                        </div>
                    </div>
                    <div class="notice__ornament-line"></div>
                </div>
            </div>
        </div>
        <v-dialog
            v-model="dialogNoticeMobile"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card class="ma-0 pa-0">
                <div class="d-flex align-center pa-1 pr-2 pl-2" style="background:#2ca59f">
                    <h1 class="text-body-subtitle-inverse">CERRAR</h1>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="white"
                        depressed
                        :ripple="false"
                        fab
                        x-small
                        @click="ac_closeMobileNew()"
                    >
                        <v-icon color="#ca0000">mdi-close</v-icon>
                    </v-btn>
                </div>
                <div
                    v-for="(newNotice, index) in listNews"
                    :key="index"
                >
                    <div class="pl-2 pr-2 pb-3 notice__fill-information" @click="ac_goNews(newNotice)">
                        <h1 class="text-body-new-title" v-if="newNotice.type_publicity=='s'">ACTIVIDAD SOCIAL</h1>
                        <h1 class="text-body-new-title" v-if="newNotice.type_publicity=='l'">OPORTUNIDAD LABORAL</h1>
                        <h1 class="text-body-new-title" v-if="newNotice.type_publicity=='a'">OPORTUNIDAD ACADÉMICA</h1>
                        <h1 class="text-body-new-title">{{newNotice.title}}</h1>
                        <h1 class="text-body-new-subtitle">{{newNotice.subtitle}}</h1>
                        <div class="d-flex mt-1">
                            <div class="notice__fill-img mr-1">
                                <v-img
                                    :src="newNotice.route_img|imgMiniature"
                                    width="100%"
                                    height="100%"
                                    contain
                                ></v-img>
                            </div>
                            <div class="d-flex align-center">
                            <h1 class="text-body-new-subtitle">
                                {{ac_getResumenDescription(newNotice.description)}}
                            </h1>
                            </div>
                        </div>
                    </div>
                    <div class="notice__ornament-line"></div>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import {
    OMenuComp,
    OMenuMobile,
    OButtonWhatsAppComp,
    OFooterComp
} from '../../components/organisms'
import { Whatsapp, Publicity } from '../../models'
import { imgMiniature } from '../../filters'
import { scrollMixin } from '../../mixin'
export default {
    mixins: [scrollMixin],
    filters: {
        imgMiniature
    },
    components: {
        OMenuComp,
        OMenuMobile,
        OButtonWhatsAppComp,
        OFooterComp
    },
    data: () => ({
        dialogNoticeMobile: false,
        listPersonsWhatsapp: [],
        listMenu: [
            {
                icon: "mdi-inbox-full",
                title: "Conócenos",
                submenu: [
                    {
                        title: "Quienes somos",
                        route: "about",
                        icon: "mdi-account-supervisor-circle"
                    },
                    {
                        title: "Directorio",
                        route: "directory-public",
                        icon: "mdi-account-tie"
                    }
                ]
            },{
                icon: "mdi-inbox-full",
                title: "Afiliados",
                submenu: [
                    {
                        title: "Nuestros afiliados",
                        route: "abis-members",
                        icon: "mdi-note-search-outline"
                    }, {
                        title: "Afiliarse a ABIS Ch. profesionales",
                        route: "membership",
                        icon: "mdi-file-table-outline"
                    }, {
                        title: "Afiliarse a ABIS Ch. universitarios",
                        route: "membership-academic",
                        icon: "mdi-file-table-outline"
                    }, {
                        title: "Verificar trámite",
                        route: "verify-process",
                        icon: "mdi-file-search-outline"
                    }
                ]
            },{
                icon: "mdi-comment-account",
                title: "Noticias",
                submenu: [
                    {
                        title: "Oportunidades laborales",
                        route: "job-opportunities",
                        icon: "mdi-file-document"
                    },{
                        title: "Oportunidades académicas",
                        route: "academic-opportunities",
                        icon: "mdi-account-tie"
                    },{
                        title: "Actividades sociales",
                        route: "social-activities",
                        icon: "mdi-human-greeting-proximity"
                    },{
                        title: "Convenios interinstitucionales",
                        route: "interinstitutional",
                        icon: "mdi-human-greeting-proximity"
                    }
                ]
            },{
                icon: "mdi-file-account",
                title: "Publicaciones",
                submenu: [
                   {
                        title: "Documentos",
                        route: "documents-public",
                        icon: "mdi-file-pdf-box"
                    },{
                        title: "Imágenes",
                        route: "gallery-public",
                        icon: "mdi-image"
                    }
                ]
            }
        ],
        listNews: [],
        styleNotices: 'right: -100%; opacity:0;'
    }),
    methods: {
        ac_upScroll() {
            window.scroll({
                top: 0,
                behavior: 'smooth'
            });
        },
        getRequest() {
            const getlistNews = Publicity.getLastNews()
            const getListNumbers = Whatsapp.listPhones()
            Promise.all([getlistNews, getListNumbers]).then(responses => {
                this.listNews = responses[0]
                this.listPersonsWhatsapp = responses[1]
                if (responses[0].length > 0 && window.innerWidth>=500) this.styleNotices = 'right: 0px; opacity:1;'
            }).catch(error => {
                console.log(error)
            })
        },
        ac_showNotices() {
            if (window.innerWidth > 480) {
                if (this.listNews.length > 0) {
                    this.styleNotices = 'right: 0px; opacity:1;'
                }
            } else this.dialogNoticeMobile = true
        },
        ac_closeNotices() {
            this.styleNotices = 'right: -100%; opacity:0;'
        },
        ac_closeMobileNew() {
            this.dialogNoticeMobile = false
        },
        ac_getResumenDescription(description) {
            if (description === null) return ''
            else {
                var newDescription = ''
                if (description.length > 120) {
                    for(var i=0; i<=120; i++) {
                        newDescription += description[i]
                    }
                    newDescription += '...'
                    return newDescription
                } else return description
            }
        },
        ac_goNews(news) {
            if (window.innerWidth > 480) this.styleNotices = 'right: -100%; opacity:0;'
            else this.dialogNoticeMobile = false
            setTimeout(() => {
                var goRoute = true
                if (this.$route.name == 'publication') {
                    if (this.$route.params.id == news.id) {
                        goRoute = false
                    }
                }
                if (goRoute) {
                    var velocity = this.m_scroll_velocity(window.scrollY)
                    this.m_scroll_animation(velocity)
                    setTimeout(() => {
                        this.$router.push({name: 'publication', params: news})
                    }, velocity)
                }
            }, 250)
        },
        ac_openWhatsapp(number) {
            setTimeout(() => {
                window.open('https://api.whatsapp.com/send?phone=591'+number.number)
            }, 250)
        },
        ac_openFacebook() {
            window.open('https://www.facebook.com/ABIS-Filial-Chuquisaca-105543531963236')
        }
    },
    mounted() {
        this.getRequest()
    }
}
</script>