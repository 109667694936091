<template>
    <div class="card-person card-person--normal-size animation__card--opacity">
        <div class="d-flex">
            <h1 class="text-body-private-title mr-1">N°:</h1>
            <h1 class="text-body-private-subtitle">
                {{index}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-private-title mr-1">Fecha de Envío:</h1>
            <h1 class="text-body-private-subtitle">
                {{interested.date}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-private-title mr-1">Hora de Envío:</h1>
            <h1 class="text-body-private-subtitle">
                {{interested.hours}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-private-title mr-1">Estado:</h1>
            <h1 class="text-body-private-subtitle">
                {{interested.validated_date!=null ? 'Revisado' : 'Sin revisión'}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-private-title mr-1">Fecha de Revisión:</h1>
            <h1 class="text-body-private-subtitle">
                {{interested.validated_date!=null ? interested.validated_date : 'S/F'}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-private-title mr-1">Hora de Revisión:</h1>
            <h1 class="text-body-private-subtitle">
                {{interested.validate_hours!=null ? interested.validate_hours : 'S/H'}}</h1>
        </div>
        <v-divider></v-divider>
        <div class="d-flex justify-center mt-2">
            <OBtnOptionToolTipComp
                smsTooltip="Ver formulario"
                icon="mdi-clipboard-list"
                @click="$emit('click')"
            />
        </div>
    </div>
</template>
<script>
import { OBtnOptionToolTipComp } from '../../components/organisms'
export default {
    props: {
        interested: Object,
        index: Number
    },
    components: {
        OBtnOptionToolTipComp
    }
}
</script>