<template>
    <div
        :class="errorPhoto ? 'o-photo o-photo__error' : 'o-photo o-photo__correct'"
        @click='$refs.inputFile.click()'
        style="cursor:pointer"
    >
        <div class="o-photo__picture d-flex align-center">
            <div v-if="haveImage"
                class="d-flex align-center"
                style="background:white; width:100%; height:100%;"
            >
                <v-img
                    v-if="!selectOtherImg"
                    :src="srcPreviewImage|imgMiniature"
                    width="100%"
                    height="100%"
                    contain
                ></v-img>
                <v-img
                    v-else
                    :src="imgPhotography"
                    width="100%"
                    height="100%"
                    contain
                ></v-img>
            </div>
            <div
                v-else
                class="d-flex align-center"
                style="background:white; width:100%; height:100%;"
            >
                <div
                    v-if="imgPhotography!=''"
                    class="d-flex align-center"
                    style="background:white; width:100%; height:100%;"
                >
                    <v-img
                        :src="imgPhotography"
                        width="100%"
                        height="100%"
                        contain
                    ></v-img>
                </div>
                <div v-else class="o-photo__picture d-flex align-center">
                    <div style="width:100%">
                        <h1 class="text-body-private-small-title text-center">SIN FOTO</h1>
                        <h1 class="text-body-private-small text-center">(Formato JPEG)</h1>
                        <h1 class="text-body-private-small text-center">(Máximo: 2Mb)</h1>
                    </div>
                </div>
            </div>
        </div>
        <input type="file"
            style="display: none"
            ref="inputFile"
            accept="image/jpeg"
            @change="ac_onFilePicked"
        >
        <!--accept="image/*"-->
    </div>
</template>
<script>
import { imgMiniature } from '../../filters'
export default {
    filters: {
        imgMiniature
    },
    props: {
        errorPhoto: Boolean,
        haveImage: Boolean,
        srcPreviewImage: String
    },
    data: () => ({
        imgPhotography: '',
        selectOtherImg: false
    }),
    methods: {
        loadImg(file) {
            let reader = new FileReader()
            reader.onload = (e) =>{
                this.imgPhotography = e.target.result
            }
            reader.readAsDataURL(file)
        },
        resetMiniature() {
            this.imgPhotography = ''
            this.selectOtherImg = false
        },
        ac_onFilePicked(e) {
            const files = e.target.files
            if (files[0] !== undefined) {
                let file= e.target.files[0]
                if (file.size <= 2097152) {//2097152
                    this.selectOtherImg = true
                    this.loadImg(file)
                    this.$emit('onChangePhoto', {'file': e.target.files[0], 'is_big': false})
                } else {
                    this.selectOtherImg = false
                    this.imgPhotography = ''
                    this.$emit('onChangePhoto', {'file': null, 'is_big': true})
                }
            } else {
                this.selectOtherImg = false
                this.imgPhotography = ''
                this.$emit('onChangePhoto', {'file': null, 'is_big': false})
            }
        }
    }
}
</script>