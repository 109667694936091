<template>
    <div class="card-person card-person--big-size d-flex animation__card--opacity">
        <div class="mr-2">
            <div class="card-person__photo">
                <v-img
                    :src="person.route_face|imgMiniature"
                    width="100%"
                    height="100%"
                    style="border-radius:5px"
                    contain
                ></v-img>
            </div>
            <div
                class="d-flex justify-center flex-wrap mt-1"
                style="width:110px"
            >
                <OBtnOptionToolTipComp
                    icon="mdi-format-list-bulleted"
                    colorBtn="#6b99cac7"
                    style="margin:2px"
                    :smsTooltip="'Ver información de: '+person.full_name"
                    @click="$emit('clickdetail')"
                />
                <OBtnOptionToolTipComp
                    v-if="!isInfo"
                    icon="mdi-pencil"
                    colorBtn="#6b99cac7"
                    style="margin:2px"
                    :smsTooltip="'Editar datos de: '+person.full_name"
                    @click="$emit('clickupdate')"
                />
                <OBtnOptionToolTipComp
                    v-if="isDisabled"
                    icon="mdi-eye-off"
                    colorBtn="#6b99cac7"
                    style="margin:2px"
                    :smsTooltip="'Habilitar a: '+person.full_name"
                    @click="$emit('clickenabled')"
                />
                <OBtnOptionToolTipComp
                    v-if="!isDisabled"
                    icon="mdi-file-table"
                    colorBtn="#6b99cac7"
                    style="margin:2px"
                    :smsTooltip="'Habilitar CV de: '+person.full_name"
                    @click="$emit('clickcv')"
                />
                <OBtnOptionToolTipComp
                    v-if="!isDisabled"
                    icon="mdi-account-switch"
                    colorBtn="#6b99cac7"
                    style="margin:2px"
                    :smsTooltip="'Cambiar la categoría de: '+person.full_name"
                    @click="$emit('clickcategory')"
                />
                <OBtnOptionToolTipComp
                    v-if="!isDisabled"
                    icon="mdi-account-key"
                    colorBtn="#6b99cac7"
                    style="margin:2px"
                    :smsTooltip="'Reiniciar código de: '+person.full_name"
                    @click="$emit('clickchangecode')"
                />
            </div>
        </div>
        <div>
            <div class="d-flex">
                <div>
                    <v-icon v-if="person.gender=='h'" color="blue" class="mr-1">mdi-face-man</v-icon>
                    <v-icon v-if="person.gender=='m'" color="pink" class="mr-1">mdi-face-woman</v-icon>
                    <v-icon v-if="person.gender=='o'" color="purple" class="mr-1">mdi-face-man-shimmer</v-icon>
                </div>
                <text-highlight
                    class="text-body-private-title"
                    :queries="searchPerson"
                >
                    {{ac_getFullName(person)}}
                </text-highlight>
                <!--<h1 class="text-body-private-title">{{person.full_name}}</h1>-->
            </div>
            <v-divider></v-divider>
            <div class="d-flex">
                <h1 class="text-body-private-title mr-1">CI:</h1>
                <text-highlight
                    class="text-body-private-subtitle"
                    :queries="searchPerson"
                >
                    {{person.ci}}
                </text-highlight>
                <h1 class="text-body-private-subtitle ml-1">{{person.issued_in}}</h1>
            </div>
            <div class="d-flex">
                <h1 class="text-body-private-title mr-1">Celular:</h1>
                <h1 class="text-body-private-subtitle">{{person.phone}}</h1>
            </div>
            <div class="d-flex" v-if="person.code_sib!=null">
                <h1 class="text-body-private-title mr-1">RNI:</h1>
                <h1 class="text-body-private-subtitle">{{person.code_sib}}</h1>
            </div>
            <div class="d-flex">
                <h1 class="text-body-private-title mr-1">Categoría:</h1>
                <h1 class="text-body-private-subtitle" v-if="person.category=='A'">Activo</h1>
                <h1 class="text-body-private-subtitle" v-if="person.category=='B'">Transeunte</h1>
                <h1 class="text-body-private-subtitle" v-if="person.category=='C'">Adherente</h1>
                <h1 class="text-body-private-subtitle" v-if="person.category=='U'">Universitario</h1>
            </div>
            <div class="d-flex">
                <h1 class="text-body-private-title mr-1">N° Reg. ABIS Ch.:</h1>
                <h1 class="text-body-private-subtitle">{{person.identifier}}</h1>
            </div>
            <div class="d-flex align-center">
                <h1 class="text-body-private-title mr-1">Expiración:</h1>
                <h1 class="text-body-private-subtitle mr-1">
                    {{m_date_formatDate(person.date_of_expiry)}}</h1>
                <div :class="ac_validateDate()"></div>
            </div>
        </div>
    </div>
</template>
<script>

import { datesMixin } from '../../mixin'
import { imgMiniature } from '../../filters'
import TextHighlight from 'vue-text-highlight'
import {
    OBtnOptionToolTipComp
} from '../organisms'
export default {
    components: {
        OBtnOptionToolTipComp,
        TextHighlight
    },
    filters: {
        imgMiniature
    },
    mixins: [
        datesMixin
    ],
    props: {
        person: {},
        searchPerson: String,
        isInfo: {
            type: Boolean,
            default: false
        },
        isDisabled: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        ac_validateDate() {
            if (this.person.date_of_expiry < this.m_date_systemDate(false))
                return 'card-person__ornament card-person__ornament-error'
            else return 'card-person__ornament card-person__ornament-correct'
        },
        ac_getFullName(person) {
            return `${person.full_name} ${person.last_name}`
        }
    }
}
</script>