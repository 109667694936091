<template>
    <div>
        <OBackgroundComp
            :imgBackground="imgBackground"
        />
        <div class="d-flex justify-center space-top-two mt-2">
            <div class="home__first">
                <h1 class="text-body-big text-center">PARA LOS AFILIADOS</h1>
                <h1 class="text-body-subtitle text-center">Cuando proteges el agua, proteges la vida</h1>
                <div class="d-flex justify-center flex-wrap space-top-two">
                    <div
                        class="home__first-group"
                        v-for="(option, index) in listFirstGroup"
                        :key="index"
                    >
                        <div class="home__first-group-img">
                            <v-img
                                :src="option.img"
                                width="100%"
                                height="100%"
                                contain
                            ></v-img>
                        </div>
                        <h1 style="font-size:16px; font-weight:500;" class="text-body-title text-center mt-6">{{option.title}}</h1>
                        <div class="text-center mt-2">
                            <MBtnNormalComp
                                :outlined="true"
                                color="blue"
                                @click="ac_goRouter(option.route)"
                            >VISUALIZAR</MBtnNormalComp>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="space-top-two">
            <div class="d-flex justify-center space-top space-bottom" style="background: #17191b">
                <div class="home__first">
                    <h1 class="text-body-big-inverse text-center">SOBRE ABIS CHUQUISACA</h1>
                    <h1 class="text-body-subtitle-inverse text-center mt-3" style="padding-left:10px; padding-right:10px;">
                        La Asociación Boliviana de Ingeniería Sanitaria y Ambiental - Chuquisaca,
                        es una organización profesional, sin carácter político y sin fines de lucro,
                        creada con el propósito de mejorar las condiciones sanitarias y ambientales
                        de nuestro departamento a través de gestiones técnicas, académicas y sociales
                        para potenciar a sus socios y convertirlos en profesionales referentes a nivel
                        nacional e internacional.
                    </h1>
                    <div class="text-center mt-3">
                        <MBtnNormalComp
                            color="blue"
                            :outlined="true"
                            class="mr-2"
                            @click="ac_goRouter('about')"
                        >QUIENES SOMOS</MBtnNormalComp>
                        <MBtnNormalComp
                            color="blue"
                            :outlined="true"
                            @click="ac_goRouter('directory-public')"
                        >DIRECTORIO</MBtnNormalComp>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-center space-top space-bottom">
            <div class="home__first home__third">
                <div class="home__third-information">
                    <div>
                        <h1 class="text-body-supertitle">BUSCANDO EL PROGRESO</h1>
                        <div class="home__third-ornament-line mb-6 mt-2"></div>
                        <div
                            class="d-flex mt-4"
                            v-for="text in listThird"
                            :key="text.title"
                        >
                            <div class="mr-3">
                                <v-img
                                    width="60px"
                                    max-width="60px"
                                    height="60px"
                                    max-height="60px"
                                    class="d-flex justify-center align-center"
                                    src="/imgs/public/home/form5.svg">
                                    <div class="text-center">
                                        <v-icon class="text-center" style="font-size:26px" color="rgba(255,255,255,0.8)">
                                            {{text.icon}}
                                        </v-icon>
                                    </div>
                                </v-img>
                            </div>
                            <div>
                                <h1 class="text-body-private-title">{{text.title}}</h1>
                                <h1 class="text-body-private-subtitle">{{text.text}}</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-center">
                    <div class="home__third-image">
                        <v-img
                            src="/imgs/public/home/progreso_img.jpg"
                            width="100%"
                            height="100%"
                            class="home__third-image--img"
                        ></v-img>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-center">
            <div class="home__first home__for d-flex flex-wrap">
                <div
                    v-for="(card, index) in listFor"
                    :key="index"
                    :class="index%2==0 ? 'home__for-container' : 'home__for-container pt-3 pb-3'"
                >
                    <div
                        style="height:100%"
                        :class="index%2==0 ? 'home__for--background-p pa-2 pt-4' : 'home__for--background-s pa-2 pt-4'"
                    >
                        <div class="text-center">
                            <v-icon color="rgba(255,255,255,0.8)" style="font-size:50px">
                                {{card.icon}}
                            </v-icon>
                        </div>
                        <h1 class="text-body-title-inverse text-center mt-3">{{card.title}}</h1>
                        <div class="home__first-ornament-line"></div>
                        <div class="home__first-ornament-line"></div>
                        <div class="text-center mt-3 mb-2">
                            <MBtnNormalComp
                                :outlined="true"
                                color="#D4D4D5"
                                @click="ac_goRouter(card.route)"
                            >VISUALIZAR</MBtnNormalComp>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="listRecommendation.length!=0">
            <div class="d-flex justify-center space-top">
                <div class="home__first space-left-title">
                    <h1 class="text-body-supertitle">ÚLTIMAS NOTICIAS</h1>
                    <div class="home__third-ornament-line mb-2 mt-2"></div>
                </div>
            </div>
            <div class="d-flex justify-center space-bottom">
                <div class="home__first d-flex justify-center flex-wrap">
                    <div
                        v-for="(publicity, index) in listRecommendation"
                        :key="index"
                        class="home__card-recommendation"
                    >
                        <div class="home__card-recommendation-img">
                            <v-img
                                :src="publicity.route_img|imgMiniature"
                                width="100%"
                                height="100%"
                            ></v-img>
                        </div>
                        <h1 class="text-body-private-title-inverse mt-1">{{publicity.title}}</h1>
                        <h1 class="text-body-private-subtitle-inverse">{{publicity.subtitle}}</h1>
                        <v-divider></v-divider>
                        <h1 class="text-body-private-subtitle-inverse mt-1">
                            {{ac_getDescription(publicity.description)}}</h1>
                        <MBtnNormalComp
                            :depressed="true"
                            color="white"
                            class="mt-1"
                            :dark="true"
                            :outlined="true"
                            @click="ac_goToPublication(publicity)"
                        >
                        VER NOTICIA</MBtnNormalComp>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="space-top"></div>
    </div>
</template>
<script>
import { MBtnNormalComp } from '../../components/molecules'
import {
    OBackgroundComp
} from '../../components/organisms'
import { scrollMixin } from '../../mixin'
import { Publicity } from '../../models'
import { imgMiniature } from '../../filters'
export default {
    filters: { imgMiniature },
    metaInfo() {
        const title = `ABIS Chuquisaca`
        return {
            title: title
        }
    },
    mixins: [ scrollMixin ],
    components: {
        OBackgroundComp,
        MBtnNormalComp
    },
    data: () => ({
        listRecommendation: [],
        imgBackground: '/imgs/public/home/fondo.jpg',
        listFor: [
            {
                title: 'BANCO DE PROFESIONALES',
                icon: 'mdi-account-tie',
                route: 'abis-members'
            },{
                title: 'CONVENIOS INTERINSTITUCIONALES',
                icon: 'mdi-handshake-outline',
                route: 'interinstitutional'
            },{
                title: 'DOCUMENTOS IMPORTANTES',
                icon: 'mdi-file-document-multiple-outline',
                route: 'documents-public'
            },{
                title: 'GALERÍA DE FOTOS',
                icon: 'mdi-image',
                route: 'gallery-public'
            }
        ],
        listThird: [
            {
                title: 'ÁMBITO INSTITUCIONAL',
                text: `Alianzas nacionales e internacionales coadyuvando en la elaboración de reglamentos y normas relacionadas con la ingeniería sanitaria.`,
                icon: 'mdi-home-analytics'
            },{
                title: 'ÁMBITO TÉCNICO',
                text: `Aporte contínuo en el asesoramiento de soluciones técnicas a problemas del ámbito de la ingeniería sanitaria y ambiental.`,
                icon: 'mdi-account-wrench-outline'
            },{
                title: 'ÁMBITO ACADÉMICO',
                text: `Actividades de formación y actualización con sólidos principios de base científica, en un marco de ética convirtiéndose en un referente para la región.`,
                icon: 'mdi-brain'
            },{
                title: 'ÁMBITO SOCIAL',
                text: `Participación activa en la sociedad en actividades para el desarrollo del departamento y concientización social.`,
                icon: 'mdi-human-queue'
            }
        ],
        listFirstGroup: [
            {
                title: 'OPORTUNIDADES LABORALES',
                subtitle: `Muestra tus habilidades y
                    capacidades en la muestra de tu curriculum vitae`,
                img: `/imgs/public/icons/icon_1_b.png`,
                route: 'job-opportunities'
            },{
                title: 'AFILIACIÓN VIRTUAL',
                subtitle: `Nuestra afiliación se realiza por medio de nuestra página`,
                img: `/imgs/public/icons/icon_2_b.png`,
                route: 'membership'
            },{
                title: 'OPORTUNIDADES ACADÉMICAS',
                subtitle: `Muestra tus habilidades y
                    capacidades en la muestra de tu curriculum vitae`,
                img: `/imgs/public/icons/icon_4_b.png`,
                route: 'academic-opportunities'
            },{
                title: 'ACTIVIDADES SOCIALES',
                subtitle: `Muestra tus habilidades y
                    capacidades en la muestra de tu curriculum vitae`,
                img: `/imgs/public/icons/icon_3_b.png`,
                route: 'social-activities'
            }
        ]
    }),
    methods: {
        ac_goRouter(name_route) {
            if (this.$route.name != name_route) {
                var velocity = this.m_scroll_velocity(window.scrollY)
                this.m_scroll_animation(velocity)
                setTimeout(() => {
                    this.$router.push({name: name_route})
                }, velocity)
            }
        },
        getRecommendation() {
            Publicity.getRecommendations(0, 3).then(response => {
                this.listRecommendation = response
            }).catch(error => {
                console.log(error)
            })
        },
        ac_getDescription(description) {
            if (description === null) return ''
            else {
                if (description.length <= 70) return description
                else {
                    var newDescription = ''
                    for (var i = 0; i < 70; i++) {
                        newDescription += description[i]
                    }
                    return newDescription+'...'
                }
            }
        },
        ac_goToPublication(publication) {
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            setTimeout(() => {
                this.$router.push({name: 'publication', params: publication})
            }, velocity)
        }
    },
    mounted() {
        if (window.innerWidth <= 600) {
            this.imgBackground = "/imgs/public/home/fondo_mobile.jpg"
        }
        this.getRecommendation()
    }
}
</script>