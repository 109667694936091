export default {
    methods: {
        m_description_text(description, widthWindows) {
            if (description === null) return ''
            else {
                var limit = this.m_description_calculateLength(widthWindows)
                if ((description+'').length > limit) {
                    var newDescription = ''
                    for(var i=0; i < (limit-50); i++) {
                        newDescription += description[i]
                    }
                    return newDescription+'...'
                } else return description
            }
        },
        m_description_calculateLength(widthWindows) {
            if (widthWindows >= 1150) return 400
            if (widthWindows >= 900 && widthWindows < 1150) return 300
            if (widthWindows >= 700 && widthWindows < 900) return 170
            return 160
        },
        /*m_description_validBtnActivate(description, widthWindows) {
            if (description === null) return false
            else {
                var limit = this.m_description_calculateLength(widthWindows)
                if ((description+'').length > limit) return true
                return false
            }
        }*/
    }
}