<template>
    <v-form
        v-on:submit.prevent="ac_confirmQualitie()"
        ref="formqualitie"
        v-model="validQualitie"
        lazy-validation
    >
        <ODialogHeaderComp
            :title="indexQualitie!=-1 ? 'Editar Cualidad' : 'Nueva Cualidad'"
            @clickclose="$emit('clickclose')"
        />
        <v-card class="ma-0 pa-2 pt-5">
            <MTextFieldComp
                label="Cualidad"
                v-model="dataQualitie.qualitie"
                :dense="true"
                :counter="100"
                :rules="[v => !m_rule_emptyField(v) || 'El dato es requerido',
                    v => (v + '').length <= 100 || 'La cualidad no puede exceder los 20 dígitos'
                ]"
            />
            <h1 class="text-body-private-subtitle">Calificación</h1>
            <v-progress-linear
                v-model="dataQualitie.progress"
                color="blue-grey"
                height="25"
                >
                <template v-slot:default="{ value }">
                    <strong>{{ Math.ceil(value) }}%</strong>
                </template>
            </v-progress-linear>
            <div class="text-center mt-3">
                <MBtnNormalComp
                    :outlined="true"
                    color="primary"
                    @click="ac_confirmQualitie()"
                >{{indexQualitie!=-1 ? 'GUARDAR' : 'REGISTRAR'}}</MBtnNormalComp>
            </div>
        </v-card>
    </v-form>
</template>
<script>
import {
    MBtnNormalComp,
    MTextFieldComp
} from '../../components/molecules'
import {
    ODialogHeaderComp
} from '../../components/organisms'
import {
    rulesMixin
} from '../../mixin'
export default {
    mixins: [ rulesMixin ],
    components: {
        MBtnNormalComp,
        MTextFieldComp,
        ODialogHeaderComp
    },
    props: {
        indexQualitie: Number,
        dataQualitie: Object
    },
    data: () => ({
        validQualitie: true
    }),
    methods: {
        validateQualitie() {
            if (!this.$refs.formqualitie.validate()) return false
            return true
        },
        ac_confirmQualitie() {
            if (this.validateQualitie() && this.dataQualitie.progress > 0) this.$emit('clickaction', true)
            else this.$emit('clickaction', false)
        },
        resetForm() {
            this.$refs.formqualitie.resetValidation()
        }
    }
}
</script>