import axios from 'axios'
import { apiUrls } from '../plugins/apiUrls'
import { userStore } from '../store/modules'

export default class Directory {
    constructor (
        full_name = null,
        position = null,
        description = null,
        position_view = null,
        route_file = null,
        name_file = null,
        new_img = 0
    ) {
        this.full_name = full_name
        this.position = position
        this.description = description
        this.position_view = position_view
        this.route_file = route_file
        this.name_file = name_file
        this.new_img = new_img
    }
    static getListDirectory() {
        return axios.get(
            apiUrls.DIRECTORY + '/list',
        ).then(response => {
            return response.data
        })
    }
    static addDirectory(parameters) {
        return axios.post(
            apiUrls.DIRECTORY + '/add',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static updateDirectory(parameters) {
        return axios.post(
            apiUrls.DIRECTORY + '/update',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static deleteDirectory(parameters) {
        return axios.delete(
            apiUrls.DIRECTORY + '/delete',
            { headers: userStore.state.header, data: parameters } 
        ).then(response => {
            return response.data
        })
    }
}