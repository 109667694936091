<template>
    <div>
        <div class="user__header pa-2">
            <div class="d-flex justify-center align-center">
                <v-app-bar-nav-icon class="mr-2 user__icon-menu" @click="m_menuComp_changeStateMenu()"></v-app-bar-nav-icon>
                <h1 class="text-body-private-title">DIRECTORIO</h1>
            </div>
            <v-divider class="mb-2 mt-1 user__line-mobile"></v-divider>
            <v-spacer></v-spacer>
            <div class="d-flex align-center">
                <MTextFieldComp
                    v-if="tab==0"
                    :dense="true"
                    :hideDetails="true"
                    label="Buscar"
                    :singleLine="true"
                    v-model="search"
                />
                <OBtnHeaderToolTipComp
                    v-if="tab==0"
                    smsTooltip="Nuevo Integrante"
                    icon="mdi-plus"
                    class="ml-1"
                    @click="tab=1"
                />
                <OBtnHeaderToolTipComp
                    v-if="tab==1"
                    class="ml-1"
                    smsTooltip="Volver a la lista"
                    icon="mdi-database"
                    @click="ac_backListDirectories()"
                />
            </div>
        </div>
        <MLineHeaderComp />
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <OLoaderDataComp
                    class="mt-2"
                    v-if="dialogLoaderData"
                    smsLoaderData="Obteniendo Información"
                />
                <MNoDataComp
                    v-if="!dialogLoaderData && listDirectoryFilter.length==0"
                    smsNotData="SIN DIRECTORIO"
                    class="ma-2"
                />
                <div class="d-flex justify-center flex-wrap">
                    <SCardDirectoryComp
                        class="ma-2"
                        v-for="(directory, index) in listDirectoryFilter"
                        :key="index"
                        :directory="directory"
                        :searchDirectory="searchDirectoryFilter"
                        @clickedit="ac_showFormUpdate(directory)"
                        @clickdelete="ac_confirmDeleteDirectory(directory)"
                        @clickdetail="ac_showDetailDirectory(directory)"
                    />
                </div>
            </v-tab-item>
            <v-tab-item>
                <div class="d-flex justify-center pa-2 pt-0 pb-3">
                    <v-form
                        v-on:submit.prevent="ac_confirmAction()"
                        ref="formdirectory"
                        v-model="valid"
                        lazy-validation
                        class="user--border user--radius pa-3 mt-3"
                        style="width:600px"
                    >
                        <h1 class="text-body-private-title text-center mb-2">
                            {{indexDirectory==-1 ? 'NUEVO INTEGRANTE' : 'EDITAR INTEGRANTE'}}</h1>
                        <div class="d-flex justify-center">
                            <OPhotoPublicComp
                                ref="photopublic"
                                :errorPhoto="errorPhoto"
                                :haveImage="haveImage"
                                :srcPreviewImage="dataDirectory.route_face!=undefined ? dataDirectory.route_face : ''"
                                @onChangePhoto="ac_changePhoto"
                            />
                        </div>
                        <MTextFieldComp
                            class="mt-3"
                            v-model="dataDirectory.full_name"
                            :clearable="true"
                            label="Nombre Completo"
                            :dense="true"
                            :counter="50"
                            :rules="[v => !this.m_rule_emptyField(v) || 'El dato es requerido',
                                v => (v+'').length<=50 || 'El nombre no debe exeder los 50 dígitos']"
                        />
                        <MTextFieldComp
                            class="mt-3"
                            v-model="dataDirectory.position"
                            :clearable="true"
                            label="Cargo"
                            :dense="true"
                            :counter="30"
                            :rules="[v => !this.m_rule_emptyField(v) || 'El dato es requerido',
                                v => (v+'').length<=30 || 'El cargo no debe exeder los 30 dígitos']"
                        />
                        <MTextAreaComp
                            class="mt-3"
                            v-model="dataDirectory.description"
                            :clearable="true"
                            label="Descripción"
                            :dense="true"
                            :counter="1000"
                            height="120"
                            :rules="[v => !this.m_rule_emptyField(v) || 'El dato es requerido',
                                v => (v+'').length<=1000 || 'La descripción no debe exeder los 1000 dígitos']"
                        />
                        <MSelectComp
                            label="Posición jerárquica"
                            v-model="dataDirectory.position_view"
                            :items="listPositionFilter"
                            :rules="[v => !m_rule_emptyField(v) || 'La posición es requerida']"
                        />
                        <div class="text-center">
                            <MBtnNormalComp
                                color="primary"
                                :outlined="true"
                                @click="ac_confirmAction()"
                            >GUARDAR</MBtnNormalComp>
                        </div>
                    </v-form>
                </div>
            </v-tab-item>
        </v-tabs-items>
        <v-dialog persistent width="550" v-model="dialogDetailDirectory">
            <ODialogHeaderComp
                title="Información Completa"
                @clickclose="dialogDetailDirectory=false"
            />
            <v-card class="ma-0 pa-0">
                <div class="pa-2" v-if="infoDirectory.full_name!=undefined">
                    <div class="d-flex">
                        <h1 class="text-body-private-title mr-1">Nombre:</h1>
                        <h1 class="text-body-private-subtitle">{{infoDirectory.full_name}}</h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="text-body-private-title mr-1">Posición:</h1>
                        <h1 class="text-body-private-subtitle">{{infoDirectory.position}}</h1>
                    </div>
                    <v-divider class="mt-1 mb-1"></v-divider>
                    <h1 class="text-body-private-title">Descripción</h1>
                    <h1 class="text-body-private-subtitle">{{infoDirectory.description}}</h1>
                </div>
            </v-card>
        </v-dialog>
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_closeSmsError()"
        />
        <OSmsConfirmComp
            :dialog="dialogConfirm"
            :smsConfirm="smsConfirm"
            @clickno="ac_closeSmsError()"
            @clickyes="ac_perfomOperation()"
        />
        <OSmsActionComp
            :frameCorrect="frameCorrect"
            :smsAction="smsAction"
            :timeout="timeSucess"
            :colorMessage="colorMessage"
            @click="m_dataGeneral_frameTimeReset()"
        />
        <OLoaderOperationComp
            :dialog="dialogLoaderOperation"
            smsLoaderOperation="Realizando Operación"
        />
    </div>
</template>
<script>
import {
    MTextFieldComp,
    MLineHeaderComp,
    MNoDataComp,
    MBtnNormalComp,
    MTextAreaComp,
    MSelectComp
} from '../../components/molecules'
import {
    OBtnHeaderToolTipComp,
    OSmsErrorComp,
    OSmsConfirmComp,
    OSmsActionComp,
    OLoaderOperationComp,
    OLoaderDataComp,
    OPhotoPublicComp,
    ODialogHeaderComp
} from '../../components/organisms'
import {
    SCardDirectoryComp
} from '../../components/species'
import {
    dataGeneralComponetsMixin,
    responseServerMixin,
    rulesMixin,
    userMixin,
    menuCompMixing,
    scrollMixin
} from '../../mixin'
import {
    Directory
} from '../../models'
export default {
    mixins: [
        dataGeneralComponetsMixin,
        responseServerMixin,
        rulesMixin,
        userMixin,
        menuCompMixing,
        scrollMixin
    ],
    components: {
        MTextFieldComp,
        OBtnHeaderToolTipComp,
        MLineHeaderComp,
        SCardDirectoryComp,
        OSmsErrorComp,
        OSmsConfirmComp,
        OSmsActionComp,
        OLoaderOperationComp,
        OLoaderDataComp,
        MNoDataComp,
        MBtnNormalComp,
        MTextAreaComp,
        MSelectComp,
        OPhotoPublicComp,
        ODialogHeaderComp
    },
    data: () => ({
        valid: true,
        viewTable: true,
        search: '',
        dataDirectory: new Directory(),
        indexDirectory: -1,
        idDirectory: 0,
        listDirectories: [],
        isDelete: false,
        tab: 0,
        listPosition: ['1','2','3','4','5','6','7','8','9','10'],
        errorPhoto: false,
        haveImage: false,
        infoDirectory: {},
        dialogDetailDirectory:false
    }),
    computed: {
        listDirectoryFilter: function() {
            if (this.search === null) return this.listDirectories
            else {
                return this.listDirectories.filter(directory => {
                    return directory.full_name.toLowerCase()
                            .indexOf(this.search.toLowerCase()) !== -1
                })
            }
        },
        searchDirectoryFilter: function() {
            if (this.search === null) return ''
            else return this.search
        },
        listPositionFilter: function() {
            var positionsFilter = []
            for (var i = 0; i < this.listPosition.length; i++) {
                const positions = this.listDirectories.filter(directory => {
                    return directory.position_view === this.listPosition[i]
                })
                if (positions.length === 0) positionsFilter.push(this.listPosition[i])
                else {
                    if (this.haveImage) {
                        if (this.listPosition[i] === this.dataDirectory.position_view) positionsFilter.push(this.listPosition[i])
                    }
                }
            }
            return positionsFilter
        }
    },
    methods: {
        processError(error) {
            this.dialogLoaderOperation = false
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_action(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        ac_closeSmsError() {
            if (this.noAccess) {
                localStorage.removeItem('abis')
                this.$router.replace('/login')
            } else {
                this.indexDirectory = -1
                this.dialogError = false
                this.dialogConfirm = false
                if (!this.isDelete) { this.isDelete = false }
            }
        },
        getListDirectories() {
            this.dialogLoaderData = true
            Directory.getListDirectory().then(response => {
                this.listDirectories = response
                this.dialogLoaderData = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_changePhoto(data) {
            if (data.is_big) {
                this.dataDirectory.route_file = null
                this.errorPhoto = true
                this.dataDirectory.new_img = 0
                this.showEvent(['Error de imagen',
                    'El tamaño no debe exceder los 2MB'], 'error')
            } else {
                this.dataDirectory.route_file = data.file
                if (data.file !== null) {
                    this.errorPhoto = false
                    this.dataDirectory.new_img = 1
                } else {
                    this.dataDirectory.new_img = 0
                    if (this.indexDirectory === -1) this.errorPhoto = true
                }
            }
        },
        validateFormDirectory() {
            if (!this.$refs.formdirectory.validate()) return false
            return true
        },
        smsErrorForm() {
            this.smsError = [`Revise los datos marcados con rojo y corrija porfavor`]
            this.dialogError = true
            this.actionError = true
        },
        ac_confirmAction() {
            if (!this.validateFormDirectory()) {
                if (this.indexDirectory === -1 && this.dataDirectory.route_file === null) this.errorPhoto = true
                this.smsErrorForm()
            } else {
                if (this.indexDirectory === -1 && this.dataDirectory.route_file === null) {
                    this.errorPhoto = true
                    this.smsErrorForm()
                } else {
                    var velocity = this.m_scroll_velocity(window.scrollY)
                    this.m_scroll_animation(velocity)
                    setTimeout(() => {
                        if (this.indexDirectory !== -1) this.smsConfirm = ['¿Está seguro de editar los datos de:', `${this.dataDirectory.full_name}?`]
                        else this.smsConfirm = ['¿Está seguro de registrar a: ', `${this.dataDirectory.full_name}?`]
                        this.dialogConfirm = true
                    }, velocity)
                }
            }
        },
        showEvent(message, colorSms) {
            this.colorMessage = colorSms
            this.smsAction = message
            this.frameCorrect = true
            this.timeSucess = setTimeout(() => {
                this.frameCorrect = false
            }, 3000)
        },
        deleteDirectory() {
            Directory.deleteDirectory({id: this.idDirectory}).then(() => {
                this.listDirectories.splice(this.indexDirectory, 1)
                this.indexDirectory = -1
                this.idDirectory = 0
                this.isDelete = false
                this.dialogLoaderOperation = false
                this.showEvent(['Operación exitosa',
                    'se eliminó el integrante'], 'success')
            }).catch(error => {
                this.processError(error)
            })
        },
        addDirectory() {
            this.dataDirectory.name_file = new Date().getTime()
            Directory.addDirectory(this.$objectToFD(this.dataDirectory)).then(response => {
                this.listDirectories.unshift(response)
                this.ac_backListDirectories()
                this.dialogLoaderOperation = false
                this.showEvent(['Operación exitosa',
                    'se registró el integrante'], 'success')
            }).catch(error => {
                this.processError(error)
            })
        },
        updateDirectory() {
            this.dataDirectory.name_file = new Date().getTime()
            Directory.updateDirectory(this.$objectToFD(this.dataDirectory)).then(response => {
                Object.assign(this.listDirectories[this.indexDirectory], response)
                this.ac_backListDirectories()
                this.dialogLoaderOperation = false
                this.showEvent(['Operación exitosa',
                    'se aditó el integrante'], 'success')
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_perfomOperation() {
            this.dialogConfirm = false
            this.dialogLoaderOperation = true
            if (this.isDelete) this.deleteDirectory()
            else {
                if (this.indexDirectory !== -1) this.updateDirectory()
                else this.addDirectory()
            }
        },
        ac_backListDirectories() {
            this.tab = 0
            setTimeout(() => {
                this.haveImage = false
                this.$refs.photopublic.resetMiniature()
                this.indexDirectory = -1
                this.dataDirectory = new Directory()
                this.$refs.formdirectory.resetValidation()
                this.errorPhoto = false
            }, 200)  
        },
        ac_showFormUpdate(directory) {
            this.haveImage = true
            this.indexDirectory = this.listDirectories.indexOf(directory)
            this.dataDirectory = Object.assign({}, directory)
            this.dataDirectory.new_img = 0
            this.dataDirectory.route_file = null
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            setTimeout(() => {
                this.tab = 1
            }, velocity)
        },
        ac_confirmDeleteDirectory(directory) {
            this.isDelete = true
            this.indexDirectory = this.listDirectories.indexOf(directory)
            this.idDirectory = directory.id
            this.smsConfirm = ['¿Está seguro de eliminar a:', `${directory.full_name}?`]
            this.dialogConfirm = true
        },
        ac_showDetailDirectory(directory) {
            this.infoDirectory = directory
            this.dialogDetailDirectory = true
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.viewTable = false
        if (this.m_user_saveToken()) {
            this.getListDirectories()
        } else this.$router.replace('/login')
    }
}
</script>