import axios from "axios"
import { apiUrls } from '../plugins/apiUrls'
export default class Specialty {
    constructor (
        specialty = null,
        entity = null,
        location = null,
        route_file = null,
        issuance_certificate = null,
        name_file = null
    ) {
        this.specialty = specialty
        this.entity = entity
        this.location = location
        this.route_file = route_file
        this.issuance_certificate = issuance_certificate
        this.name_file = name_file
    }
    static addSpecialty(parameters) {
        return axios.post(
            apiUrls.SPECIALTY + '/add',
            parameters
        ).then(response => {
            return response.data
        })
    }
    static updateSpecialty(parameters) {
        return axios.post(
            apiUrls.SPECIALTY + '/update',
            parameters
        ).then(response => {
            return response.data
        })
    }
}