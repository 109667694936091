<template>
    <div>
        <div class="user__header pa-2">
            <div class="d-flex justify-center align-center">
                <v-app-bar-nav-icon class="mr-2 user__icon-menu" @click="m_menuComp_changeStateMenu()"></v-app-bar-nav-icon>
                <h1 class="text-body-private-title">AFILIADOS EXPIRADOS</h1>
            </div>
            <v-divider class="mb-2 mt-1 user__line-mobile"></v-divider>
            <v-spacer></v-spacer>
            <div class="d-flex align-center">
                <OSearchComp
                    :dense="true"
                    :hideDetails="true"
                    label="Buscar"
                    :singleLine="true"
                    v-model="search"
                    @search="ac_searchPerson()"
                />
                <OBtnHeaderToolTipComp
                    class="ml-1"
                    :smsTooltip="viewTable ? 'Cambiar a vista por cards' : 'Cambiar a vista por tabla'"
                    :icon=" viewTable ? 'mdi-table-eye' : 'mdi-table-eye-off'"
                    @click="viewTable = !viewTable"
                />
            </div>
        </div>
        <MLineHeaderComp />
        <div class="d-flex pa-2">
            <h1 class="text-body-private-title mr-1">N° de Afiliados Expirados:</h1>
            <h1 class="text-body-private-subtitle">{{totalExpired}}</h1>
        </div>
        <v-divider></v-divider>
        <OLoaderDataComp
            class="mt-2"
            v-if="dialogLoaderData"
            smsLoaderData="Obteniendo Afiliados"
        />
        <MNoDataComp
            v-if="!dialogLoaderData && listPerson.length==0 && !viewTable"
            smsNotData="SIN AFILIADOS"
            class="ma-2"
        />
        <div class="d-flex justify-center mt-3" v-if="viewTable">
            <STableAffiliationsComp
                v-if="!dialogLoaderData"
                :listPerson="listPerson"
                :searchPerson="searchPersonFilter"
                :isInfo="true"
                @clickdetail="ac_showDetailPerson"
            />
        </div>
        <div class="d-flex justify-center flex-wrap" v-else>
            <SCardPersonComp
                v-for="(person, index) in listPerson"
                :key="index"
                class="ma-2"
                :person="person"
                :searchPerson="searchPersonFilter"
                :isInfo="true"
                @clickdetail="ac_showDetailPerson(person)"
            />
        </div>
        <v-dialog
            persistent
            width="420px"
            v-model="dialogDetail"
        >
            <v-card class="ma-0 pa-0">
                <ODialogHeaderComp
                    :title="person.full_name+' '+person.last_name"
                    @clickclose="dialogDetail=false"
                />
                <OInfoAffiliateComp
                    :person="person"
                />
            </v-card>
        </v-dialog>
        <SPaginationComp
            class="mt-2"
            v-if="!dialogLoaderData && listPerson.length>0"
            :condicionGo="page < last_page"
            :condicionBack="page > 1"
            :titlePagination="'Pag. '+page+' de '+last_page"
            @clickback="ac_changePage(false)"
            @clickgo="ac_changePage(true)"
        />
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_closeSmsError()"
        />
    </div>
</template>
<script>
import {
    MLineHeaderComp,
    MNoDataComp
} from '../../components/molecules'
import {
    OSearchComp,
    OLoaderDataComp,
    OSmsErrorComp,
    OBtnHeaderToolTipComp,
    OInfoAffiliateComp,
    ODialogHeaderComp
} from '../../components/organisms'
import {
    STableAffiliationsComp,
    SCardPersonComp,
    SPaginationComp
} from '../../components/species'
import {
    Person
} from '../../models'
import {
    menuCompMixing,
    dataGeneralComponetsMixin,
    scrollMixin,
    rulesMixin,
    responseServerMixin,
    userMixin
} from '../../mixin'
export default {
    mixins: [
        menuCompMixing,
        dataGeneralComponetsMixin,
        scrollMixin,
        rulesMixin,
        responseServerMixin,
        userMixin
    ],
    components: {
        MLineHeaderComp,
        OSearchComp,
        MNoDataComp,
        OLoaderDataComp,
        OSmsErrorComp,
        STableAffiliationsComp,
        SCardPersonComp,
        SPaginationComp,
        OBtnHeaderToolTipComp,
        OInfoAffiliateComp,
        ODialogHeaderComp
    },
    data: () => ({
        search: '',
        listPerson: [],
        page: 0,
        last_page: 0,
        viewTable: true,
        dialogDetail: false,
        person: {},
        totalExpired: 0
    }),
    computed: {
        searchPersonFilter: function() {
            if (this.m_rule_emptyField(this.search)) return ''
            else return this.search
        }
    },
    methods: {
        processError(error) {
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_action(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        getPersons(page, search) {
            this.listPerson = []
            this.dialogLoaderData = true
            Person.getExpiryAffiliate(page, search).then(response => {
                this.listPerson = response.data
                this.page = response.page
                this.last_page = response.last_page
                this.totalExpired = response.total
                this.dialogLoaderData = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_closeSmsError() {
            if (this.noAccess) {
                localStorage.removeItem('abis')
                this.$router.replace('/login')
            } else this.dialogError = false
        },
        ac_changePage(state) {
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            if (state) { ++this.page }
            else { --this.page }
            setTimeout(() => {
                this.getPersons(this.page, this.search)
            }, velocity)
        },
        ac_searchPerson() {
            if (this.search===null) {
                this.search = ''
                this.getPersons(1, '')
            } else this.getPersons(1, this.search)
        },
        ac_showDetailPerson(person) {
            this.person = person
            this.dialogDetail = true
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.viewTable = false
        if (this.m_user_saveToken()) {
            this.getPersons(1, '')
        } else this.$router.replace('/login')
    }
}
</script>