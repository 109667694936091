<template>
    <div>
        <div class="d-none">
            <div ref="sheetimg">
                <h1 class="text-body-private-title">{{dataSheet.title}}</h1>
                <div class="d-flex">
                    <h1 class="text-body-private-title mr-1">Interesado</h1>
                    <h1 class="text-body-private-subtitle">{{dataSheet.interested}}</h1>
                </div>
                <div class="d-flex">
                    <h1 class="text-body-private-title mr-1">CI</h1>
                    <h1 class="text-body-private-subtitle">{{dataSheet.ci}}</h1>
                </div>
                <div class="d-flex mt-1 error">
                    <v-img
                        :src="dataSheet.routeImg|imgNormal"
                        width="100%"
                        contain
                    ></v-img>
                </div>
            </div>
        </div>
        <div class="section-print">
            <div class="user__header pa-2">
                <div class="d-flex justify-center align-center">
                    <v-app-bar-nav-icon class="mr-2 user__icon-menu" @click="m_menuComp_changeStateMenu()"></v-app-bar-nav-icon>
                    <h1 class="text-body-private-title">RECEPCIÓN - UNIVERSITARIOS</h1>
                </div>
                <v-divider class="mb-2 mt-1 user__line-mobile"></v-divider>
                <v-spacer></v-spacer>
                <div class="d-flex align-center">
                    <OSearchComp
                        v-if="tab==0"
                        :dense="true"
                        :hideDetails="true"
                        label="Buscar"
                        :singleLine="true"
                        v-model="search"
                        @search="ac_searchProcess()"
                    />
                    <OBtnHeaderToolTipComp
                        v-if="tab==1"
                        smsTooltip="Volver a la lista de trámites."
                        icon="mdi-database"
                        class="ml-1"
                        @click="ac_backTab(0)"
                    />
                    <OBtnHeaderToolTipComp
                        v-if="tab==2"
                        smsTooltip="Volver a la lista de solicitudes."
                        icon="mdi-format-list-bulleted"
                        class="ml-1"
                        @click="ac_backTab(1)"
                    />
                    <OBtnHeaderToolTipComp
                        class="ml-1"
                        smsTooltip="Refrescar la lista de trámites"
                        icon="mdi-refresh"
                        @click="ac_refreshList()"
                    />
                    <OBtnHeaderToolTipComp
                        class="ml-1"
                        :smsTooltip="viewTable ? 'Cambiar a vista por cards' : 'Cambiar a vista por tabla'"
                        :icon="viewTable ? 'mdi-table-eye' : 'mdi-table-eye-off'"
                        @click="viewTable = !viewTable"
                    />
                </div>
            </div>
            <MLineHeaderComp />
            <v-tabs-items
                style="background:transparent !important"
                v-model="tab"
                :touchless="true"
            >
                <v-tab-item>
                    <OLoaderDataComp
                        class="mt-2"
                        v-if="dialogLoaderData"
                        smsLoaderData="Obteniendo Trámites"
                    />
                    <MNoDataComp
                        v-if="!dialogLoaderData && listReviewProcedure.length==0 && !viewTable"
                        smsNotData="SIN TRÁMITES RECIBIDOS"
                        class="ma-2"
                    />
                    <div class="d-flex justify-center mt-3" v-if="viewTable">
                        <STableReviewProcedureComp
                            v-if="!dialogLoaderData"
                            :listReviewProcedure="listReviewProcedure"
                            :searchCi="searchCiFilter"
                            @clickform="ac_showListForms"
                            @clickdelete="ac_confirmDeleteProcess"
                        />
                    </div>
                    <div class="d-flex justify-center flex-wrap" v-else>
                        <SCardReviewProcessComp
                            class="ma-2"
                            v-for="(process, index) in listReviewProcedure"
                            :key="index"
                            :process="process"
                            :searchCi="searchCiFilter"
                            @clickform="ac_showListForms(process)"
                            @clickdelete="ac_confirmDeleteProcess(process)"
                        />
                    </div>
                    <SPaginationComp
                        class="mt-2"
                        v-if="!dialogLoaderData && listReviewProcedure.length>0"
                        :condicionGo="page < last_page"
                        :condicionBack="page > 1"
                        :titlePagination="'Pag. '+page+' de '+last_page"
                        @clickback="ac_changePage(false)"
                        @clickgo="ac_changePage(true)"
                    />
                </v-tab-item>
                <v-tab-item>
                    <div class="pa-2" v-if="dataProcess.date!=undefined">
                        <div class="d-flex">
                            <h1 class="text-body-private-title mr-1">Interesado:</h1>
                            <h1 class="text-body-private-subtitle">
                                {{dataProcess.list_interested[0].full_name+' '+dataProcess.list_interested[0].last_name}}</h1>
                        </div>
                        <div class="d-flex">
                            <h1 class="text-body-private-title mr-1">Interesado:</h1>
                            <h1 class="text-body-private-subtitle">{{dataProcess.list_interested[0].ci}}</h1>
                        </div>
                        <div class="d-flex">
                            <h1 class="text-body-private-title mr-1">Inicio de solicitud:</h1>
                            <h1 class="text-body-private-subtitle">{{dataProcess.date+' '+dataProcess.hours}}</h1>
                        </div>
                        <div class="d-flex">
                            <h1 class="text-body-private-title mr-1">Estado:</h1>
                            <h1 class="text-body-private-subtitle">
                                {{dataProcess.state_process=='c' ? 'Enviado' : 'Observado'}}</h1>
                        </div>
                    </div>
                    <v-divider></v-divider>
                    <div class="d-flex justify-center mt-3" v-if="viewTable">
                        <STableInterestedRequests
                            :listInterested="dataProcess.list_interested"
                            @clickshowform="ac_showFormInterested"
                        />
                    </div>
                    <div class="d-flex justify-center flex-wrap" v-else>
                        <SCardInterestedComp
                            class="ma-2"
                            v-for="(interested, index) in dataProcess.list_interested"
                            :key="index"
                            :index="index+1"
                            :interested="interested"
                            @click="ac_showFormInterested(interested)"
                        />
                    </div>
                </v-tab-item>
                <v-tab-item>
                    <div class="d-flex justify-center mt-2 mb-4">
                        <SFormReviewAcademicComp
                            :dataObservation="dataObservation"
                            :dataInterested="dataInterested"
                            :dataPreviousInterested="dataPreviousInterested"
                            :existsPreviusProcess="existsPreviusProcess"
                            :isObservation="isObservation"
                            :mobile="mobile"
                            :errorsForm="errorsForm"
                            @clickPhoto="ac_showPhoto"
                            @clickObservation="ac_confirmationObservation()"
                            @clickaffiliation="ac_saveAffiliate()"
                            @clickdownload="ac_downloadImg"
                            @clickprinter="ac_printerImg"
                        />
                    </div>
                </v-tab-item>
            </v-tabs-items>
        </div>
        <OModalImgServerComp
            :dialog="modalImg"
            :src="srcImg"
            :title="titlePDF"
            ref="modalimage"
            @clickclose="ac_closeModalImage()"
        />
        <OModalPdfComp
            :src="srcPDF"
            :dialog="dialogPDF"
            :title="titlePDF"
            @clickclose="dialogPDF=false"
        />
        <OSmsConfirmComp
            :dialog="dialogConfirm"
            :smsConfirm="smsConfirm"
            @clickno="dialogConfirm=false"
            @clickyes="ac_perfomOperation()"
        />
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_closeSmsError()"
        />
        <OLoaderOperationComp
            :dialog="dialogLoaderOperation"
            smsLoaderOperation="Realizando Operación"
        />
        <OSmsActionComp
            :frameCorrect="frameCorrect"
            :smsAction="smsAction"
            :timeout="timeSucess"
            @click="m_dataGeneral_frameTimeReset()"
        />
    </div>
</template>
<script>
import {
    MLineHeaderComp,
    MNoDataComp
} from '../../components/molecules'
import {
    OLoaderDataComp,
    OSmsErrorComp,
    OSmsConfirmComp,
    OLoaderOperationComp,
    OSmsActionComp,
    OBtnHeaderToolTipComp,
    OModalImgServerComp,
    OSearchComp,
    OModalPdfComp
} from '../../components/organisms'
import {
    STableReviewProcedureComp,
    STableInterestedRequests,
    SFormReviewAcademicComp,
    SCardReviewProcessComp,
    SPaginationComp,
    SCardInterestedComp
} from '../../components/species'
import {
    ProcessAffiliationAcademic,
    ObservationAcademic,
    AcademicFormError,
    Person
} from '../../models'
import {
    dataGeneralComponetsMixin,
    responseServerMixin,
    rulesMixin,
    userMixin,
    menuCompMixing,
    scrollMixin
} from '../../mixin'
import { imgNormal } from '../../filters'
export default {
    filters: {
        imgNormal
    },
    mixins: [
        dataGeneralComponetsMixin,
        responseServerMixin,
        rulesMixin,
        userMixin,
        menuCompMixing,
        scrollMixin
    ],
    components: {
        OLoaderDataComp,
        OSmsErrorComp,
        OSmsConfirmComp,
        OLoaderOperationComp,
        OSmsActionComp,
        MLineHeaderComp,
        STableReviewProcedureComp,
        STableInterestedRequests,
        OBtnHeaderToolTipComp,
        SFormReviewAcademicComp,
        OModalImgServerComp,
        MNoDataComp,
        SCardReviewProcessComp,
        SPaginationComp,
        OSearchComp,
        SCardInterestedComp,
        OModalPdfComp
    },
    data: () => ({
        errorsForm: new AcademicFormError(),
        tab: 0,
        listReviewProcedure: [],
        page: 0,
        last_page: 0,
        dataProcess: {},
        dataInterested: {},
        dataObservation: new ObservationAcademic(),
        isObservation: false,
        idAffiliation: false,
        indexProcess: -1,
        search: '',
        viewTable: true,
        mobile: false,
        isDelete: false,
        idProcess: -1,
        srcPDF: '',
        dialogPDF: false,
        titlePDF: '',
        modalImg: false,
        srcImg: '',
        dataPreviousInterested: {},
        existsPreviusProcess: false,
        dataSheet: {
            routeImg: '',
            title: '',
            interested: '',
            ci: ''
        }
    }),
    computed: {
        searchCiFilter: function() {
            if (this.m_rule_emptyField(this.search)) return ''
            else return this.search
        }
    },
    methods: {
        processError(error) {
            this.dialogLoaderOperation = false
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_action(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        ac_closeSmsError() {
            this.isDelete = false
            if (this.noAccess) {
                localStorage.removeItem('abis')
                this.$router.replace('/login')
            } else this.dialogError = false
        },
        getReviewProcedures(page, ci) {
            this.listReviewProcedure = []
            this.dialogLoaderData = true
            ProcessAffiliationAcademic.getListProcessAffiliation(page, ci, 's').then(response => {
                this.listReviewProcedure = response.data
                this.page = response.page
                this.last_page = response.last_page
                this.dialogLoaderData = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_showListForms(process) {
            this.dataProcess = process
            this.indexProcess = this.listReviewProcedure.indexOf(process)
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            setTimeout(() => {
                this.tab = 1
            }, velocity)
        },
        ac_showFormInterested(interested) {
            if (this.dataProcess.list_interested.length>1) {
                const indexInterested = this.dataProcess.list_interested.indexOf(interested)
                if (indexInterested != 0) {
                    this.existsPreviusProcess = true
                    this.dataPreviousInterested = this.dataProcess.list_interested[indexInterested-1]
                } else this.existsPreviusProcess = false
            } else { this.existsPreviusProcess = false }
            this.dataInterested = interested
            this.isObservation = interested.list_observations.length > 0 ? false : true
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            setTimeout(() => {
                this.tab = 2
            }, velocity)
        },
        ac_backTab(tab) {
            this.errorsForm = new AcademicFormError()
            this.tab = tab
            this.dataObservation = new ObservationAcademic()
        },
        ac_confirmationObservation() {
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            this.idAffiliation = false
            this.smsConfirm = ['¿Está seguro de observar el trámite de afiliación de:',
                `${this.dataInterested.full_name} ${this.dataInterested.last_name}?`]
            this.dataObservation.fk_interested_academic = this.dataInterested.id
            this.dataObservation.id_process = this.dataProcess.id
            setTimeout(() => {
                this.dialogConfirm = true
            }, velocity)
        },
        showEvent(message) {
            this.smsAction = message
            this.frameCorrect = true
            this.timeSucess = setTimeout(() => {
                this.frameCorrect = false
            }, 3000)
        },
        ac_perfomOperation() {
            this.dialogConfirm = false
            this.dialogLoaderOperation = true
            if (this.isDelete) {
                ProcessAffiliationAcademic.deleteProcess({'id_process': this.idProcess}).then(() => {
                    this.listReviewProcedure.splice(this.indexProcess, 1)
                    this.isDelete = false
                    this.indexProcess = -1
                    this.dialogLoaderOperation = false
                    this.showEvent(['Eliminación exitosa',
                            `El trámite fue eliminado`])
                }).catch(error => {
                    this.processError(error)
                })
            } else {
                if (this.idAffiliation) {
                    var sendDataInterested = Object.assign({}, this.dataInterested)
                    sendDataInterested.code_qr = `${new Date().getTime()}`
                    if (sendDataInterested.code_sib===null) {
                        sendDataInterested.code_sib = ''
                    }
                    sendDataInterested.id_process = this.dataProcess.id
                    Person.addAffiliateUniversity(sendDataInterested).then(() => {
                        this.listReviewProcedure.splice(this.indexProcess ,1)
                        this.dataObservation = new ObservationAcademic()
                        this.dialogLoaderOperation = false
                        this.tab = 0
                        this.indexProcess = -1
                        this.showEvent(['Afiliación exitosa',
                            `De: ${this.dataInterested.full_name}`])
                    }).catch(error => {
                        this.processError(error)
                    })
                } else {
                    ObservationAcademic.addObservation(this.dataObservation).then(() => {
                        this.listReviewProcedure.splice(this.indexProcess ,1)
                        this.dataObservation = new ObservationAcademic()
                        this.errorsForm = new AcademicFormError()
                        this.dialogLoaderOperation = false
                        this.tab = 0
                        this.indexProcess = -1
                        this.showEvent(['Observación registrada',
                            `Para: ${this.dataInterested.full_name}`])
                    }).catch(error => {
                        this.processError(error)
                    })
                }
            }
        },
        ac_saveAffiliate() {
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            this.idAffiliation = true
            this.smsConfirm = [`¿Está seguro de afiliar a ${this.dataInterested.full_name} ${this.dataInterested.last_name}?`]
            setTimeout(() => {
                this.dialogConfirm = true
            }, velocity)
        },
        //new metodos
        ac_changePage(state) {
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            if (state) { ++this.page }
            else { --this.page }
            setTimeout(() => {
                this.getReviewProcedures(this.page, this.search)
            }, velocity)
        },
        ac_searchProcess() {
            if (this.m_rule_emptyField(this.search)) {
                this.search = ''
                this.getReviewProcedures(1, '')
            } else this.getReviewProcedures(1, this.search)
        },
        ac_refreshList() {
            if (this.tab != 0) this.tab = 0
            this.getReviewProcedures(1, '')
        },
        ac_confirmDeleteProcess(process) {
            this.idProcess = process.id
            this.indexProcess = this.listReviewProcedure.indexOf(process)
            this.isDelete = true
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            this.smsConfirm = ['¿Está seguro de eliminar el trámite?']
            setTimeout(() => {
                this.dialogConfirm = true
            }, velocity)
        },
        ac_showPhoto(file) {
            const dataRoute = file.route.split(".")
            this.titlePDF = file.title
            if (dataRoute[1] != 'pdf') {
                this.srcImg = file.route
                this.modalImg = true
            } else {
                if (this.mobile) {
                    Person.showPdfMobile(file.route)
                } else {
                    this.srcPDF = ''
                    this.srcPDF = Person.showPdfPc(file.route)
                    setTimeout(() => {
                        this.dialogPDF = true
                    }, 200)
                }
            }
        },
        ac_closeModalImage() {
            this.$refs.modalimage.resetSize()
            this.modalImg = false
        },
        ac_downloadImg(route) {
            Person.downloadImage(route)
        },
        ac_printerImg(data) {
            this.dataSheet.routeImg = data.route
            this.dataSheet.title = data.title
            this.dataSheet.interested = data.interested
            this.dataSheet.ci = data.ci
            this.$nextTick(() => {
                setTimeout(() => {
                    this.print(this.$refs.sheetimg);
                }, 500)
            })
        },
        print(element) {
            let printSection = document.getElementById('printSection')
            var domClone = element.cloneNode(true)
            //var nodoImg = document.createElement("img")
            //nodoImg.setAttribute("src", '/imgs/logo.png')
            //nodoImg.onload = function() {
            printSection.appendChild(domClone)
            window.print()
            printSection.innerHTML = ""
            //}
        },
    },
    mounted() {
        if (window.innerWidth <= 600) {
            this.mobile = true
            this.viewTable = false
        }
        if (this.m_user_saveToken()) {
            this.getReviewProcedures(1, '')
        } else this.$router.replace('/login')
    }
}
</script>