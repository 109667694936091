import Vue from 'vue'
import VueRouter from 'vue-router'
import {
  Home,
  Membership,
  VerifyProcess,
  CorrectObservation,
  InfoAffiliate,
  Form,
  Afiliado,
  GalleryPublic,
  About,
  DirectoryPublic,
  AbisMembers,
  NewsPublic,
  DocumentsPublic,
  NewsAcademic,
  NewsSocial,
  ConventionsPublic,
  Publication,
  MembershipAcademic,
  FormAcademic,
  CorrectObservationAcademic
} from '../views/public'

import{
  Affiliations,
  WhatsApp,
  News,
  ReviewProcedures,
  Verify,
  Users,
  Expired,
  ProceduresStoped,
  Directory,
  Gallery,
  MyProfile,
  DisabledMembers,
  ReviewCv,
  Resources,
  SeeCv,
  ProcessPayments,
  StalledRequests,
  Documents,
  Convention,
  UniversityReview,
  VerifiUniversity,
  UniversityProcedureStopped
} from '../views/private'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
        path: '/admin',
        redirect: '/admin/affiliations'
    },
    {
      path: '/',
      component: () => import('../views/public/AppPublic'),
      children: [
        {
          path: '/',
          name: 'home',
          component: Home
        },{
          path: '/membership',
          name: 'membership',
          component: Membership
        },{
          path: '/verify-process',
          name: 'verify-process',
          component: VerifyProcess
        },{
          path: '/correct-observation',
          name: 'correct-observation',
          component: CorrectObservation
        },{
          path: '/info-affiliate',
          name: 'info-affiliate',
          component: InfoAffiliate
        },{
          path: '/form-affiliate',
          name: 'form-affiliate',
          component: Form
        },{
          path: '/afiliado/:code',
          name: 'afiliado',
          component: Afiliado
        },{
          path: '/gallery-public',
          name: 'gallery-public',
          component: GalleryPublic
        },{
          path: '/about',
          name: 'about',
          component: About
        },{
          path: '/directory-public',
          name: 'directory-public',
          component: DirectoryPublic
        },{
          path: '/abis-members',
          name: 'abis-members',
          component: AbisMembers
        },{
          path: '/job-opportunities',
          name: 'job-opportunities',
          component: NewsPublic
        },{
          path: '/documents-public',
          name: 'documents-public',
          component: DocumentsPublic
        },{
          path: '/academic-opportunities',
          name: 'academic-opportunities',
          component: NewsAcademic
        }, {
          path: '/social-activities',
          name: 'social-activities',
          component: NewsSocial
        }, {
          path: '/interinstitutional',
          name: 'interinstitutional',
          component: ConventionsPublic
        }, {
          path: '/publication/:id',
          name: 'publication',
          component: Publication
        }, {
          path: '/membership-academic',
          name: 'membership-academic',
          component: MembershipAcademic
        }, {
          path: '/university-form',
          name: 'university-form',
          component: FormAcademic
        }, {
          path: '/correct-university-procedure',
          name: 'correct-university-procedure',
          component: CorrectObservationAcademic
        }
      ]
    },
    {
      path: '/admin',
      component: () => import('../views/private/AppPrivate'),
      children: [
        {
          path: '/admin/affiliations',
          name: 'affiliations',
          component: Affiliations
        }, {
          path: '/admin/whatsapp',
          name: 'whatsapp',
          component: WhatsApp
        }, {
          path: '/admin/news',
          name: 'news',
          component: News
        }, {
          path: '/admin/review',
          name: 'review',
          component: ReviewProcedures
        }, {
          path: '/admin/verify',
          name: 'verify',
          component: Verify
        }, {
          path: '/admin/users',
          name: 'users',
          component: Users
        }, {
          path: '/admin/expired',
          name: 'expired',
          component: Expired
        }, {
          path: '/admin/procedures-stoped',
          name: 'procedures-stoped',
          component: ProceduresStoped
        }, {
          path: '/admin/directory',
          name: 'directory',
          component: Directory
        }, {
          path: '/admin/gallery',
          name: 'gallery',
          component: Gallery
        }, {
          path: '/admin/my-profile',
          name: 'my-profile',
          component: MyProfile
        }, {
          path: '/admin/disabled-members',
          name: 'disabled-members',
          component: DisabledMembers
        }, {
          path: '/admin/review-cv',
          name: 'review-cv',
          component: ReviewCv
        }, {
          path: '/admin/resources',
          name: 'resources',
          component: Resources
        }, {
          path: '/admin/see-cv',
          name: 'see-cv',
          component: SeeCv
        }, {
          path: '/admin/process-payment',
          name: 'process-payment',
          component: ProcessPayments
        }, {
          path: '/admin/stalled-requests',
          name: 'stalled-requests',
          component: StalledRequests
        }, {
          path: '/admin/documents',
          name: 'documents',
          component: Documents
        }, {
          path: '/admin/convention',
          name: 'convention',
          component: Convention
        }, {
          path: '/admin/university-review',
          name: 'university-review',
          component: UniversityReview
        }, {
          path: '/admin/verify-university',
          name: 'verify-university',
          component: VerifiUniversity
        }, {
          path: '/admin/university-procedure-stopped',
          name: 'university-procedure-stopped',
          component: UniversityProcedureStopped
        }
      ]
    },
    {
      path: '/login',
      component: () => import('../views/login/Login')
    }
  ]
})
export default router
