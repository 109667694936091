<template>
    <div>
        <div class="user__header pa-2">
            <div class="d-flex justify-center align-center">
                <v-app-bar-nav-icon class="mr-2 user__icon-menu" @click="m_menuComp_changeStateMenu()"></v-app-bar-nav-icon>
                <h1 class="text-body-private-title">NOTICIAS</h1>
            </div>
            <v-divider class="mb-2 mt-1 user__line-mobile"></v-divider>
            <v-spacer></v-spacer>
            <div class="d-flex align-center flex-wrap"> <!-- arreglar el responsive-->
                <MSelectComp
                    v-if="tab==0"
                    v-model="typePublicity"
                    :items="optionsTypePublicity"
                    style="width:220px"
                    itemValue="value"
                    itemText="title"
                    class="mr-1"
                    :hideDetails="true"
                    @change="ac_changeStatePublicity"
                />
                <div class="d-flex">
                    <MSelectComp
                        v-if="tab==0"
                        v-model="searchOption"
                        :items="itemsOptionsPublic"
                        style="width:220px"
                        itemValue="value"
                        itemText="title"
                        :hideDetails="true"
                        @change="ac_changeStatePublicity"
                    />
                    <OBtnHeaderToolTipComp
                        v-if="tab==0"
                        smsTooltip="Nueva Noticia"
                        icon="mdi-plus"
                        class="ml-1"
                        @click="tab=1"
                    />
                    <OBtnHeaderToolTipComp
                        v-if="tab==1"
                        class="ml-1"
                        smsTooltip="Volver a la lista"
                        icon="mdi-database"
                        @click="ac_backListPublicity()"
                    />
                </div>
            </div>
        </div>
        <MLineHeaderComp />
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <div class="d-flex pa-2">
                    <h1 class="text-body-private-title mr-1">Total Noticias:</h1>
                    <h1 class="text-body-private-subtitle">{{total}}</h1>
                </div>
                <v-divider></v-divider>
                <OLoaderDataComp
                    class="mt-2"
                    v-if="dialogLoaderData"
                    smsLoaderData="Obteniendo Noticias"
                />
                <MNoDataComp
                    v-if="!dialogLoaderData && listPublicity.length==0"
                    smsNotData="SIN NOTICIAS"
                    class="ma-2"
                />
                <div class="d-flex justify-center flex-wrap">
                    <SCardPublicityComp
                        class="ma-2"
                        v-for="(publicity, index) in listPublicity"
                        :key="index"
                        :publicity="publicity"
                        @clickedit="ac_showFormEditPublicity(publicity)"
                        @clickdelete="ac_confirmDeletePublicity(publicity)"
                        @clickpublic="ac_confirmChangeState(publicity)"
                        @clickbig="ac_showImgBig(publicity)"
                        @clickdetail="ac_showDetailPhoto(publicity)"
                    />
                </div>
                <SPaginationComp
                    class="mt-2"
                    v-if="!dialogLoaderData && listPublicity.length>0"
                    :condicionGo="page < last_page"
                    :condicionBack="page > 1"
                    :titlePagination="'Pag. '+page+' de '+last_page"
                    @clickback="ac_changePage(false)"
                    @clickgo="ac_changePage(true)"
                />
            </v-tab-item>
            <v-tab-item>
                <div class="d-flex justify-center pa-2 pt-0 pb-3">
                    <v-form
                        v-on:submit.prevent="ac_confirmAction()"
                        ref="formpublicity"
                        v-model="valid"
                        lazy-validation
                        class="user--border user--radius pa-3 mt-3"
                        style="width:460px"
                    >
                        <h1 class="text-body-private-title text-center mb-2">
                            {{indexPublicity==-1 ? 'NUEVA NOTICIA' : 'EDITAR NOTICIA'}}</h1>
                        <div class="d-flex justify-center">
                            <OPhotoPublicComp
                                ref="photopublic"
                                :errorPhoto="errorPhoto"
                                :haveImage="haveImage"
                                :srcPreviewImage="dataPublicity.route_img!=undefined ? dataPublicity.route_img : ''"
                                @onChangePhoto="ac_changePhoto"
                            />
                        </div>
                        <MTextFieldComp
                            class="mt-3"
                            v-model="dataPublicity.title"
                            :clearable="true"
                            label="Título"
                            :dense="true"
                            :counter="100"
                            :rules="[v => !this.m_rule_emptyField(v) || 'El dato es requerido',
                                v => (v+'').length<=100 || 'El título no debe exeder los 100 dígitos']"
                        />
                        <MTextFieldComp
                            class="mt-3"
                            v-model="dataPublicity.subtitle"
                            :clearable="true"
                            label="Subtítulo"
                            :dense="true"
                            :counter="50"
                            :rules="[v => !this.m_rule_emptyField(v) || 'El dato es requerido',
                                v => (v+'').length<=50 || 'El subtítulo no debe exeder los 50 dígitos']"
                        />
                        <MSelectComp
                            class="mt-3"
                            :items="listOptionsTypeFilter"
                            itemValue="value"
                            itemText="title"
                            v-model="dataPublicity.type_publicity"
                            label="Tipo de Noticia"
                            :dense="true"
                            :rules="[v => !this.m_rule_emptyField(v) || 'El dato es requerido']"
                        />
                        <MTextAreaComp
                            class="mt-3"
                            v-model="dataPublicity.description"
                            :clearable="true"
                            label="Descripción"
                            :dense="true"
                            :counter="1000"
                            height="120"
                            :rules="[v => (v+'').length<=1000 || 'La descripción no debe exeder los 1000 dígitos']"
                        />
                        <ODateOneComp
                            label="Fecha límite publicación"
                            v-model="dateExpiration"
                            :dense="true"
                            class="mt-2"
                            @change="ac_electionDateExpense"
                            :rules="[v => !m_rule_emptyField(v) || 'El dato es requerido',
                                v => ac_validDate(v) || 'La fecha debe ser mayor a la actual']"
                        />
                        <div class="text-center">
                            <MBtnNormalComp
                                color="primary"
                                :outlined="true"
                                @click="ac_confirmAction()"
                            >GUARDAR</MBtnNormalComp>
                        </div>
                    </v-form>
                </div>
            </v-tab-item>
        </v-tabs-items>
        <v-dialog persistent width="800" v-model="dialogDetailPhoto">
            <ODialogHeaderComp
                title="Información Completa"
                @clickclose="dialogDetailPhoto=false"
            />
            <v-card class="ma-0 pa-0">
                <div class="pa-2" v-if="infoPublicity.title!=undefined">
                    <div class="d-flex align-center">
                        <h1 class="text-body-private-title mr-1">Título:</h1>
                        <h1 class="text-body-private-subtitle">{{infoPublicity.title}}</h1>
                    </div>
                    <div class="d-flex align-center">
                        <h1 class="text-body-private-title mr-1">Subtítulo:</h1>
                        <h1 class="text-body-private-subtitle">{{infoPublicity.subtitle}}</h1>
                    </div>
                    <div class="d-flex align-center">
                        <h1 class="text-body-private-title mr-1">Expiración:</h1>
                        <h1 class="text-body-private-subtitle mr-1">
                            {{m_date_formatDate(infoPublicity.expiration)}}</h1>
                        <div :class="ac_getExpiration(infoPublicity.expiration)"></div>
                    </div>
                    <div class="d-flex align-center">
                        <h1 class="text-body-private-title mr-1">Tipo de Noticia:</h1>
                        <h1 class="text-body-private-subtitle" v-if="infoPublicity.type_publicity=='s'">Actividades sociales</h1>
                        <h1 class="text-body-private-subtitle" v-if="infoPublicity.type_publicity=='l'">Oportunidades laborales</h1>
                        <h1 class="text-body-private-subtitle" v-if="infoPublicity.type_publicity=='a'">Oportunidades académicas</h1>
                    </div>
                    <v-divider class="mt-1 mb-1"></v-divider>
                    <h1 class="text-body-private-title">DESCRIPCIÓN</h1>
                    <h1 class="text-body-private-subtitle">{{infoPublicity.description}}</h1>
                </div>
            </v-card>
        </v-dialog>
        <OBigImageComp
            :dialog="dialogImgBig"
            :listGallery="listPublicity"
            :mobile="mobile"
            :positionCarrusel="positionCarrusel"
            @clickclose="dialogImgBig=false"
        />
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_closeSmsError()"
        />
        <OSmsConfirmComp
            :dialog="dialogConfirm"
            :smsConfirm="smsConfirm"
            @clickno="ac_closeSmsError()"
            @clickyes="ac_perfomOperation()"
        />
        <OSmsActionComp
            :frameCorrect="frameCorrect"
            :smsAction="smsAction"
            :timeout="timeSucess"
            :colorMessage="colorMessage"
            @click="m_dataGeneral_frameTimeReset()"
        />
        <OLoaderOperationComp
            :dialog="dialogLoaderOperation"
            smsLoaderOperation="Realizando Operación"
        />
    </div>
</template>
<script>
import {
    MTextFieldComp,
    MLineHeaderComp,
    MNoDataComp,
    MBtnNormalComp,
    MTextAreaComp,
    MSelectComp
} from '../../components/molecules'
import {
    OBtnHeaderToolTipComp,
    OSmsErrorComp,
    OSmsConfirmComp,
    OSmsActionComp,
    OLoaderOperationComp,
    OLoaderDataComp,
    OPhotoPublicComp,
    OBigImageComp,
    ODateOneComp,
    ODialogHeaderComp
} from '../../components/organisms'
import {
    SCardDirectoryComp,
    SCardPublicityComp,
    SPaginationComp
} from '../../components/species'
import {
    dataGeneralComponetsMixin,
    responseServerMixin,
    rulesMixin,
    userMixin,
    menuCompMixing,
    scrollMixin,
    datesMixin
} from '../../mixin'
import {
    Publicity
} from '../../models'
export default {
    mixins: [
        dataGeneralComponetsMixin,
        responseServerMixin,
        rulesMixin,
        userMixin,
        menuCompMixing,
        scrollMixin,
        datesMixin
    ],
    components: {
        MTextFieldComp,
        OBtnHeaderToolTipComp,
        MLineHeaderComp,
        SCardDirectoryComp,
        OSmsErrorComp,
        OSmsConfirmComp,
        OSmsActionComp,
        OLoaderOperationComp,
        OLoaderDataComp,
        MNoDataComp,
        MBtnNormalComp,
        MTextAreaComp,
        MSelectComp,
        OPhotoPublicComp,
        SCardPublicityComp,
        SPaginationComp,
        OBigImageComp,
        ODateOneComp,
        ODialogHeaderComp
    },
    data: () => ({
        valid: true,
        viewTable: true,
        searchOption: 2,
        dataPublicity: new Publicity(),
        indexPublicity: -1,
        idPublicity: 0,
        listPublicity: [],
        page: 0,
        total: 0,
        last_page: 0,
        isDelete: false,
        tab: 0,
        errorPhoto: false,
        haveImage: false,
        itemsOptionsPublic: [
            { title: 'Todos', value: 2 },
            { title: 'Publicados', value: 1 },
            { title: 'No Publicados', value: 0 }
        ],
        isChangeState: false,
        dialogImgBig: false,
        mobile: false,
        positionCarrusel: 0,
        dateExpiration: '',
        infoPublicity: {},
        dialogDetailPhoto: false,
        optionsTypePublicity: [
            { title: 'Todos', value: 't' },
            { title: 'Oportunidades laborales', value: 'l' },
            { title: 'Oportunidades académicas', value: 'a' },
            { title: 'Actividades sociales', value: 's' }
        ],
        typePublicity: 't'
    }),
    computed: {
        listOptionsTypeFilter: function () {
            return this.optionsTypePublicity.filter(option => {
                return option.value !== 't'
            })
        }
    },
    methods: {
        processError(error) {
            this.dialogLoaderOperation = false
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_action(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        ac_closeSmsError() {
            if (this.noAccess) {
                localStorage.removeItem('abis')
                this.$router.replace('/login')
            } else {
                this.isChangeState = false
                this.indexPublicity = -1
                this.dialogError = false
                this.dialogConfirm = false
                this.isDelete = false
            }
        },
        getListPublicity(page, option_public, typePublicity) {
            this.listPublicity = []
            this.dialogLoaderData = true
            Publicity.getListPublicity(page, option_public, typePublicity).then(response => {
                this.listPublicity = response.data
                this.page = response.page
                this.last_page = response.last_page
                this.total = response.total
                this.dialogLoaderData = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_changePhoto(data) {
            if (data.is_big) {
                this.dataPublicity.route_file = null
                this.errorPhoto = true
                this.dataPublicity.new_img = 0
                this.showEvent(['Error de imagen',
                    'El tamaño no debe exceder los 2MB'], 'error')
            } else {
                this.dataPublicity.route_file = data.file
                if (data.file !== null) {
                    this.errorPhoto = false
                    this.dataPublicity.new_img = 1
                } else {
                    this.dataPublicity.new_img = 0
                    if (this.indexPublicity === -1) this.errorPhoto = true
                }
            }
        },
        validatePublicity() {
            if (!this.$refs.formpublicity.validate()) return false
            return true
        },
        smsErrorForm() {
            this.smsError = [`Revise los datos marcados con rojo y corrija porfavor`]
            this.dialogError = true
            this.actionError = true
        },
        ac_confirmAction() {
            if (!this.validatePublicity()) {
                if (this.indexPublicity === -1 && this.dataPublicity.route_file === null) this.errorPhoto = true
                this.smsErrorForm()
            } else {
                if (this.indexPublicity === -1 && this.dataPublicity.route_file === null) {
                    this.errorPhoto = true
                    this.smsErrorForm()
                } else {
                    var velocity = this.m_scroll_velocity(window.scrollY)
                    this.m_scroll_animation(velocity)
                    setTimeout(() => {
                        if (this.indexPublicity !== -1) this.smsConfirm = ['¿Está seguro de editar la noticia?']
                        else this.smsConfirm = ['¿Está seguro de registrar la noticia?']
                        this.dialogConfirm = true
                    }, velocity)
                }
            }
        },
        showEvent(message, colorSms) {
            this.colorMessage = colorSms
            this.smsAction = message
            this.frameCorrect = true
            this.timeSucess = setTimeout(() => {
                this.frameCorrect = false
            }, 3000)
        },
        deletePublicity() {
            Publicity.deletePublicity({id: this.idPublicity}).then(() => {
                this.listPublicity.splice(this.indexPublicity, 1)
                this.indexPublicity = -1
                this.idPublicity = 0
                this.isDelete = false
                this.dialogLoaderOperation = false
                this.showEvent(['Operación exitosa',
                    'se eliminó la noticia'], 'success')
            }).catch(error => {
                this.processError(error)
            })
        },
        addPublicity() {
            this.dataPublicity.name_file = new Date().getTime()
            Publicity.addPublicity(this.$objectToFD(this.dataPublicity)).then(response => {
                this.listPublicity.unshift(response)
                this.ac_backListPublicity()
                this.dialogLoaderOperation = false
                this.showEvent(['Operación exitosa',
                    'se registró la noticia'], 'success')
            }).catch(error => {
                this.processError(error)
            })
        },
        updatePhoto() {
            this.dataPublicity.name_file = new Date().getTime()
            Publicity.updatePublicity(this.$objectToFD(this.dataPublicity)).then(response => {
                Object.assign(this.listPublicity[this.indexPublicity], response)
                this.ac_backListPublicity()
                this.dialogLoaderOperation = false
                this.showEvent(['Operación exitosa',
                    'se editó la noticia'], 'success')
            }).catch(error => {
                this.processError(error)
            })
        },
        changeStatePublicity() {
            Publicity.changeState({id: this.idPublicity}).then(response => {
                this.listPublicity[this.indexPublicity].public = response
                this.isChangeState = false
                this.indexPublicity = -1
                this.dialogLoaderOperation = false
                this.showEvent(['Operación exitosa',
                    'se cambio el estado de la noticia'], 'success')
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_perfomOperation() {
            this.dialogConfirm = false
            this.dialogLoaderOperation = true
            if (this.isChangeState) {
                this.changeStatePublicity()
            } else {
                if (this.isDelete) this.deletePublicity()
                else {
                    if (this.indexPublicity !== -1) this.updatePhoto()
                    else this.addPublicity()
                }
            }
        },
        ac_backListPublicity() {
            this.tab = 0
            setTimeout(() => {
                this.dateExpiration = ''
                this.haveImage = false
                this.$refs.photopublic.resetMiniature()
                this.indexPublicity = -1
                this.dataPublicity = new Publicity()
                this.$refs.formpublicity.resetValidation()
                this.errorPhoto = false
            }, 200)  
        },
        ac_showFormEditPublicity(publicity) {
            this.dateExpiration = this.m_date_formatDate(publicity.expiration)
            this.haveImage = true
            this.indexPublicity = this.listPublicity.indexOf(publicity)
            this.dataPublicity = Object.assign({}, publicity)
            this.dataPublicity.new_img = 0
            this.dataPublicity.route_file = null
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            setTimeout(() => {
                this.tab = 1
            }, velocity)
        },
        ac_confirmDeletePublicity(publicity) {
            this.isDelete = true
            this.indexPublicity = this.listPublicity.indexOf(publicity)
            this.idPublicity = publicity.id
            this.smsConfirm = ['¿Está seguro de eliminar la noticia?']
            this.dialogConfirm = true
        },
        ac_changePage(state) {
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            if (state) { ++this.page }
            else { --this.page }
            setTimeout(() => {
                this.getListPublicity(this.page, this.searchOption, this.typePublicity)
            }, velocity)
        },
        ac_changeStatePublicity(e) {
            this.getListPublicity(1, this.searchOption, e)
        },
        ac_confirmChangeState(publicity) {
            this.idPublicity = publicity.id
            this.indexPublicity = this.listPublicity.indexOf(publicity)
            this.isChangeState = true
            const smsState = publicity.public == 0 ? 'PUBLICAR' : 'QUITAR LA PUBLICACIÓN de'
            this.smsConfirm = [`¿Está seguro de ${smsState} la noticia?`]
            this.dialogConfirm = true
        },
        ac_showImgBig(publicity) {
            this.positionCarrusel = this.listPublicity.indexOf(publicity)
            setTimeout(() => {
                this.dialogImgBig = true
            }, 150)
        },
        ac_electionDateExpense(e) {
            this.dateExpiration = this.m_date_formatDate(e)
            this.dataPublicity.expiration = e
        },
        ac_validDate() {
            if(this.dataPublicity.expiration <= this.m_date_systemDate(false)) return false
            return true
        },
        ac_showDetailPhoto(publicity) {
            this.infoPublicity = publicity
            this.dialogDetailPhoto = true
        },
        ac_getExpiration(expiration) {
            if (expiration >= this.m_date_systemDate(false)) return 'card-person__ornament card-person__ornament-correct'
            return 'card-person__ornament card-person__ornament-error'
        }
    },
    mounted() {
        if (window.innerWidth <= 600) {
            this.viewTable = false
            this.mobile = true
        }
        if (this.m_user_saveToken()) {
            this.getListPublicity(1, 2, 't')
        } else this.$router.replace('/login')
    }
    
}
</script>