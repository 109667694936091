<template>
    <div>
        <div class="d-flex justify-center">
            <div class="home__first form__contianer space-bottom-two">
                <div style="width:100%">
                    <v-tabs-items style="background:transparent !important;" v-model="tab" :touchless="true">
                        <v-tab-item>
                            <div>
                                <h1 class="text-body-big text-center">FORMULARIO DE AFILIACIÓN</h1>
                                <div class="d-flex justify-center pa-2">
                                    <SFormMembershipAcademicComp
                                        :dataInterested="dataInterested"
                                        :dataObservation="dataObservation"
                                        :firstProcess="true"
                                        :mobile="mobile"
                                        @onClickForm="onClickForm"
                                        @clickImageBig="ac_imageBig"
                                        @eventError="ac_showErrorBigFile"
                                    />
                                </div>
                            </div>
                        </v-tab-item>
                        <v-tab-item>
                            <div class="membership__recommendations">
                                <h1 class="text-body-big text-center mb-1">ENVÍO EXITOSO</h1>
                                <div class="pa-2 pt-0">
                                    <div class="form__send-data">
                                        <div class="d-flex pb-1">
                                            <div class="mr-1">
                                                <v-icon color="#51e933">mdi-check-circle</v-icon>
                                            </div>
                                            <h1 class="text-body-subtitle">
                                                <b>{{dataPdfPerson.full_name}}</b> ABIS Chuquisaca recibió correctamente el envio de su formulario.</h1>
                                        </div>
                                        <div class="d-flex pb-1">
                                            <div class="mr-1">
                                                <v-icon color="#51e933">mdi-check-circle</v-icon>
                                            </div>
                                            <h1 class="text-body-subtitle">
                                                Guarde el código asignado para realizar el seguimiento a su trámite de afiliación
                                            </h1>
                                        </div>
                                        <div class="d-flex pb-1">
                                            <div class="mr-1">
                                                <v-icon color="#51e933">mdi-check-circle</v-icon>
                                            </div>
                                            <h1 class="text-body-subtitle">
                                                Puede realizar el seguimiento a su trámite de afiliación ingresando a "VERIFICAR AFILIACIÓN"
                                            </h1>
                                        </div>
                                        <div class="d-flex pb-1">
                                            <div class="mr-1">
                                                <v-icon color="#51e933">mdi-check-circle</v-icon>
                                            </div>
                                            <h1 class="text-body-subtitle">
                                                Descargue el documento PDF, para respaldo de sus datos de afiliación.
                                            </h1>
                                        </div>
                                        <MBtnNormalComp
                                            color="error"
                                            :outlined="true"
                                            class="mt-1"
                                            @click="ac_dowloadPdfProcess()"
                                        >
                                        <v-icon class="mr-1">mdi-download-box</v-icon>
                                        PDF DATOS AFILIACIÓN</MBtnNormalComp>
                                        <h1 class="text-center" style="font-size:23px">CODIGO: {{codeAffiliate}}</h1>
                                        <div class="d-flex justify-center mt-2 pb-2">
                                            <MBtnNormalComp
                                                color="primary"
                                                @click="ac_goVerifyProcess()"
                                            >
                                                <v-icon color="white" class="mr-1">mdi-clipboard-clock</v-icon>
                                                VERIFICAR AFILIACIÓN
                                            </MBtnNormalComp>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </v-tab-item>
                    </v-tabs-items>
                </div>
            </div>
        </div>
        <OModalImgComp
            :dialog="modalImg"
            :src="srcImg"
            :title="titleImgBig"
            @clickclose="modalImg=false"
        />
        <OModalPdfComp
            :dialog="dialogPdfPreview"
            :src="srcPdf"
            :title="titleImgBig"
            @clickclose="dialogPdfPreview=false"
        />
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="true"
            @click="dialogError=false"
        />
        <OLoaderOperationComp
            :dialog="dialogLoaderOperation"
            smsLoaderOperation="Enviando Solicitud"
        />
        <SmsCongratulationsComp
            :dialog="dialogConfirm"
            :smsCongratulations="smsAgreement"
            title="CONFIRMACIÓN"
            icon="mdi-clipboard-edit"
            btnSms="SI"
            @click="ac_performOperation()"
            @clickerror="dialogConfirm=false"
        />
        <OSmsActionComp
            :frameCorrect="frameCorrect"
            :smsAction="smsAction"
            :timeout="timeSucess"
            colorMessage="error"
            @click="m_dataGeneral_frameTimeReset()"
        />
    </div>
</template>
<script>
import {
    MBtnNormalComp
} from '../../components/molecules'
import {
    OSmsErrorComp,
    OLoaderOperationComp,
    SmsCongratulationsComp,
    OModalImgComp,
    OSmsActionComp,
    OModalPdfComp
} from '../../components/organisms'
import {
    SFormMembershipAcademicComp
} from '../../components/species'
import {
    rulesMixin,
    responseServerMixin,
    dataGeneralComponetsMixin,
    generateCodeMixin,
    scrollMixin
} from '../../mixin'
import {
    ProcessAffiliationAcademic,
    InterestedAcademic,
    ObservationAcademic
} from '../../models'
import jsPDF from 'jspdf'
export default {
    metaInfo() {
        const title = `ABIS Ch. - Formulario de Afiliación`
        return {
            title: title
        }
    },
    mixins: [
        rulesMixin,
        responseServerMixin,
        dataGeneralComponetsMixin,
        generateCodeMixin,
        scrollMixin
    ],
    components: {
        OSmsErrorComp,
        OLoaderOperationComp,
        SFormMembershipAcademicComp,
        MBtnNormalComp,
        SmsCongratulationsComp,
        OModalImgComp,
        OSmsActionComp,
        OModalPdfComp
    },
    data: () => ({
        tab: 0,
        dataProcedure: new ProcessAffiliationAcademic(),
        dataInterested: new InterestedAcademic(),
        dataObservation: new ObservationAcademic(),
        mobile: false,
        codeAffiliate: '',
        modalImg: false,
        srcImg: '',
        smsAgreement: `¿Declara usted, que los datos personales proporcionados y la documentación
            enviada en el proceso de afiliación y se hace responsable ante cualquier inconveniente
            que surja respecto a la veracidad de estos, conforme a ley?`,
        dialogPdfPreview: false,
        srcPdf: '',
        titleImgBig: '',
        dataPdfPerson: {},
        dateProcess: {}
    }),
    methods: {
        processError(error) {
            this.dialogLoaderOperation = false
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_action(error)
            this.dialogError = true
        },
        showEvent(message) {
            this.smsAction = message
            this.frameCorrect = true
            this.timeSucess = setTimeout(() => {
                this.frameCorrect = false
            }, 3000)
        },
        convertToUppercaseLetter(input) {
            var newInput = input+''
            var inputFinal = ''
            var count = 0
            for(var i=0; i < newInput.length; i++) {
                if (newInput[i] !== ' ' && count === 0) {
                    inputFinal += newInput[i].toUpperCase()
                    count++
                } else {
                    if (newInput[i] === ' ') {
                        inputFinal += newInput[i]
                        count = 0
                    } else {
                        inputFinal += newInput[i].toLowerCase()
                    }
                }
            }
            return inputFinal
        },
        onClickForm(e) {
            if (!e.validForm || (this.dataInterested.code_sib === null && this.dataInterested.category !== 'C')) {
                this.smsError = ['Corrija todos los datos marcados con rojo porfavor']
                if (this.dataInterested.code_sib === null && this.dataInterested.category !== 'C') {
                    this.smsError = ['Corrija los datos marcados con rojo','Para afiliados Activos y Transeúntes es necesario el RNI SIB']
                }
                this.dialogError = true
            } else {
                this.dataInterested.full_name = this.convertToUppercaseLetter(this.dataInterested.full_name)
                this.dataInterested.last_name = this.convertToUppercaseLetter(this.dataInterested.last_name)
                this.dataInterested.email = this.dataInterested.email.toLowerCase()
                this.dataPdfPerson = {
                    'full_name': this.dataInterested.full_name,
                    'last_name': this.dataInterested.last_name,
                    'ci': this.dataInterested.ci,
                    'issued_in': this.dataInterested.issued_in
                }
                var velocity = this.m_scroll_velocity(window.scrollY)
                this.m_scroll_animation(velocity)
                setTimeout(() => {
                    this.dialogConfirm = true
                }, velocity)
            }
        },
        ac_performOperation() {
            this.dialogConfirm = false
            this.dialogLoaderOperation = true
            var letters = ['b','c','d','e','f','g','h','j','m','n','p','r','s','t','u','v','w','x','y','z','1','2','3','4','5','6','7','8','9']
            var code_random = ''
            for(var i = 0; i < 4; i++) {
                code_random += letters[Math.floor(Math.random() * letters.length)]+''
            }
            this.dataProcedure.generate_code = this.m_code_encript(code_random)
            this.dataProcedure.number_procedure = new Date().getTime()
            const parameters = {
                'procedure': this.dataProcedure,
                'interested': this.dataInterested
            }
            ProcessAffiliationAcademic.sendProcessAffiliation(this.$objectToFD(parameters)).then(response => {
                this.dateProcess = response
                this.dataInterested = new InterestedAcademic()
                this.codeAffiliate = code_random
                this.tab = 1
                this.dialogLoaderOperation = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_imageBig(parameters) {
            var file = parameters.value
            this.titleImgBig = parameters.title
            let reader = new FileReader()
            reader.onload = (file) =>{
                if (parameters.value.type == 'application/pdf') {
                    if (this.mobile) {
                        this.showEvent(['La vista previa del PDF, solo es', 'para computadora'])
                    } else {
                        this.srcPdf = file.target.result
                        this.dialogPdfPreview = true
                    }
                } else {
                    this.srcImg = file.target.result
                    this.modalImg = true
                }
            }
            reader.readAsDataURL(file)
        },
        ac_goVerifyProcess() {
            this.$router.push({name: 'verify-process'})
        },
        showEvent(message) {
            this.smsAction = message
            this.frameCorrect = true
            this.timeSucess = setTimeout(() => {
                this.frameCorrect = false
            }, 3000)
        },
        ac_showErrorBigFile(smsError) {
            this.m_dataGeneral_frameTimeReset()
            setTimeout(() => {
                this.showEvent(smsError)
            }, 200)
        },
        ac_dowloadPdfProcess() {
            const interested = 'Interesado: '+this.dataPdfPerson.full_name+' '+this.dataPdfPerson.last_name
            const ci = 'CI: '+this.dataPdfPerson.ci+' '+this.dataPdfPerson.issued_in+'.'
            const date = 'Fecha de solicitud: '+this.dateProcess.date
            const hours = 'Hora de solicitud: '+this.dateProcess.hours
            const code = 'Código: '+this.codeAffiliate
            const doc = new jsPDF('p','mm','letter');
            doc.setFontSize(16)
            doc.text("DATOS DE AFILIACIÓN", 19, 20)
            var img = new Image();
            img.src = '/imgs/logo/logo.png';
            doc.addImage(img, 'JPEG', 140, 10, 60, 50);
            doc.setFontSize(13)
            doc.text(interested, 19, 35)
            doc.text(ci, 19, 45)
            doc.text(date, 19, 55)
            doc.text(hours, 19, 65)
            doc.text(code, 19, 75)
            doc.save("Datos_afiliacion.pdf");   
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.mobile = true
        this.dialogConfirm = false
    }
}
</script>