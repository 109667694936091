<template>
    <div>
        <v-tooltip bottom color="#062242">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    depressed
                    v-bind="attrs"
                    v-on="on"
                    :disabled="disabled"
                    :outlined="true"
                    small
                    :ripple="false"
                    @click="$emit('click')"
                    color="#0073b681"
                >
                    <v-icon small color="primary">{{icon}}</v-icon>
                </v-btn>
            </template>
            <div class="d-flex align-center">
                <v-icon class="mr-1" color="white">mdi-hand-pointing-right</v-icon>
                <h1 class="text-body-subtitle-inverse">{{smsTooltip}}</h1>
            </div>
        </v-tooltip>
    </div>
</template>
<script>
export default {
    props: {
        icon: String,
        smsTooltip: String,
        disabled: Boolean
        /*colorBtn: {
            default: 'white',
            type: String
        }*/
    }
}
</script>
