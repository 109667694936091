<template>
    <v-dialog
        :value="dialog"
        :width="ac_getWidthImg()"
        persistent
        style="position:relative"
    >
        <div class="gallery__carrusel">
            <div class="gallery__carrusel__btn-close">
                <v-btn
                    x-small
                    color="white"
                    class="mr-1"
                    fab
                    depressed
                    :ripple="false"
                    @click="resetSize()"
                >
                    <v-icon color="black">mdi-magnify-close</v-icon>
                </v-btn>
                <v-btn
                    x-small
                    color="white"
                    class="mr-1"
                    fab
                    depressed
                    :ripple="false"
                    @click="ac_zoomImage(false)"
                >
                    <v-icon color="black">mdi-magnify-minus-cursor</v-icon>
                </v-btn>
                <v-btn
                    x-small
                    color="white"
                    class="mr-1"
                    fab
                    depressed
                    :ripple="false"
                    @click="ac_zoomImage(true)"
                >
                    <v-icon color="black">mdi-magnify-plus-cursor</v-icon>
                </v-btn>
                <v-btn
                    x-small
                    color="white"
                    fab
                    depressed
                    :ripple="false"
                    @click="$emit('clickclose')"
                >
                    <v-icon color="black">mdi-close</v-icon>
                </v-btn>
            </div>
            <div style="height:100%;">
                <div class="gallery__title d-flex align-center">
                    <h1 class="text-body-private-title">{{title}}</h1>
                </div>
                <div class="gallery__img-server">
                    <div>
                        <img
                            :src="src|imgNormal"
                            :style="styleImg"
                        />
                    </div>
                </div>
            </div>
        </div>
    </v-dialog>
</template>
<script>
import { imgNormal } from '../../filters'
export default {
    filters: { imgNormal },
    model: {
        prop: 'dialog',
        event: 'click'
    },
    props: {
        src: String,
        dialog: Boolean,
        title: String
    },
    data: () => ({
        styleImg: 'height:100%',
        size: 100
    }),
    methods: {
        ac_getWidthImg() {
            if (window.innerWidth <= 1400) return '850'
            else return '1200'
        },
        ac_zoomImage(state) {
            if (state) { this.size += 10 }
            else { this.size -= 10 }
            this.styleImg = `height:${this.size}%`
        },
        resetSize() {
            this.size = 100
            this.styleImg = 'height:100%'
        }

    }
}
</script>
