<template>
    <div>
        <div class="user__header pa-2">
            <div class="d-flex justify-center align-center">
                <v-app-bar-nav-icon class="mr-2 user__icon-menu" @click="m_menuComp_changeStateMenu()"></v-app-bar-nav-icon>
                <h1 class="text-body-private-title">VERIFICAR TRÁMITE</h1>
            </div>
            <v-divider class="mb-2 mt-1 user__line-mobile"></v-divider>
            <v-spacer></v-spacer>
            <div class="d-flex align-center">
                <OSearchComp
                    :dense="true"
                    :hideDetails="true"
                    label="Buscar CI"
                    :singleLine="true"
                    v-model="search"
                    @search="ac_searchProcessPerson()"
                />
            </div>
        </div>
        <MLineHeaderComp />
        <OLoaderDataComp
            class="mt-2"
            v-if="dialogLoaderData"
            smsLoaderData="Obteniendo Trámites"
        />
        <MNoDataComp
            v-if="!dialogLoaderData && listProcess.id==undefined"
            smsNotData="SIN TRÁMITES"
            class="ma-2"
        />
        <div :class="ac_alignmentInfo()">
            <div class="d-flex justify-center">
                <OPhotoReviewComp
                    v-if="listProcess.id!=undefined"
                    class="mr-3"
                    :imageOnly="true"
                    :srcPreviewImage="listProcess.list_interested[0].route_face"
                />
            </div>
            <div class="card-person animation__card--opacity ma-2" v-if="listProcess.id!=undefined">
                <div class="d-flex">
                    <h1 class="text-body-private-title mr-1">Nombre Completo:</h1>
                    <h1 class="text-body-private-subtitle">
                        {{listProcess.list_interested[0].full_name+' '+listProcess.list_interested[0].last_name}}</h1>
                </div>
                <div class="d-flex">
                    <h1 class="text-body-private-title mr-1">CI:</h1>
                    <h1 class="text-body-private-subtitle">
                        {{`${listProcess.list_interested[0].ci} ${listProcess.list_interested[0].issued_in}`}}</h1>
                </div>
                <div class="d-flex">
                    <h1 class="text-body-private-title mr-1">Género:</h1>
                    <h1 class="text-body-private-subtitle">
                        {{listProcess.list_interested[0].gender!='h' ? 'Mujer' : 'Hombre'}}</h1>
                </div>
                <div class="d-flex">
                    <h1 class="text-body-private-title mr-1">Inicio de Afiliación:</h1>
                    <h1 class="text-body-private-subtitle">
                        {{`${listProcess.hours} ${listProcess.date}`}}</h1>
                </div>
                <div class="d-flex">
                    <h1 class="text-body-private-title mr-1">Formularios enviados:</h1>
                    <h1 class="text-body-private-subtitle">{{listProcess.list_interested.length}}</h1>
                </div>
                <div class="d-flex">
                    <h1 class="text-body-private-title mr-1">Estado:</h1>
                    <h1 class="text-body-private-subtitle">
                        {{listProcess.state_process!='c' ? 'Observado' : 'En curso'}}</h1>
                </div>
                <div class="d-flex">
                    <h1 class="text-body-private-title mr-1">Revisado por ABIS:</h1>
                    <h1 class="text-body-private-subtitle">
                        {{listProcess.list_interested[0].validated_date!=null ? 'SI' : 'NO'}}</h1>
                </div>
            </div>
        </div>
        <OSmsActionComp
            :frameCorrect="frameCorrect"
            :smsAction="smsAction"
            colorMessage="error"
            :timeout="timeSucess"
            @click="m_dataGeneral_frameTimeReset()"
        />
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_closeSmsError()"
        />
    </div>
</template>
<script>
import {
    MLineHeaderComp,
    MNoDataComp
} from '../../components/molecules'
import {
    OSearchComp,
    OSmsErrorComp,
    OSmsActionComp,
    OLoaderDataComp,
    OPhotoReviewComp
} from '../../components/organisms'
import {
    dataGeneralComponetsMixin,
    responseServerMixin,
    rulesMixin,
    userMixin,
    menuCompMixing
} from '../../mixin'
import {
    imgMiniature
} from '../../filters'
import {
    ProcessAffiliation
} from '../../models'
export default {
    filters: {
        imgMiniature
    },
    mixins: [
        dataGeneralComponetsMixin,
        responseServerMixin,
        rulesMixin,
        userMixin,
        menuCompMixing
    ],
    components: {
        OSearchComp,
        MLineHeaderComp,
        OSmsErrorComp,
        OSmsActionComp,
        OLoaderDataComp,
        MNoDataComp,
        OPhotoReviewComp
    },
    data: () => ({
        search: '',
        listProcess: {},
        srcImg: '',
        mobile: false
    }),
    methods: {
        processError(error) {
            this.dialogLoaderOperation = false
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_action(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        ac_closeSmsError() {
            if (this.noAccess) {
                localStorage.removeItem('abis')
                this.$router.replace('/login')
            }
        },
        showEvent(message) {
            this.smsAction = message
            this.frameCorrect = true
            this.timeSucess = setTimeout(() => {
                this.frameCorrect = false
            }, 3000)
        },
        ac_searchProcessPerson() {
            this.m_dataGeneral_frameTimeReset()
            if (this.m_rule_emptyField(this.search)) {
                setTimeout(() => {
                    this.showEvent(['Ingrese Texto',`para buscar`])
                }, 200)
            } else {
                this.listProcess = {}
                this.dialogLoaderData = true
                ProcessAffiliation.verifyProcess(this.search).then(response => {
                    this.listProcess = response
                    this.dialogLoaderData = false
                }).catch(error => {
                    this.processError(error)
                })
            }
        },
        ac_alignmentInfo() {
            if (this.mobile) return 'd-block mt-2'
            else return 'd-flex justify-center align-center mt-2'
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.mobile = true
        if (!this.m_user_saveToken()) this.$router.replace('/login')
    }
}
</script>