<template>
    <div class="card-person card-person--big-size animation__card--opacity d-flex">
        <div class="mr-2">
            <div
                class="card-person__photo"
                style="cursor:pointer"
                @click="$emit('clickbig')"
            >
                <v-img
                    src="/imgs/private/pdf_cargado.jpg"
                    width="100%"
                    height="100%"
                    style="border-radius:5px"
                    contain
                ></v-img>
            </div>
            <div style="width:120px" class="d-flex justify-center flex-wrap mt-1">
                <OBtnOptionToolTipComp
                    :smsTooltip="`Editar documento`"
                    icon="mdi-pencil"
                    style="margin:2px"
                    @click="$emit('clickedit')"
                />
                <OBtnOptionToolTipComp
                    :smsTooltip="`Eliminar documento`"
                    icon="mdi-trash-can"
                    style="margin:2px"
                    @click="$emit('clickdelete')"
                />
                <OBtnOptionToolTipComp
                    :smsTooltip="document.public==1 ? 'Dejar de publicar fotografía' : 'Publicar fotografía'"
                    :icon="document.public==1 ? 'mdi-eye' : 'mdi-eye-off'"
                    style="margin:2px"
                    @click="$emit('clickpublic')"
                />
                <OBtnOptionToolTipComp
                    smsTooltip="Abrir documento en otra ventana"
                    icon="mdi-download-box-outline"
                    style="margin:2px"
                    @click="$emit('clickdownload')"
                />
            </div>
        </div>
        <div>
            <h1 class="text-body-private-title">{{document.title}}</h1>
            <div class="d-flex align-center">
                <h1 class="text-body-private-title mr-1">Estado:</h1>
                <h1 class="text-body-private-subtitle mr-1">
                    {{document.public==0 ? 'No publicado' : 'Publicado'}}</h1>
                <div :class="m_user_stateOrnament(document.public)"></div>
            </div>
            <div class="d-flex align-center">
                <h1 class="text-body-private-title mr-1">Fecha:</h1>
                <h1 class="text-body-private-subtitle">
                    {{document.date}}</h1>
            </div>
            <v-divider class="mt-1 mb-1"></v-divider>
            <h1 class="text-body-private-small">
                {{document.description}}</h1>
        </div>
    </div>
</template>
<script>
import { imgMiniature } from '../../filters'
import { userMixin } from '../../mixin'
import {
    OBtnOptionToolTipComp
} from '../organisms'
export default {
    mixins: [ userMixin ],
    filters: { imgMiniature },
    components: {
        OBtnOptionToolTipComp
    },
    props: {
        document: {}
    }
}
</script>