export default {
    methods: {
        m_utilitie_departament(issued_in) {
            switch(issued_in) {
                case 'ch': return 'Chuquisaca'
                case 'pt': return 'Potosí'
                case 'lp': return 'La Paz'
                case 'or': return 'Oruro'
                case 'cb': return 'Cochabamba'
                case 'tj': return 'Tarija'
                case 'sc': return 'Santa Cruz'
                case 'pd': return 'Pando'
                case 'be': return 'Beni'
            }
        },
        m_utilitie_category(category) {
            if (category === 'A') return 'Activo'
            if (category === 'B') return 'Transeunte'
            if (category === 'C') return 'Adherente'
        }
    }
}