<template>
    <div>
        <div class="user__header pa-2">
            <div class="d-flex justify-center align-center">
                <v-app-bar-nav-icon class="mr-2 user__icon-menu" @click="m_menuComp_changeStateMenu()"></v-app-bar-nav-icon>
                <h1 class="text-body-private-title">CURRICULUM VITAE</h1>
            </div>
            <v-divider class="mb-2 mt-1 user__line-mobile"></v-divider>
            <v-spacer></v-spacer>
            <div class="d-flex align-center">
                <OSearchComp
                    v-if="tab==0"
                    :dense="true"
                    :hideDetails="true"
                    label="Afiliado"
                    :singleLine="true"
                    v-model="search"
                    @search="ac_searchProcess()"
                />
                <OBtnHeaderToolTipComp
                    v-if="tab==1"
                    smsTooltip="Volver a la lista de afiliados."
                    icon="mdi-database"
                    class="ml-1"
                    @click="tab=0"
                />
            </div>
        </div>
        <MLineHeaderComp />
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <OLoaderDataComp
                    class="mt-2"
                    v-if="dialogLoaderData"
                    smsLoaderData="Obteniendo Afiliados"
                />
                <MNoDataComp
                    v-if="!dialogLoaderData && listPerson.length==0"
                    smsNotData="SIN AFILIADOS"
                    class="ma-2"
                />
                <div class="d-flex justify-center flex-wrap">
                    <SCardSeeCvPersonComp
                        v-for="(person, index) in listPerson"
                        :key="index"
                        class="ma-2"
                        :person="person"
                        :searchPerson="searchFilter"
                        @clickcv="ac_showCvAffiliate(person)"
                    />
                </div>
                <SPaginationComp
                    class="mt-2"
                    v-if="!dialogLoaderData && listPerson.length>0"
                    :condicionGo="page < last_page"
                    :condicionBack="page > 1"
                    :titlePagination="'Pag. '+page+' de '+last_page"
                    @clickback="ac_changePage(false)"
                    @clickgo="ac_changePage(true)"
                />
            </v-tab-item>
            <v-tab-item>
                <div class="pa-2">
                    <div class="d-flex">
                        <h1 class="text-body-private-title mr-1">AFILIADO:</h1>
                        <h1 class="text-body-private-subtitle">{{person.full_name}}</h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="text-body-private-title mr-1">CI:</h1>
                        <h1 class="text-body-private-subtitle">
                            {{person.ci+' '+person.issued_in}}</h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="text-body-private-title mr-1">Categoría:</h1>
                        <h1 class="text-body-private-subtitle" v-if="person.category=='A'">Activo</h1>
                        <h1 class="text-body-private-subtitle" v-if="person.category=='B'">Adherente</h1>
                        <h1 class="text-body-private-subtitle" v-if="person.category=='C'">Transeunte</h1>
                        <h1 class="text-body-private-subtitle" v-if="person.category=='U'">Universitario</h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="text-body-private-title mr-1">N° Registro ABIS Ch.:</h1>
                        <h1 class="text-body-private-subtitle">
                            {{person.identifier}}</h1>
                    </div>
                    <v-divider class="mt-2 mb-2"></v-divider>
                    <div v-if="person.other!=undefined">
                        <div>
                            <h1 class="text-body-private-title">PERFIL</h1>
                            <h1 class="text-body-private-subtitle">{{person.other[0].profile}}</h1>
                            <div class="d-flex">
                                <h1 class="text-body-private-subtitle mr-1">Facebook:</h1>
                                <h1 class="text-body-private-subtitle">
                                    {{person.other[0].facebook!=null ? person.other[0].facebook : 'S/F'}}</h1>
                            </div>
                            <div class="d-flex">
                                <h1 class="text-body-private-subtitle mr-1">Instagram:</h1>
                                <h1 class="text-body-private-subtitle">
                                    {{person.other[0].instagram!=null ? person.other[0].instagram : 'S/I'}}</h1>
                            </div>
                        </div>
                        <v-divider class="mt-2 mb-2"></v-divider>
                        <div>
                            <h1 class="text-body-private-title">IDIOMAS</h1>
                            <h1 class="text-body-private-subtitle" v-if="person.languajes.length==0">
                                Sin Idiomas</h1>
                            <div class="d-flex flex-wrap">
                                <div v-for="(languaje, index) in person.languajes"
                                    :key="index"
                                    class="ma-2 review-cv__group-info"
                                >
                                    <h1 class="text-body-private-subtitle text-center">{{languaje.languaje}}</h1>
                                    <h1 class="text-body-private-small text-center">
                                        {{languaje.nivel=='b' ? '(Básico)' : languaje.nivel=='m' ? '(Medio)' : '(Avanzado)'}}</h1>
                                </div>
                            </div>
                        </div>
                        <v-divider class="mt-2 mb-2"></v-divider>
                        <div>
                            <h1 class="text-body-private-title">CUALIDADES</h1>
                            <div class="d-flex flex-wrap">
                                <div v-for="(qualitie, index) in person.qualities"
                                    :key="index"
                                    class="ma-2 review-cv__group-info"
                                >
                                    <h1 class="text-body-private-subtitle text-center">{{qualitie.qualitie}}</h1>
                                    <v-progress-linear height="5" :value="qualitie.progress"></v-progress-linear>
                                </div>
                            </div>
                        </div>
                        <v-divider class="mt-2 mb-2"></v-divider>
                        <div>
                            <h1 class="text-body-private-title">PROFESIONES</h1>
                            <div class="d-flex flex-wrap">
                                <div v-for="(profession, index) in person.professions"
                                    :key="index"
                                    class="ma-2 review-cv__group-info"
                                >
                                    <h1 class="text-body-private-title">{{profession.profession}}</h1>
                                    <h1 class="text-body-private-small">{{profession.entity}}</h1>
                                    <h1 class="text-body-private-small">{{profession.location}}</h1>
                                </div>
                            </div>
                        </div>
                        <v-divider class="mt-2 mb-2"></v-divider>
                        <div>
                            <h1 class="text-body-private-title">ESPECIALIDADES</h1>
                            <h1 class="text-body-private-subtitle" v-if="person.specialties.length==0">
                                Sin Especialidades</h1>
                            <div class="d-flex flex-wrap">
                                <div v-for="(specialty, index) in person.specialties"
                                    :key="index"
                                    class="ma-2 review-cv__group-info"
                                >
                                    <h1 class="text-body-private-title">{{specialty.specialty}}</h1>
                                    <h1 class="text-body-private-small">{{specialty.entity}}</h1>
                                    <h1 class="text-body-private-small">{{specialty.location}}</h1>
                                </div>
                            </div>
                        </div>
                        <v-divider class="mt-2 mb-2"></v-divider>
                        <div>
                            <h1 class="text-body-private-title">EXPERIENCIA LABORAL</h1>
                            <h1 class="text-body-private-subtitle" v-if="person.experiences.length==0">
                                Sin Experiencia Laboral</h1>
                            <div class="d-flex flex-wrap">
                                <div v-for="(experience, index) in person.experiences"
                                    :key="index"
                                    class="ma-2 review-cv__group-info"
                                >
                                    <h1 class="text-body-private-title">{{experience.position}}</h1>
                                    <h1 class="text-body-private-small">{{experience.employer}}</h1>
                                    <h1 class="text-body-private-small">{{experience.location}}</h1>
                                    <h1 class="text-body-private-small">
                                        {{'Del '+m_date_formatDate(experience.beginning)+' al '+m_date_formatDate(experience.final)}}
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-tab-item>
        </v-tabs-items>
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_closeSmsError()"
        />
    </div>
</template>
<script>
import {
    MNoDataComp,
    MLineHeaderComp
} from '../../components/molecules'
import {
    OLoaderDataComp,
    OSearchComp,
    OBtnHeaderToolTipComp,
    OSmsErrorComp
} from '../../components/organisms'
import {
    SPaginationComp,
    SCardSeeCvPersonComp
} from '../../components/species'
import {
    menuCompMixing,
    userMixin,
    responseServerMixin,
    scrollMixin,
    datesMixin
} from '../../mixin'
import { Person } from '../../models'
export default {
    mixins: [
        menuCompMixing,
        userMixin,
        responseServerMixin,
        scrollMixin,
        datesMixin
    ],
    components: {
        MNoDataComp,
        OLoaderDataComp,
        OSearchComp,
        OBtnHeaderToolTipComp,
        MLineHeaderComp,
        OSmsErrorComp,
        SPaginationComp,
        SCardSeeCvPersonComp
    },
    data: () => ({
        noAccess: false,
        dialogError: false,
        smsError: [],
        actionError: true,
        dialogLoaderData: false,
        tab: 0,
        listPerson: [],
        search: '',
        page: 0,
        last_page: 0,
        person: {}
    }),
    computed: {
        searchFilter: function() {
            if (this.search === null) return ''
            return this.search
        }
    },
    methods: {
        processError(error) {
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_action(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        getPersons(page, search) {
            this.listPerson = []
            this.dialogLoaderData = true
            Person.getPersonsCv(page, search).then(response => {
                this.listPerson = response.data
                this.last_page = response.last_page
                this.page = response.page
                this.dialogLoaderData = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_searchProcess() {
            if (this.search === null) this.getPersons(1, '')
            else this.getPersons(1, this.search)
        },
        ac_changePage(state) {
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            if (state) { ++this.page }
            else { --this.page }
            setTimeout(() => {
                this.getPersons(this.page, this.search)
            }, velocity)
        },
        ac_showCvAffiliate(person) {
            this.person = person
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            setTimeout(() => {
                this.tab = 1
            }, velocity)
        }
    },
    mounted() {
        if (!this.m_user_saveToken()) this.$router.replace('/login')
    }
}
</script>