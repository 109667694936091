<template>
    <div>
        <div class="about__background">
            <v-img
                :src="srcBackground"
                gradient="to top right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.2)"
                width="100%"
                height="100%"
            >
                <OCardAnimationLogoComp />
            </v-img>
        </div>
        <div class="d-flex justify-center space-top-two">
            <div class="pt-2">
                <h1 class="text-body-big text-center">QUIENES SOMOS</h1>
                <v-divider class="mb-1"></v-divider>
                <h1 style="font-size:14px" class="text-body-subtitle text-center">ASOCIACIÓN BOLIVIANA DE INGENIERÍA SANITARIA Y AMBIENTAL</h1>
            </div>
        </div>
        <div class="d-flex justify-center space-top-two">
            <div class="home__first about__general">
                <div class="about__general-img about__general--ornament-left">
                    <v-img
                        src="/imgs/public/about/image_1.jpg"
                        height="100%"
                        width="100%"
                        class="about__general--image-left"
                    ></v-img>
                </div>
                <div class="about__general-group-info">
                    <div class="about__general-info">
                        <h1 class="text-body-subtitle-inverse text-center">
                            
                        La Asociación Boliviana de Ingeniería Sanitaria y Ambiental (ABIS) fue
                        fundada en la ciudad de La Paz el 17 de Agosto de 1957, constituyéndose
                        en una organización profesional, sin carácter político y sin fines de lucro,
                        creada con el propósito fundamental de mejorar las condiciones sanitarias
                        y ambientales del país, además de cooperar con las demás asociaciones
                        similares del continente; es de carácter científico, académico y profesional
                        y está afiliada a la Asociación Interamericana de Ingeniería Sanitaria
                        y Ambiental (A.I.D.I.S).
                        </h1>
                    </div>
                </div>
                <div class="about__general-img about__general--ornament-right">
                    <v-img
                        src="/imgs/public/about/image_4.jpg"
                        height="100%"
                        width="100%"
                        class="about__general--image-right"
                    ></v-img>
                </div>
            </div>
        </div>
        <!--quienes somos-->
        <div class="d-flex justify-center space-top">
            <div class="home__first about__know">
                <div class="about__know-container-img">
                    <div class="about__know-img-ornament"></div>
                    <v-img
                        src="/imgs/public/about/image_3.jpg"
                        width="100%"
                        height="100%"
                    ></v-img>
                </div>
                <div class="about__know-information">
                    <h1 class="text-body-supertitle">ABIS CHUQUISACA</h1>
                    <div class="home__third-ornament-line mb-4 mt-2"></div>
                    <h1 class="text-body-subtitle">
                        En agosto de 2021, por encargo del directorio nacional de ABIS y gracias a la iniciativa
                        de un grupo de profesionales chuquisaqueños relacionados con la temática del saneamiento
                        básico y el medio ambiente, se trabajó en la organización de ABIS - Chuquisaca, habiéndose
                        culminado con su refundación el 21 de octubre de la gestión 2021, en la misma fecha y en pleno
                        cumplimiento de normas estaturas, se reconoció y validó por la instancia de ABIS y AIDIS
                        el primer directorio de ABIS- Chuquisaca.
                    </h1>
                </div>
            </div>
        </div>
        <div class="space-top">
            <div class="d-flex justify-center space-top space-bottom" style="background: #17191b">
                <div class="home__first">
                    <h1 class="text-body-big-inverse text-center">OBJETIVO GENERAL</h1>
                    <v-divider dark class="mt-2"></v-divider>
                    <h1 class="text-body-subtitle-inverse text-center mt-3" style="padding-left:10px; padding-right:10px;">
                        Organizar a través de una asociación departamental a los profesionales relacionados con
                        las actividades de la ingeniería sanitaria y ambiental, desarrollando acciones que se
                        reflejen en el crecimiento económico, social y técnico de la ABIS - Chuquisaca y de cada
                        uno de sus afiliados.
                    </h1>
                </div>
            </div>
        </div>
        <div class="d-flex justify-center space-top">
            <div class="home__first space-left-title">
                <h1 class="text-body-big">OBJETIVOS ESPECÍFICOS</h1>
                <div class="home__third-ornament-line mb-2 mt-2"></div>
            </div>
        </div>
        <div class="d-flex justify-center">
            <div class="d-flex justify-center flex-wrap about__specific">
                <div class="about__specific-objetives"
                    v-for="(objetive, index) in specific_objetives"
                    :key="index"
                >
                    <div class="about__specific-icon d-flex justify-center align-center">
                        <div class="about__specific-icon-ornament"></div>
                        <div class="about__specific-img d-flex justify-center align-center">
                            <v-img
                                :src="objetive.img"
                                width="70px"
                                max-width="70px"
                            ></v-img>
                        </div>
                    </div>
                    <div class="about__specific-info">
                        <h1 class="text-body-subtitle text-center">
                            {{objetive.title}}
                        </h1>
                    </div>
                </div>
            </div>
        </div>
        <!--MISION-->
        <div class="d-flex justify-center space-top">
            <div class="home__first space-left-title">
                <h1 class="text-body-big">MISIÓN Y VISIÓN</h1>
                <div class="home__third-ornament-line mb-4 mt-2"></div>
            </div>
        </div>
        <div class="space-bottom">
            <div class="d-flex justify-center flex-wrap" style="padding:0px 10px">
                <div class="about__mission about__mission--primary">
                    <div>
                        <h1 class="text-body-supertitle-inverse text-center">MISIÓN</h1>
                    </div>
                    <div class="about__mission-line mb-2 mt-2"></div>
                    <h1 class="text-body-subtitle-inverse text-center">
                        Contribuir al desarrollo del departamento y a nuestra sociedad en materia de ingeniería,
                        impulsando y participando en los ámbitos técnico, económico, social y ambiental,
                        promoviendo acciones convergentes de la ingeniería sanitaria y ciencias ambientales
                        con otras disciplinas que procuran el desarrollo humano sostenible, la formación,
                        investigación y la aplicación técnica y ética de la ingeniería, actuando como referente
                        gremial en apoyo a sus asociados.
                    </h1>
                </div>
                <div class="about__mission about__mission--secondary">
                    <div>
                        <h1 class="text-body-supertitle-inverse text-center">VISIÓN</h1>
                    </div>
                    <div class="about__mission-line mb-2 mt-2"></div>
                    <h1 class="text-body-subtitle-inverse text-center">
                        Ser reconocidos como una institución especialista referente en ingeniería sanitaria
                        y ambiental, comprometida con el departamento de Chuquisaca, la sociedad y sus miembros
                        en los ámbitos técnico, económico, social, ambiental y en el contexto del desarrollo
                        humano sustentable.
                    </h1>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    OCardAnimationLogoComp
} from '../../components/organisms'
export default {
    metaInfo() {
        const title = `ABIS Ch. - NOSOTROS`
        return {
            title: title
        }
    },
    components: {
        OCardAnimationLogoComp
    },
    data: () => ({
        model: 0,
        colors: [
            'primary',
            'secondary',
            'yellow darken-2',
            'red',
            'orange',
        ],
        specific_objetives: [
            {
                img: '/imgs/public/icons/ob_1.png',
                title: `Desarrollar actividades destinadas a la actualización de los profesionales y
                    empresas del sector, con el fin de que respondan adecuadamente a las necesidades
                    locales, regionales y nacionales.`
            },{
                img: '/imgs/public/icons/ob_2.png',
                title: `Participar conjuntamente las entidades públicas y privadas en el diseño de las
                    políticas, normas técnicas y reglamentarias relacionadas con los temas de agua,
                    saneamiento y ambiente a nivel departamental y nacional.`
            },{
                img: '/imgs/public/icons/ob_3.png',
                title: `Promover la participación comunitaria en la concepción y desarrollo de acciones
                    políticas y técnicas, que contribuyan a adaptar y mitigar los efectos del cambio
                    climático, aplicando tecnologías amigables con el medio ambiente proyectos de agua
                    y saneamiento.`
            },{
                img: '/imgs/public/icons/ob_4.png',
                title: `Intervenir ante los organismos y personas cuando corresponda en defensa de los
                    legítimos intereses de los afiliados de ABIS - Chuquisaca.`
            },{
                img: '/imgs/public/icons/ob_5.png',
                title: `Impulsar actividades para que los afiliados de ABIS - Chuquisaca generen una
                conciencia colectiva para que el ejercicio profesional o comercial que realizan
                se encuentre dentro de las normas éticas, técnicas y legales.`
            }
        ],
        srcBackground: '/imgs/public/about/fondo.jpg'
    }),
    mounted() {
        if (window.innerWidth <= 600) this.srcBackground = '/imgs/public/about/fondo_mobile.jpg'
    }
}
</script>