import axios from "axios"
import { apiUrls } from '../plugins/apiUrls'
import { userStore } from '../store/modules'

export default class Document {
    constructor (
        route_file = null,
        title = null,
        description = null,
        link = null,
        name_file = null,
        with_link = 0
    ) {
        this.route_file = route_file
        this.title = title
        this.description = description
        this.link = link
        this.name_file = name_file
        this.with_link = with_link
    }

    static getListDocuments(page, option_public) {
        return axios.get(
            apiUrls.DOCUMENT + '/list?page='+page+'&option_public='+option_public,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static addDocument(parameters) {
        return axios.post(
            apiUrls.DOCUMENT + '/add',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static updateDocument(parameters) {
        return axios.post(
            apiUrls.DOCUMENT + '/update',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static deleteDocument(parameters) {
        return axios.delete(
            apiUrls.DOCUMENT + '/delete',
            { headers: userStore.state.header, data: parameters } 
        ).then(response => {
            return response.data
        })
    }
    static changeState(parameters) {
        return axios.patch(
            apiUrls.DOCUMENT + '/change-state',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static getListDocumentsPublic(page) {
        return axios.get(apiUrls.DOCUMENT + '/list-public?page='+page).then(response => {
            return response.data
        })
    }
}