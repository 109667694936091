<template>
    <v-dialog
        :value="dialog"
        :width="ac_getWidthImg()"
        persistent
        style="position:relative"
    >
        <div class="gallery__carrusel">
            <div class="gallery__carrusel__btn-close">
                <v-btn
                    x-small
                    color="white"
                    fab
                    depressed
                    :ripple="false"
                    @click="$emit('clickclose')"
                >
                    <v-icon color="black">mdi-close</v-icon>
                </v-btn>
            </div>
            <div style="height:100%;">
                <div style="background:#739ee0e0; min-height:42px;">
                    <h1 class="text-body-private-title pr-9 pl-1 pt-1 pb-1">{{title}}</h1>
                </div>
                <v-img
                    :src="src|imgNormal"
                    height="90%"
                    contain
                >
                </v-img>
            </div>
        </div>
    </v-dialog>
</template>
<script>
import { imgNormal } from '../../filters'
export default {
    filters: { imgNormal },
    model: {
        prop: 'dialog',
        event: 'click'
    },
    props: {
        src: String,
        dialog: Boolean,
        title: String,
        mobile: Boolean
    },
    methods: {
        ac_getWidthImg() {
            if (window.innerWidth <= 1400) return '850'
            else return '1200'
        }
    }
}
</script>
