<template>
    <div class="d-flex o-card-qualitie">
        <div class="o-card-qualitie__icon-animate-o-pt">
            <v-img
                width="60px"
                max-width="60px"
                height="60px"
                max-height="60px"
                class="d-flex justify-center align-center"
                src="/imgs/public/home/form1.svg">
                <div class="text-center">
                    <v-icon class="text-center" style="font-size:30px" color="white">{{qualitie.icon}}</v-icon>
                </div>
            </v-img>
        </div>
        <div class="ml-2 o-card-qualitie__title-animate-o" v-intersect="onIntersect">
            <h1 class="text-body-supertitle-inverse">{{qualitie.title}}</h1>
            <h1 class="text-body-subtitle-inverse mt-2">{{qualitie.subtitle}}</h1>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        qualitie: {},
        index: Number
    },
    data: () => ({
        activateAnimation: false
    }),
    methods: {
        onIntersect (entries, observer) {
            if (!this.activateAnimation && entries[0].isIntersecting && window.innerWidth > 600) {
                this.activateAnimation = true
                var effectTitle =  document.getElementsByClassName('o-card-qualitie__title-animate-o')
                effectTitle[this.index].style.opacity = 1

                var effectIcon =  document.getElementsByClassName('o-card-qualitie__icon-animate-o-pt')
                effectIcon[this.index].style.opacity = 1
                effectIcon[this.index].style.paddingTop = '0px'
            }
        },
        noCellAnimation() {
            var effectTitle =  document.getElementsByClassName('o-card-qualitie__title-animate-o')
            effectTitle[this.index].style.opacity = 1

            var effectIcon =  document.getElementsByClassName('o-card-qualitie__icon-animate-o-pt')
            effectIcon[this.index].style.opacity = 1
            effectIcon[this.index].style.paddingTop = '0px'
        }
    },
    mounted() {
        if (window.innerWidth <= 600) { this.noCellAnimation() }
    }
}
</script>