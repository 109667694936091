import axios from 'axios'
import { apiUrls } from '../plugins/apiUrls'
import { userStore } from '../store/modules'
export default class ProcessPayments{
    constructor(
        route_file = null,
        reference = null
    ){
        this.route_file = route_file
        this.reference = reference
    }
    static getStateAccount(parameters) {
        return axios.post(
            apiUrls.PROCESS_PAYMENT + '/state-account',
            parameters
        ).then(response => {
            return response.data
        })
    }
    static add(parameters) {
        return axios.post(
            apiUrls.PROCESS_PAYMENT + '/add',
            parameters
        ).then(response => {
            return response.data
        })
    }
    static sendCorrection(parameters) {
        return axios.post(
            apiUrls.PROCESS_PAYMENT + '/send-correction',
            parameters
        ).then(response => {
            return response.data
        })
    }
    static getListProcess(page, search, received) {
        return axios.get(
            apiUrls.PROCESS_PAYMENT + '/list?page='+page+'&search='+search+'&received='+received,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static updateProcess(parameters) {
        return axios.put(
            apiUrls.PROCESS_PAYMENT + '/observation',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static extendedAccreditation(parameters) {
        return axios.post(
            apiUrls.PROCESS_PAYMENT + '/extend-accreditation',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static deleteProcess(parameters) {
        return axios.delete(
            apiUrls.PROCESS_PAYMENT + '/delete',
            { headers: userStore.state.header, data: parameters } 
        ).then(response => {
            return response.data
        })
    }
}
