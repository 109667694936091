<template>
    <div class="card-person card-person--big-size animation__card--opacity d-flex">
        <div class="mr-2">
            <div class="card-person__photo">
                <v-img
                    :src="directory.route_face|imgMiniature"
                    width="100%"
                    height="100%"
                    style="border-radius:5px"
                    contain
                ></v-img>
            </div>
            <div class="d-flex justify-center flex-wrap mt-2">
                <OBtnOptionToolTipComp
                    smsTooltip="Información completa"
                    icon="mdi-format-list-bulleted"
                    style="margin:2px"
                    @click="$emit('clickdetail')"
                />
                <OBtnOptionToolTipComp
                    :smsTooltip="`Editar a: ${directory.full_name}`"
                    icon="mdi-pencil"
                    style="margin:2px"
                    @click="$emit('clickedit')"
                />
                <OBtnOptionToolTipComp
                    :smsTooltip="`Eliminar a :${directory.full_name}`"
                    icon="mdi-trash-can"
                    style="margin:2px"
                    @click="$emit('clickdelete')"
                />
            </div>
        </div>
        <div>
            <text-highlight
                class="text-body-private-title"
                :queries="searchDirectory"
            >
                {{directory.full_name}}
            </text-highlight>
            <div class="d-flex">
                <h1 class="text-body-private-title mr-1">Cargo:</h1>
                <h1 class="text-body-private-subtitle">{{directory.position}}</h1>
            </div>
            <div class="d-flex">
                <h1 class="text-body-private-title mr-1">Posición:</h1>
                <h1 class="text-body-private-subtitle">{{directory.position_view}}</h1>
            </div>
            <v-divider></v-divider>
            <h1 class="text-body-private-small">
                {{ac_getResumenDescription()}}</h1>
        </div>
    </div>
</template>
<script>
import { imgMiniature } from '../../filters'
import TextHighlight from 'vue-text-highlight'
import {
    OBtnOptionToolTipComp
} from '../organisms'
export default {
    filters: { imgMiniature },
    components: {
        OBtnOptionToolTipComp,
        TextHighlight
    },
    props: {
        directory: {},
        searchDirectory: String
    },
    methods: {
        ac_getResumenDescription() {
            var newDescription = ''
            if (this.directory.description.length > 100) {
                for(var i=0; i<=100; i++) {
                    newDescription += this.directory.description[i]
                }
                newDescription += '....'
                return newDescription
            } else return this.directory.description
        }
    }
}
</script>