<template>
    <v-dialog persistent :value="dialog" max-width="500">
        <v-card class="pa-0" color="#FAFAFA">
            <div class="primary d-flex justify-center pa-2">
                <div class="d-flex align-center">
                    <v-img
                        src="/imgs/logo/logo_little_inverse_2.png"
                        width="70px"
                        class="mr-3"
                    ></v-img>
                    <h1 style="letter-spacing:1px;" class="text-body-supertitle-inverse">{{title}}</h1>
                </div>
            </div>
            <v-card-title class="d-block pa-0 pt-2">
                <div class="d-flex justify-center">
                    <v-icon x-large class="animation__icon--rotate" color="primary">{{icon}}</v-icon>
                </div>
            </v-card-title>
            <v-card-text class="pa-1 pt-2 d-block">
                <h1 class="text-body-private-subtitle text-center">
                    {{smsCongratulations}}
                </h1>
            </v-card-text>
            <v-card-actions class="pa-2 d-flex justify-center">
                <MBtnNormalComp
                    color="primary"
                    :outlined="true"
                    @click="$emit('click')"
                >{{btnSms}}</MBtnNormalComp>
                <MBtnNormalComp
                    v-if="!outCancel"
                    color="error"
                    :outlined="true"
                    @click="$emit('clickerror')"
                >NO</MBtnNormalComp>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { MBtnNormalComp } from '../molecules'
export default {
    components: {
        MBtnNormalComp
    },
    model: {
        prop: 'dialog',
        event: 'click'
    },
    props: {
        dialog: Boolean,
        smsCongratulations: String,
        icon: String,
        title: String,
        btnSms: String,
        outCancel: Boolean
    }
}
</script>
