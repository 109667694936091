<template>
    <div>
        <v-navigation-drawer
            permanent
            :mini-variant="mini"
            width="300"
            class="o-menu-private"
            fixed
        >
            <div style="width:100%;">
                <div style="height:100px;" class="secondary pa-2 d-flex justify-center">
                    <v-img v-if="!mini" width="130" max-width="130" contain src="/imgs/logo/logo_little_inverse.png">
                    </v-img>
                    <div class="d-flex align-center" v-else>
                        <h1 style="font-size:15px; color:#D5D5DA;">ABIS</h1>
                    </div>
                </div>
            </div>
            <v-divider></v-divider>
            <v-list nav dense>
                <div
                    v-for="(option, index) in listMenu"
                    :key="index"
                >
                    <v-list-group
                        v-if="option.submenu.length>0"
                        class="pb-2"
                        color="white"
                    >
                        <template v-slot:activator>
                            <div v-if="mini">
                                <v-tooltip bottom color="#062242">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            v-bind="attrs"
                                            v-on="on"
                                            class="icon-menu"
                                            color="#e7e7e7f6"
                                        >{{option.icon}}</v-icon>
                                    </template>
                                    <div class="d-flex align-center">
                                        <v-icon class="mr-1" color="white">mdi-hand-pointing-down</v-icon>
                                        <h1 class="text-body-private-subtitle-inverse">{{option.title}}</h1>
                                    </div>
                                </v-tooltip>
                            </div>
                            <v-list-item  v-else class="d-flex pl-0">
                                <v-list-item-icon class="mr-3">
                                    <v-icon class="icon-menu" color="#e7e7e7f6">{{option.icon}}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-subtitle>
                                    <h1 class="text-menu-pc">{{option.title}}</h1>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                        <v-list-item
                            v-for="(suboption, i) in option.submenu"
                            :key="i"
                            :class="mini ? 'pl-2 mb-1' : 'pl-7 mb-1'"
                            :to="{name: suboption.route}"
                            active-class="o-menu-private__btn-action"
                            :ripple="false"
                        >
                            <v-list-item-icon v-if="mini">
                                <v-tooltip bottom color="#062242">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            v-bind="attrs"
                                            v-on="on"
                                            class="icon-menu"
                                            color="#e7e7e7f6"
                                        >{{suboption.icon}}</v-icon>
                                    </template>
                                    <div class="d-flex align-center">
                                        <v-icon class="mr-1" color="white">mdi-hand-pointing-right</v-icon>
                                        <h1 class="text-body-private-subtitle-inverse">{{suboption.title}}</h1>
                                    </div>
                                </v-tooltip>
                            </v-list-item-icon>
                            <v-list-item-icon v-if="!mini" class="mr-3">
                                <v-icon small class="icon-menu" color="#e7e7e7f6">{{suboption.icon}}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-subtitle v-if="!mini">
                                <h1 class="text-menu-pc">{{suboption.title}}</h1>
                            </v-list-item-subtitle>
                        </v-list-item>
                    </v-list-group>
                    <v-list-item
                        v-else
                        :key="option.title"
                        :to="{name: option.route}"
                        active-class="o-menu-private__btn-action"
                        class="mb-3"
                        :ripple="false"
                    >
                        <v-list-item-icon class="mr-3" v-if="mini">
                            <v-tooltip bottom color="#062242">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        v-bind="attrs"
                                        v-on="on"
                                        class="icon-menu"
                                        color="#e7e7e7f6"
                                    >{{option.icon}}</v-icon>
                                </template>
                                <div class="d-flex align-center">
                                    <v-icon class="mr-1" color="white">mdi-hand-pointing-right</v-icon>
                                    <h1 class="text-body-private-subtitle-inverse">{{option.title}}</h1>
                                </div>
                            </v-tooltip>
                        </v-list-item-icon>
                        <v-list-item-icon class="mr-3"  v-if="!mini">
                            <v-icon class="icon-menu" color="#e7e7e7f6">{{option.icon}}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-subtitle v-if="!mini">
                            <h1 class="text-menu-pc">{{option.title}}</h1>
                        </v-list-item-subtitle>
                    </v-list-item>
                </div>
                <v-list-item
                    active-class="o-menu-private__btn-action"
                    :ripple="false"
                    @click="$emit('closesesion')"
                >
                    <v-list-item-icon class="mr-3" v-if="mini">
                        <v-tooltip bottom color="#062242">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    class="icon-menu"
                                    color="#e7e7e7f6"
                                >mdi-close</v-icon>
                            </template>
                            <div class="d-flex align-center">
                                <v-icon class="mr-1" color="white">mdi-hand-pointing-right</v-icon>
                                <h1 class="text-body-private-subtitle-inverse">Cerrar Sesión</h1>
                            </div>
                        </v-tooltip>
                    </v-list-item-icon>
                    <v-list-item-icon class="mr-3"  v-if="!mini">
                        <v-icon color="#e7e7e7f6">mdi-account-lock</v-icon>
                    </v-list-item-icon>
                    <v-list-item-subtitle v-if="!mini">
                        <h1 class="text-menu-pc">Cerrar Sesión</h1>
                    </v-list-item-subtitle>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>
<script>
import OLoaderOperationComp from './OLoaderOperationComp.vue'
export default {
    components: {
        OLoaderOperationComp
    },
    props: {
        mini: Boolean,
        listMenu: Array,
        quantityProcess: Number,
        quantityProcessAcademic: Number
    },
    data: () => ({
        group: null
    })
}
</script>