<template>
    <div>
        <div class="d-none">
            <div ref="sheetimg">
                <div class="d-flex">
                    <h1 class="text-body-private-title mr-1">Afiliado</h1>
                    <h1 class="text-body-private-subtitle">{{dataSheet.interested}}</h1>
                </div>
                <div class="d-flex">
                    <h1 class="text-body-private-title mr-1">CI</h1>
                    <h1 class="text-body-private-subtitle">{{dataSheet.ci}}</h1>
                </div>
                <div class="d-flex mt-1 error">
                    <v-img
                        :src="dataSheet.routeImg|imgNormal"
                        width="100%"
                        contain
                    ></v-img>
                </div>
            </div>
        </div>
        <div class="section-print">
            <div class="user__header pa-2">
                <div class="d-flex justify-center align-center">
                    <v-app-bar-nav-icon class="mr-2 user__icon-menu" @click="m_menuComp_changeStateMenu()"></v-app-bar-nav-icon>
                    <h1 class="text-body-private-title">SOLICITUDES DE PAGO</h1>
                </div>
                <v-divider class="mb-2 mt-1 user__line-mobile"></v-divider>
                <v-spacer></v-spacer>
                <div class="d-flex align-center">
                    <OSearchComp
                        v-if="tab==0"
                        :dense="true"
                        :hideDetails="true"
                        label="Buscar"
                        :singleLine="true"
                        v-model="search"
                        @search="ac_searchProcess()"
                    />
                    <OBtnHeaderToolTipComp
                        v-if="tab==0"
                        class="ml-1"
                        :smsTooltip="viewTable ? 'Cambiar a vista por cards' : 'Cambiar a vista por tabla'"
                        :icon="viewTable ? 'mdi-table-eye' : 'mdi-table-eye-off'"
                        @click="viewTable = !viewTable"
                    />
                    <OBtnHeaderToolTipComp
                        :class="tab==0 ? 'ml-1' : 'mr-1 ml-1'"
                        smsTooltip="Refrescar la lista"
                        icon="mdi-refresh"
                        @click="ac_refreshList()"
                    />
                    <OBtnHeaderToolTipComp
                        v-if="tab==1"
                        smsTooltip="Volver a la lista"
                        icon="mdi-database"
                        @click="tab=0"
                    />
                </div>
            </div>
            <MLineHeaderComp />
            <v-tabs-items
                style="background:transparent !important"
                v-model="tab"
                :touchless="true"
            >
                <v-tab-item>
                    <OLoaderDataComp
                        class="mt-2"
                        v-if="dialogLoaderData"
                        smsLoaderData="Obteniendo solicitudes de Pago"
                    />
                    <MNoDataComp
                        v-if="!dialogLoaderData && listProcessPayments.length==0 && !viewTable"
                        smsNotData="SIN SOLICITUDES"
                        class="ma-2"
                    />
                    <div class="d-flex justify-center mt-3" v-if="viewTable">
                        <STableProcessPaymentComp
                            v-if="!dialogLoaderData"
                            :listProcessPayments="listProcessPayments"
                            :search="searchFilter"
                            @clickadd="ac_showForm"
                            @clickdelete="ac_confirmDeleteProcess"
                        />
                    </div>
                    <div class="d-flex justify-center flex-wrap" v-else>
                        <SCardProcessPaymentComp
                            class="ma-2"
                            v-for="(process, index) in listProcessPayments"
                            :key="index"
                            :process="process"
                            :search="searchFilter"
                            @clickadd="ac_showForm(process)"
                            @clickdelete="ac_confirmDeleteProcess(process)"
                        />
                    </div>
                    <SPaginationComp
                        class="mt-2"
                        v-if="!dialogLoaderData && listProcessPayments.length>0"
                        :condicionGo="page < last_page"
                        :condicionBack="page > 1"
                        :titlePagination="'Pag. '+page+' de '+last_page"
                        @clickback="ac_changePage(false)"
                        @clickgo="ac_changePage(true)"
                    />
                </v-tab-item>
                <v-tab-item>
                    <div class="pa-2" v-if="process.person!=undefined">
                        <div class="d-flex">
                            <h1 class="text-body-private-title mr-1">Afiliado:</h1>
                            <h1 class="text-body-private-subtitle">
                                {{process.person.full_name+' '+process.person.last_name}}</h1>
                        </div>
                        <div class="d-flex">
                            <h1 class="text-body-private-title mr-1">CI:</h1>
                            <h1 class="text-body-private-subtitle">{{process.person.ci}}</h1>
                        </div>
                        <div class="d-flex">
                            <h1 class="text-body-private-title mr-1">Inicio de solicitud:</h1>
                            <h1 class="text-body-private-subtitle">{{process.date}}</h1>
                        </div>
                        <div class="d-flex">
                            <h1 class="text-body-private-title mr-1">Referencia de Pago:</h1>
                            <h1 class="text-body-private-subtitle">
                                {{process.reference!=null ? process.reference : 'Sin Referencia'}}</h1>
                        </div>
                        <div class="d-flex">
                            <h1 class="text-body-private-title mr-1">Observación:</h1>
                            <h1 class="text-body-private-subtitle">
                                {{process.observation!=null ? process.observation : 'Sin Observación'}}</h1>
                        </div>
                        <div class="d-flex">
                            <h1 class="text-body-private-title mr-1">Estado de pagos:</h1>
                            <h1 class="text-body-private-subtitle">
                                {{ac_withValidity() ? 'Al día' : 'Adeudado'}}
                            </h1>
                        </div>
                        <div class="d-flex">
                            <h1 class="text-body-private-title mr-1">Expiración de acreditación:</h1>
                            <h1 class="text-body-private-subtitle">{{m_date_formatDate(process.person.date_of_expiry)}}</h1>
                        </div>
                        <div class="d-flex" v-if="!ac_withValidity()">
                            <h1 class="text-body-private-title mr-1">N° de Cuotas atrasadas:</h1>
                            <h1 class="text-body-private-subtitle">
                                {{m_calculate_debt(process.person.dif_days)}}</h1>
                        </div>
                        <div class="d-flex" v-if="!ac_withValidity()">
                            <h1 class="text-body-private-title mr-1">Monto a Cancelar:</h1>
                            <h1 class="text-body-private-subtitle">
                                {{m_calculate_payment(process.person.dif_days, 15)+' Bs.'}}</h1>
                        </div>
                        <v-divider class="mt-1 mb-1"></v-divider>
                        <div class="d-flex flex-wrap">
                            <MBtnNormalComp
                                :outlined="true"
                                color="blue"
                                @click="ac_showFile()"
                                style="margin:2px"
                            >VER COMPROBANTE DE PAGO</MBtnNormalComp>
                            <MBtnNormalComp
                                :outlined="true"
                                color="blue"
                                @click="dialogObservation=true"
                                style="margin:2px"
                            >Observar Solicitud</MBtnNormalComp>
                            <MBtnNormalComp
                                :outlined="true"
                                color="blue"
                                @click="dialogAccreditation=true"
                                style="margin:2px"
                            >Extender Acreditación</MBtnNormalComp>
                            <div class="d-flex align-center">
                                <OIconToolTipComp
                                    :mobile="mobile"
                                    :large="true"
                                    icon="mdi-download-circle"
                                    color="blue"
                                    class="mr-1 ml-1"
                                    smsTooltip="Descargar archivo"
                                    @click="ac_downloadFile()"
                                />
                                <OIconToolTipComp
                                    v-if="ac_validTypeFile()"
                                    :mobile="mobile"
                                    :large="true"
                                    icon="mdi-printer"
                                    color="blue"
                                    class="mr-1"
                                    smsTooltip="Imprimir imagen"
                                    @click="ac_printImage()"
                                />
                            </div>
                        </div>
                    </div>
                </v-tab-item>
            </v-tabs-items>
        </div>

        <v-dialog persistent width="400" v-model="dialogObservation">
            <ODialogHeaderComp
                title="Observar Solicitud de Pago"
                @clickclose="ac_closeDialogObservation()"
            />
            <v-card class="ma-0 pa-2">
                <div class="d-flex">
                    <h1 class="text-body-private-title mr-1">AFILIADO:</h1>
                    <h1 class="text-body-private-subtitle" v-if="process.person!=undefined">
                        {{process.person.full_name+' '+process.person.last_name}}
                    </h1>
                </div>
                <v-form
                    v-on:submit.prevent="ac_confirmObservation()"
                    ref="formobservation"
                    v-model="valid"
                    lazy-validation
                >
                    <MTextFieldComp
                        class="mt-3"
                        v-model="observation"
                        :clearable="true"
                        label="Observación"
                        :dense="true"
                        :counter="100"
                        :rules="[
                            v => !this.m_rule_emptyField(v) || 'El dato es requerido',
                            v => (v+'').length<=100 || 'La observación no debe exeder los 100 dígitos']"
                    />
                </v-form>
                <div class="text-center">
                    <MBtnNormalComp
                        color="primary"
                        :outlined="true"
                        @click="ac_confirmObservation()"
                    >GUARDAR OBSERVACIÓN</MBtnNormalComp>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog persistent width="400" v-model="dialogAccreditation">
            <ODialogHeaderComp
                title="Extender Acreditación"
                @clickclose="ac_closeExtension()"
            />
            <v-card class="ma-0 pa-2" v-if="process.person!=undefined">
                <div class="d-flex">
                    <h1 class="text-body-private-title mr-1">Afiliado:</h1>
                    <h1 class="text-body-private-subtitle">
                        {{process.person.full_name+' '+process.person.last_name}}
                    </h1>
                </div>
                <div class="d-flex">
                    <h1 class="text-body-private-title mr-1">Estado de pagos:</h1>
                    <h1 class="text-body-private-subtitle">
                        {{ac_withValidity() ? 'Al día' : 'Adeudado'}}
                    </h1>
                </div>
                <div class="d-flex">
                    <h1 class="text-body-private-title mr-1">Expiración de acreditación:</h1>
                    <h1 class="text-body-private-subtitle">{{m_date_formatDate(process.person.date_of_expiry)}}</h1>
                </div>
                <div class="d-flex" v-if="!ac_withValidity()">
                    <h1 class="text-body-private-title mr-1">N° de Cuotas atrasadas:</h1>
                    <h1 class="text-body-private-subtitle">
                        {{m_calculate_debt(process.person.dif_days)}}</h1>
                </div>
                <div class="d-flex" v-if="!ac_withValidity()">
                    <h1 class="text-body-private-title mr-1">Monto a Cancelar:</h1>
                    <h1 class="text-body-private-subtitle">
                        {{m_calculate_payment(process.person.dif_days, 15)+' Bs.'}}</h1>
                </div>
                <v-divider class="mt-1 mb-1"></v-divider>
                <v-form
                    v-on:submit.prevent="ac_confirmarExtension()"
                    ref="formaccreditation"
                    v-model="valid"
                    lazy-validation
                >
                    <MTextFieldComp
                        class="mt-2"
                        v-model="number_payments"
                        :clearable="true"
                        label="N° de cuotas a extender"
                        :dense="true"
                        :counter="10"
                        :rules="[
                            v => !this.m_rule_emptyField(v) || 'El dato es requerido',
                            v => this.m_rule_numberInteger(v) || 'El número de cuotas debe ser un número',
                            v => (v+'').length<=10 || 'La número de cuotas no debe exceder los 10 dígitos']"
                    />
                </v-form>
                <div class="text-center">
                    <MBtnNormalComp
                        color="primary"
                        :outlined="true"
                        @click="ac_confirmarExtension()"
                    >EXTENDER ACREDITACIÓN</MBtnNormalComp>
                </div>
            </v-card>
        </v-dialog>

        <OModalImgServerComp
            :dialog="modalImg"
            :src="srcImg"
            :title="titlePDF"
            ref="modalimage"
            @clickclose="ac_closeModalImage()"
        />
        <OModalPdfComp
            :src="srcPDF"
            :dialog="dialogPDF"
            :title="titlePDF"
            @clickclose="dialogPDF=false"
        />

        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_closeSmsError()"
        />
        <OSmsConfirmComp
            :dialog="dialogConfirm"
            :smsConfirm="smsConfirm"
            @clickno="ac_closeSmsError()"
            @clickyes="ac_perfomOperation()"
        />
        <OSmsActionComp
            :frameCorrect="frameCorrect"
            :smsAction="smsAction"
            :timeout="timeSucess"
            @click="m_dataGeneral_frameTimeReset()"
        />
        <OLoaderOperationComp
            :dialog="dialogLoaderOperation"
            smsLoaderOperation="Realizando Operación"
        />
    </div>
</template>
<script>
import {
    MTextFieldComp,
    MLineHeaderComp,
    MBtnNormalComp,
    MNoDataComp
} from '../../components/molecules'
import {
    OSmsErrorComp,
    OSmsConfirmComp,
    OLoaderOperationComp,
    OLoaderDataComp,
    OSmsActionComp,
    OBtnHeaderToolTipComp,
    OModalImgServerComp,
    OModalPdfComp,
    ODialogHeaderComp,
    OSearchComp,
    OIconToolTipComp
} from '../../components/organisms'
import {
    SPaginationComp,
    STableProcessPaymentComp,
    SCardProcessPaymentComp
} from '../../components/species'
import { imgNormal } from '../../filters'
import {
    menuCompMixing,
    userMixin,
    responseServerMixin,
    rulesMixin,
    dataGeneralComponetsMixin,
    datesMixin,
    calculatePayementsMixin,
    scrollMixin
} from '../../mixin'
import { ProcessPayments, Person } from '../../models'
export default {
    filters: {
        imgNormal
    },
    mixins: [
        menuCompMixing,
        userMixin,
        responseServerMixin,
        rulesMixin,
        dataGeneralComponetsMixin,
        datesMixin,
        calculatePayementsMixin,
        scrollMixin
    ],
    components: {
        OSmsErrorComp,
        OSmsConfirmComp,
        OLoaderOperationComp,
        OLoaderDataComp,
        OSmsActionComp,
        MTextFieldComp,
        MLineHeaderComp,
        SPaginationComp,
        STableProcessPaymentComp,
        OBtnHeaderToolTipComp,
        MBtnNormalComp,
        OModalImgServerComp,
        OModalPdfComp,
        ODialogHeaderComp,
        OSearchComp,
        SCardProcessPaymentComp,
        MNoDataComp,
        OIconToolTipComp
    },
    data: () => ({
        search: '',
        listProcessPayments: [],
        page: 0,
        last_page: 0,
        total: 0,
        viewTable: true,
        indexProcess: -1,
        process: {},
        tab: 0,
        srcPDF: '',
        dialogPDF: false,
        titlePDF: '',
        modalImg: false,
        srcImg: '',
        dialogObservation: false,
        observation: null,
        valid: true,
        isObservation: false,
        number_payments: null,
        dialogAccreditation: false,
        isDelete: false,
        idProcess: -1,
        mobile: false,
        dataSheet: {
            routeImg: '',
            interested: '',
            ci: ''
        }
    }),
    computed: {
        searchFilter: function() {
            if (this.search === null) return ''
            return this.search
        }
    },
    methods: {
        processError(error) {
            this.dialogLoaderOperation = false
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_action(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        getProcessPayments(page, search) {
            this.listProcessPayments = []
            this.dialogLoaderData = true
            ProcessPayments.getListProcess(page, search, 's').then(response => {
                this.listProcessPayments = response.data
                this.page = response.page
                this.last_page = response.last_page
                this.total = response.total
                this.dialogLoaderData = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_closeSmsError() {
            if (this.noAccess) {
                localStorage.removeItem('abis')
                this.$router.replace('/login')
            } else {
                this.dialogError = false
                this.dialogConfirm = false
                if (!this.isDelete) {
                    if (this.isObservation) this.dialogObservation = true
                    else this.dialogAccreditation = true
                } else this.isDelete = false 
            }
        },
        ac_showForm(process) {
            this.process = process
            this.indexProcess = this.listProcessPayments.indexOf(process)
            this.tab = 1
        },
        ac_withValidity() {
            if (this.process.person !== undefined) {
                if (this.process.person.is_expired === 0) return true
                return false
            }
            return false
        },
        ac_closeModalImage() {
            this.$refs.modalimage.resetSize()
            this.modalImg = false
        },
        ac_showFile() {
            const dataRoute = this.process.route_file.split(".")
            this.titlePDF = 'Comprobante de Pago'
            if (dataRoute[1] != 'pdf') {
                this.srcImg = this.process.route_file
                this.modalImg = true
            } else {
                if (this.mobile) {
                    Person.showPdfMobile(this.process.route_file)
                } else {
                    this.srcPDF = ''
                    this.srcPDF = Person.showPdfPc(this.process.route_file)
                    setTimeout(() => {
                        this.dialogPDF = true
                    }, 200)
                }
            }
        },
        ac_closeDialogObservation() {
            this.isObservation = false
            this.dialogObservation = false
            this.observation = null
            this.$refs.formobservation.resetValidation()
        },
        validObservation() {
            if (!this.$refs.formobservation.validate()) return false
            return true
        },
        ac_confirmObservation() {
            this.isObservation = true
            if (this.validObservation()) {
                this.dialogObservation = false
                this.smsConfirm = ['¿Está seguro de observar la solicitud de pago de:',
                    `${this.process.person.full_name} ${this.process.person.last_name}?`]
                this.dialogConfirm = true
            }
        },
        showEvent(message) {
            this.smsAction = message
            this.frameCorrect = true
            this.timeSucess = setTimeout(() => {
                this.frameCorrect = false
            }, 3000)
        },
        addObservation() {
            ProcessPayments.updateProcess({id: this.process.id, observation: this.observation}).then(() => {
                this.listProcessPayments.splice(this.indexProcess, 1)
                this.ac_closeDialogObservation()
                this.dialogLoaderOperation = false
                this.tab = 0
                this.showEvent(['Operación exitosa', 'Se observó el pago'])
            }).catch(error => {
                this.processError(error)
            })
        },
        addAccreditation() {
            const parameters = {
                id_process: this.process.id,
                id_person: this.process.person.id,
                number_days: parseInt(this.number_payments) * 30
            }
            ProcessPayments.extendedAccreditation(parameters).then(() => {
                this.listProcessPayments.splice(this.indexProcess, 1)
                this.ac_closeExtension()
                this.dialogLoaderOperation = false
                this.tab = 0
                this.showEvent(['Operación exitosa', 'Se extendió la acreditación'])
            }).catch(error => {
                this.processError(error)
            })
        },
        deleteProcess() {
            ProcessPayments.deleteProcess({id: this.idProcess}).then(() => {
                this.listProcessPayments.splice(this.indexProcess, 1)
                this.isDelete = false
                this.dialogLoaderOperation = false
                this.showEvent(['Operación exitosa', 'Se eliminó la solicitud'])
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_perfomOperation() {
            this.dialogConfirm = false
            this.dialogLoaderOperation = true
            if (this.isDelete) this.deleteProcess()
            else {
                if (this.isObservation) this.addObservation()
                else this.addAccreditation()
            }
        },
        validAccreditation() {
            if (!this.$refs.formaccreditation.validate()) return false
            return true
        },
        ac_confirmarExtension() {
            if (this.validAccreditation()) {
                this.dialogAccreditation = false
                this.smsConfirm = [`N° de cuotas a extender: ${this.number_payments}`,
                `Total Pago: ${parseInt(this.number_payments)*15} Bs.`,
                `N° de días añadidos: ${parseInt(this.number_payments)*30}`,
                `Afiliado: ${this.process.person.full_name} ${this.process.person.last_name}`]
                this.dialogConfirm = true
            }
        },
        ac_closeExtension() {
            this.isObservation = false
            this.dialogAccreditation = false
            this.number_payments = null
            this.$refs.formaccreditation.resetValidation()
        },
        ac_confirmDeleteProcess(process) {
            this.idProcess = process.id
            this.indexProcess = this.listProcessPayments.indexOf(process)
            this.isDelete = true
            this.smsConfirm = [`¿Está seguro de eliminar la solicitud de: 
                ${process.person.full_name} ${process.person.last_name}?`]
            this.dialogConfirm = true
        },
        ac_searchProcess() {
            if (this.search === null) this.getProcessPayments(1, '')
            else this.getProcessPayments(1, this.search)
        },
        ac_changePage(state) {
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            if (state) { ++this.page }
            else { --this.page }
            setTimeout(() => {
                this.getProcessPayments(this.page, this.search)
            }, velocity)
        },
        ac_refreshList() {
            this.search = ''
            if (this.tab != 0) this.tab = 0
            this.getProcessPayments(1, '')
        },
        ac_downloadFile() {
            Person.downloadImage(this.process.route_file)
        },
        ac_validTypeFile() {
            const dataRoute = this.process.route_file.split(".")
            if (dataRoute[1] != 'pdf') return true
            return false
        },
        ac_printImage() {
            this.dataSheet.routeImg = this.process.route_file
            this.dataSheet.interested = this.process.person.full_name+' '+this.process.person.last_name
            this.dataSheet.ci = this.process.person.ci+' '+this.process.person.issued_in
            this.$nextTick(() => {
                setTimeout(() => {
                    this.print(this.$refs.sheetimg);
                }, 500)
            })
        },
        print(element) {
            let printSection = document.getElementById('printSection')
            var domClone = element.cloneNode(true)
            printSection.appendChild(domClone)
            window.print()
            printSection.innerHTML = ""
        }
    },
    mounted() {
        if (window.innerWidth <= 600) {
            this.viewTable = false
            this.mobile = true
        }
        if (this.m_user_saveToken()) {
            this.getProcessPayments(1, '')
        } else this.$router.replace('/login')
    }
}
</script>