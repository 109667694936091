<template>
    <div class="o-card-animation-logo">
        <div style="width:100%">
            <div class="d-flex justify-center">
                <div class="o-card-animation-logo__logo">
                    <v-img
                        src="/imgs/logo/logo_little_inverse.png"
                        width="100%"
                    ></v-img>
                </div>
            </div>
            <div class="d-flex justify-center mt-1">
                <h1 class="text-fond-title text-fond--shadow mr-3"
                    style="color:#329EF4"
                >ABIS</h1>
                <h1 class="text-fond-title text-fond--shadow"
                    style="color:#f5f4f4cb"
                >Chuquisaca</h1>
            </div>
            <div>
                <div class="home__message">
                    <div class="home__group-text">
                        <div
                            class="home__message-title d-flex justify-center align-center"
                            v-for="(message, index) in listMessages"
                            :key="index"
                        >
                            <h1 class="text-fond-subtitle">{{message}}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data: () => ({
        listMessages: [
            'Trabajo Cooperativo entre profesionales',
            'Actualización de profesionales',
            'Fortalecimiento de sus miembros',
            'Facilidad de oportunidades'
        ]
    })
}
</script>