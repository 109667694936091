<template>
    <div>
        <div class="user__header pa-2">
            <div class="d-flex justify-center align-center">
                <v-app-bar-nav-icon class="mr-2 user__icon-menu" @click="m_menuComp_changeStateMenu()"></v-app-bar-nav-icon>
                <h1 class="text-body-private-title">CELULARES Whatsapp</h1>
            </div>
            <v-divider class="mb-2 mt-1 user__line-mobile"></v-divider>
            <v-spacer></v-spacer>
            <div class="d-flex align-center">
                <MTextFieldComp
                    :dense="true"
                    :hideDetails="true"
                    label="Buscar"
                    :singleLine="true"
                    v-model="search"
                />
                <OBtnHeaderToolTipComp
                    smsTooltip="Nuevo whatsapp"
                    icon="mdi-plus"
                    class="ml-1"
                    @click="dialogPhone=true"
                />
                <OBtnHeaderToolTipComp
                    class="ml-1"
                    :smsTooltip="viewTable ? 'Cambiar a vista por cards' : 'Cambiar a vista por tabla'"
                    :icon=" viewTable ? 'mdi-table-eye' : 'mdi-table-eye-off'"
                    @click="viewTable = !viewTable"
                />
            </div>
        </div>
        <MLineHeaderComp />
        <OLoaderDataComp
            class="mt-2"
            v-if="dialogLoaderData"
            smsLoaderData="Obteniendo Números"
        />
        <MNoDataComp
            v-if="!dialogLoaderData && listPhoneFilter.length==0 && !viewTable"
            smsNotData="SIN CELULARES"
            class="ma-2"
        />
        <div class="d-flex justify-center mt-3" v-if="viewTable">
            <STablePhoneComp
                v-if="!dialogLoaderData"
                :listPhones="listPhoneFilter"
                :searchPhone="searchFilter"
                @clickedit="ac_showFormUpdate"
                @clickdelete="ac_confirmDelete"
            />
        </div>
        <div class="d-flex justify-center flex-wrap"  v-else>
            <SCardWhatsappComp
                class="ma-2"
                v-for="(phone, index) in listPhoneFilter"
                :key="index"
                :phone="phone"
                :searchPhone="searchFilter"
                @clickedit="ac_showFormUpdate(phone)"
                @clickdelete="ac_confirmDelete(phone)"
            />
        </div>
        <v-dialog
            v-model="dialogPhone"
            persistent
            width="450"
        >
            <ODialogHeaderComp
                :title="indePhone==-1 ? 'Nuevo Número' : 'Editar Número'"
                @clickclose="ac_closeDialogPhone()"
            />
                <v-card class="ma-0 pa-2">
                    <v-form
                        v-on:submit.prevent="ac_confirmAction()"
                        ref="formphone"
                        v-model="valid"
                        lazy-validation
                    >
                        <MTextFieldComp
                            class="mt-2"
                            v-model="dataPhone.name"
                            :clearable="true"
                            label="Nombre (*)"
                            :dense="true"
                            :counter="30"
                            :rules="[v => !this.m_rule_emptyField(v) || 'El dato es requerido',
                                v => (v+'').length<=20 || 'El nombre no puede exceder los 30 dígitos']"
                        />
                        <MTextFieldComp
                            class="mt-3"
                            v-model="dataPhone.number"
                            :clearable="true"
                            label="Celular (*)"
                            :dense="true"
                            :counter="8"
                            :rules="[v => !this.m_rule_emptyField(v) || 'El dato es requerido',
                                v => this.m_rule_numberInteger(v) || 'El celular debe ser un número',
                                v => (v+'').length==8 || 'El celular debe contener 8 dígitos']"
                        />
                        <MTextFieldComp
                            class="mt-3"
                            v-model="dataPhone.profession"
                            :clearable="true"
                            label="Cargo (*)"
                            :dense="true"
                            :counter="30"
                            :rules="[v => !this.m_rule_emptyField(v) || 'El dato es requerido',
                                v => (v+'').length<=30 || 'El cargo no debe exceder los 30 dígitos']"
                        />
                    </v-form>
                    <div class="d-flex justify-center">
                        <MBtnNormalComp
                            color="primary"
                            :outlined="true"
                            @click="ac_confirmAction()"
                        >
                            guardar
                        </MBtnNormalComp>
                    </div>
                </v-card>
        </v-dialog>

        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_closeSmsError()"
        />
        <OSmsConfirmComp
            :dialog="dialogConfirm"
            :smsConfirm="smsConfirm"
            @clickno="ac_closeSmsError()"
            @clickyes="ac_perfomOperation()"
        />
        <OSmsActionComp
            :frameCorrect="frameCorrect"
            :smsAction="smsAction"
            :timeout="timeSucess"
            @click="m_dataGeneral_frameTimeReset()"
        />
        <OLoaderOperationComp
            :dialog="dialogLoaderOperation"
            smsLoaderOperation="Realizando Operación"
        />
    </div>
</template>
<script>
import {
    MTextFieldComp,
    MLineHeaderComp,
    MNoDataComp,
    MBtnNormalComp
} from '../../components/molecules'
import {
    OBtnHeaderToolTipComp,
    OSmsErrorComp,
    OSmsConfirmComp,
    OSmsActionComp,
    OLoaderOperationComp,
    OLoaderDataComp,
    ODialogHeaderComp
} from '../../components/organisms'
import {
    SCardWhatsappComp,
    STablePhoneComp
} from '../../components/species'
import {
    menuCompMixing,
    dataGeneralComponetsMixin,
    rulesMixin,
    responseServerMixin,
    userMixin
} from '../../mixin'
import { Whatsapp } from '../../models'
export default {
    mixins: [
        menuCompMixing,
        dataGeneralComponetsMixin,
        rulesMixin,
        responseServerMixin,
        userMixin
    ],
    components: {
        MTextFieldComp,
        OBtnHeaderToolTipComp,
        OSmsErrorComp,
        OSmsConfirmComp,
        OSmsActionComp,
        OLoaderOperationComp,
        OLoaderDataComp,
        MLineHeaderComp,
        MNoDataComp,
        SCardWhatsappComp,
        ODialogHeaderComp,
        MBtnNormalComp,
        STablePhoneComp
    },
    data: () => ({
        listPhones: [],
        dataPhone: new Whatsapp(),
        indePhone: -1,
        isDelete: false,
        search: '',
        viewTable: true,
        dialogPhone: false,
        valid: true,
        idPhone: 0
    }),
    computed: {
        listPhoneFilter: function() {
            if (this.search === null) return this.listPhones
            else {
                return this.listPhones.filter(phone => {
                    return phone.name.toLowerCase()
                            .indexOf(this.search.toLowerCase()) !== -1
                })
            }
        },
        searchFilter: function() {
            if (this.search === null) return ''
            return this.search
        }
    },
    methods: {
        processError(error) {
            this.dialogLoaderOperation = false
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_action(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        ac_closeSmsError() {
            if (this.noAccess) {
                localStorage.removeItem('abis')
                this.$router.replace('/login')
            } else {
                this.indePhone = -1
                this.dialogError = false
                this.dialogConfirm = false
                if (!this.isDelete) {
                    this.isDelete = false
                    this.dialogPhone = true
                }
            }
        },
        getListPhones() {
            this.dialogLoaderData = true
            Whatsapp.listPhones().then(response => {
                this.listPhones = response
                this.dialogLoaderData = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_confirmDelete(phone) {
            this.idPhone = phone.id
            this.indePhone = this.listPhones.indexOf(phone)
            this.isDelete = true
            this.smsConfirm = ['¿Está seguro de eliminar el número de:',
                `${phone.name}?`]
            this.dialogConfirm = true
        },
        ac_closeDialogPhone() {
            this.indePhone = -1
            this.dataPhone = new Whatsapp()
            this.$refs.formphone.resetValidation()
            this.dialogPhone = false
        },
        ac_showFormUpdate(phone) {
            this.indePhone = this.listPhones.indexOf(phone)
            this.dataPhone = Object.assign({}, phone)
            this.dialogPhone = true
        },
        validateDataPhone() {
            if (!this.$refs.formphone.validate()) return false
            return true
        },
        ac_confirmAction() {
            this.dialogPhone = false
            if (!this.validateDataPhone()) {
                this.smsError = [`Revise los datos marcados con rojo y corrija porfavor`]
                this.dialogError = true
                this.actionError = true
            } else {
                if (this.indePhone === -1) this.smsConfirm = ['¿Está seguro de registrar el número de:', `${this.dataPhone.name}?`]
                else this.smsConfirm = ['¿Está seguro de editar los datos de:', `${this.dataPhone.name}?`]
                this.dialogConfirm = true
            }
        },
        showEvent(message) {
            this.smsAction = message
            this.frameCorrect = true
            this.timeSucess = setTimeout(() => {
                this.frameCorrect = false
            }, 3000)
        },
        addPhone() {
            Whatsapp.addPhone(this.dataPhone).then(response => {
                this.listPhones.unshift(response)
                this.ac_closeDialogPhone()
                this.dialogLoaderOperation = false
                this.showEvent(['Operación exitosa',
                    'se registró el celular'])
            }).catch(error => {
                this.processError(error)
            })
        },
        updatePhone() {
            Whatsapp.updatePhone(this.dataPhone).then(response => {
                Object.assign(this.listPhones[this.indePhone], response)
                this.ac_closeDialogPhone()
                this.dialogLoaderOperation = false
                this.showEvent(['Operación exitosa',
                    'se edito el celular'])
            }).catch(error => {
                this.processError(error)
            })
        },
        deletePhone() {
            Whatsapp.deletePhone({id: this.idPhone}).then(() => {
                this.listPhones.splice(this.indePhone, 1)
                this.dialogLoaderOperation = false
                this.isDelete = false
                this.indePhone = -1
                this.showEvent(['Operación exitosa',
                    'se eliminó el celular'])
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_perfomOperation() {
            this.dialogConfirm = false
            this.dialogLoaderOperation = true
            if (this.isDelete) this.deletePhone()
            else {
                if (this.indePhone !== -1) this.updatePhone()
                else this.addPhone()
            }
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.viewTable = false
        if (this.m_user_saveToken()) {
            this.getListPhones()
        } else this.$router.replace('/login')
    }
}
</script>