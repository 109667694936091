<template>
    <div class="gallery-public__container-img animation__card--opacity">
        <div
            class="gallery-public__image d-flex align-center"
            style="opacity:1; cursor:initial"
        >
            <v-img
                :src="person.route_face|imgMiniature"
                contain
                width="100%"
                height="100%"
            ></v-img>
        </div>
        <v-divider></v-divider>
        <div class="gallery-public__image-title">
            <h1 class="text-body-private-small-title text-center">
            {{person.full_name+' '+person.last_name}}</h1>
            <h1 class="text-body-private-small text-center">
            {{person.identifier}}</h1>
            <h1 class="text-body-private-small-title text-center" v-if="searchPerson!=''">
            {{searchPerson}}</h1>
            <div class="d-flex justify-center text-center mt-1 mb-1">
                <OBtnOptionToolTipComp
                    smsTooltip="Whatsapp"
                    class="mr-1"
                    icon="mdi-whatsapp"
                    @click="$emit('clicknumber')"
                />
                <OBtnOptionToolTipComp
                    smsTooltip="Información"
                    class="mr-1"
                    icon="mdi-format-list-bulleted"
                    @click="$emit('clickdetail')"
                />
                <MBtnNormalComp
                    v-if="person.admin_public==1 && person.user_public==1"
                    :outlined="true"
                    color="blue"
                    @click="$emit('clickcv')"
                >CV</MBtnNormalComp>
            </div>
        </div>
    </div>
</template>
<script>
import { MBtnNormalComp } from '../molecules'
import { OBtnOptionToolTipComp } from '../organisms'
import { imgMiniature } from '../../filters'
export default {
    components: {
        MBtnNormalComp,
        OBtnOptionToolTipComp
    },
    filters: {
        imgMiniature
    },
    props: {
        person: {},
        searchPerson: String
    }
}
</script>