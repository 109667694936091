<template>
    <v-form
        v-on:submit.prevent="ac_confirmProfession()"
        ref="formprofession"
        v-model="validProfession"
        lazy-validation
    >
        <ODialogHeaderComp
            :title="indexProfession!=-1 ? 'Editar Profesión' : 'Nueva Profesión'"
            @clickclose="$emit('clickclose')"
        />
        <v-card class="ma-0 pa-2 pt-5">
            <MTextFieldComp
                label="Título a nivel técnico, licenciatura y/o posgrado"
                v-model="dataProfession.profession"
                :dense="true"
                :counter="100"
                :rules="[v => !m_rule_emptyField(v) || 'El dato es requerido',
                    v => (v + '').length <= 100 || 'La profesión no puede exceder los 100 dígitos'
                ]"
            />
            <MTextFieldComp
                label="Universidad / Instituto"
                v-model="dataProfession.entity"
                class="mt-2"
                :dense="true"
                :counter="150"
                :rules="[v => !m_rule_emptyField(v) || 'El dato es requerido',
                    v => (v + '').length <= 150 || 'La universidad o institución no puede exceder los 150 dígitos'
                ]"
            />
            <MTextFieldComp
                label="Localidad Entidad"
                v-model="dataProfession.location"
                :dense="true"
                class="mt-2"
                :counter="100"
                :rules="[v => !m_rule_emptyField(v) || 'El dato es requerido',
                    v => (v + '').length <= 100 || 'La localización de la entidad graduada no puede exceder los 50 dígitos'
                ]"
            />
            <OPdfInputComp
                class="mt-1"
                titleFile="Respaldo PDF anverso y reverso en un solo archivo"
                :subtitleFile="indexProfession!=-1 ? 'Sin Nuevo Archivo' : 'Sin archivo'"
                :errorPdf="errorPdf"
                :validPdf="validPdf"
                @onChangePdf="ac_onChangePdf"
            />
            <div class="text-center mt-3">
                <MBtnNormalComp
                    :outlined="true"
                    color="primary"
                    @click="ac_confirmProfession()"
                >{{indexProfession!=-1 ? 'GUARDAR' : 'REGISTRAR'}}</MBtnNormalComp>
            </div>
        </v-card>
    </v-form>
</template>
<script>
import {
    MBtnNormalComp,
    MTextFieldComp
} from '../../components/molecules'
import {
    ODialogHeaderComp,
    OPdfInputComp
} from '../../components/organisms'
import {
    rulesMixin
} from '../../mixin'
export default {
    mixins: [ rulesMixin ],
    components: {
        MBtnNormalComp,
        MTextFieldComp,
        ODialogHeaderComp,
        OPdfInputComp
    },
    props: {
        indexProfession: Number,
        dataProfession: Object
    },
    data: () => ({
        validProfession: true,
        errorPdf: false,
        validPdf: false
    }),
    methods: {
        validateProfession() {
            if (!this.$refs.formprofession.validate()) return false
            return true
        },
        ac_confirmProfession() {
            if (this.dataProfession.route_file === null && this.indexProfession === -1) this.errorPdf = true
            if (this.validateProfession()) {
                if (this.indexProfession === -1) {
                    if (this.dataProfession.route_file !== null)
                        this.$emit('clickaction', true)
                    else this.$emit('clickaction', false)
                } else this.$emit('clickaction', true)
            } else this.$emit('clickaction', false)
        },
        resetForm() {
            this.validPdf = false
            this.errorPdf = false
            this.$refs.formprofession.resetValidation()
        },
        ac_onChangePdf(data) {
            if (data.is_big) {
                this.dataProfession.route_file = null
                this.errorPdf = true
                this.validPdf = false
                this.$emit('eventError')
            } else {
                this.dataProfession.route_file = data.file
                if (data.file !== null) {
                    this.errorPdf = false
                    this.validPdf = true
                } else {
                    if (this.indexSpecialty === -1) this.errorPdf = true
                    this.validPdf = false
                }
            }
        }
    }
}
</script>