import axios from 'axios'
import { apiUrls } from '../plugins/apiUrls'
import { userStore } from '../store/modules'
export default class ProcessAffiliation {
    constructor(
        number_procedure = null,
        generate_code = null
    ){
        this.number_procedure = number_procedure
        this.generate_code = generate_code
    }
    static sendProcessAffiliation(parameters) {
        return axios.post(
            apiUrls.PROCESS_AFFILIATION + '/create-request',
            parameters
        ).then(response => {
            return response.data
        })
    }
    static getProcess(parameters) {
        return axios.post(
            apiUrls.PROCESS_AFFILIATION + '/get-process',
            parameters
        ).then(response => {
            return response.data
        })
    }
    static getListProcessAffiliation(page, ci, type_process) {
        return axios.get(
            apiUrls.PROCESS_AFFILIATION + '/list-process?page='+page+'&ci='+ci+'&type_process='+type_process,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static verifyProcess(search) {
        return axios.get(
            apiUrls.PROCESS_AFFILIATION + '/verify-process?search=' + search,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static quantityProcess(header) {
        return axios.get(
            apiUrls.PROCESS_AFFILIATION + '/quantity-process',
            { headers: header }
        ).then(response => {
            return response.data
        })
    }
    static deleteProcess(parameters) {
        return axios.delete(
            apiUrls.PROCESS_AFFILIATION + '/delete-process',
            { headers: userStore.state.header, data: parameters } 
        ).then(response => {
            return response.data
        })
    }
}