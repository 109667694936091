const domain = process.env.VUE_APP_ROOT_API;
export const apiUrls = {
    PICTURE: domain + 'image',
    PROCESS_AFFILIATION: domain + 'process-affiliation',
    PERSON: domain + 'person',
    INTERESTED: domain + 'interested',
    USER: domain + 'user',
    PROCESS_CV: domain + 'process-cv',
    LANGUAJE: domain + 'languaje',
    QUALITIE: domain + 'qualitie',
    OTHER: domain + 'other',
    SPECIALTY: domain + 'specialty',
    PROFESSION: domain + 'profession',
    EXPERIENCE: domain + 'experience',
    WHATSAPP: domain + 'phone',
    DIRECTORY: domain + 'directory',
    GALLERY: domain + 'gallery',
    PUBLICITY: domain + 'publicity',
    PROCESS_PAYMENT: domain + 'process-payment',
    DOCUMENT: domain + 'document',
    CONVENTION: domain + 'convention',

    PROCESS_AFFILIATION_ACADEMIC: domain + 'process-affiliation-academic',
    INTERESTED_ACADEMIC: domain + 'interested-academic',
}