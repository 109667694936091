<template>
    <div class="s-card-public-affiliate animation__card--opacity">
        <div class="mr-2">
            <div class="s-card-public-affiliate__container-photo">
                <div class="s-card-public-affiliate__photo">
                    <v-img
                        :src="person.route_face|imgMiniature"
                        width="100%"
                        height="100%"
                        style="border-radius:5px"
                        contain
                    ></v-img>
                </div>
            </div>
            <div class="d-flex justify-center mt-1">
                <h1 class="text-body-private-small-title mr-1">CI:</h1>
                <text-highlight
                    class="text-body-private-small"
                    :queries="searchPerson"
                >
                    {{person.ci}}
                </text-highlight>
                <h1 class="text-body-private-small ml-1">{{person.issued_in}}</h1>
            </div>
            <h1 class="text-body-private-small-title text-center ml-1">{{person.identifier}}</h1>
        </div>
        <div class="s-card-public-affiliate__first-group pa-2">
            <div class="d-flex align-center">
                <v-icon :color="person.gender!='h' ? 'pink' : 'blue'" class="mr-1">
                    {{person.gender!='h' ? 'mdi-face-woman' : 'mdi-face-man'}}</v-icon>
                <h1 class="text-body-private-title">{{person.full_name}}</h1>
            </div>
            <h1 class="text-body-private-small mt-1 mb-1">{{person.other[0].profile}}</h1>
            <h1 class="text-body-private-title mt-3 mb-1">Profesiones</h1>
            <div
                v-for="profession in person.professions"
                :key="profession.profession"
                class="mb-1"
            >
                <div class="d-flex">
                    <v-icon small color="primary">mdi-arrow-right-bold</v-icon>
                    <h1 class="text-body-private-small-title">
                        {{profession.profession}}</h1>
                </div>
                <!--<v-divider></v-divider>-->
                <h1 class="text-body-private-small pl-4">
                    {{profession.entity+', '+profession.location}}</h1>
            </div>
            <v-divider class="mb-3 mt-3"></v-divider>
            <h1 class="text-body-private-title mb-1">Especialidades</h1>
            <div
                v-for="specialtie in person.specialties"
                :key="specialtie.specialty"
                class="mb-1"
            >
                <div class="d-flex">
                    <v-icon small color="primary">mdi-arrow-right-bold</v-icon>
                    <h1 class="text-body-private-small-title">
                        {{specialtie.specialty}}</h1>
                </div>
                <h1 class="text-body-private-small ml-4">
                    {{specialtie.entity+', '+specialtie.location}}</h1>
            </div>
            <v-divider class="mb-3 mt-3"></v-divider>
            <h1 class="text-body-private-title mb-1">Experiencia</h1>
            <div
                v-for="(experience, index) in person.experiences"
                :key="index"
                class="mb-1"
            >
                <div class="s-card-public-affiliate__experience">
                    <div class="d-flex">
                        <v-icon small color="primary">mdi-arrow-right-bold</v-icon>
                        <h1 class="text-body-private-small-title">{{experience.position}}</h1>
                    </div>
                    <v-spacer></v-spacer>
                    <h1 class="text-body-private-small pl-4">
                        {{'Del '+m_date_formatDate(experience.beginning)+' al '+m_date_formatDate(experience.final)}}</h1>
                </div>
                <h1 class="text-body-private-small pl-4">
                    {{experience.employer+', '+experience.location}}</h1>
            </div>
        </div>
        <div class="s-card-public-affiliate__second-group pa-2">
            <h1 class="text-body-private-title mb-1">Cualidades</h1>
            <div
                v-for="qualitie in person.qualities"
                :key="qualitie.qualitie"
                class="mb-3"
            >
                <h1 class="text-body-private-small">{{qualitie.qualitie}}</h1>
                <v-progress-linear height="5" :value="qualitie.progress"></v-progress-linear>
            </div>
            <v-divider class="mt-5 mb-3"></v-divider>
            <h1 class="text-body-private-title mb-1">Idiomas</h1>
            <div
                v-for="languaje in person.languajes"
                :key="languaje.languaje"
                class="mb-1"
            >
                <h1 class="text-body-private-small">
                    {{languaje.languaje+' ('+getTextLevel(languaje.nivel)+')'}}</h1>
            </div>
            <v-divider class="mt-3 mb-3"></v-divider>
            <h1 class="text-body-private-title">Contacto</h1>
            <div class="mt-1">
                <MBtnNormalComp
                    color="green"
                    :dark="true"
                    :depressed="true"
                    width="140"
                >
                    <v-icon class="mr-1" small>mdi-whatsapp</v-icon>
                    WHATSAPP</MBtnNormalComp>
            </div>
            <div class="mt-2" v-if="person.other[0].facebook!=null">
                <MBtnNormalComp
                    color="blue"
                    :dark="true"
                    :depressed="true"
                    width="140"
                >
                    <v-icon class="mr-1" small>mdi-facebook</v-icon>
                    FACEBOOK</MBtnNormalComp>
            </div>
            <div class="mt-2" v-if="person.other[0].instagram!=null">
                <MBtnNormalComp
                    color="pink"
                    :dark="true"
                    :depressed="true"
                    width="140"
                >
                    <v-icon class="mr-1" small>mdi-instagram</v-icon>
                    INSTAGRAM</MBtnNormalComp>
            </div>
        </div>
    </div>
</template>
<script>

import { datesMixin } from '../../mixin'
import { imgMiniature } from '../../filters'
import TextHighlight from 'vue-text-highlight'
import {
    MBtnNormalComp
} from '../molecules'
export default {
    components: {
        TextHighlight,
        MBtnNormalComp
    },
    filters: {
        imgMiniature
    },
    mixins: [
        datesMixin
    ],
    props: {
        person: {},
        searchPerson: String
    },
    methods: {
        getTextLevel(level) {
            if (level === 'b') return 'Básico'
            if (level === 'm') return 'Medio'
            return 'Avanzado'
        }
    }
}
</script>