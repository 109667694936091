<template>
    <v-dialog
        :value="dialog"
        :width="ac_getWidthImg()"
        persistent
        style="position:relative"
    >
        <div class="gallery__carrusel">
            <div class="gallery__carrusel__btn-close">
                <v-btn
                    small
                    color="white"
                    fab
                    depressed
                    :ripple="false"
                    @click="$emit('clickclose')"
                >
                    <v-icon color="black">mdi-close</v-icon>
                </v-btn>
            </div>
            <v-carousel
                hide-delimiters
                height="100%"
                :value="positionMutable"
            >
                <v-carousel-item
                    v-for="(photography, i) in listGallery"
                    :key="i"
                >
                    <div class="gallery__title d-flex align-center">
                        <h1 class="text-body-private-title">{{photography.title}}</h1>
                    </div>
                    <div style="height:90%">
                        <v-img
                            v-if="!mobile"
                            :src="photography.route_img|imgNormal"
                            contain
                            height="100%"
                        >
                        </v-img>
                        <v-img
                            v-else
                            :src="photography.route_img|imgMiniature"
                            height="100%"
                            contain
                        ></v-img>
                    </div>
                </v-carousel-item>
            </v-carousel>
        </div>
    </v-dialog>
</template>
<script>
import { imgMiniature, imgNormal } from '../../filters'
export default {
    filters: {
        imgMiniature,
        imgNormal
    },
    model: {
        prop: 'dialog',
        event: 'click'
    },
    props: {
        dialog: Boolean,
        listGallery: Array,
        mobile: Boolean,
        positionCarrusel: Number
    },
    computed: {
        positionMutable: function() {
            if (this.positionCarrusel !== undefined) return this.positionCarrusel
            else return 0
        }
    },
    methods: {
        ac_getWidthImg() {
            if (window.innerWidth <= 1400) return '1000'
            else return '1200'
        }
    }
}
</script>