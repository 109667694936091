<template>
    <div class="s-card-languajes">
        <div class="d-flex">
            <h1 class="text-body-title">TÍTULOS ACADÉMICOS.</h1>
            <v-spacer></v-spacer>
            <OBtnHeaderToolTipComp
                v-if="listProfessions.length <= 4 && action==2"
                smsTooltip="Añadir profesión"
                icon="mdi-plus"
                class="ml-1"
                colorBtn="#2196F3"
                @click="$emit('click')"
            />
        </div>
        <v-divider class="mt-2 mb-2" v-if="action==2"></v-divider>
        <div class="d-flex" v-if="action==2">
            <div class="membership__ornament-circle mr-2 mt-1"></div>
            <h2 class="text-body-subtitle">Solo se pueden introducir 5 profesiones.</h2>
        </div>
        <v-divider class="mt-2"></v-divider>
        <h1
            v-if="listProfessions.length==0"
            class="mt-1 text-center text-body-private-title"
        >SIN TÍTULOS ACADÉMICOS</h1>
        <div
            v-for="(profession, index) in listProfessions"
            :key="index"
            class="d-flex align-center s-card-languajes__option"
        >
            <OBtnCircleComp
                v-if="ac_activateEdit(profession)"
                smsTooltip="Editar profesión"
                color="#2196F3"
                @click="$emit('clickupdate', profession)"
            >
                <v-icon color="white" small>mdi-pencil</v-icon>
            </OBtnCircleComp>
            <div class="ml-2">
                <h1 :style="ac_colorText(profession)" class="text-body-private-title">{{profession.profession}}</h1>
                <h1 :style="ac_colorText(profession)" class="text-body-private-subtitle">{{profession.entity}}</h1>
                <h1 :style="ac_colorText(profession)" class="text-body-private-subtitle">{{profession.location}}</h1>
                <MBtnNormalComp
                    v-if="profession.pdf!=null"
                    color="blue"
                    class="mt-1"
                    :outlined="true"
                    :dark="true"
                    @click="$emit('clickpdf', ac_sendData(profession))"
                >VER Docuemnto PDF</MBtnNormalComp>
            </div>
        </div>
    </div>
</template>
<script>
import {
    MBtnNormalComp
} from '../../components/molecules'
import {
    OBtnHeaderToolTipComp,
    OBtnCircleComp
} from '../../components/organisms'
export default {
    components: {
        OBtnHeaderToolTipComp,
        OBtnCircleComp,
        MBtnNormalComp
    },
    props: {
        listProfessions: Array,
        action: Number
    },
    methods: {
        ac_sendData(profession) {
            const parameters = {
                'pdf': profession.pdf,
                'title': profession.profession
            }
            return parameters
        },
        ac_activateEdit(profession) {
            if (this.action === 2) return true
            if (this.action === 0) return false
            if (profession.validate === 1) return false
            return true
        },
        ac_colorText(profession) {
            if (this.action === 2) return ''
            if (this.action === 0) return ''
            if (profession.validate === 1) return ''
            return 'color:red'
        }
    }
}
</script>