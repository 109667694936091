export default {
    namespaced: true,
    state: {
        mini: false,
        style_body: 'margin-left: 300px'
    },
    getters: {},
    mutations: {
        changeMini(state){
            if (!state.mini) state.style_body = 'margin-left: 56px'
            else state.style_body = 'margin-left: 300px'
            state.mini = !state.mini
        }
    }
}