<template>
    <div class="pa-2">
        <div class="d-flex">
            <h1 class="text-body-private-title mr-1">CI:</h1>
            <h1 class="text-body-private-subtitle">{{`${person.ci} ${person.issued_in}`}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-private-title mr-1">Género:</h1>
            <h1 class="text-body-private-subtitle">
                {{person.gender=='h' ? 'Masculino' : person.gender=='m' ? 'Femenino' : 'Otro'}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-private-title mr-1">Fecha de Nac.:</h1>
            <h1 class="text-body-private-subtitle">
                {{person.date_of_birth!=undefined ? m_date_formatDate(person.date_of_birth) : ''}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-private-title mr-1">Edad:</h1>
            <h1 class="text-body-private-subtitle">{{ac_getEdad()}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-private-title mr-1">Estado:</h1>
            <h1 class="text-body-private-subtitle">
                {{person.enabled!=0 ? 'Habilitado' : 'Inhabilitado'}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-private-title mr-1">N° de Registro SIB:</h1>
            <h1 class="text-body-private-subtitle">{{person.code_sib!=null ? person.code_sib : 'S/N'}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-private-title mr-1">N° Reg. ABIS Ch.:</h1>
            <h1 class="text-body-private-subtitle">{{person.identifier}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-private-title mr-1">Categoría:</h1>
            <h1 class="text-body-private-subtitle" v-if="person.category=='A'">Activo</h1>
            <h1 class="text-body-private-subtitle" v-if="person.category=='B'">Adherente</h1>
            <h1 class="text-body-private-subtitle" v-if="person.category=='C'">Transeunte</h1>
            <h1 class="text-body-private-subtitle" v-if="person.category=='U'">Universitario</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-private-title mr-1">Celular:</h1>
            <h1 class="text-body-private-subtitle">{{person.phone}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-private-title mr-1">Dirección:</h1>
            <h1 class="text-body-private-subtitle">{{person.address}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-private-title mr-1">E-Mail:</h1>
            <h1 class="text-body-private-subtitle">
                {{person.email!=null ? person.email : 'S/E'}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-private-title mr-1">
                Tiempo afiliado a ABIS Ch. (días):
            </h1>
            <h1 class="text-body-private-subtitle">
                {{person.membership_days}}</h1>
        </div>
        <v-divider class="mt-1 mb-1"></v-divider>
        <div class="d-flex align-center">
            <h1 class="text-body-private-title mr-1">Expiración Acreditación:</h1>
            <h1 class="text-body-private-subtitle mr-1">
                {{person.date_of_expiry!=undefined ? m_date_formatDate(person.date_of_expiry) : ''}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-private-title mr-1">
                {{person.is_expired==1 ? 'Días inhabilitado:' : 'Días de validez:'}}
            </h1>
            <h1 class="text-body-private-subtitle">
                {{person.dif_days}}</h1>
        </div>
        <div class="d-flex">
            <h1 class="text-body-private-title mr-1">
                Fecha de afiliación:
            </h1>
            <h1 class="text-body-private-subtitle">
                {{person.date_creation}}</h1>
        </div>
        <div class="d-flex" v-if="ac_withValidity()">
            <h1 class="text-body-private-title mr-1">N° de Cuotas atrasadas:</h1>
            <h1 class="text-body-private-subtitle">
                {{m_calculate_debt(person.dif_days)}}</h1>
        </div>
        <div class="d-flex" v-if="ac_withValidity()">
            <h1 class="text-body-private-title mr-1">Monto a Cancelar:</h1>
            <h1 class="text-body-private-subtitle">
                {{m_calculate_payment(person.dif_days, 15)+' Bs.'}}</h1>
        </div>
    </div>
</template>
<script>
import { datesMixin, calculatePayementsMixin } from '../../mixin'
import moment from 'moment'
export default {
    mixins: [ datesMixin, calculatePayementsMixin ],
    props: {
        person: Object
    },
    methods: {
        ac_getEdad() {
            var currentDate = moment(this.m_date_systemDate(false))
            var date_of_birth = moment(this.person.date_of_birth)
            if (this.person.full_name == undefined) return ''
            else {
                return currentDate.diff(date_of_birth, 'year') + ' Años'
            }
        },
        ac_withValidity() {
            if (this.person.date_of_expiry !== undefined) {
                if (this.person.is_expired === 0) return false
                return true
            }
            return false
        }
    }
}
</script>