<template>
    <div class="pb-4">
        <div class="d-flex justify-center">
            <div class="home__first form__contianer">
                <h1 class="text-center text-body-big">VERIFICACIÓN DE AFILIACIÓN</h1>
            </div>
        </div>
        <div class="d-flex justify-center mt-2">
            <div class="home__first verify-process">
                <div class="verify-process__container">
                    <v-tabs-items style="background:transparent !important;" v-model="tab" :touchless="true">
                        <v-tab-item>
                            <div class="d-flex pb-2">
                                <div class="verify-process__ornament-circle mr-2" style="margin-top:5px"></div>
                                <h1 class="text-body-subtitle">Ingrese los datos requeridos para la visualización de su afiliación.</h1>
                            </div>
                            <div class="d-flex pb-2">
                                <div class="verify-process__ornament-circle mr-2" style="margin-top:5px"></div>
                                <h1 class="text-body-subtitle">Si no recuerda el código proporcionado, porvafor comuniquese con ABIS Chuquisaca por
                                    los números proporcionados en la página.</h1>
                            </div>
                            <div class="d-flex pb-2">
                                <div class="verify-process__ornament-circle mr-2" style="margin-top:5px"></div>
                                <h1 class="text-body-subtitle">Respete mayúsculas y minúsculas en la inserción del código</h1>
                            </div>
                            <div class="d-flex justify-center mt-4">
                                <div class="verify-process__information">
                                    <v-form
                                        v-on:submit.prevent="ac_getProcedue()"
                                        ref="formcredentials"
                                        v-model="valid"
                                        lazy-validation
                                    >
                                        <div class="d-flex pt-3">
                                            <div class="s-form-membership__title" v-if="!mobile">
                                                <h1 class="text-body-private-title pt-2 text-right mr-2">Categoría Trámite (*) :</h1>
                                            </div>
                                            <div class="s-form-membership__input" style="width:100%">
                                                <MSelectComp
                                                    :label="mobile ? 'Categoría Trámite' : ''"
                                                    v-model="typeProcess"
                                                    :class="mobile ? 'mt-3' : ''"
                                                    :items="itemsProcess"
                                                    itemText="name"
                                                    itemValue="value"
                                                    :rules="[v => !m_rule_emptyField(v) || 'El dato es requerido']"
                                                />
                                            </div>
                                        </div>
                                        <div class="d-flex">
                                            <div class="s-form-membership__title" v-if="!mobile">
                                                <h1 class="text-body-private-title pt-2 text-right mr-2">Carnet de Identidad (*) :</h1>
                                            </div>
                                            <div class="s-form-membership__input" style="width:100%">
                                                <MTextFieldComp
                                                    :label="mobile ? 'Carnet de Identidad (*)' : ''"
                                                    v-model="dataCredentials.ci"
                                                    :counter=20
                                                    :rules="[v => !m_rule_emptyField(v) || 'El dato es requerido',
                                                        v => (v + '').length <= 20 || 'El CI no deben exceder los 20 dígitos'
                                                    ]"
                                                />
                                            </div>
                                        </div>
                                        <div class="d-flex">
                                            <div class="s-form-membership__title" v-if="!mobile">
                                                <h1 class="text-body-private-title pt-2 text-right mr-2">Código (*) :</h1>
                                            </div>
                                            <div class="s-form-membership__input" style="width:100%">
                                                <MTextFieldComp
                                                    :label="mobile ? 'Código' : ''"
                                                    v-model="dataCredentials.code"
                                                    :counter=10
                                                    :class="mobile ? 'mt-3' : ''"
                                                    :type="showPassword ? 'text' : 'password'"
                                                    @clicksee="showPassword = !showPassword"
                                                    @click="ac_getProcedue()"
                                                    :icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                                    :rules="[v => !m_rule_emptyField(v) || 'El dato es requerido',
                                                        v => (v + '').length <= 10 || 'El código no deben exceder los 10 dígitos'
                                                    ]"
                                                />
                                            </div>
                                        </div>
                                        <div class="d-flex justify-center">
                                            <MBtnNormalComp
                                                :depressed="true"
                                                color="primary"
                                                :outlined="true"
                                                @click="ac_getProcedue()"
                                            >VERIFICAR</MBtnNormalComp>
                                        </div>
                                    </v-form>
                                </div>
                            </div>
                        </v-tab-item>
                        <v-tab-item>
                            <div v-if="dataProcessAffiliation.state_process != undefined">
                                <div class="d-flex pb-2">
                                    <div><v-icon color="green" class="mr-1">mdi-check-circle</v-icon></div>
                                    <h1 class="text-body-private-subtitle">Si su trámite de afiliación se encuentra observado, presione en el botón
                                                "CORREGIR DATOS" y envié nuevamente el formulario de afiliación.</h1>
                                </div>
                                <div class="d-flex pb-2">
                                    <div><v-icon color="green" class="mr-1">mdi-check-circle</v-icon></div>
                                    <h1 class="text-body-private-subtitle">Si el estado del trámite está “En Curso”, por favor
                                        espere a que ABIS Chuquisaca revise la información para su afiliación.</h1>
                                </div>
                                <div class="d-flex pb-1 mt-4">
                                    <h1 class="text-body-private-title mr-1">INTERESADO:</h1>
                                    <h1 class="text-body-private-subtitle">{{dataProcessAffiliation.list_interested[0].full_name}}</h1>
                                </div>
                                <div class="d-flex pb-1">
                                    <h1 class="text-body-private-title mr-1">CI:</h1>
                                    <h1 class="text-body-private-subtitle">
                                        {{dataProcessAffiliation.list_interested[0].ci+' '+dataProcessAffiliation.list_interested[0].issued_in}}</h1>
                                </div>
                                <div class="d-flex pb-1">
                                    <h1 class="text-body-private-title mr-1">Fecha de envió:</h1>
                                    <h1 class="text-body-private-subtitle">{{dataProcessAffiliation.list_interested[0].date}}</h1>
                                </div>
                                <div class="d-flex pb-1">
                                    <h1 class="text-body-private-title mr-1">Hora de envió:</h1>
                                    <h1 class="text-body-private-subtitle">{{dataProcessAffiliation.list_interested[0].hours}}</h1>
                                </div>
                                <div class="d-flex pb-1">
                                    <h1 class="text-body-private-title mr-1">Fecha de revisión:</h1>
                                    <h1 class="text-body-private-subtitle">
                                        {{dataProcessAffiliation.list_interested[0].validated_date!=null ? dataProcessAffiliation.list_interested[0].validated_date : 'S/F'}}</h1>
                                </div>
                                <div class="d-flex pb-1">
                                    <h1 class="text-body-private-title mr-1">Hora de revisión:</h1>
                                    <h1 class="text-body-private-subtitle">
                                        {{dataProcessAffiliation.list_interested[0].validate_hours!=null ? dataProcessAffiliation.list_interested[0].validate_hours : 'S/F'}}</h1>
                                </div>
                                <div class="d-flex pb-1">
                                    <h1 class="text-body-private-title mr-1">Estado del trámite:</h1>
                                    <h1 class="text-body-private-subtitle">
                                        {{dataProcessAffiliation.list_interested[0].validated_date!=null ? 'En observación' : 'En curso'}}</h1>
                                </div>
                                <MBtnNormalComp
                                    v-if="dataProcessAffiliation.list_interested[0].list_observations.length>0"
                                    class="mt-1"
                                    color="primary"
                                    @click="ac_goCorrectObservation()"
                                >CORREGIR DATOS</MBtnNormalComp>
                            </div>
                        </v-tab-item>
                    </v-tabs-items>
                </div>
            </div>
        </div>              
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="dialogError=false"
        />
        <OLoaderOperationComp
            :dialog="dialogLoaderOperation"
            smsLoaderOperation="Enviando Solicitud"
        />
        <SmsCongratulationsComp
            :dialog="dialogCongratulations"
            :outCancel="true"
            title="FELICIDADES"
            icon="mdi-account-check"
            btnSms="INGRESAR"
            :smsCongratulations="smsCongratulation"
            @click="ac_goInfoAffiliate()"
        />
    </div>
</template>
<script>
import {
    MTextFieldComp,
    MBtnNormalComp,
    MSelectComp
} from '../../components/molecules'
import {
    OSmsErrorComp,
    OLoaderOperationComp,
    SmsCongratulationsComp
} from '../../components/organisms'
import {
    rulesMixin,
    dataGeneralComponetsMixin,
    responseServerMixin,
    generateCodeMixin,
    scrollMixin
} from '../../mixin'
import {
    ProcessAffiliation,
    ProcessAffiliationAcademic
} from '../../models'
export default {
    metaInfo() {
        const title = `ABIS Ch. - Verificar Afiliación`
        return {
            title: title
        }
    },
    mixins: [
        rulesMixin,
        dataGeneralComponetsMixin,
        responseServerMixin,
        generateCodeMixin,
        scrollMixin
    ],
    components : {
        MTextFieldComp,
        MBtnNormalComp,
        OSmsErrorComp,
        OLoaderOperationComp,
        SmsCongratulationsComp,
        MSelectComp
    },
    data: () => ({
        itemsProcess: [
            { name: 'Profesional', value: 0 },
            { name: 'Universitario', value: 1 }
        ],
        typeProcess: null,
        tab: 0,
        dataCredentials: {
            ci: null,
            code: null
        },
        mobile: false,
        showPassword: false,
        valid: true,
        dataProcessAffiliation: {},
        dialogCongratulations: false,
        smsCongratulation: `Usted es miembro oficial de ABIS Chuquisaca,
            porfavor presione en el botón 'INGRESAR', para ser direccionado a la página de contenido`
    }),
    methods: {
        processError(error) {
            this.dialogLoaderOperation = false
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_action(error)
            this.dialogError = true
        },
        validateData() {
            if (!this.$refs.formcredentials.validate()) return false
            return true
        },
        getProcedureSystem() {
            this.dialogLoaderOperation = true
            var sendCredentials = Object.assign({}, this.dataCredentials)
            sendCredentials.code = this.m_code_encript(this.dataCredentials.code)
            ProcessAffiliation.getProcess(sendCredentials).then(response => {
                this.dialogLoaderOperation = false
                if (response.state_process !== undefined) {
                    if (response.state_process === 'z')
                        this.dialogCongratulations = true
                    else {
                        this.dataProcessAffiliation = response
                        this.tab = 1
                    }
                } else {
                    this.smsError = [`No se encontró su tramite de afiliación`,
                        `Verifique que sus datos de acceso sean correctos`,
                        `Si olvido sus credendiales, comuniquese con ABIS, mediante los números proporcionados`,
                        `GRACIAS`
                    ]
                    this.dialogError = true
                    this.actionError = true
                }
            }).catch(error => {
                this.processError(error)
            })
        },
        getProcedureAcademicSystem() {
            this.dialogLoaderOperation = true
            var sendCredentials = Object.assign({}, this.dataCredentials)
            sendCredentials.code = this.m_code_encript(this.dataCredentials.code)
            ProcessAffiliationAcademic.getProcess(sendCredentials).then(response => {
                this.dialogLoaderOperation = false
                if (response.state_process !== undefined) {
                    if (response.state_process === 'z')
                        this.dialogCongratulations = true
                    else {
                        this.dataProcessAffiliation = response
                        this.tab = 1
                    }
                } else {
                    this.smsError = [`No se encontró su tramite de afiliación`,
                        `Verifique que sus datos de acceso sean correctos`,
                        `Si olvido sus credendiales, comuniquese con ABIS, mediante los números proporcionados`,
                        `GRACIAS`
                    ]
                    this.dialogError = true
                    this.actionError = true
                }
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_getProcedue() {
            if (this.validateData()) {
                if (this.typeProcess === 0) this.getProcedureSystem()
                else this.getProcedureAcademicSystem()
            } else {
                this.smsError = [`Revise los campos marcados con rojo y corrija, porfavor`]
                this.dialogError = true
                this.actionError = true
            }
        },
        ac_goInfoAffiliate() {
            this.dialogCongratulations = false
            setTimeout(() => {
                this.$router.push({name: 'info-affiliate'})
            }, 200)
        },
        ac_goCorrectObservation() {
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            setTimeout(() => {
                if (this.typeProcess === 0) {
                    this.$router.push({ name: 'correct-observation', params: {
                            id_process: this.dataProcessAffiliation.id,
                            interested: this.dataProcessAffiliation.list_interested[0],
                            credentials: this.dataCredentials
                        }
                    })
                } else {
                    this.$router.push({ name: 'correct-university-procedure', params: {
                            id_process: this.dataProcessAffiliation.id,
                            interested: this.dataProcessAffiliation.list_interested[0],
                            credentials: this.dataCredentials
                        }
                    })
                }
            }, velocity)
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.mobile = true
    }
}
</script>