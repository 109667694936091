import axios from "axios"
import { apiUrls } from '../plugins/apiUrls'
export default class Languaje {
    constructor (
        languaje = null,
        nivel = 'b'
    ) {
        this.languaje = languaje
        this.nivel = nivel
    }
    static addLanguaje(parameters) {
        return axios.post(
            apiUrls.LANGUAJE + '/add',
            parameters
        ).then(response => {
            return response.data
        })
    }
    static updateLanguaje(parameters) {
        return axios.put(
            apiUrls.LANGUAJE + '/update',
            parameters
        ).then(response => {
            return response.data
        })
    }
}