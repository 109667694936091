import axios from "axios"
import { apiUrls } from '../plugins/apiUrls'
export default class Experience {
    constructor (
        employer = null,
        position = null,
        location = null,
        beginning = null,
        final = null,
        route_file = null,
        name_file = null
    ) {
        this.employer = employer
        this.position = position
        this.location = location
        this.beginning = beginning
        this.final = final
        this.route_file = route_file
        this.name_file = name_file
    }
    static addExperience(parameters) {
        return axios.post(
            apiUrls.EXPERIENCE + '/add',
            parameters
        ).then(response => {
            return response.data
        })
    }
    static updateExperience(parameters) {
        return axios.post(
            apiUrls.EXPERIENCE + '/update',
            parameters
        ).then(response => {
            return response.data
        })
    }
}