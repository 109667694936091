<template>
    <v-form
        v-on:submit.prevent="ac_confirmNewLanguaje()"
        ref="formlanguaje"
        v-model="validLanguaje"
        lazy-validation
    >
        <ODialogHeaderComp
            :title="indexLanguaje!=-1 ? 'Editar Idioma' : 'Nuevo Idioma'"
            @clickclose="$emit('clickclose')"
        />
        <v-card class="ma-0 pa-2 pt-5">
            <MTextFieldComp
                label="Idioma"
                v-model="dataLanguaje.languaje"
                :dense="true"
                :counter="20"
                :rules="[v => !m_rule_emptyField(v) || 'El dato es requerido',
                    v => (v + '').length <= 20 || 'El idioma no puede exceder los 20 dígitos'
                ]"
            />
            <MSelectComp
                class="mt-2"
                label="Nivel"
                :items="itemsLevel"
                itemValue="value"
                itemText="title"
                v-model="dataLanguaje.nivel"
                :hideDetails="true"
            />
            <div class="text-center mt-3">
                <MBtnNormalComp
                    :outlined="true"
                    color="primary"
                    @click="ac_confirmNewLanguaje()"
                >{{indexLanguaje!=-1 ? 'GUARDAR' : 'REGISTRAR'}}</MBtnNormalComp>
            </div>
        </v-card>
    </v-form>
</template>
<script>
import {
    MBtnNormalComp,
    MTextFieldComp,
    MSelectComp
} from '../../components/molecules'
import {
    ODialogHeaderComp,
    OPdfInputComp
} from '../../components/organisms'
import {
    rulesMixin
} from '../../mixin'
export default {
    mixins: [ rulesMixin ],
    components: {
        MBtnNormalComp,
        MTextFieldComp,
        MSelectComp,
        ODialogHeaderComp,
        OPdfInputComp
    },
    props: {
        indexLanguaje: Number,
        dataLanguaje: Object        
    },
    data: () => ({
        validLanguaje: true,
        itemsLevel: [{ title: 'Básico', value: 'b'},
            { title: 'Medio', value: 'm'},
            { title: 'Avanzado', value: 'a'}],
    }),
    methods: {
        validateLanguaje() {
            if (!this.$refs.formlanguaje.validate()) return false
            return true
        },
        ac_confirmNewLanguaje() {
            if (this.validateLanguaje()) this.$emit('clickaction', true)
            else this.$emit('clickaction', false)
        },
        resetForm() {
            this.$refs.formlanguaje.resetValidation()
        }
    }
}
</script>