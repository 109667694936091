<template>
    <v-form
        v-on:submit.prevent="ac_sendToConfirmation()"
        ref="formmembership"
        v-model="valid"
        lazy-validation
        class="s-form-membership mb-3"
    >
        <div class="o-description__ornament"></div>
        <div class="s-form-membership__border pt-2">
            <div class="mt-4 s-form-membership__group">
                <div class="d-flex">
                    <div class="s-form-membership__title" v-if="!mobile">
                        <h1 class="text-body-private-title pt-2 text-right mr-2">Nombre (*) :</h1>
                    </div>
                    <div class="d-flex s-form-membership__container-input">
                        <div :class="mobile ? 'd-flex' : 'd-flex s-form-membership__input'" style="width:100%">
                            <MTextFieldComp
                                :label="mobile ? 'Nombre (*)' : ''"
                                v-model="dataInterested.full_name"
                                :dense="mobile ? true : false"
                                :class="mobile ? 'mt-2' : ''"
                                :disabled="!firstProcess && dataObservation.full_name_obs==null"
                                :counter=50
                                :rules="[v => !m_rule_emptyField(v) || 'El dato es requerido',
                                    v => (v + '').length <= 50 || 'El nombre no debe exeder los 50 dígitos'
                                ]"
                            />
                        </div>
                        <div v-if="!firstProcess">
                            <OSmsObservationComp
                                v-if="dataObservation.full_name_obs!=null"
                                :style="mobile ? 'margin-top:11px' : 'margin-top:3px'"
                                smsTooltip="Ver observación"
                                :smsObservation="dataObservation.full_name_obs"
                            />
                            <div :style="mobile ? 'margin-top:16px' : 'margin-top:7px'" v-else>
                                <v-icon color="green">mdi-check-circle</v-icon>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="s-form-membership__title" v-if="!mobile">
                        <h1 class="text-body-private-title pt-2 text-right mr-2">Apellidos (*) :</h1>
                    </div>
                    <div class="d-flex s-form-membership__container-input">
                        <div :class="mobile ? 'd-flex' : 'd-flex s-form-membership__input'" style="width:100%">
                            <MTextFieldComp
                                :label="mobile ? 'Apellidos (*)' : ''"
                                v-model="dataInterested.last_name"
                                :dense="mobile ? true : false"
                                :class="mobile ? 'mt-2' : ''"
                                :disabled="!firstProcess && dataObservation.last_name_obs==null"
                                :counter=50
                                :rules="[v => (v + '').length <= 50 || 'El apellido paterno no debe exeder los 50 dígitos']"
                            />
                        </div>
                        <div v-if="!firstProcess">
                            <OSmsObservationComp
                                v-if="dataObservation.last_name_obs!=null"
                                :style="mobile ? 'margin-top:11px' : 'margin-top:3px'"
                                smsTooltip="Ver observación"
                                :smsObservation="dataObservation.last_name_obs"
                            />
                            <div :style="mobile ? 'margin-top:16px' : 'margin-top:7px'" v-else>
                                <v-icon color="green">mdi-check-circle</v-icon>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="s-form-membership__title" v-if="!mobile">
                        <h1 class="text-body-private-title pt-2 text-right mr-2">Género (*) :</h1>
                    </div>
                    <div class="d-flex s-form-membership__container-input">
                        <div :class="mobile ? 'd-flex' : 'd-flex s-form-membership__input'" style="width:100%">
                            <MSelectComp
                                :label="mobile ? 'Género (*)' : ''"
                                :items="itemsGender"
                                :class="mobile ? 'mt-2' : ''"
                                v-model="dataInterested.gender"
                                :dense="mobile ? true : false"
                                :disabled="!firstProcess && dataObservation.gender_obs==null"
                                itemText="title"
                                itemValue="value"
                                :rules="[v => !m_rule_emptyField(v) || 'El dato es requerido']"
                            />
                        </div>
                        <div v-if="!firstProcess">
                            <OSmsObservationComp
                                v-if="dataObservation.gender_obs!=null"
                                :style="mobile ? 'margin-top:11px' : 'margin-top:3px'"
                                smsTooltip="Ver observación"
                                :smsObservation="dataObservation.gender_obs"
                            />
                            <div :style="mobile ? 'margin-top:16px' : 'margin-top:7px'" v-else>
                                <v-icon color="green">mdi-check-circle</v-icon>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="s-form-membership__title" v-if="!mobile">
                        <h1 class="text-body-private-title pt-2 text-right mr-2">Carnet de Identidad (*) :</h1>
                    </div>
                    <div class="d-flex s-form-membership__container-input">
                        <div :class="mobile ? 'd-flex' : 'd-flex s-form-membership__input'" style="width:100%">
                            <MTextFieldComp
                                :label="mobile ? 'Carnet de Identidad (*)' : ''"
                                v-model="dataInterested.ci"
                                :class="mobile ? 'mt-2' : ''"
                                :dense="mobile ? true : false"
                                :disabled="!firstProcess && dataObservation.ci_obs==null"
                                :counter=10
                                :rules="[v => !m_rule_emptyField(v) || 'El dato es requerido',
                                    v => (v + '').length <= 10 || 'El CI no deben exceder los 10 dígitos'
                                ]"
                            />
                        </div>
                        <div v-if="!firstProcess">
                            <OSmsObservationComp
                                v-if="dataObservation.ci_obs!=null"
                                :style="mobile ? 'margin-top:11px' : 'margin-top:3px'"
                                smsTooltip="Ver observación"
                                :smsObservation="dataObservation.ci_obs"
                            />
                            <div :style="mobile ? 'margin-top:16px' : 'margin-top:7px'" v-else>
                                <v-icon color="green">mdi-check-circle</v-icon>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="s-form-membership__title" v-if="!mobile">
                        <h1 class="text-body-private-title pt-2 text-right mr-2">Expedido en (*) :</h1>
                    </div>
                    <div class="d-flex s-form-membership__container-input">
                        <div :class="mobile ? 'd-flex' : 'd-flex s-form-membership__input'" style="width:100%">
                            <MSelectComp
                                :label="mobile ? 'Expedido en (*)' : ''"
                                :items="itemsDepartments"
                                :dense="mobile ? true : false"
                                :class="mobile ? 'mt-2' : ''"
                                v-model="dataInterested.issued_in"
                                :disabled="!firstProcess && dataObservation.issued_in_obs==null"
                                itemText="title"
                                itemValue="value"
                                :rules="[v => !m_rule_emptyField(v) || 'El dato es requerido']"
                            />
                        </div>
                        <div v-if="!firstProcess">
                            <OSmsObservationComp
                                v-if="dataObservation.issued_in_obs!=null"
                                :style="mobile ? 'margin-top:11px' : 'margin-top:3px'"
                                smsTooltip="Ver observación"
                                :smsObservation="dataObservation.issued_in_obs"
                            />
                            <div :style="mobile ? 'margin-top:16px' : 'margin-top:7px'" v-else>
                                <v-icon color="green">mdi-check-circle</v-icon>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="s-form-membership__title" v-if="!mobile">
                        <h1 class="text-body-private-title pt-2 text-right mr-2">Fecha Nacimiento (*) :</h1>
                    </div>
                    <div class="d-flex s-form-membership__container-input">
                        <div :class="mobile ? 'd-flex' : 'd-flex s-form-membership__input'" style="width:100%">
                            <ODateOneComp
                                :label="mobile ? 'Fecha de Nacimiento' : ''"
                                v-model="dateOfBith"
                                :dense="mobile ? true : false"
                                :class="mobile ? 'mt-2' : ''"
                                :disabled="!firstProcess && dataObservation.date_of_birth_obs==null"
                                @change="ac_electionDateExpense"
                                :rules="[v => !m_rule_emptyField(v) || 'El dato es requerido']"
                            />
                        </div>
                        <div v-if="!firstProcess">
                            <OSmsObservationComp
                                v-if="dataObservation.date_of_birth_obs!=null"
                                :style="mobile ? 'margin-top:4px' : 'margin-top:3px'"
                                smsTooltip="Ver observación"
                                :smsObservation="dataObservation.date_of_birth_obs"
                            />
                            <div :style="mobile ? 'margin-top:16px' : 'margin-top:7px'" v-else>
                                <v-icon color="green">mdi-check-circle</v-icon>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="s-form-membership__title" v-if="!mobile">
                        <h1 class="text-body-private-title pt-2 text-right mr-2">Celular (*) :</h1>
                    </div>
                    <div class="d-flex s-form-membership__container-input">
                        <div :class="mobile ? 'd-flex' : 'd-flex s-form-membership__input'" style="width:100%">
                            <MTextFieldComp
                                :label="mobile ? 'Celular' : ''"
                                v-model="dataInterested.phone"
                                :dense="mobile ? true : false"
                                :class="mobile ? 'mt-2' : ''"
                                :disabled="!firstProcess && dataObservation.phone_obs==null"
                                :counter=8
                                :rules="[v => !m_rule_emptyField(v) || 'El dato es requerido',
                                    v => m_rule_numberInteger(v) || 'El celular solo debe contener números',
                                    v => (v + '').length == 8 || 'El celular debe contar con 8 dígitos'
                                ]"
                            />
                        </div>
                        <div v-if="!firstProcess">
                            <OSmsObservationComp
                                v-if="dataObservation.phone_obs!=null"
                                :style="mobile ? 'margin-top:11px' : 'margin-top:3px'"
                                smsTooltip="Ver observación"
                                :smsObservation="dataObservation.phone_obs"
                            />
                            <div :style="mobile ? 'margin-top:16px' : 'margin-top:7px'" v-else>
                                <v-icon color="green">mdi-check-circle</v-icon>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="s-form-membership__title" v-if="!mobile">
                        <h1 class="text-body-private-title pt-2 text-right mr-2">Dirección (*) :</h1>
                    </div>
                    <div class="d-flex s-form-membership__container-input">
                        <div :class="mobile ? 'd-flex' : 'd-flex s-form-membership__input'" style="width:100%">
                            <MTextFieldComp
                                :label="mobile ? 'Dirección' : ''"
                                v-model="dataInterested.address"
                                :class="mobile ? 'mt-2' : ''"
                                :counter=50
                                :dense="mobile ? true : false"
                                :disabled="!firstProcess && dataObservation.address_obs==null"
                                :rules="[v => !m_rule_emptyField(v) || 'El dato es requerido',
                                    v => (v + '').length <= 50 || 'La dirección debe exceder los 50 dígitos'
                                ]"
                            />
                        </div>
                        <div v-if="!firstProcess">
                            <OSmsObservationComp
                                v-if="dataObservation.address_obs!=null"
                                :style="mobile ? 'margin-top:11px' : 'margin-top:3px'"
                                smsTooltip="Ver observación"
                                :smsObservation="dataObservation.address_obs"
                            />
                            <div :style="mobile ? 'margin-top:16px' : 'margin-top:7px'" v-else>
                                <v-icon color="green">mdi-check-circle</v-icon>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex">
                    <div class="s-form-membership__title" v-if="!mobile">
                        <h1 class="text-body-private-title pt-2 text-right mr-2">E-Mail (*) :</h1>
                    </div>
                    <div class="d-flex s-form-membership__container-input">
                        <div :class="mobile ? 'd-flex' : 'd-flex s-form-membership__input'" style="width:100%">
                            <MTextFieldComp
                                :label="mobile ? 'E-Mail (*)' : ''"
                                :dense="mobile ? true : false"
                                :class="mobile ? 'mt-2' : ''"
                                v-model="dataInterested.email"
                                :disabled="!firstProcess && dataObservation.email_obs==null"
                                :counter=50
                                :rules="[
                                    v => !m_rule_withSpaces(v) || 'El E-mail no debe contener espacios',
                                    v => !m_rule_emptyField(v) || 'El dato es requerido',
                                    v => (v + '').length <= 50 || 'El e-mail no debe exceder los 50 dígitos'
                                ]"
                            />
                        </div>
                        <div v-if="!firstProcess">
                            <OSmsObservationComp
                                v-if="dataObservation.email_obs!=null"
                                :style="mobile ? 'margin-top:11px' : 'margin-top:3px'"
                                smsTooltip="Ver observación"
                                :smsObservation="dataObservation.email_obs"
                            />
                            <div :style="mobile ? 'margin-top:16px' : 'margin-top:7px'" v-else>
                                <v-icon color="green">mdi-check-circle</v-icon>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-center flex-wrap">
                    <div class="ma-2">
                        <OPhotoComp
                            acceptFile="image/jpeg"
                            textFormat="JPEG"
                            @onChangePhoto="ac_onChangePhoto"
                            :errorPhoto="errorsPhoto.errorFace"
                            :srcPreviewImage="firstProcess ? '' : dataInterested.route_face"
                            :haveImage="firstProcess ? false : true"
                            :observationImg="dataObservation.route_face_obs!=null ? true : false"
                            class="mb-2"
                            firstTitle="FOTOGRAFÍA"
                            secondTitle="PERSONAL"
                            thirdTitle="(4x4 cm)"
                            reference="inputPhoto"
                        />
                        <div class="d-flex justify-center align-center">
                            <MBtnNormalComp
                                color="primary"
                                :disabled="dataInterested.route_face!=null ? false : true"
                                @click="$emit('clickImageBig', ac_sendDataBigImage(1))"
                            >
                                AMPLIAR
                            </MBtnNormalComp>
                            <div v-if="!firstProcess" class="d-flex align-center">
                                <OSmsObservationComp
                                    v-if="dataObservation.route_face_obs!=null"
                                    smsTooltip="Ver observación"
                                    :isPhoto="true"
                                    class="ml-1"
                                    :smsObservation="dataObservation.route_face_obs"
                                />
                                <div v-else class="ml-1">
                                    <v-icon color="green">mdi-check-circle</v-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ma-2">
                        <OPhotoComp
                            acceptFile="image/jpeg, application/pdf"
                            textFormat="JPEG/PDF"
                            @onChangePhoto="ac_onChangeIdentity"
                            :errorPhoto="errorsPhoto.errorIdentity"
                            firstTitle="CARNET DE"
                            secondTitle="IDENTIDAD"
                            thirdTitle="ANVERSO"
                            class="mb-2"
                            reference="inputIdentity"
                            :srcPreviewImage="firstProcess ? '' : dataInterested.route_identity"
                            :haveImage="firstProcess ? false : true"
                            :observationImg="dataObservation.route_identity_obs!=null ? true : false"
                        />
                        <div class="d-flex justify-center align-center">
                            <MBtnNormalComp
                                color="primary"
                                :disabled="dataInterested.route_identity!=null ? false : true"
                                @click="$emit('clickImageBig', ac_sendDataBigImage(2))"
                            >
                                AMPLIAR
                            </MBtnNormalComp>
                            <div v-if="!firstProcess" class="d-flex align-center">
                                <OSmsObservationComp
                                    v-if="dataObservation.route_identity_obs!=null"
                                    smsTooltip="Ver observación"
                                    :isPhoto="true"
                                    class="ml-1"
                                    :smsObservation="dataObservation.route_identity_obs"
                                />
                                <div v-else class="ml-1">
                                    <v-icon color="green">mdi-check-circle</v-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ma-2">
                        <OPhotoComp
                            acceptFile="image/jpeg, application/pdf"
                            textFormat="JPEG/PDF"
                            @onChangePhoto="ac_onChangeIdentity_r"
                            :errorPhoto="errorsPhoto.errorIdentity_R"
                            firstTitle="CARNET DE"
                            secondTitle="IDENTIDAD"
                            thirdTitle="REVERSO"
                            class="mb-2"
                            reference="inputIdentityR"
                            :srcPreviewImage="firstProcess ? '' : dataInterested.route_identity_r"
                            :haveImage="firstProcess ? false : true"
                            :observationImg="dataObservation.route_identity_r_obs!=null ? true : false"
                        />
                        <div class="d-flex justify-center align-center">
                            <MBtnNormalComp
                                color="primary"
                                :disabled="dataInterested.route_identity_r!=null ? false : true"
                                @click="$emit('clickImageBig', ac_sendDataBigImage(8))"
                            >
                                AMPLIAR
                            </MBtnNormalComp>
                            <div v-if="!firstProcess" class="d-flex align-center">
                                <OSmsObservationComp
                                    v-if="dataObservation.route_identity_r_obs!=null"
                                    smsTooltip="Ver observación"
                                    :isPhoto="true"
                                    class="ml-1"
                                    :smsObservation="dataObservation.route_identity_r_obs"
                                />
                                <div v-else class="ml-1">
                                    <v-icon color="green">mdi-check-circle</v-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ma-2">    
                        <OPhotoComp
                            acceptFile="image/jpeg, application/pdf"
                            textFormat="JPEG/PDF"
                            @onChangePhoto="ac_onChangePhotoTicket"
                            :errorPhoto="errorsPhoto.errorPayment"
                            firstTitle="COMPROBANTE"
                            class="mb-2"
                            secondTitle="DE PAGO"
                            reference="inputTicket"
                            :srcPreviewImage="firstProcess ? '' : dataInterested.route_payment"
                            :haveImage="firstProcess ? false : true"
                            :observationImg="dataObservation.route_payment_obs!=null ? true : false"
                        />
                        <div class="d-flex justify-center align-center">
                            <MBtnNormalComp
                                color="primary"
                                :disabled="dataInterested.route_payment!=null ? false : true"
                                @click="$emit('clickImageBig', ac_sendDataBigImage(3))"
                            >
                                AMPLIAR
                            </MBtnNormalComp>
                            <div v-if="!firstProcess" class="d-flex align-center">
                                <OSmsObservationComp
                                    v-if="dataObservation.route_payment_obs!=null"
                                    smsTooltip="Ver observación"
                                    :isPhoto="true"
                                    class="ml-1"
                                    :smsObservation="dataObservation.route_payment_obs"
                                />
                                <div v-else class="ml-1">
                                    <v-icon color="green">mdi-check-circle</v-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ma-2">
                        <OPhotoComp
                            acceptFile="image/jpeg, application/pdf"
                            textFormat="JPEG/PDF"
                            @onChangePhoto="ac_onChangePhotoCuA"
                            :errorPhoto="errorsPhoto.errorCu_A"
                            firstTitle="CARNET"
                            secondTitle="UNIVERSITARIO"
                            thirdTitle="ANVERSO"
                            class="mb-2"
                            reference="inputCuA"
                            :srcPreviewImage="firstProcess ? '' : dataInterested.route_cu_a"
                            :haveImage="firstProcess ? false : true"
                            :observationImg="dataObservation.route_cu_a_obs!=null ? true : false"
                        />
                        <div class="d-flex justify-center align-center">
                            <MBtnNormalComp
                                color="primary"
                                :disabled="dataInterested.route_cu_a!=null ? false : true"
                                @click="$emit('clickImageBig', ac_sendDataBigImage(4))"
                            >
                                AMPLIAR
                            </MBtnNormalComp>
                            <div v-if="!firstProcess" class="d-flex align-center">
                                <OSmsObservationComp
                                    v-if="dataObservation.route_cu_a_obs!=null"
                                    smsTooltip="Ver observación"
                                    :isPhoto="true"
                                    class="ml-1"
                                    :smsObservation="dataObservation.route_cu_a_obs"
                                />
                                <div v-else class="ml-1">
                                    <v-icon color="green">mdi-check-circle</v-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ma-2">
                        <OPhotoComp
                            acceptFile="image/jpeg, application/pdf"
                            textFormat="JPEG/PDF"
                            @onChangePhoto="ac_onChangePhotoCuR"
                            :errorPhoto="errorsPhoto.errorCu_R"
                            firstTitle="CARNET"
                            secondTitle="UNIVERSITARIO"
                            thirdTitle="REVERSO"
                            class="mb-2"
                            reference="inputCuR"
                            :srcPreviewImage="firstProcess ? '' : dataInterested.route_cu_r"
                            :haveImage="firstProcess ? false : true"
                            :observationImg="dataObservation.route_cu_r_obs!=null ? true : false"
                        />
                        <div class="d-flex justify-center align-center">
                            <MBtnNormalComp
                                color="primary"
                                :disabled="dataInterested.route_cu_r!=null ? false : true"
                                @click="$emit('clickImageBig', ac_sendDataBigImage(5))"
                            >
                                AMPLIAR</MBtnNormalComp>
                            <div v-if="!firstProcess" class="d-flex align-center">
                                <OSmsObservationComp
                                    v-if="dataObservation.route_cu_r_obs!=null"
                                    smsTooltip="Ver observación"
                                    :isPhoto="true"
                                    class="ml-1"
                                    :smsObservation="dataObservation.route_cu_r_obs"
                                />
                                <div v-else class="ml-1">
                                    <v-icon color="green">mdi-check-circle</v-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ma-2">
                        <OPhotoComp
                            acceptFile="image/jpeg, application/pdf"
                            textFormat="JPEG/PDF"
                            @onChangePhoto="ac_onChangePhotoTuitionA"
                            :errorPhoto="errorsPhoto.errorTuition_A"
                            firstTitle="MATRÍCULA"
                            class="mb-2"
                            reference="inputTuitionA"
                            :srcPreviewImage="firstProcess ? '' : dataInterested.route_tuition_a"
                            :haveImage="firstProcess ? false : true"
                            :observationImg="dataObservation.route_tuition_a_obs!=null ? true : false"
                        />
                        <div class="d-flex justify-center align-center">
                            <MBtnNormalComp
                                color="primary"
                                :disabled="dataInterested.route_tuition_a!=null ? false : true"
                                @click="$emit('clickImageBig', ac_sendDataBigImage(6))"
                            >
                                AMPLIAR</MBtnNormalComp>
                            <div v-if="!firstProcess" class="d-flex align-center">
                                <OSmsObservationComp
                                    v-if="dataObservation.route_tuition_a_obs!=null"
                                    smsTooltip="Ver observación"
                                    :isPhoto="true"
                                    class="ml-1"
                                    :smsObservation="dataObservation.route_tuition_a_obs"
                                />
                                <div v-else class="ml-1">
                                    <v-icon color="green">mdi-check-circle</v-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ma-2">
                        <OPhotoComp
                            acceptFile="image/jpeg, application/pdf"
                            textFormat="JPEG/PDF"
                            @onChangePhoto="ac_onChangePhotoTuitionR"
                            :errorPhoto="errorsPhoto.errorTuition_R"
                            firstTitle="KARDEX"
                            class="mb-2"
                            reference="inputTuitionR"
                            :srcPreviewImage="firstProcess ? '' : dataInterested.route_tuition_r"
                            :haveImage="firstProcess ? false : true"
                            :observationImg="dataObservation.route_tuition_r_obs!=null ? true : false"
                        />
                        <div class="d-flex justify-center align-center">
                            <MBtnNormalComp
                                color="primary"
                                :disabled="dataInterested.route_tuition_r!=null ? false : true"
                                @click="$emit('clickImageBig', ac_sendDataBigImage(7))"
                            >
                                AMPLIAR</MBtnNormalComp>
                            <div v-if="!firstProcess" class="d-flex align-center">
                                <OSmsObservationComp
                                    v-if="dataObservation.route_tuition_r_obs!=null"
                                    smsTooltip="Ver observación"
                                    :isPhoto="true"
                                    class="ml-1"
                                    :smsObservation="dataObservation.route_tuition_r_obs"
                                />
                                <div v-else class="ml-1">
                                    <v-icon color="green">mdi-check-circle</v-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
            <div class="text-center pt-5 pb-3">
                <MBtnNormalComp
                    :small="false"
                    color="primary"
                    :outlined="true"
                    @click="ac_sendToConfirmation()"
                >{{firstProcess ? 'ENVIAR SOLICITUD' : 'ENVIAR CORRECIÓN'}}</MBtnNormalComp>
            </div>
        </div>
    </v-form>
</template>
<script>
import {
    MTextFieldComp,
    MSelectComp,
    MBtnNormalComp
} from '../../components/molecules'
import {
    OPhotoComp,
    OSmsObservationComp,
    ODateOneComp
} from '../../components/organisms'
//import { Person } from '../../models'
import {
    rulesMixin,
    datesMixin,
    personMixin
} from '../../mixin'
export default {
    mixins: [
        rulesMixin,
        datesMixin,
        personMixin
    ],
    components: {
        MTextFieldComp,
        MSelectComp,
        OPhotoComp,
        OSmsObservationComp,
        MBtnNormalComp,
        ODateOneComp,
        personMixin
    },
    props: {
        dataInterested: Object,
        dataObservation: Object,
        firstProcess: Boolean,
        titleForm: String,
        mobile: Boolean
    },
    data: () => ({
        dateOfBith: '',
        valid: true,
        errorsPhoto: {
            errorFace: false,
            errorIdentity: false,
            errorIdentity_R: false,
            errorPayment: false,
            errorCu_A: false,
            errorCu_R: false,
            errorTuition_A: false,
            errorTuition_R: false
        },
        dataNewPhotos: {
            route_face: null,
            route_payment: null,
            route_identity: null,
            route_identity_r: null,
            route_cu_a: null,
            route_cu_r: null,
            route_tuition_a: null,
            route_tuition_r: null
        }
    }),
    methods: {
        ac_electionDateExpense(e) {
            this.dateOfBith = this.m_date_formatDate(e)
            this.dataInterested.date_of_birth = e
        },
        ac_onChangePhoto(data) {
            if (data.is_big || data.is_another_file) {
                if (this.firstProcess) {
                    this.dataInterested.route_face = null
                    this.errorsPhoto.errorFace = true
                }
                if (data.is_another_file) var smsError = ['El archivo debe ser', 'jpeg o pdf']
                else var smsError = ['El archivo no puede exceder', 'los 2MB']
                this.$emit('eventError', smsError)
            } else {
                if (this.firstProcess) {
                    if (data.election_photo) {
                        this.dataInterested.route_face = data.file
                        this.errorsPhoto.errorFace = false
                    } else {
                        this.dataInterested.route_face = null
                        this.errorsPhoto.errorFace = true
                    }
                } else {
                    if (data.election_photo) this.dataNewPhotos.route_face = data.file
                    else this.dataNewPhotos.route_face = null
                }
            }
        },
        ac_onChangePhotoTicket(data) {
            if (data.is_big || data.is_another_file) {
                if (this.firstProcess) {
                    this.dataInterested.route_payment = null
                    this.errorsPhoto.errorPayment = true
                }
                if (data.is_another_file) var smsError = ['El archivo debe ser', 'jpeg o pdf']
                else var smsError = ['El archivo no puede exceder', 'los 2MB']
                this.$emit('eventError', smsError)
            } else {
                if (this.firstProcess) {
                    if (data.election_photo) {
                        this.dataInterested.route_payment = data.file
                        this.errorsPhoto.errorPayment = false
                    } else {
                        this.dataInterested.route_payment = null
                        this.errorsPhoto.errorPayment = true
                    }
                } else {
                    if (data.election_photo) this.dataNewPhotos.route_payment = data.file
                    else this.dataNewPhotos.route_payment = null
                }
            }
        },
        ac_onChangeIdentity(data) {
            if (data.is_big || data.is_another_file) {
                if (this.firstProcess) {
                    this.dataInterested.route_identity = null
                    this.errorsPhoto.errorIdentity = true
                }
                if (data.is_another_file) var smsError = ['El archivo debe ser', 'jpeg o pdf']
                else var smsError = ['El archivo no puede exceder', 'los 2MB']
                this.$emit('eventError', smsError)
            } else {
                if (this.firstProcess) {
                    if (data.election_photo) {
                        this.dataInterested.route_identity = data.file
                        this.errorsPhoto.errorIdentity = false
                    } else {
                        this.dataInterested.route_identity = null
                        this.errorsPhoto.errorIdentity = true
                    }
                } else {
                    if (data.election_photo) this.dataNewPhotos.route_identity = data.file
                    else this.dataNewPhotos.route_identity = null
                }
            }
        },
        ac_onChangePhotoCuA(data) {
            if (data.is_big || data.is_another_file) {
                if (this.firstProcess) {
                    this.dataInterested.route_cu_a = null
                    this.errorsPhoto.errorCu_A = true
                }
                if (data.is_another_file) var smsError = ['El archivo debe ser', 'jpeg o pdf']
                else var smsError = ['El archivo no puede exceder', 'los 2MB']
                this.$emit('eventError', smsError)
            } else {
                if (this.firstProcess) {
                    if (data.election_photo) {
                        this.dataInterested.route_cu_a = data.file
                        this.errorsPhoto.errorCu_A = false
                    } else {
                        this.dataInterested.route_cu_a = null
                        this.errorsPhoto.errorCu_A = true
                    }
                } else {
                    if (data.election_photo) this.dataNewPhotos.route_cu_a = data.file
                    else this.dataNewPhotos.route_cu_a = null
                }
            }
        },
        ac_onChangePhotoCuR(data) {
            if (data.is_big || data.is_another_file) {
                if (this.firstProcess) {
                    this.dataInterested.route_cu_r = null
                    this.errorsPhoto.errorCu_R = true
                }
                if (data.is_another_file) var smsError = ['El archivo debe ser', 'jpeg o pdf']
                else var smsError = ['El archivo no puede exceder', 'los 2MB']
                this.$emit('eventError', smsError)
            } else {
                if (this.firstProcess) {
                    if (data.election_photo) {
                        this.dataInterested.route_cu_r = data.file
                        this.errorsPhoto.errorCu_R = false
                    } else {
                        this.dataInterested.route_cu_r = null
                        this.errorsPhoto.errorCu_R = true
                    }
                } else {
                    if (data.election_photo) this.dataNewPhotos.route_cu_r = data.file
                    else this.dataNewPhotos.route_cu_r = null
                }
            }
        },
        //new metodths
        ac_onChangePhotoTuitionA(data) {
            if (data.is_big || data.is_another_file) {
                if (this.firstProcess) {
                    this.dataInterested.route_tuition_a = null
                    this.errorsPhoto.errorTuition_A = true
                }
                if (data.is_another_file) var smsError = ['El archivo debe ser', 'jpeg o pdf']
                else var smsError = ['El archivo no puede exceder', 'los 2MB']
                this.$emit('eventError', smsError)
            } else {
                if (this.firstProcess) {
                    if (data.election_photo) {
                        this.dataInterested.route_tuition_a = data.file
                        this.errorsPhoto.errorTuition_A = false
                    } else {
                        this.dataInterested.route_tuition_a = null
                        this.errorsPhoto.errorTuition_A = true
                    }
                } else {
                    if (data.election_photo) this.dataNewPhotos.route_tuition_a = data.file
                    else this.dataNewPhotos.route_tuition_a = null
                }
            }
        },
        ac_onChangePhotoTuitionR(data) {
            if (data.is_big || data.is_another_file) {
                if (this.firstProcess) {
                    this.dataInterested.route_tuition_r = null
                    this.errorsPhoto.errorTuition_R = true
                }
                if (data.is_another_file) var smsError = ['El archivo debe ser', 'jpeg o pdf']
                else var smsError = ['El archivo no puede exceder', 'los 2MB']
                this.$emit('eventError', smsError)
            } else {
                if (this.firstProcess) {
                    if (data.election_photo) {
                        this.dataInterested.route_tuition_r = data.file
                        this.errorsPhoto.errorTuition_R = false
                    } else {
                        this.dataInterested.route_tuition_r = null
                        this.errorsPhoto.errorTuition_R = true
                    }
                } else {
                    if (data.election_photo) this.dataNewPhotos.route_tuition_r = data.file
                    else this.dataNewPhotos.route_tuition_r = null
                }
            }
        },
        ac_onChangeIdentity_r(data) {
            if (data.is_big || data.is_another_file) {
                if (this.firstProcess) {
                    this.dataInterested.route_identity_r = null
                    this.errorsPhoto.errorIdentity_R = true
                }
                if (data.is_another_file) var smsError = ['El archivo debe ser', 'jpeg o pdf']
                else var smsError = ['El archivo no puede exceder', 'los 2MB']
                this.$emit('eventError', smsError)
            } else {
                if (this.firstProcess) {
                    if (data.election_photo) {
                        this.dataInterested.route_identity_r = data.file
                        this.errorsPhoto.errorIdentity_R = false
                    } else {
                        this.dataInterested.route_identity_r = null
                        this.errorsPhoto.errorIdentity_R = true
                    }
                } else {
                    if (data.election_photo) this.dataNewPhotos.route_identity_r = data.file
                    else this.dataNewPhotos.route_identity_r = null
                }
            }
        },
        validateData() {
            if (!this.$refs.formmembership.validate()) return false
            return true
        },
        activeErrorsPhotographs() {
            if (this.dataInterested.route_face === null) this.errorsPhoto.errorFace = true
            if (this.dataInterested.route_identity === null) this.errorsPhoto.errorIdentity = true
            if (this.dataInterested.route_identity_r === null) this.errorsPhoto.errorIdentity_R = true
            if (this.dataInterested.route_payment === null) this.errorsPhoto.errorPayment = true
            if (this.dataInterested.route_cu_a === null) this.errorsPhoto.errorCu_A = true
            if (this.dataInterested.route_cu_r === null) this.errorsPhoto.errorCu_R = true
            if (this.dataInterested.route_tuition_a === null) this.errorsPhoto.errorTuition_A = true
            if (this.dataInterested.route_tuition_r === null) this.errorsPhoto.errorTuition_R = true
        },
        validatePhotographs() {
            if (this.dataInterested.route_face === null
                || this.dataInterested.route_payment === null
                || this.dataInterested.route_identity === null
                || this.dataInterested.route_identity_r === null
                || this.dataInterested.route_cu_a === null
                || this.dataInterested.route_cu_r === null
                || this.dataInterested.route_tuition_a === null
                || this.dataInterested.route_tuition_r === null) return false
            return true
        },
        ac_sendToConfirmation() {
            var validationForm = false
            if (this.validateData() && this.validatePhotographs()) {
                validationForm = true
            } else {
                if (!this.validatePhotographs()) {
                    this.activeErrorsPhotographs()
                }
            }
            this.$emit('onClickForm', {
                validForm: validationForm,
                dataCorrectionImgs: this.dataNewPhotos 
            })
        },
        ac_sendDataBigImage(option) {
            var parameters = {
                value: '',
                imgFilter: false,
                title: ''
            }
            switch(option) {
                case 1: {
                    parameters.value = this.dataInterested.route_face
                    parameters.title = 'Fotografía Personal'
                    if (!this.firstProcess) {
                        if (this.dataNewPhotos.route_face !== null) {
                            parameters.imgFilter = false
                            parameters.value = this.dataNewPhotos.route_face
                        } else {
                            parameters.imgFilter = true
                            parameters.value = this.dataInterested.route_face
                        }
                    }
                    return parameters
                }
                case 2: {
                    parameters.title = 'Carnet de Identidad Anverso'
                    parameters.value = this.dataInterested.route_identity
                    if (!this.firstProcess) {
                        if (this.dataNewPhotos.route_identity !== null) {
                            parameters.imgFilter = false
                            parameters.value = this.dataNewPhotos.route_identity
                        } else {
                            parameters.imgFilter = true
                            parameters.value = this.dataInterested.route_identity
                        }
                    }
                    return parameters
                }
                case 3: {
                    parameters.title = 'Comprobante de pago'
                    parameters.value = this.dataInterested.route_payment
                    if (!this.firstProcess) {
                        if (this.dataNewPhotos.route_payment !== null) {
                            parameters.imgFilter = false
                            parameters.value = this.dataNewPhotos.route_payment
                        } else {
                            parameters.imgFilter = true
                            parameters.value = this.dataInterested.route_payment
                        }
                    }
                    return parameters
                }
                case 4: {
                    parameters.title = 'Carnet Universitario Anverso'
                    parameters.value = this.dataInterested.route_cu_a
                    if (!this.firstProcess) {
                        if (this.dataNewPhotos.route_cu_a !== null) {
                            parameters.imgFilter = false
                            parameters.value = this.dataNewPhotos.route_cu_a
                        } else {
                            parameters.imgFilter = true
                            parameters.value = this.dataInterested.route_cu_a
                        }
                    }
                    return parameters
                }
                case 5: {
                    parameters.title = 'Carnet Universitario Reverso'
                    parameters.value = this.dataInterested.route_cu_r
                    if (!this.firstProcess) {
                        if (this.dataNewPhotos.route_cu_r !== null) {
                            parameters.imgFilter = false
                            parameters.value = this.dataNewPhotos.route_cu_r
                        } else {
                            parameters.imgFilter = true
                            parameters.value = this.dataInterested.route_cu_r
                        }
                    }
                    return parameters
                }
                case 6: {
                    parameters.title = 'Matrícula'
                    parameters.value = this.dataInterested.route_tuition_a
                    if (!this.firstProcess) {
                        if (this.dataNewPhotos.route_tuition_a !== null) {
                            parameters.imgFilter = false
                            parameters.value = this.dataNewPhotos.route_tuition_a
                        } else {
                            parameters.imgFilter = true
                            parameters.value = this.dataInterested.route_tuition_a
                        }
                    }
                    return parameters
                }
                case 7: {
                    parameters.title = 'Kardex'
                    parameters.value = this.dataInterested.route_tuition_r
                    if (!this.firstProcess) {
                        if (this.dataNewPhotos.route_tuition_r !== null) {
                            parameters.imgFilter = false
                            parameters.value = this.dataNewPhotos.route_tuition_r
                        } else {
                            parameters.imgFilter = true
                            parameters.value = this.dataInterested.route_tuition_r
                        }
                    }
                    return parameters
                }
                case 8: {
                    parameters.title = 'Carnet de Identidad Reverso'
                    parameters.value = this.dataInterested.route_identity_r
                    if (!this.firstProcess) {
                        if (this.dataNewPhotos.route_identity_r !== null) {
                            parameters.imgFilter = false
                            parameters.value = this.dataNewPhotos.route_identity_r
                        } else {
                            parameters.imgFilter = true
                            parameters.value = this.dataInterested.route_identity_r
                        }
                    }
                    return parameters
                }
            }
        }
    },
    mounted() {
        if (!this.firstProcess) {
            this.dateOfBith = this.m_date_formatDate(this.dataInterested.date_of_birth)
        }
    }
}
</script>