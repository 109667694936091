<template>
    <div class="s-card-languajes">
        <div class="d-flex">
            <h1 class="text-body-supertitle">EXPERIENCIA</h1>
            <v-spacer></v-spacer>
            <OBtnHeaderToolTipComp
                v-if="listExperience.length <= 9 && action==2"
                smsTooltip="Añadir Experiencia laboral"
                icon="mdi-plus"
                class="ml-1"
                colorBtn="#2196F3"
                @click="$emit('click')"
            />
        </div>
        <v-divider class="mt-2 mb-2" v-if="action==2"></v-divider>
        <div class="d-flex" v-if="action==2">
            <div class="membership__ornament-circle mr-2 mt-1"></div>
            <h2 class="text-body-subtitle">Solo se pueden introducir 10 experiencias laborales.</h2>
        </div>
        <v-divider class="mt-2"></v-divider>
        <h1
            v-if="listExperience.length==0"
            class="mt-1 text-center text-body-private-title"
        >SIN EXPERIENCIA</h1>
        <div
            v-for="(experience, index) in listExperience"
            :key="index"
            class="d-flex align-center s-card-languajes__option"
        >
            <OBtnCircleComp
                v-if="ac_activateEdit(experience)"
                smsTooltip="Editar especialidad"
                color="#2196F3"
                @click="$emit('clickupdate', experience)"
            >
                <v-icon color="white" small>mdi-pencil</v-icon>
            </OBtnCircleComp>
            <div class="ml-1">
                <h1 :style="ac_colorText(experience)" class="text-body-private-title">{{experience.employer+' ('+experience.location+')'}}</h1>
                <h1 :style="ac_colorText(experience)" class="text-body-private-subtitle">{{experience.position}}</h1>
                <h1 :style="ac_colorText(experience)" class="text-body-private-subtitle">
                    {{'Del '+m_date_formatDate(experience.beginning)+' al '+m_date_formatDate(experience.final)}}</h1>
                <MBtnNormalComp
                    v-if="experience.pdf!=null"
                    color="blue"
                    class="mt-1"
                    :outlined="true"
                    :dark="true"
                    @click="$emit('clickpdf', ac_sendData(experience))"
                >VER Docuemnto PDF</MBtnNormalComp>
            </div>
        </div>
    </div>
</template>
<script>
import { MBtnNormalComp } from '../molecules'
import {
    OBtnHeaderToolTipComp,
    OBtnCircleComp
} from '../../components/organisms'
import { datesMixin } from '../../mixin'
export default {
    mixins: [ datesMixin ],
    components: {
        OBtnHeaderToolTipComp,
        OBtnCircleComp,
        MBtnNormalComp
    },
    props: {
        listExperience: Array,
        action: Number
    },
    methods: {
        ac_sendData(experience) {
            const parameters = {
                'pdf': experience.pdf,
                'title': experience.position
            }
            return parameters
        },
        ac_activateEdit(experience) {
            if (this.action === 2) return true
            if (this.action === 0) return false
            if (experience.validate === 1) return false
            return true
        },
        ac_colorText(experience) {
            if (this.action === 2) return ''
            if (this.action === 0) return ''
            if (experience.validate === 1) return ''
            return 'color:red'
        }
    }
}
</script>