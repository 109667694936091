<template>
    <v-form
        v-on:submit.prevent="ac_confirmNewLanguaje()"
        ref="formexperiencia"
        v-model="validExperiencia"
        lazy-validation
    >
        <ODialogHeaderComp
            :title="indexExperience!=-1 ? 'Editar Experiencia' : 'Nuevo Experiencia'"
            @clickclose="$emit('clickclose')"
        />
        <v-card class="ma-0 pa-2 pt-5">
            <MTextFieldComp
                label="Empresa"
                v-model="dataExperience.employer"
                :dense="true"
                :counter=50
                :rules="[v => !m_rule_emptyField(v) || 'El dato es requerido',
                    v => (v + '').length <= 50 || 'La experiencia no puede exceder los 50 dígitos'
                ]"
            />
            <MTextFieldComp
                label="Localidad"
                v-model="dataExperience.location"
                :dense="true"
                :counter=50
                :rules="[v => !m_rule_emptyField(v) || 'El dato es requerido',
                    v => (v + '').length <= 50 || 'La localidad no puede exceder los 50 dígitos'
                ]"
            />
            <MTextFieldComp
                label="Cargo"
                v-model="dataExperience.position"
                :dense="true"
                :counter=50
                :rules="[v => !m_rule_emptyField(v) || 'El dato es requerido',
                    v => (v + '').length <= 50 || 'El cargo no puede exceder los 50 dígitos'
                ]"
            />
            <div class="d-flex">
                <div class="pr-1">
                    <ODateOneComp
                        v-model="date_beginning"
                        :hideDetails="true"
                        label="Del"
                        :dense="true"
                        @change="ac_electionDateBeninning"
                    />
                </div>
                <div class="pl-1">
                    <ODateOneComp
                        v-model="date_final"
                        :hideDetails="true"
                        label="Al"
                        :dense="true"
                        @change="ac_electionDateFinal"
                    />
                </div>
            </div>
            <OPdfInputComp
                class="mt-2"
                titleFile="Respaldo Experiencia Laboral - PDF"
                :subtitleFile="indexExperience!=-1 ? 'Sin Nuevo Archivo' : 'Sin archivo'"
                :errorPdf="errorPdf"
                :validPdf="validPdf"
                @onChangePdf="ac_onChangePdf"
            />
            <div class="text-center mt-3">
                <MBtnNormalComp
                    :outlined="true"
                    color="primary"
                    @click="ac_confirmExperience()"
                >{{indexExperience!=-1 ? 'GUARDAR' : 'REGISTRAR'}}</MBtnNormalComp>
            </div>
        </v-card>
    </v-form>
</template>
<script>
import {
    MBtnNormalComp,
    MTextFieldComp
} from '../../components/molecules'
import {
    ODialogHeaderComp,
    ODateOneComp,
    OPdfInputComp
} from '../../components/organisms'
import {
    rulesMixin,
    datesMixin
} from '../../mixin'
export default {
    mixins: [ rulesMixin, datesMixin ],
    components: {
        MBtnNormalComp,
        MTextFieldComp,
        ODialogHeaderComp,
        ODateOneComp,
        OPdfInputComp
    },
    props: {
        indexExperience: Number,
        dataExperience: Object        
    },
    data: () => ({
        validExperiencia: true,
        date_beginning: '',
        date_final: '',
        errorPdf: false,
        validPdf: false
    }),
    methods: {
        validateFormExperience() {
            if (!this.$refs.formexperiencia.validate()) return false
            return true
        },
        ac_confirmExperience() {
            if (this.validateFormExperience()) {
                if (this.dataExperience.beginning >= this.dataExperience.final) this.$emit('clickaction', false)
                if (this.indexExperience === -1) {
                    if (this.dataExperience.route_file !== null)
                        this.$emit('clickaction', true)
                    else this.$emit('clickaction', false)
                } else this.$emit('clickaction', true)
            } else this.$emit('clickaction', false)
        },
        resetForm() {
            this.validPdf = false
            this.errorPdf = false
            this.$refs.formexperiencia.resetValidation()
        },
        ac_electionDateBeninning(e) {
            this.date_beginning = this.m_date_formatDate(e)
            this.dataExperience.beginning = e
        },
        initialDates() {
            const date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
            this.date_beginning = this.m_date_formatDate(date)
            this.date_final = this.m_date_formatDate(date)
        },
        ac_electionDateFinal(e) {
            this.date_final = this.m_date_formatDate(e)
            this.dataExperience.final = e
        },
        chargueValuesDate() {
            this.date_beginning = this.m_date_formatDate(this.dataExperience.beginning)
            this.date_final = this.m_date_formatDate(this.dataExperience.final)
        },
        ac_onChangePdf(data) {
            if (data.is_big) {
                this.dataExperience.route_file = null
                this.errorPdf = true
                this.validPdf = false
                this.$emit('eventError')
            } else {
                this.dataExperience.route_file = data.file
                if (data.file !== null) {
                    this.errorPdf = false
                    this.validPdf = true
                } else {
                    if (this.indexExperience === -1) this.errorPdf = true
                    this.validPdf = false
                }
            }
        }
    },
    mounted() {
        this.initialDates()
    }
}
</script>