import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import {
  menuCompStore,
  userStore
} from './modules'

export default new Vuex.Store({
  modules: {
    menuCompStore,
    userStore
  }
})

