<template>
    <div>
        <h1 class="text-body-title text-center">REGISTRO DE PAGO</h1>
        <div class="d-flex justify-center mt-2">
            <OPhotoPaymentComp
                acceptFile="image/jpeg, application/pdf"
                :listAcceptFile="['image/jpeg', 'application/pdf']"
                textFormat="JPEG/PDF"
                :errorPhoto="errorPhoto"
                firstTitle="COMPROBANTE"
                secondTitle="DE PAGO"
                class="mb-2"
                reference="inputpayment"
                :srcPreviewImage="firstProcess ? '' : dataProcessPayment.route_file"
                :haveImage="firstProcess ? false : true"
                @onChangePhoto="ac_onChangePhoto"
            />
        </div>
        <h1 class="mt-2 text-body-private-subtitle">Porfavor especifique la referencia de pago</h1>
        <v-form
            v-on:submit.prevent="sendValidFile()"
            ref="formpayment"
            v-model="valid"
            lazy-validation
        >
            <MTextAreaComp
                v-model="dataProcessPayment.reference"
                class="mt-3"
                label="Referencia"
                height="100"
                :counter="200"
                :rules="[v => !m_rule_emptyField(v) || 'El dato es requerido',
                    v => (v + '').length <= 200 || 'La referencia no puede exceder los 200 dígitos'
                ]"
            />
        </v-form>
        <div class="d-flex justify-center mt-2">
            <MBtnNormalComp
                :outlined="true"
                color="blue"
                class="mr-1"
                :disabled="ac_getDisabled()"
                @click="$emit('clickbigfile')"
            >AMPLIAR</MBtnNormalComp>
            <MBtnNormalComp
                color="blue"
                :disabled="ac_getDisabled()"
                @click="$emit('clicksend', sendValidFile())"
            >ENVIAR</MBtnNormalComp>
        </div>
    </div>
</template>
<script>
import { rulesMixin } from '../../mixin'
import { OPhotoPaymentComp } from '../organisms'
import { MBtnNormalComp, MTextAreaComp } from '../molecules'
export default {
    mixins: [ rulesMixin ],
    components: {
        OPhotoPaymentComp,
        MBtnNormalComp,
        MTextAreaComp
    },
    props: {
        dataProcessPayment: {},
        firstProcess: Boolean
    },
    data: () => ({
        valid: true,
        errorPhoto: false,
        newRouteFile: null,
        valid: true
    }),
    methods: {
        ac_onChangePhoto(data) {
            if (data.is_big || data.is_another_file) {
                if (this.firstProcess) {
                    this.dataProcessPayment.route_file = null
                    this.errorPhoto = true
                }
                if (data.is_another_file) var smsError = ['El archivo debe ser', 'jpeg o pdf']
                else var smsError = ['El archivo no puede exceder', 'los 2MB']
                this.$emit('eventError', smsError)
            } else {
                if (this.firstProcess) {
                    if (data.election_photo) {
                        this.dataProcessPayment.route_file = data.file
                        this.errorPhoto = false
                    } else {
                        this.dataProcessPayment.route_file = null
                        this.errorPhoto = true
                    }
                } else {
                    if (data.election_photo) this.newRouteFile = data.file
                    else this.newRouteFile = null
                    this.$emit('changephoto', this.newRouteFile)
                }
            }
        },
        ac_getDisabled() {
            if (this.dataProcessPayment.route_file !== null) return false
            return true
        },
        validateForm() {
            if (!this.$refs.formpayment.validate()) return false
            return true
        },
        sendValidFile() {
            if (this.validateForm()) {
                if (this.firstProcess && this.dataProcessPayment.route_file===null) return false
                if (!this.firstProcess && this.newRouteFile === null) return false
                return true
            }
            return false
        }
    },

}
</script>