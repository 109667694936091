<template>
    <div class="table-container">
        <table class="s-table-review-procedure animation__card--opacity" border="0">
            <thead>
                <th>Nombre Completo</th>
                <th>Fotografía</th>
                <th>CI</th>
                <th>Celular</th>
                <th>Categoría</th>
                <th>Identificador</th>
                <th>Expiración</th>
                <th>Acciones</th>
            </thead>
            <tbody>
                <tr
                    v-for="(person, index) in listPerson"
                    :key="index"
                >
                    <td class="s-table-review-procedure__border-line">
                        <text-highlight
                            class="text-body-private-subtitle"
                            :queries="searchPerson"
                        >
                            {{ac_getFullName(person)}}
                        </text-highlight>
                    </td>
                    <td class="s-table-review-procedure__border-line">
                        <v-img
                            :src="person.route_face|imgMiniature"
                            width="85px"
                            style="border-radius:5px"
                            contain
                        ></v-img>
                    </td>
                    <td class="s-table-review-procedure__border-line">
                        <div class="d-flex justify-center">
                            <text-highlight
                                class="text-body-private-subtitle"
                                :queries="searchPerson"
                            >
                                {{person.ci}}
                            </text-highlight>
                            <h1 class="text-body-private-subtitle ml-1">{{person.issued_in}}</h1>
                        </div>
                    </td>
                    <td class="s-table-review-procedure__border-line">{{person.phone}}</td>
                    <td class="s-table-review-procedure__border-line">
                        {{ac_getCategory(person.category)}}
                    </td>
                    <td class="s-table-review-procedure__border-line">{{person.identifier}}</td>
                    <td class="s-table-review-procedure__border-line">
                        <div class="d-flex align-center">
                            <h1 class="text-body-private-subtitle mr-1">
                                {{m_date_formatDate(person.date_of_expiry)}}</h1>
                            <div :class="ac_validateDate(person)" style="margin-top:-2px"></div>
                        </div>
                    </td>
                    <td>
                        <div class="d-flex justify-center align-center flex-wrap">
                            <OBtnOptionToolTipComp
                                icon="mdi-format-list-bulleted"
                                colorBtn="#6b99cac7"
                                style="margin:2px"
                                :smsTooltip="'Ver información de: '+person.full_name"
                                @click="$emit('clickdetail', person)"
                            />
                            <OBtnOptionToolTipComp
                                v-if="!isInfo"
                                icon="mdi-pencil"
                                colorBtn="#6b99cac7"
                                style="margin:2px"
                                :smsTooltip="'Editar datos de: '+person.full_name"
                                @click="$emit('clickupdate', person)"
                            />
                            <OBtnOptionToolTipComp
                                v-if="isDisabled"
                                icon="mdi-eye-off"
                                colorBtn="#6b99cac7"
                                style="margin:2px"
                                :smsTooltip="'Habilitar a: '+person.full_name"
                                @click="$emit('clickenabled', person)"
                            />
                            <OBtnOptionToolTipComp
                                v-if="!isDisabled"
                                icon="mdi-file-table"
                                colorBtn="#6b99cac7"
                                style="margin:2px"
                                :smsTooltip="'Habilitar CV de: '+person.full_name"
                                @click="$emit('clickcv', person)"
                            />
                            <OBtnOptionToolTipComp
                                v-if="!isDisabled"
                                icon="mdi-account-switch"
                                colorBtn="#6b99cac7"
                                style="margin:2px"
                                :smsTooltip="'Cambiar la categoría de: '+person.full_name"
                                @click="$emit('clickcategory', person)"
                            />
                            <OBtnOptionToolTipComp
                                v-if="!isDisabled"
                                icon="mdi-account-key"
                                colorBtn="#6b99cac7"
                                style="margin:2px"
                                :smsTooltip="'Reiniciar código de: '+person.full_name"
                                @click="$emit('clickchangecode', person)"
                            />
                        </div>
                    </td>
                </tr>
                <tr v-if="listPerson.length==0" class="text-center">
                    <td colspan="8">SIN DATOS</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import { OBtnOptionToolTipComp } from '../organisms'
import TextHighlight from 'vue-text-highlight'
import { userMixin, datesMixin } from '../../mixin'
import { imgMiniature } from '../../filters'
export default {
    filters: {
        imgMiniature
    },
    mixins: [ userMixin, datesMixin ],
    props: {
        listPerson: Array,
        searchPerson: String,
        isInfo: {
            type: Boolean,
            default: false
        },
        isDisabled: {
            type: Boolean,
            default: false
        }
    },
    components: {
        OBtnOptionToolTipComp,
        TextHighlight,
    },
    methods: {
        ac_getCategory(category) {
            if (category === 'A') return "Activo"
            if (category === 'B') return "Transeunte"
            if (category === 'C') return "Adherente"
            return "Universitario"
        },
        ac_validateDate(person) {
            if (person.date_of_expiry < this.m_date_systemDate(false))
                return 'card-person__ornament card-person__ornament-error'
            else return 'card-person__ornament card-person__ornament-correct'
        },
        ac_getFullName(person) {
            return `${person.full_name} ${person.last_name}`
        }
    }
}
</script>