<template>
    <div class="d-flex" style="width:100%; height:100%;">
        <div id="printSection"></div>
        <OMenuPrivateMobileComp
            :listMenu="listMenu"
            :quantityProcess="quantityProcess"
            :quantityProcessAcademic="quantityProcessAcademic"
            @closesesion="ac_closeSesion()"
        />
        <OMenuPrivateComp
            :listMenu="listMenu"
            :mini="mini"
            :quantityProcess="quantityProcess"
            :quantityProcessAcademic="quantityProcessAcademic"
            @closesesion="ac_closeSesion()"
        />
        <div :style="style_body" class="app-private__router">
            <router-view />
        </div>
        <OLoaderOperationComp
            :dialog="dialogSesion"
            smsLoaderOperation="Cerrando Sesión"
        />
    </div>
</template>
<script>
import { mapState } from 'vuex'
import {
    OMenuPrivateComp,
    OMenuPrivateMobileComp,
    OLoaderOperationComp
} from '../../components/organisms'
import {
    ProcessAffiliation,
    User,
    ProcessAffiliationAcademic
} from '../../models'
export default {
    metaInfo() {
        const title = `ABIS Ch. - ADMINISTRADOR`
        return {
            title: title
        }
    },
    components: {
        OMenuPrivateComp,
        OMenuPrivateMobileComp,
        OLoaderOperationComp
    },
    computed: {
        ...mapState('menuCompStore', ['mini', 'style_body']),
    },
    data: () => ({
        quantityProcess: 0,
        quantityProcessAcademic: 0,
        listMenu: [],
        dialogSesion: false
    }),
    methods: {
        generateMenu() {
            if (JSON.parse(localStorage.getItem('abis')).user.category === 0) {
                this.listMenu = [
                    {
                        icon: 'mdi-web-box',
                        title: 'Página Web',
                        submenu: [
                            {
                                route: 'whatsapp',
                                icon: 'mdi-whatsapp',
                                title: 'Números de Whatsapp'
                            },{
                                route: 'directory',
                                icon: 'mdi-account-group',
                                title: 'Directorio'
                            },{
                                route: 'gallery',
                                icon: 'mdi-image-size-select-actual',
                                title: 'Galeria'
                            },{
                                route: 'documents',
                                icon: 'mdi-file-pdf-box',
                                title: 'Documentos'
                            },{
                                route: 'news',
                                icon: 'mdi-clipboard-list',
                                title: 'Noticias'
                            },{
                                route: 'convention',
                                icon: 'mdi-handshake-outline',
                                title: 'Convenios'
                            }
                        ]
                    },
                    {
                        icon: 'mdi-file-table',
                        title: 'Trámites Profesionales',
                        submenu: [
                            {
                                route: 'review',
                                icon: 'mdi-file-move',
                                title: 'Recepción de Trámites'
                            },{
                                route: 'verify',
                                icon: 'mdi-file-sign',
                                title: 'Verificar Trámite'
                            },{
                                route: 'procedures-stoped',
                                icon: 'mdi-file-remove',
                                title: 'Trámites Estancados'
                            }
                        ]
                    },
                    {
                        icon: 'mdi-file-table',
                        title: 'Trámites Universitarios',
                        submenu: [
                            {
                                route: 'university-review',
                                icon: 'mdi-file-move',
                                title: 'Recepción de Trámites'
                            },{
                                route: 'verify-university',
                                icon: 'mdi-file-sign',
                                title: 'Verificar Trámite'
                            },{
                                route: 'university-procedure-stopped',
                                icon: 'mdi-file-remove',
                                title: 'Trámites Estancados'
                            }
                        ]
                    },
                    {
                        icon: 'mdi-file-multiple',
                        title: 'Curriculum Vitae',
                        submenu: [
                            {
                                route: 'review-cv',
                                icon: 'mdi-file-move',
                                title: 'Solicitudes CV'
                            },{
                                route: 'see-cv',
                                icon: 'mdi-file-sign',
                                title: 'Ver Curriculum Vitae'
                            }
                        ]
                    },
                    {
                        icon: 'mdi-card-account-details-outline',
                        title: 'Extensión de Acreditación',
                        submenu: [
                            {
                                route: 'process-payment',
                                icon: 'mdi-card-bulleted-outline',
                                title: 'Solicitudes de Pago'
                            },{
                                route: 'stalled-requests',
                                icon: 'mdi-card-bulleted-off-outline',
                                title: 'Solicitudes Estancadas'
                            }
                        ]
                    },
                    {
                        icon: 'mdi-human-male',
                        title: 'Afiliados',
                        submenu: [
                            {
                                route: 'affiliations',
                                icon: 'mdi-account-tie',
                                title: 'Afiliados'
                            },{
                                route: 'expired',
                                icon: 'mdi-account-remove',
                                title: 'Afiliados Expirados'
                            },{
                                route: 'disabled-members',
                                icon: 'mdi-account-lock',
                                title: 'Afiliados Inhabilitados'
                            },{
                                route: 'resources',
                                icon: 'mdi-account-wrench',
                                title: 'Recursos Afiliados'
                            }
                        ]
                    },{
                        icon: 'mdi-account-circle',
                        title: 'Usuarios',
                        route: 'users',
                        submenu: []
                    },{
                        icon: 'mdi-account-details',
                        title: 'Mi Perfil',
                        route: 'my-profile',
                        submenu: []
                    }
                ]
            } else {
                if (JSON.parse(localStorage.getItem('abis')).user.category === 1) {
                    this.listMenu = [
                        {
                            route: 'whatsapp',
                            icon: 'mdi-whatsapp',
                            title: 'Números de Whatsapp',
                            submenu: []
                        },{
                            route: 'directory',
                            icon: 'mdi-account-group',
                            title: 'Directorio',
                            submenu: []
                        },{
                            route: 'gallery',
                            icon: 'mdi-image-size-select-actual',
                            title: 'Galeria',
                            submenu: []
                        },{
                            route: 'documents',
                            icon: 'mdi-file-pdf-box',
                            title: 'Documentos'
                        },{
                            route: 'news',
                            icon: 'mdi-clipboard-list',
                            title: 'Noticias',
                            submenu: []
                        },{
                            route: 'convention',
                            icon: 'mdi-handshake-outline',
                            title: 'Convenios'
                        },{
                            icon: 'mdi-account-details',
                            title: 'Mi Perfil',
                            route: 'my-profile',
                            submenu: []
                        }
                    ]
                } else {
                    this.listMenu = [
                        {
                            icon: 'mdi-file-table',
                            title: 'Trámites Afiliación',
                            submenu: [
                                {
                                    route: 'review',
                                    icon: 'mdi-file-move',
                                    title: 'Recepción de Trámites'
                                },{
                                    route: 'verify',
                                    icon: 'mdi-file-sign',
                                    title: 'Verificar Trámite'
                                },{
                                    route: 'procedures-stoped',
                                    icon: 'mdi-file-remove',
                                    title: 'Trámites Estancados'
                                }
                            ]
                        },
                        {
                            icon: 'mdi-file-table',
                            title: 'Trámites Profesionales',
                            submenu: [
                                {
                                    route: 'review',
                                    icon: 'mdi-file-move',
                                    title: 'Recepción de Trámites'
                                },{
                                    route: 'verify',
                                    icon: 'mdi-file-sign',
                                    title: 'Verificar Trámite'
                                },{
                                    route: 'procedures-stoped',
                                    icon: 'mdi-file-remove',
                                    title: 'Trámites Estancados'
                                }
                            ]
                        },
                        {
                            icon: 'mdi-file-multiple',
                            title: 'Curriculum Vitae',
                            submenu: [
                                {
                                    route: 'review-cv',
                                    icon: 'mdi-file-move',
                                    title: 'Solicitudes CV'
                                },{
                                    route: 'see-cv',
                                    icon: 'mdi-file-sign',
                                    title: 'Ver Curriculum Vitae'
                                }
                            ]
                        },
                        {
                            icon: 'mdi-card-account-details-outline',
                            title: 'Extensión de Acreditación',
                            submenu: [
                                {
                                    route: 'process-payment',
                                    icon: 'mdi-card-bulleted-outline',
                                    title: 'Solicitudes de Pago'
                                },{
                                    route: 'stalled-requests',
                                    icon: 'mdi-card-bulleted-off-outline',
                                    title: 'Solicitudes Estancadas'
                                }
                            ]
                        },
                        {
                            icon: 'mdi-human-male',
                            title: 'Afiliados',
                            submenu: [
                                {
                                    route: 'affiliations',
                                    icon: 'mdi-account-tie',
                                    title: 'Afiliados'
                                },{
                                    route: 'expired',
                                    icon: 'mdi-account-remove',
                                    title: 'Afiliados Expirados'
                                },{
                                    route: 'disabled-members',
                                    icon: 'mdi-account-lock',
                                    title: 'Afiliados Inhabilitados'
                                },{
                                    route: 'resources',
                                    icon: 'mdi-account-wrench',
                                    title: 'Recursos Afiliados'
                                }
                            ]
                        },
                        {
                            icon: 'mdi-account-details',
                            title: 'Mi Perfil',
                            route: 'my-profile',
                            submenu: []
                        }
                    ]
                }
            }
        },
        getQuantityProcess() {
            const apitoken = JSON.parse(localStorage.getItem('abis')).token
            var header = {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + apitoken,
            }
            const quantityProcess = ProcessAffiliation.quantityProcess(header)
            const quantityProcessAcademic = ProcessAffiliationAcademic.quantityProcess(header)
            Promise.all([quantityProcess, quantityProcessAcademic]).then(responses => {
            //ProcessAffiliation.quantityProcess(header).then(response => {
                this.quantityProcess = responses[0]
                this.quantityProcessAcademic = responses[1]
            }).catch(error => {
                console.log(error)
            })
        },
        ac_closeSesion() {
            this.dialogSesion = true
            const apitoken = JSON.parse(localStorage.getItem('abis')).token
            var header = {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + apitoken,
            }
            User.logout(header).then(() => {
                localStorage.removeItem('abis')
                this.dialogSesion = false
                setTimeout(() => {
                    this.$router.push('/login')
                }, 200)
            }).catch(error => {
                console.log(error)
                localStorage.removeItem('abis')
                this.dialogSesion = false
                setTimeout(() => {
                    this.$router.push('/login')
                }, 200)
            })
        }
    },
    mounted() {
        if (JSON.parse(localStorage.getItem('abis'))) {
            this.generateMenu()
            this.getQuantityProcess()
        }
    }
}
</script>
    