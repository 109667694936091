<template>
    <div class="o-footer d-flex justify-center">
        <div class="pt-5 pb-5 o-footer__container">
            <div class="o-footer__first-group">
                <div class="o-footer__google-map">
                    <iframe
                        class="o-footer__iframe"
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3881.898106387863!2d-65.26465535100682!3d-19.046669796675484!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6ad7458e07af384f!2sSIB%20Chuquisaca!5e0!3m2!1ses!2sus!4v1648927127474!5m2!1ses!2sus"
                        width="300"
                        height="200"
                        style="border:0;"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade">
                    </iframe>
                </div>
                <div>
                    <div class="d-flex align-center">
                        <v-icon class="mr-2" color="#E1E6E6">mdi-map</v-icon>
                        <h1 class="text-body-subtitle-inverse">UBICACIÓN: Sucre - Bolivia</h1>
                    </div>
                    <div class="d-flex align-center mt-4" style="max-width:440px">
                        <v-icon class="mr-2" color="#E1E6E6">mdi-home-map-marker</v-icon>
                        <h1 class="text-body-subtitle-inverse">
                            Calle destacamento 111 N°192 (entre ayacucho y olañeta), edificio SIB Chuquisaca primer piso</h1>
                    </div>
                    <div class="d-flex align-center mt-4">
                        <v-icon class="mr-2" color="#E1E6E6">mdi-phone</v-icon>
                        <h1 class="text-body-subtitle-inverse">Teléfono: (4) 6461463</h1>
                    </div>
                    <div class="d-flex align-center mt-4">
                        <v-icon class="mr-2" color="#E1E6E6">mdi-email</v-icon>
                        <h1 class="text-body-subtitle-inverse">Correo: abis.chuquisaca@gmail.com</h1>
                    </div>
                </div>
            </div>
            <div class="o-footer__logos">
                <div class="d-flex justify-center align-center">
                    <div class="o-footer__logos-img mr-3">
                        <v-img
                            src="/imgs/public/conventions/aidis.jpeg"
                            width="100%"
                            height="100%"
                            class="mr-2"
                            contain
                            style="border-radius:160px !important; cursor:pointer;"
                            @click="ac_openLinkWeb(true)"
                        ></v-img>
                    </div>
                    <div class="o-footer__logos-img">
                        <v-img
                            src="/imgs/public/conventions/abis.jpeg"
                            width="100%"
                            height="100%"
                            contain
                            @click="ac_openLinkWeb(false)"
                            style="border-radius:160px !important; cursor:pointer;"
                        ></v-img>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        listSchedule: []
    },
    methods: {
        ac_openLinkWeb(isAidis) {
            if (isAidis) window.open('https://abis.org.bo/')
            else window.open('https://aidisnet.org/')
        }
    }
}
</script>
