import axios from 'axios'
import { apiUrls } from '../plugins/apiUrls'
export default class InterestedAcademic {
    constructor(
        full_name = null,
        last_name = null,
        ci = null,
        issued_in = null,
        gender = null,
        date_of_birth = null,
        phone = null,
        address = null,
        email = null,
        route_face = null,
        route_payment = null,
        route_identity = null,
        route_identity_r = null,
        route_cu_a = null,
        route_cu_r = null,
        route_tuition_a = null,
        route_tuition_r = null
    ) {
       this.full_name = full_name
       this.last_name = last_name
       this.ci = ci
       this.issued_in = issued_in
       this.gender = gender
       this.date_of_birth = date_of_birth
       this.phone = phone
       this.address = address
       this.email = email
       this.route_face = route_face
       this.route_payment = route_payment
       this.route_identity = route_identity
       this.route_identity_r = route_identity_r
       this.route_cu_a = route_cu_a
       this.route_cu_r = route_cu_r
       this.route_tuition_a = route_tuition_a
       this.route_tuition_r = route_tuition_r
    }
    
    static correctObservationUniversity(parameters) {
        return axios.post(
            apiUrls.INTERESTED_ACADEMIC + '/correction-observations',
            parameters
        ).then(response => {
            return response.data
        })
    }
}