<template>
    <v-btn
        :ripple="false"
        fab
        :color="color"
        x-small
        :icon="icon"
        depressed
        outlined
        :retain-focus-on-click="false"
        @click="$emit('click')"
    >
        <slot></slot>
    </v-btn>
</template>
<script>
export default {
    props: {
        color: String,
        icon: Boolean
    }
}
</script>