<template>
    <div class="s-card-languajes">
        <div class="d-flex">
            <h1 class="text-body-supertitle">IDIOMAS</h1>
            <v-spacer></v-spacer>
            <OBtnHeaderToolTipComp
                v-if="listLanguajes.length <= 4 && action==2"
                smsTooltip="Añadir lenguaje"
                icon="mdi-plus"
                colorBtn="#2196F3"
                class="ml-1"
                @click="$emit('click')"
            />
        </div>
        <v-divider class="mt-2 mb-2" v-if="action==2"></v-divider>
        <div class="d-flex" v-if="action==2">
            <div class="membership__ornament-circle mr-2 mt-1"></div>
            <h2 class="text-body-subtitle">Solo se pueden introducir 5 idiomas.</h2>
        </div>
        <v-divider class="mt-2"></v-divider>
        <h1
            v-if="listLanguajes.length==0"
            class="mt-1 text-center text-body-private-title"
        >SIN IDIOMAS</h1>
        <div
            v-for="(languaje, index) in listLanguajes"
            :key="index"
            class="d-flex align-center s-card-languajes__option"
        >
            <OBtnCircleComp
                v-if="ac_activateEdit(languaje)"
                smsTooltip="Editar idioma"
                color="#2196F3"
                @click="$emit('clickupdate', languaje)"
            >
                <v-icon color="white" small>mdi-pencil</v-icon>
            </OBtnCircleComp>
            <h1 :style="ac_colorText(languaje)" class="text-body-private-subtitle ml-1">
                {{languaje.languaje}} ({{getLevelLanguaje(languaje)}})</h1>
        </div>

    </div>
</template>
<script>
import {
    OBtnHeaderToolTipComp,
    OBtnCircleComp
} from '../../components/organisms'
export default {
    components: {
        OBtnHeaderToolTipComp,
        OBtnCircleComp
    },
    props: {
        listLanguajes: Array,
        action: Number
    },
    methods: {
        getLevelLanguaje(languaje) {
            if (languaje.nivel === 'b') return 'Básico'
            if (languaje.nivel === 'm') return 'Medio'
            return 'Avanzado'
        },
        ac_activateEdit(languaje) {
            if (this.action === 2) return true
            if (this.action === 0) return false
            if (languaje.validate === 1) return false
            return true
        },
        ac_colorText(languaje) {
            if (this.action === 2) return ''
            if (this.action === 0) return ''
            if (languaje.validate === 1) return ''
            return 'color:red'
        }
    }
}
</script>