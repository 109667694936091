<template>
    <div class="d-flex">
        <v-app-bar
            fixed
            class="o-menu-mobile"
            height="52"
        >
            <div class="d-flex align-center" style="width:100%">
                <div>
                <v-img
                    src="/imgs/logo/logo_little_inverse.png"
                    height="40px"
                    width="100px"
                    contain
                    @click="ac_goHome()"
                ></v-img>
                </div>
                <v-spacer></v-spacer>
                <div class="d-flex align-center">
                    <v-btn
                        icon
                        :ripple="false"
                        class="mr-1"
                        small
                        :retain-focus-on-click="false"
                        @click="$emit('clickface')"
                    >
                        <v-icon color="#2196F3">mdi-facebook</v-icon>
                    </v-btn>
                    <v-btn
                        icon
                        color="#a6f1ac"
                        :ripple="false"
                        class="mr-3"
                        :retain-focus-on-click="false"
                        width="40"
                        min-width="40px"
                        height="40px"
                        min-height="40px"
                        @click="$emit('clicknews')"
                    >
                        <v-icon small>mdi-bell</v-icon>
                        <h1 class="o-menu__number-notices mb-2">{{numberNews}}</h1>
                    </v-btn>
                    <v-app-bar-nav-icon style="color:#F5F5F5" @click="drawer=true"></v-app-bar-nav-icon>
                </div>
            </div>
        </v-app-bar>
        <v-navigation-drawer
                v-model="drawer"
                fixed
                temporary
                width="360"
        >
            <v-toolbar class="o-menu-mobile__header">
                <div class="d-flex align-center">
                    <v-img
                        src="/imgs/logo/logo_little_inverse_2.png"
                        width="75px"
                    ></v-img>
                </div>
                <v-spacer></v-spacer>
                <v-icon @click="drawer=!drawer" color="white">
                    mdi-close-circle
                </v-icon>
            </v-toolbar>
            <v-list nav class="pb-0 pt-2 pb-1">
                <v-list-item-group color="primary">
                    <v-list-item @click="ac_goRouter('home')">
                        <v-list-item-icon class="mr-1">
                            <v-icon small color="#0073B6">mdi-home</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            <h1 class="text-menu">INICIO</h1>
                        </v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
            <v-list nav class="pb-0 pt-0">
                <div
                    v-for="(option, index) in listMenu"
                    :key="index"
                >
                    <v-list-group
                        v-if="option.submenu.length>0"
                        
                        :ripple="false"
                        class="mb-1"
                    >
                        <template v-slot:activator>
                            <v-list-item-icon class="mr-1">
                                <v-icon small color="#0073B6">{{option.icon}}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                                <h1 class="text-menu">{{option.title}}</h1>
                            </v-list-item-title>
                        </template>
                        <v-list-item
                            v-for="(child, index) in option.submenu"
                            :key="index"
                            class="pl-6"
                            @click="ac_goRouter(child.route)"
                        >
                            <v-list-item-title class="d-flex align-center">
                                <v-list-item-icon class="mr-1">
                                    <v-icon small color="#0073B6">{{child.icon}}</v-icon>
                                </v-list-item-icon>
                                <h1 class="text-menu">{{child.title}}</h1>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list-group>
                    <v-list-item-group
                        v-else
                        color="primary"
                    >
                        <v-list-item
                            @click="drawer=!drawer"
                            class="mb-1"
                        >
                            <v-list-item-icon class="mr-1">
                                <v-icon small color="#0073B6">{{option.icon}}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                                <h1 class="text-menu">{{option.title}}</h1>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list-item-group>
                </div>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>
<script>
import { MBtnCircleComp } from '../molecules'
import { scrollMixin } from '../../mixin'
export default {
    mixins: [ scrollMixin ],
    components: {
        MBtnCircleComp
    },
    props: {
        listMenu: Array,
        numberNews: Number
    },
    data: () => ({
        drawer: false,
        listNotices: [
            "Cursos de postgrado",
            "Capacidad de la norma #125"
        ]
    }),
    methods: {
        ac_goRouter(name_route) {
            this.drawer = !this.drawer
            setTimeout(() => {
                if (this.$route.name != name_route) {
                    var velocity = this.m_scroll_velocity(window.scrollY)
                    this.m_scroll_animation(velocity)
                    setTimeout(() => {
                        this.$router.push({name: name_route})
                    }, velocity)
                }
            }, 250)
        },
        ac_goHome() {
            if (this.$route.name != 'home') {
                var velocity = this.m_scroll_velocity(window.scrollY)
                this.m_scroll_animation(velocity)
                setTimeout(() => {
                    this.$router.push({name: 'home'})
                }, velocity)
            }
        }
    }
}
</script>
