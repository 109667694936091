<template>
    <div>
        <v-menu
            transition="slide-x-transition"
            bottom
            right
            offset-y
            :close-on-content-click="closeOnContentClick"
        >
            <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" v-if="!mobile">
                    <v-tooltip
                        bottom
                        color="#062242"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-bind="attrs"
                                v-on="on"
                                @click="$emit('clickopen')"
                                icon
                                :ripple="false"
                                depressed
                                small
                            >
                                <v-icon color="primary">{{icon}}</v-icon>
                            </v-btn>
                        </template>
                        <div class="d-flex align-center">
                            <v-icon class="mr-1" color="white">mdi-hand-pointing-right</v-icon>
                            <h1 class="text-body-subtitle-inverse">{{smsTooltip}}</h1>
                        </div>
                    </v-tooltip>
                </div>
                <div
                    v-else
                    v-bind="attrs"
                    v-on="on"
                    @click="$emit('clickopen')"
                    style="cursor:pointer"
                >
                    <v-icon color="primary">{{icon}}</v-icon>
                </div>
            </template>
            <div class="pa-3 pb-2" :style="mobile ? 'background:white;' : 'background:white; width:300px;'">
                <div class="d-flex align-center mb-1">
                    <h1 class="text-body-private-title">{{title}}</h1>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="red"
                        depressed
                        :ripple="false"
                        fab
                        x-small
                        @click="$emit('clickclose')"
                    >
                        <v-icon color="white">mdi-close</v-icon>
                    </v-btn>
                </div>
                <MTextAreaComp
                    label="Observación"
                    :singleLine="true"
                    :counter="100"
                    height="120"
                    style="width:300px"
                    :value="value"
                    @input="(e)=>(this.$emit('input', e))"
                    :rules="[v => (v + '').length <= 100]"
                />
                <div class="text-center">
                    <MBtnNormalComp
                        :outlined="true"
                        color="primary"
                        @click="$emit('clicksave')"
                    >GUARDAR</MBtnNormalComp>
                </div>
            </div>
        </v-menu>
    </div>
</template>
<script>
import {
    MBtnNormalComp,
    MTextAreaComp
} from '../molecules'
import { rulesMixin } from '../../mixin'
export default {
    mixins: [ rulesMixin ],
    components: {
        MBtnNormalComp,
        MTextAreaComp
    },
    model: {
        prop: 'value',
        event: 'input'
    },
    props: {
        closeOnContentClick: Boolean,
        value: [String, Number],
        title: String,
        icon: String,
        colorIcon: String,
        smsTooltip: String,
        mobile: Boolean
    }
}
</script>