import axios from "axios"
import { apiUrls } from '../plugins/apiUrls'
import { userStore } from '../store/modules'

export default class Publicity {
    constructor (
        route_file = null,
        title = null,
        subtitle = null,
        type_publicity = null,
        expiration = null,
        description = null,
        name_file = null,
        new_img = 0
    ) {
        this.route_file = route_file
        this.title = title
        this.subtitle = subtitle
        this.type_publicity = type_publicity
        this.expiration = expiration
        this.description = description
        this.name_file = name_file
        this.new_img = new_img
    }

    static getListPublicity(page, option_public, typePublicity) {
        return axios.get(
            apiUrls.PUBLICITY + '/list?page='+page+'&option_public='+option_public+'&type_publicity='+typePublicity,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static addPublicity(parameters) {
        return axios.post(
            apiUrls.PUBLICITY + '/add',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static updatePublicity(parameters) {
        return axios.post(
            apiUrls.PUBLICITY + '/update',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static deletePublicity(parameters) {
        return axios.delete(
            apiUrls.PUBLICITY + '/delete',
            { headers: userStore.state.header, data: parameters } 
        ).then(response => {
            return response.data
        })
    }
    static changeState(parameters) {
        return axios.patch(
            apiUrls.PUBLICITY + '/change-state',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static getLastNews() {
        return axios.get(
            apiUrls.PUBLICITY + '/last-news'
        ).then(response => {
            return response.data
        })
    }
    static getListPublicityPublic(page, type_publicity) {
        return axios.get(
            apiUrls.PUBLICITY + '/list-public?page='+page+'&type_publicity='+type_publicity
        ).then(response => {
            return response.data
        })
    }
    static getPublication(id) {
        return axios.get(
            apiUrls.PUBLICITY + '/publication?id='+id
        ).then(response => {
            return response.data
        })
    }
    static getRecommendations(id, quantity) {
        return axios.get(
            apiUrls.PUBLICITY + '/recommendation?id='+id+'&quantity='+quantity
        ).then(response => {
            return response.data
        })
    }
}