import axios from "axios"
import { apiUrls } from '../plugins/apiUrls'
import { userStore } from '../store/modules'
export default class ObservationAcademic {
    constructor(
        full_name_obs = null,
        last_name_obs = null,
        ci_obs = null,
        issued_in_obs = null,
        gender_obs = null,
        date_of_birth_obs = null,
        phone_obs = null,
        address_obs = null,
        email_obs = null,
        route_face_obs = null,
        route_payment_obs = null,
        route_identity_obs = null,
        route_identity_r_obs = null,
        route_cu_a_obs = null,
        route_cu_r_obs = null,
        route_tuition_a_obs = null,
        route_tuition_r_obs = null,
        fkinterested = null,
        id_process = null
    ) {
       this.full_name_obs = full_name_obs
       this.last_name_obs = last_name_obs
       this.ci_obs = ci_obs
       this.issued_in_obs = issued_in_obs
       this.gender_obs = gender_obs
       this.date_of_birth_obs = date_of_birth_obs
       this.phone_obs = phone_obs
       this.address_obs = address_obs
       this.email_obs = email_obs
       this.route_face_obs = route_face_obs
       this.route_payment_obs = route_payment_obs
       this.route_identity_obs = route_identity_obs
       this.route_identity_r_obs = route_identity_r_obs
       this.route_cu_a_obs = route_cu_a_obs
       this.route_cu_r_obs = route_cu_r_obs
       this.route_tuition_a_obs = route_tuition_a_obs
       this.route_tuition_r_obs = route_tuition_r_obs
       this.fkinterested = fkinterested
       this.id_process = id_process
    }
    static addObservation(parameters) {
        return axios.post(
            apiUrls.PROCESS_AFFILIATION_ACADEMIC + '/add-observation',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
}