import axios from "axios"
import { apiUrls } from '../plugins/apiUrls'
import { userStore } from '../store/modules'

export default class Convention {
    constructor (
        route_file = null,
        title = null,
        description = null,
        name_file = null,
        new_img = 0
    ) {
        this.route_file = route_file
        this.title = title
        this.description = description
        this.name_file = name_file
        this.new_img = new_img
    }

    static getListConvention(page, option_public) {
        return axios.get(
            apiUrls.CONVENTION + '/list?page='+page+'&option_public='+option_public,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static addConvention(parameters) {
        return axios.post(
            apiUrls.CONVENTION + '/add',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static updateConvention(parameters) {
        return axios.post(
            apiUrls.CONVENTION + '/update',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static deleteConvention(parameters) {
        return axios.delete(
            apiUrls.CONVENTION + '/delete',
            { headers: userStore.state.header, data: parameters } 
        ).then(response => {
            return response.data
        })
    }
    static changeState(parameters) {
        return axios.patch(
            apiUrls.CONVENTION + '/change-state',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static getListConventionPublic(page) {
        return axios.get(apiUrls.CONVENTION + '/list-public?page='+page).then(response => {
            return response.data
        })
    }
}