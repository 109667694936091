<template>
    <v-form
        v-on:submit.prevent="ac_sendForm()"
        ref="formchangepass"
        v-model="valid"
        lazy-validation
        class="pt-2"
    >
        <MTextFieldComp
            v-if="isMyProfile"
            class="mb-2"
            v-model="dataUser.username"
            :clearable="true"
            label="Username"
            :dense="true"
            :counter="20"
            @click="ac_sendForm()"
            :rules="[v => !this.m_rule_emptyField(v) || 'El dato es requerido',
                v => (v+'').length<=20 || 'El username no debe exeder los 20 dígitos',
                v => (v+'').length>=6 || 'El username debe ser igual o mayor a 6 dígitos']"
        />
        <MTextFieldComp
            class="mb-2"
            v-model="dataUser.password"
            :clearable="true"
            label="Nuevo Password"
            :dense="true"
            :counter="20"
            :type="showPassword ? 'text' : 'password'"
            :icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @clicksee="showPassword = !showPassword"
            @click="ac_sendForm()"
            :rules="[v => !this.m_rule_emptyField(v) || 'El dato es requerido',
                v => !this.m_rule_withSpaces(v) || 'El password no debe contener espacios',
                v => (v+'').length<=20 || 'El password no debe exeder los 20 dígitos',
                v => (v+'').length>=5 || 'El password debe ser igual o mayor a 5 dígitos']"
        />
        <MTextFieldComp
            class="mb-2"
            v-model="dataUser.repitpassword"
            :clearable="true"
            label="Repita Password"
            :dense="true"
            :counter="20"
            :type="showPassword ? 'text' : 'password'"
            :icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @clicksee="showPassword = !showPassword"
            @click="ac_sendForm()"
            :rules="[v => !this.m_rule_emptyField(v) || 'El dato es requerido',
                v => !this.m_rule_withSpaces(v) || 'El password no debe contener espacios',
                v => (v+'').length<=20 || 'El password no debe exeder los 20 dígitos',
                v => (v+'').length>=5 || 'El password debe ser igual o mayor a 5 dígitos',
                v => !this.ac_passwordEquals(v) || 'Las contraseñas no coinciden']"
        />
        <div class="text-center">
            <MBtnNormalComp
                color="primary"
                :outlined="true"
                @click="ac_sendForm()"
            >CAMBIAR</MBtnNormalComp>
        </div>
    </v-form>
</template>
<script>
import {
    MBtnNormalComp,
    MTextFieldComp
} from '../molecules'
import {
    rulesMixin
} from '../../mixin'
export default {
    mixins: [
        rulesMixin
    ],
    components: {
        MBtnNormalComp,
        MTextFieldComp
    },
    props: {
        dataUser: Object,
        isMyProfile: Boolean
    },
    data: () => ({
        showPassword: false,
        valid: true
    }),
    methods: {
        ac_passwordEquals(input) {
            if (input != this.dataUser.password) return true
            return false
        },
        validateDataUser() {
            if (!this.$refs.formchangepass.validate()) return false
            return true
        },
        ac_sendForm() {
            const validateForm = this.validateDataUser()
            this.$emit('clickchange', validateForm)
        },
        resetForm() {
            this.$refs.formchangepass.resetValidation()
        }
    }

}
</script>

