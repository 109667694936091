<template>
    <v-text-field
        :disabled="disabled"
        :label="label"
        :dense="dense"
        :single-line="singleLine"
        :counter="counter"
        outlined
        :hide-details="hideDetails"
        :rules="rules"
        :type="type"
        :value="value"
        @input="(e)=>(this.$emit('input', e))"
        :background-color="background"
        :clearable="true"
        :append-icon="icon"
        @keyup.enter="$emit('click')"
        @click:append="$emit('clicksee')"
        :placeholder="placeholder"
    ></v-text-field>
</template>
<script>
export default {
    model: {
        prop: 'value',
        event: 'input'
    },
    props: {
        label: String,
        singleLine: Boolean,
        hideDetails: Boolean,
        value: [Number, String],
        rules: Array,
        type: String,
        background: String,
        //clearable: Boolean,
        counter: Number,
        icon: String,
        disabled: Boolean,
        placeholder: String,
        dense: Boolean
    }
}
</script>