<template>
    <v-card-title class="d-flex pa-2 primary">
        <h1 class="text-body-subtitle-inverse">{{title}}</h1>
        <v-spacer></v-spacer>
        <v-btn
            v-if="!withoutButtonClosure"
            color="white"
            depressed
            :ripple="false"
            fab
            x-small
            @click="$emit('clickclose')"
        >
            <v-icon color="#ca0000">mdi-close</v-icon>
        </v-btn>
    </v-card-title>
</template>
<script>
export default {
    props: {
        title: String,
        withoutButtonClosure: Boolean
    }
}
</script>
