<template>
    <div class="news-public__container">
        <div class="news-public__desaign-mobile">
            <h1 class="text-body-private-title">{{newPublicity.title}}</h1>
            <h1 class="text-body-private-subtitle">{{newPublicity.subtitle}}</h1>
        </div>
        <div class="news-public__fond-img" :style="ac_getHeight()">
            <div
                class="news-public__image d-flex align-center"
                :style="mobile ? 'opacity:1' : ''"
                @click="$emit('clickimage')"
            >
                <div class="news-public__image--ornament">
                    <v-img
                        width="100px"
                        max-width="100px"
                        src="/imgs/logo/logo_little.png"
                    ></v-img>
                </div>
                <div class="news-public__image-fond"></div>
                <v-img
                    :src="newPublicity.route_img|imgMiniature"
                    width="100%"
                    height="100%"
                ></v-img>
            </div>
        </div>
        <div class="news-public__info">
            <div class="news-public__desaign-pc">
                <div class="d-flex news-public__desaign-pc-title">
                    <h1 class="text-body-private-title"
                        @click="$emit('clickimage')"
                    >{{newPublicity.title}}</h1>
                </div>
                <h1 class="text-body-private-subtitle">{{newPublicity.subtitle}}</h1>
            </div>
            <v-divider v-if="newPublicity.description!=null" class="mt-1 mb-1 news-public__desaign-pc"></v-divider>
            <div class="just-line-break">
                <h1 class="text-body-private-subtitle">
                {{ac_getDescription()}}</h1>
            </div>
            <v-btn
                class="mt-1"
                x-small
                :outlined="true"
                color="#1E95F1"
                @click="$emit('clickbtnimage')"
            >VER PUBLICACIÓN</v-btn>
        </div>
    </div>
</template>
<script>
import { imgMiniature } from '../../filters'
import { newDescription } from '../../mixin'
export default {
    mixins: [ newDescription ],
    filters: { imgMiniature },
    props: {
        newPublicity: {},
        contianImg: Boolean,
        mobile: Boolean
    },
    methods: {
        ac_getDescription() {
            if (this.newPublicity.all_text) return this.newPublicity.description
            else return this.m_description_text(this.newPublicity.description, window.innerWidth)
        },
        ac_getHeight() {
            if (window.innerWidth <= 480) {
                const newHeight = parseInt((window.innerWidth * this.newPublicity.height) / this.newPublicity.width)
                return `min-height:${newHeight}px;`
            } else return ''
        }
        
    }
}
</script>