<template>
    <div @click="$emit('click')" style="cursor:pointer" class="pa-2" v-if="frameCorrect">
        <v-snackbar
            :value="frameCorrect"
            :multi-line="multiLine"
            right
            :bottom="true"
            :timeout="timeSucess"
            :color="colorMessage"
        >
            <div class="d-block">
                <h1 class="text-body-subtitle-inverse" v-for="(tex, index) in smsAction" :key="index">{{tex}}</h1>
            </div>
        </v-snackbar>
    </div>
</template>
<script>
export default {
    props: {
        timeSucess: Number,
        frameCorrect: Boolean,
        multiLine: Boolean,
        smsAction: Array,
        colorMessage: {
            default: '#0a813c',
            type: String
        }
    }
}
</script>
