<template>
    <div style="height:100%">
        <div class="d-flex justify-center">
            <div class="home__first form__contianer">
                <h1 class="text-center text-body-big">
                    {{ac_getTitleTab()}}
                </h1>
            </div>
        </div>
        <div class="d-flex justify-center mt-2">
            <div class="home__first verify-process">
                <div class="verify-process__container">
                    <v-tabs-items style="background:transparent !important;" v-model="tab" :touchless="true">
                        <v-tab-item>
                            <div class="d-flex pb-2">
                                <div class="verify-process__ornament-circle mr-2" style="margin-top:5px"></div>
                                <h2 class="text-body-subtitle">Si no recuerda el código proporcionado, porvafor comuniquese con ABIS Chuquisaca por
                                    los números proporcionados en la página.</h2>
                            </div>
                            <div class="d-flex justify-center mt-4 mb-3">
                                <div class="verify-process__information verify-process__form">
                                    <v-form
                                        v-on:submit.prevent="ac_getProcedue()"
                                        ref="formcredentials"
                                        v-model="valid"
                                        lazy-validation
                                    >
                                        <div class="d-flex pt-3">
                                            <div class="s-form-membership__title" v-if="!mobile">
                                                <h1 class="text-body-private-title pt-2 text-right mr-2">Carnet de Identidad (*) :</h1>
                                            </div>
                                            <div class="s-form-membership__input" style="width:100%">
                                                <MTextFieldComp
                                                    :label="mobile ? 'Carnet de Identidad (*)' : ''"
                                                    v-model="dataCredentials.ci"
                                                    :counter=20
                                                    :rules="[v => !m_rule_emptyField(v) || 'El dato es requerido',
                                                        v => (v + '').length <= 20 || 'El CI no deben exceder los 20 dígitos'
                                                    ]"
                                                />
                                            </div>
                                        </div>
                                        <div class="d-flex">
                                            <div class="s-form-membership__title" v-if="!mobile">
                                                <h1 class="text-body-private-title pt-2 text-right mr-2">Código (*) :</h1>
                                            </div>
                                            <div class="s-form-membership__input" style="width:100%">
                                                <MTextFieldComp
                                                    :label="mobile ? 'Código' : ''"
                                                    v-model="dataCredentials.code"
                                                    :counter=10
                                                    :class="mobile ? 'mt-3' : ''"
                                                    :type="showPassword ? 'text' : 'password'"
                                                    @clicksee="showPassword = !showPassword"
                                                    @click="ac_getProcedue()"
                                                    :icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                                    :rules="[v => !m_rule_emptyField(v) || 'El dato es requerido',
                                                        v => (v + '').length <= 10 || 'El código no deben exceder los 10 dígitos'
                                                    ]"
                                                />
                                            </div>
                                        </div>
                                        <div class="d-flex justify-center">
                                            <MBtnNormalComp
                                                :depressed="true"
                                                color="primary"
                                                :outlined="true"
                                                @click="ac_getProcedue()"
                                            >INGRESAR</MBtnNormalComp>
                                        </div>
                                    </v-form>
                                </div>
                            </div>
                        </v-tab-item>
                        <v-tab-item>
                            <div class="pa-2 info-affiliate__smsError" v-if="!ac_withValidity()">
                                <h1 class="text-body-private-subtitle text-center">
                                    Su acreditación a ABIS Chuquisaca a expirado, porfavor realice el
                                    trámite de pago, previo depósito de las cuotas adeudadas. GRACIAS
                                </h1>
                            </div>
                            <div class="d-flex align-center flex-wrap">
                                <div class="d-flex justify-center info-affiliate__qr">
                                    <div id="codeqr">
                                        <vue-qr :text="linkAffiliate" :size="200"></vue-qr>
                                    </div>
                                </div>
                                <div class="d-flex justify-center info-affiliate__photo">
                                    <OPhotoReviewComp
                                        :srcPreviewImage="dataPerson.route_face"
                                        :isError="false"
                                        :imageOnly="true"
                                    />
                                </div>
                                <div class="info-affiliate__data">
                                    <div class="d-flex pb-2">
                                        <h1 class="text-body-private-title" v-if="dataPerson.full_name!=undefined">
                                            Bienvenido {{dataPerson.full_name+' '+dataPerson.last_name}}</h1>
                                    </div>
                                    <div class="d-flex pb-1">
                                        <h1 class="text-body-private-title mr-1">CI:</h1>
                                        <h1 class="text-body-private-subtitle">
                                            {{dataPerson.ci+' '+dataPerson.issued_in}}</h1>
                                    </div>
                                    <div class="d-flex pb-1">
                                        <h1 class="text-body-private-title mr-1">RNI:</h1>
                                        <h1 class="text-body-private-subtitle">
                                            {{dataPerson.code_sib!=null ? dataPerson.code_sib : 'S/RNI'}}</h1>
                                    </div>
                                    <div class="d-flex pb-1">
                                        <h1 class="text-body-private-title mr-1">N° Registro ABIS - Ch:</h1>
                                        <h1 class="text-body-private-subtitle">{{dataPerson.identifier}}</h1>
                                    </div>
                                    <div class="d-flex pb-1">
                                        <h1 class="text-body-private-title mr-1">Celular:</h1>
                                        <h1 class="text-body-private-subtitle">{{dataPerson.phone}}</h1>
                                    </div>
                                    <div class="d-flex pb-1">
                                        <h1 class="text-body-private-title mr-1">Dirección:</h1>
                                        <h1 class="text-body-private-subtitle">{{dataPerson.address}}</h1>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex flex-wrap pb-1 pt-1">
                                <MBtnNormalComp
                                    v-if="ac_withValidity()"
                                    color="error"
                                    :outlined="true"
                                    style="margin:2px"
                                    @click="ac_generatePdf()"
                                >
                                <v-icon>mdi-file-pdf-box</v-icon>
                                CERTIFICADO</MBtnNormalComp>
                                <MBtnNormalComp
                                    v-if="ac_withValidity()"
                                    color="primary"
                                    :outlined="true"
                                    style="margin:2px"
                                    @click="ac_goCurriculunVitae()"
                                >
                                CURRICULUM VITAE</MBtnNormalComp>
                                <MBtnNormalComp
                                    color="primary"
                                    class="ml-1"
                                    :outlined="true"
                                    style="margin:2px"
                                    @click="seeStatementOfAccounts()"
                                >
                                ESTADO DE CUENTA</MBtnNormalComp>
                                <MBtnNormalComp
                                    color="primary"
                                    class="ml-1"
                                    :outlined="true"
                                    style="margin:2px"
                                    @click="ac_sendDeposit()"
                                >
                                FORMULARIO DE PAGO</MBtnNormalComp>
                            </div>
                        </v-tab-item>
                        <v-tab-item>
                            <div v-if="!isRequestCvPublication && dataPerson.admin_public==0">
                                <div class="d-flex pb-2">
                                    <div class="verify-process__ornament-circle mr-2" style="margin-top:5px"></div>
                                    <h2 class="text-body-subtitle">Recuerde que esta sección solo
                                        es un resumen de su Curriculum Vitae.</h2>
                                </div>
                                <div class="d-flex pb-2">
                                    <div class="verify-process__ornament-circle mr-2" style="margin-top:5px"></div>
                                    <h2 class="text-body-subtitle">Resalte los mejores atributos de acuerdo
                                        a la categoría.</h2>
                                </div>
                                <div class="d-flex pb-2">
                                    <div class="verify-process__ornament-circle mr-2" style="margin-top:5px"></div>
                                    <h2 class="text-body-subtitle">Las categorías del Perfil, Profesiones, Cualidades,
                                        son categorías obligatorias y deben contener información antes de enviar la 
                                        solicitud de publicación.</h2>
                                </div>
                                <div class="d-flex pb-2">
                                    <div class="verify-process__ornament-circle mr-2" style="margin-top:5px"></div>
                                    <h2 class="text-body-subtitle">Una vez enviada la solicitud de publicación,
                                        no puede modificar la información de su CV.</h2>
                                </div>
                                <div class="d-flex pb-2">
                                    <div class="verify-process__ornament-circle mr-2" style="margin-top:5px"></div>
                                    <h2 class="text-body-subtitle">Concluido el llenado, presione en
                                        "SOLICITAR PUBLICACIÓN", para que ABIS Chuquisaca, revise y apruebe
                                        su publicación.</h2>
                                </div>
                            </div>
                            <div v-else class="mb-1">
                                <div v-if="dataPerson.admin_public==1">
                                    <div class="d-flex pb-2">
                                        <div class="verify-process__ornament-circle mr-2" style="margin-top:5px"></div>
                                        <h2 class="text-body-subtitle">
                                            Su Curriculum Vitae (resumen) fue aprovado.
                                        </h2>
                                    </div>
                                    <div class="d-flex pb-2">
                                        <div class="verify-process__ornament-circle mr-2" style="margin-top:5px"></div>
                                        <h2 class="text-body-subtitle">
                                            Para verificar, busque su CV en la sección AFILIADOS colocando su CI o
                                            utilizando las opciones de búsqueda.
                                        </h2>
                                    </div>
                                    <div class="d-flex pb-2">
                                        <div class="verify-process__ornament-circle mr-2" style="margin-top:5px"></div>
                                        <h2 class="text-body-subtitle">
                                            Puede habilitar y deshabilitar la publicación de su CV presionado click en
                                            el botón PUBLICAR / NO PUBLICAR
                                        </h2>
                                    </div>
                                    <div class="d-flex align-center">
                                        <h1 class="text-body-private-title mr-2">
                                            ESTADO: {{dataPerson.user_public==0 ? 'NO PUBLICADO' : 'PUBLICADO'}}</h1>
                                        <MBtnNormalComp
                                            :outlined="true"
                                            color="primary"
                                            @click="ac_changeStatePublications()"
                                        >{{dataPerson.user_public==0 ? 'PUBLICAR' : 'NO PUBLICAR'}}</MBtnNormalComp>
                                    </div>
                                </div>
                                <div v-if="dataPerson.admin_public==2">
                                    <div class="d-flex pb-2">
                                        <div class="verify-process__ornament-circle mr-2" style="margin-top:5px"></div>
                                        <h2 class="text-body-subtitle">ABIS Chuquisaca anuló su Curriculum Vitae.</h2>
                                    </div>
                                    <div class="d-flex pb-2">
                                        <div class="verify-process__ornament-circle mr-2" style="margin-top:5px"></div>
                                        <h2 class="text-body-subtitle">Para volver a solicitar el llenado de su Curriculum Vitae
                                            y ser habilitado, comuniquese con los números proporcionados en la página. GRACIAS
                                        </h2>
                                    </div>
                                </div>
                                <div v-if="processCv.received!=undefined">
                                    <div v-if="processCv.received == 's'">
                                        <div class="d-flex pb-2">
                                            <div class="verify-process__ornament-circle mr-2" style="margin-top:5px"></div>
                                            <h2 class="text-body-subtitle">Su solicitud para la publicación de su Curriculum Vitae
                                                se encuentra en curso.
                                            </h2>
                                        </div>
                                        <div class="d-flex pb-2">
                                            <div class="verify-process__ornament-circle mr-2" style="margin-top:5px"></div>
                                            <h2 class="text-body-subtitle">Espere a que ABIS Chuquisaca realice la revisión
                                                de su información porfavor. GRACIAS
                                            </h2>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div class="d-flex pb-2">
                                            <div class="verify-process__ornament-circle mr-2" style="margin-top:5px"></div>
                                            <h2 class="text-body-subtitle">Su Curriculum Vitae fue observado, porfavor
                                                corrija todos los datos marcados con rojo.
                                            </h2>
                                        </div>
                                        <div class="d-flex" v-if="processCv.observation!=null">
                                            <h1 class="text-body-private-title mr-1">Observación:</h1>
                                            <h1 class="text-body-private-subtitle">{{processCv.observation}}</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex pb-2">
                                <MBtnNormalComp
                                    :outlined="true"
                                    color="primary"
                                    @click="tab=1"
                                >VOLVER ATRÁS</MBtnNormalComp>
                                <MBtnNormalComp
                                    v-if="ac_validateObservations()"
                                    :outlined="true"
                                    color="primary"
                                    class="ml-1"
                                    @click="ac_confirmObservations()"
                                >ENVIAR CORRECCIONES</MBtnNormalComp>
                                <MBtnNormalComp
                                    v-if="!isRequestCvPublication && dataPerson.admin_public==0"
                                    class="ml-1"
                                    :outlined="true"
                                    color="primary"
                                    @click="ac_confirmPublicationCv()"
                                >SOLICITAR PUBLICACIÓN</MBtnNormalComp>
                            </div>
                            <div
                                v-if="dataPerson.admin_public!=2"
                                class="d-flex justify-center flex-wrap mt-1"
                            >
                                <SCardOtherComp
                                    :other="other"
                                    :action="noRequestOfPublications()"
                                    @click="ac_showFormOther"
                                    class="ma-2"
                                />
                                <SCardLanguajesComp
                                    :listLanguajes="listLanguajes"
                                    @click="dialogLanguaje=true"
                                    :action="noRequestOfPublications()"
                                    @clickupdate="ac_showFormEditLanguaje"
                                    class="ma-2"
                                />
                                <SCardQualitieComp
                                    :listQualities="listQualities"
                                    @click="dialogQualitie=true"
                                    :action="noRequestOfPublications()"
                                    @clickupdate="ac_showFormEditQualitie"
                                    class="ma-2"
                                />
                                <SCardProfession
                                    :action="noRequestOfPublications()"
                                    :listProfessions="listProfessions"
                                    @click="dialogProfession=true"
                                    @clickupdate="ac_showFormProfession"
                                    @clickpdf="ac_showPdf"
                                    class="ma-2"
                                />
                                <SCardSpecialtiesComp
                                    :action="noRequestOfPublications()"
                                    :listSpecialties="listSpecialties"
                                    :mobile="mobile"
                                    @click="dialogSpecialty=true"
                                    @clickupdate="ac_showFormSpecialty"
                                    @clickpdf="ac_showPdf"
                                    class="ma-2"
                                />
                                <SCardExperienceComp
                                    :action="noRequestOfPublications()"
                                    :listExperience="listExperience"
                                    class="ma-2"
                                    @click="ac_openFormExperience()"
                                    @clickupdate="ac_showFormExperience"
                                    @clickpdf="ac_showPdf"
                                />
                            </div>
                        </v-tab-item>
                        <v-tab-item>
                            <div>
                                <div v-if="processPayment.state_process != undefined">
                                    <div class="d-flex pb-2">
                                        <h1 class="text-body-private-title mr-1">Estado de trámite de pago:</h1>
                                        <h1 class="text-body-subtitle">
                                            {{processPayment.state_process!='s' ? 'Observado' : 'Enviado'}}
                                        </h1>
                                    </div>
                                    <div class="d-flex pb-2">
                                        <h1 class="text-body-private-title mr-1">Fecha de inicio del trámite:</h1>
                                        <h1 class="text-body-subtitle">
                                            {{processPayment.date}}
                                        </h1>
                                    </div>
                                    <div class="d-flex pb-2">
                                        <h1 class="text-body-private-title mr-1">Revisón del trámite de pago:</h1>
                                        <h1 class="text-body-subtitle">
                                            {{processPayment.received!='s' ? 'Revisado' : 'En curso'}}
                                        </h1>
                                    </div>
                                    <div class="d-flex pb-2" v-if="processPayment.state_process=='o' && processPayment.received=='r'">
                                        <h1 class="text-body-private-title mr-1">Observación:</h1>
                                        <h1 class="text-body-subtitle">
                                            {{processPayment.observation}}
                                        </h1>
                                    </div>
                                    <div class="d-flex pb-2" v-if="processPayment.state_process=='o' && processPayment.received=='r'">
                                        <h1 class="text-body-private-title mr-1">Referencia:</h1>
                                        <h1 class="text-body-subtitle">
                                            {{processPayment.reference}}
                                        </h1>
                                    </div>
                                    <div class="pb-2" v-if="processPayment.state_process=='o' && processPayment.received=='r'">
                                        <MBtnNormalComp
                                            :outlined="true"
                                            color="blue"
                                            @click="ac_showFormUpdate()"
                                        >EDITAR FORMULARIO DE PAGO</MBtnNormalComp>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="d-flex pb-2">
                                        <div class="verify-process__ornament-circle mr-2" style="margin-top:5px"></div>
                                        <h1 class="text-body-subtitle">
                                            Antes de realizar el trámite de pago para extensión de acreditación,
                                            debe contar con el comprobante de pago, expresado en formato jpg o pdf.
                                        </h1>
                                    </div>
                                    <div class="d-flex pb-2">
                                        <div class="verify-process__ornament-circle mr-2" style="margin-top:5px"></div>
                                        <h1 class="text-body-subtitle">
                                            Si usted no tiene cuotas pendientes, puede extender la fecha de acreditación de 
                                            afiliación llenando el formulario de pago.
                                        </h1>
                                    </div>
                                    <div v-if="dataPerson.date_of_expiry!=undefined" class="mb-2">
                                        <div class="d-flex">
                                            <h1 class="text-body-private-title mr-1">Estado de pagos:</h1>
                                            <h1 class="text-body-private-subtitle">
                                                {{ac_withValidity() ? 'Al día' : 'Adeudado'}}
                                            </h1>
                                        </div>
                                        <div class="d-flex">
                                            <h1 class="text-body-private-title mr-1">Expiración de acreditación:</h1>
                                            <h1 class="text-body-private-subtitle">{{m_date_formatDate(dataPerson.date_of_expiry)}}</h1>
                                        </div>
                                        <div class="d-flex" v-if="!ac_withValidity()">
                                            <h1 class="text-body-private-title mr-1">N° de Cuotas atrasadas:</h1>
                                            <h1 class="text-body-private-subtitle">
                                                {{m_calculate_debt(dataPerson.diff_in_days)}}</h1>
                                        </div>
                                        <div class="d-flex" v-if="!ac_withValidity()">
                                            <h1 class="text-body-private-title mr-1">Monto a Cancelar:</h1>
                                            <h1 class="text-body-private-subtitle">
                                                {{m_calculate_payment(dataPerson.diff_in_days, 15)+' Bs.'}}</h1>
                                        </div>
                                    </div>
                                </div>
                                <div class="pb-2 d-flex flex-wrap">
                                    <MBtnNormalComp
                                        :outlined="true"
                                        color="blue"
                                        style="margin:2px"
                                        @click="ac_backAffiliate()"
                                    >VOLVER ATRÁS</MBtnNormalComp>
                                    <MBtnNormalComp
                                        v-if="ac_validShowPayment()"
                                        :outlined="true"
                                        color="blue"
                                        style="margin:2px"
                                        @click="ac_showPaymentServer()"
                                    >VER COMPROBANTE</MBtnNormalComp>
                                    <MBtnNormalComp
                                        v-if="ac_validBtnActionForm()"
                                        :outlined="true"
                                        color="blue"
                                        style="margin:2px"
                                        @click="showFormPayment=true"
                                    >LLENAR FORMULARIO DE PAGO</MBtnNormalComp>
                                </div>
                                <div class="d-flex justify-center" v-if="showFormPayment">
                                    <div class="user--border user--radius pa-2 pl-3 pr-3">
                                        <SFormPaymentComp
                                            :dataProcessPayment="dataProcessPayment"
                                            :firstProcess="firstProcess"
                                            @eventError="ac_showErrorFile"
                                            @clickbigfile="ac_showBigFile()"
                                            @clicksend="ac_confirmSendPayement"
                                            @changephoto="ac_chargueNewFile"
                                        />
                                    </div>
                                </div>
                            </div>
                        </v-tab-item>
                    </v-tabs-items>
                </div>
            </div>
        </div>
        <!-- OPERATIONS CURRICULUM VITAE-->
        <v-dialog persistent width="400" v-model="dialogOther">
            <SFormOtherComp
                ref="containerformother"
                :dataOther="dataOther"
                @clickaction="ac_confirmOther"
                @clickclose="ac_closeOther()"
            />
        </v-dialog>
        <v-dialog persistent width="400" v-model="dialogLanguaje">
            <SFormLanguajeComp
                ref="containerformlanguaje"
                :indexLanguaje="indexLanguaje"
                :dataLanguaje="dataLanguaje"
                @clickaction="ac_confirmNewLanguaje"
                @clickclose="ac_closeDialogLanguaje()"
            />
        </v-dialog>
        <v-dialog persistent width="400" v-model="dialogQualitie">
            <SFormQualitieComp
                ref="containerformqualitie"
                :indexQualitie="indexQualitie"
                :dataQualitie="dataQualitie"
                @clickaction="ac_confirmNewQualitie"
                @clickclose="ac_closeDialogQualitie()"
            />
        </v-dialog>
        <v-dialog persistent width="500" v-model="dialogProfession">
            <SFormProfessionComp
                ref="contianerformprofession"
                :dataProfession="dataProfession"
                :indexProfession="indexProfession"
                @clickaction="ac_confirmProfession"
                @clickclose="ac_closeDialogProfession()"
                @eventError="ac_errorFilePdf()"
            />
        </v-dialog>
        <v-dialog persistent width="400" v-model="dialogSpecialty">
            <SFormSpecialtyComp
                ref="containerformspecilty"
                :dataSpecialty="dataSpecialty"
                @clickaction="ac_confirmSpecialty"
                :indexSpecialty="indexSpecialty"
                @clickclose="ac_closeDialogSpecialty()"
                @eventError="ac_errorFilePdf()"
            />
        </v-dialog>
        <v-dialog persistent width="400" v-model="dialogExperience">
            <SFormExperienceComp
                ref="containerformexperience"
                :dataExperience="dataExperience"
                :indexExperience="indexExperience"
                @clickaction="ac_confirmExperience"
                @clickclose="ac_closeDialogExperience()"
                @eventError="ac_errorFilePdf()"
            />
        </v-dialog>
        <v-dialog width="400" v-model="dialogAccount" persistent>
            <ODialogHeaderComp
                title="Estado de Cuenta"
                @clickclose="dialogAccount=false"
            />
            <v-card class="ma-0 pa-2" v-if="dataPerson.date_of_expiry!=undefined">
                <div class="d-flex">
                    <h1 class="text-body-private-title mr-1">Estado de pagos:</h1>
                    <h1 class="text-body-private-subtitle">
                        {{ac_withValidity() ? 'Al día' : 'Adeudado'}}
                    </h1>
                </div>
                <div class="d-flex">
                    <h1 class="text-body-private-title mr-1">Expiración de acreditación:</h1>
                    <h1 class="text-body-private-subtitle">{{m_date_formatDate(dataPerson.date_of_expiry)}}</h1>
                </div>
                <div class="d-flex" v-if="!ac_withValidity()">
                    <h1 class="text-body-private-title mr-1">N° de Cuotas atrasadas:</h1>
                    <h1 class="text-body-private-subtitle">
                        {{m_calculate_debt(dataPerson.diff_in_days)}}</h1>
                </div>
                <div class="d-flex" v-if="!ac_withValidity()">
                    <h1 class="text-body-private-title mr-1">Monto a Cancelar:</h1>
                    <h1 class="text-body-private-subtitle">
                        {{m_calculate_payment(dataPerson.diff_in_days, 15)+' Bs.'}}</h1>
                </div>
            </v-card>
        </v-dialog>
        <OModalImgComp
            :dialog="modalImg"
            :src="srcImg"
            :title="titleImg"
            :isServer="isServerImg"
            @clickclose="modalImg=false"
        />
        <OModalPdfComp
            :dialog="dialogPdfPreview"
            :src="srcPdf"
            :title="titlePdf"
            @clickclose="dialogPdfPreview=false"
        />
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="dialogError=false"
        />
        <OLoaderOperationComp
            :dialog="dialogLoaderOperation"
            smsLoaderOperation="Enviando Solicitud"
        />
        <OSmsActionComp
            :frameCorrect="frameCorrect"
            :smsAction="smsAction"
            :colorMessage="colorMessage"
            :timeout="timeSucess"
            @click="m_dataGeneral_frameTimeReset()"
        />
        <OSmsConfirmComp
            :dialog="dialogConfirm"
            :smsConfirm="smsConfirm"
            @clickno="ac_closeDialogConfirm()"
            @clickyes="ac_perfomOperation()"
        />
    </div>
</template>
<script>
import {
    MTextFieldComp,
    MBtnNormalComp,
    MSelectComp
} from '../../components/molecules'
import {
    OSmsErrorComp,
    OPhotoReviewComp,
    OLoaderOperationComp,
    ODialogHeaderComp,
    OSmsActionComp,
    OSmsConfirmComp,
    OModalPdfComp,
    OModalImgComp
} from '../../components/organisms'
import {
    SCardLanguajesComp,
    SFormLanguajeComp,
    SCardQualitieComp,
    SFormQualitieComp,
    SFormOtherComp,
    SCardOtherComp,
    SCardSpecialtiesComp,
    SFormSpecialtyComp,
    SFormProfessionComp,
    SCardProfession,
    SCardExperienceComp,
    SFormExperienceComp,
    SFormPaymentComp
} from '../../components/species'
import {
    imgMiniature
} from '../../filters'
import {
    rulesMixin,
    dataGeneralComponetsMixin,
    responseServerMixin,
    generateCodeMixin,
    datesMixin,
    calculatePayementsMixin,
    scrollMixin
} from '../../mixin'
import {
    Person,
    Languaje,
    Qualitie,
    Other,
    Specialty,
    Profession,
    Experience,
    ProcessCv,
    ProcessPayments
} from '../../models'
import jsPDF from 'jspdf' 
import VueQr from 'vue-qr'
import domtoimage from "dom-to-image-more";
export default {
    metaInfo() {
        const title = `ABIS Ch. - Mi Información`
        return {
            title: title
        }
    },
    filters: {
        imgMiniature
    },
    mixins: [
        rulesMixin,
        dataGeneralComponetsMixin,
        responseServerMixin,
        generateCodeMixin,
        datesMixin,
        calculatePayementsMixin,
        scrollMixin
    ],
    components: {
        OSmsErrorComp,
        MTextFieldComp,
        MBtnNormalComp,
        OPhotoReviewComp,
        VueQr,
        OLoaderOperationComp,
        SCardLanguajesComp,
        ODialogHeaderComp,
        MSelectComp,
        OSmsActionComp,
        SFormLanguajeComp,
        SFormQualitieComp,
        SCardQualitieComp,
        SFormOtherComp,
        SCardOtherComp,
        SCardSpecialtiesComp,
        SFormSpecialtyComp,
        SFormProfessionComp,
        SCardProfession,
        SCardExperienceComp,
        SFormExperienceComp,
        OSmsConfirmComp,
        OModalPdfComp,
        OModalImgComp,
        SFormPaymentComp
    },
    data: () => ({
        dataPerson: {},
        tab: 0,
        dataCredentials: {
            ci: null,
            code: null
        },
        valid: true,
        mobile: false,
        showPassword: false,
        linkAffiliate: 'www.abischuquisaca.com/afiliado/',
        dataLanguaje: new Languaje(),
        dialogLanguaje: false,
        listLanguajes: [],
        indexLanguaje: -1,
        dataQualitie: new Qualitie(),
        dialogQualitie: false,
        listQualities: [],
        indexQualitie: -1,
        dataOther: new Other(),
        dialogOther: false,
        other: {},
        dataSpecialty: new Specialty(),
        dialogSpecialty: false,
        listSpecialties: [],
        indexSpecialty: -1,
        dataProfession: new Profession(),
        dialogProfession: false,
        listProfessions: [],
        indexProfession: -1,
        dataExperience: new Experience(),
        dialogExperience: false,
        listExperience: [],
        indexExperience: -1,
        dialogPdfPreview: false,
        srcPdf: '',
        titlePdf: '',
        isRequestCvPublication: false,
        processCv: {},
        isCorrectionOfObservations: false,
        isChangeState: false,
        dialogAccount: false,
        processPayment: {},
        titleImg: '',
        modalImg: false,
        srcImg: '',
        isServerImg: true,
        showFormPayment: false,
        dataProcessPayment: new ProcessPayments(),
        firstProcess: true,
        newRouteFile: null
    }),
    methods: {
        processError(error) {
            this.dialogLoaderOperation = false
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_action(error)
            this.dialogError = true
        },
        getProcedureSystem() {
            this.dialogLoaderOperation = true
            var sendCredentials = Object.assign({}, this.dataCredentials)
            sendCredentials.code = this.m_code_encript(this.dataCredentials.code)
            Person.getIntoPerson(sendCredentials).then(response => {
                this.linkAffiliate += response.code_qr
                this.dataPerson = response
                this.dialogLoaderOperation = false
                this.tab = 1
            }).catch(error => {
                this.processError(error)
            })
        },
        validateData() {
            if (!this.$refs.formcredentials.validate()) return false
            return true
        },
        ac_getProcedue() {
            if (this.validateData()) {
                this.getProcedureSystem()
            } else {
                this.smsError = [`Revise los campos marcados con rojo y corrija, porfavor`]
                this.dialogError = true
                this.actionError = true
            }
        },
        //generate PDF
        ac_generatePdf() {
            const smsAbis_part1 = `La asociación Boliviana de Ingeniería Sanitaria y Ambiental - Chuquisaca, certifica que el señor /a:`
            const smsAbis_part2 = `${this.dataPerson.full_name} ${this.dataPerson.last_name}`
            var distance_name = (((274-(smsAbis_part2.length*3.4))-100)/2)+100
            const issuedFormat = this.dataPerson.issued_in[0].toUpperCase()+''+this.dataPerson.issued_in[1]+'.'
            const smsAbis_part3 = `Con carnet de identidad N° ${this.dataPerson.ci} ${issuedFormat} es socio/a de ABIS - Chuqusica,`
            const smsAbis_part4 = `con el siguiente número de registro ABIS - Ch:`
            const smsAbis_part5 = `${this.dataPerson.identifier}`
            var distance_identifier = (((274-(smsAbis_part5.length*3.4))-100)/2)+100
            const smsAbis_part6 = `El certificado emitido, puede ser validado por medio del código QR.`
            const smsQr_part1 =  `Fecha de emisión     :`
            const smsQr_part2 =  `${this.m_date_systemDate(true)}`
            const smsQr_part3 =  `Fecha de expiración :`
            const smsQr_part4 =  `${this.m_date_formatDate(this.dataPerson.date_of_expiry)}`
            const photoFace = 'https://www.abischuquisaca.com/api/image?path=' + this.dataPerson.route_face
            const doc = new jsPDF('l','mm','letter');
            var node = document.getElementById("codeqr");
            domtoimage.toJpeg(node, { quality: 1 }).then(function (dataUrl) {
                var img = new Image();
                img.src = '/imgs/public/certificate/certificado_beta.jpg';
                doc.addImage(img, 'JPEG', 0, 0, 280,220);
                doc.setTextColor(0, 0, 0)
                doc.setFontSize(12)
                doc.text(smsQr_part1, 13, 164)
                doc.text(smsQr_part2, 68, 164)
                doc.text(smsQr_part3, 13, 170)
                doc.text(smsQr_part4, 68, 170)
                doc.setFontSize(16)
                doc.text(smsAbis_part2, distance_name, 110)
                doc.setFontSize(12)
                doc.text(smsAbis_part3, 108, 125)
                doc.text(smsAbis_part4, 108, 131)
                doc.setFontSize(16)
                doc.text(smsAbis_part5, distance_identifier, 143)
                doc.setFontSize(12)
                doc.text(smsAbis_part6, 108, 153)
                img.src = dataUrl;
                doc.addImage(img, 'JPEG', 163, 157, 45,45);
                img.src = '/imgs/public/certificate/fondoblanco.jpg';
                doc.addImage(img, 'JPEG', 163, 200, 60,20);
                doc.setFontSize(14)
                doc.text(smsAbis_part1, 13, 94)
                doc.addImage(photoFace, 'JPEG', 30, 112, 40,40);
                doc.save("certificado.pdf");
            }).catch(function (error) {
                console.error("Error de conexión: ", error);
            });
        },
        ac_withValidity() {
            if (this.dataPerson.date_of_expiry !== undefined) {
                if (this.dataPerson.expired === 0) return false
                return true
            }
            return false
        },
        smsErrorCode() {
            this.smsError = ['No se pudo identificar al afiliado',
                'Porfavor recargue la página e ingrese sus credenciales']
            this.actionError = false
            this.dialogError = true
        },
        ac_goCurriculunVitae() {
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            setTimeout(() => {
                this.dialogLoaderOperation = true
                var code = this.m_code_encript(this.dataCredentials.code)
                Person.getListCv(this.dataCredentials.ci, code).then(response => {
                    this.dialogLoaderOperation = false
                    if (response.exist !== 1) {
                        this.smsErrorCode()
                    } else {
                        this.listLanguajes = response.list_languajes
                        this.listQualities = response.list_qualities
                        this.other = response.other.length > 0 ? response.other[0] : new Other()
                        this.listSpecialties = response.list_specialties
                        this.listProfessions = response.list_professions
                        this.listExperience = response.list_experience
                        this.isRequestCvPublication = response.with_cv_process === 0 ? false : true
                        this.processCv = response.with_cv_process === 1 ? response.process_cv : {}
                        this.tab = 2
                    }
                }).catch(error => {
                    this.processError(error)
                })
            }, velocity)
        },
        showEvent(message, colorSms) {
            this.colorMessage = colorSms
            this.smsAction = message
            this.frameCorrect = true
            this.timeSucess = setTimeout(() => {
                this.frameCorrect = false
            }, 3000)
        },
        //curriculum vitae
        ac_closeDialogLanguaje() {
            this.indexLanguaje = -1
            this.dataLanguaje = new Languaje()
            this.$refs.containerformlanguaje.resetForm()
            this.dialogLanguaje = false
        },
        addLanguaje() {
            this.m_dataGeneral_frameTimeReset()
            this.dataLanguaje.code = this.m_code_encript(this.dataCredentials.code)
            this.dataLanguaje.ci = this.dataCredentials.ci
            Languaje.addLanguaje(this.dataLanguaje).then(response => {
                this.dialogLoaderOperation = false
                if (response.exist !== 1) {
                    this.smsErrorCode()
                } else {
                    this.listLanguajes.unshift(response.languaje)
                    this.ac_closeDialogLanguaje()
                    setTimeout(() => {
                        this.showEvent(['Operación exitosa', 'El idioma fue registrado'], '#0a813c')
                    }, 200)
                }
            }).catch(error => {
                this.processError(error)
            })
        },
        updateLanguaje() {
            this.m_dataGeneral_frameTimeReset()
            this.dataLanguaje.code = this.m_code_encript(this.dataCredentials.code)
            this.dataLanguaje.ci = this.dataCredentials.ci
            Languaje.updateLanguaje(this.dataLanguaje).then(response => {
                this.dialogLoaderOperation = false
                if (response.exist !== 1) {
                    this.smsErrorCode()
                } else {
                    Object.assign(this.listLanguajes[this.indexLanguaje], response.languaje)
                    this.ac_closeDialogLanguaje()
                    setTimeout(() => {
                        this.showEvent(['Operación exitosa', 'El idioma fue editado'], '#0a813c')
                    }, 200)
                }
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_confirmNewLanguaje(valid) {
            if (valid) {
                this.dialogLanguaje = false
                this.dialogLoaderOperation = true
                if (this.indexLanguaje === -1) this.addLanguaje()
                else this.updateLanguaje()
            } else {
                this.m_dataGeneral_frameTimeReset()
                setTimeout(() => {
                    this.showEvent(['Debe ingresar un idioma porfavor'], 'error')
                }, 200)
            }
        },
        ac_showFormEditLanguaje(languaje) {
            this.indexLanguaje = this.listLanguajes.indexOf(languaje)
            this.dataLanguaje = Object.assign({}, languaje)
            this.dialogLanguaje = true
        },
        //qualitie
        ac_closeDialogQualitie() {
            this.indexQualitie = -1
            this.dataQualitie = new Qualitie()
            this.$refs.containerformqualitie.resetForm()
            this.dialogQualitie = false
        },
        ac_showFormEditQualitie(qualitie) {
            this.indexQualitie = this.listQualities.indexOf(qualitie)
            this.dataQualitie = Object.assign({}, qualitie)
            this.dialogQualitie = true
        },
        addQualitie() {
            this.m_dataGeneral_frameTimeReset()
            this.dataQualitie.code = this.m_code_encript(this.dataCredentials.code)
            this.dataQualitie.ci = this.dataCredentials.ci
            Qualitie.addQualitie(this.dataQualitie).then(response => {
                this.dialogLoaderOperation = false
                if (response.exist !== 1) {
                    this.smsErrorCode()
                } else {
                    this.listQualities.unshift(response.qualitie)
                    this.ac_closeDialogQualitie()
                    setTimeout(() => {
                        this.showEvent(['Operación exitosa', 'La cualidad fue registrado'], '#0a813c')
                    }, 200)
                }
            }).catch(error => {
                this.processError(error)
            })
        },
        updateQualitie() {
            this.m_dataGeneral_frameTimeReset()
            this.dataQualitie.code = this.m_code_encript(this.dataCredentials.code)
            this.dataQualitie.ci = this.dataCredentials.ci
            Qualitie.updateQualitie(this.dataQualitie).then(response => {
                this.dialogLoaderOperation = false
                if (response.exist !== 1) {
                    this.smsErrorCode()
                } else {
                    Object.assign(this.listQualities[this.indexQualitie], response.qualitie)
                    this.ac_closeDialogQualitie()
                    setTimeout(() => {
                        this.showEvent(['Operación exitosa', 'La cualidad fue editada'], '#0a813c')
                    }, 200)
                }
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_confirmNewQualitie(valid) {
            if (valid) {
                this.dialogQualitie = false
                this.dialogLoaderOperation = true
                if (this.indexQualitie === -1) this.addQualitie()
                else this.updateQualitie()
            } else {
                this.m_dataGeneral_frameTimeReset()
                setTimeout(() => {
                    this.showEvent(['Debe ingresar una cualidad porfavor'], 'error')
                }, 200)
            }
        },
        //other
        ac_showFormOther() {
            this.dataOther = Object.assign({}, this.other)
            this.dialogOther = true
        },
        ac_closeOther() {
            this.dataOther = new Other()
            this.$refs.containerformother.resetForm()
            this.dialogOther = false
        },
        ac_confirmOther(valid) {
            if (valid) {
                this.m_dataGeneral_frameTimeReset()
                this.dataOther.code = this.m_code_encript(this.dataCredentials.code)
                this.dataOther.ci = this.dataCredentials.ci
                this.dataOther.operation = this.dataOther.id != undefined ? 1 : 0,
                this.dialogOther = false
                this.dialogLoaderOperation = true
                Other.updateOther(this.dataOther).then(response => {
                    this.dialogLoaderOperation = false
                    if (response.exist !== 1) { this.smsErrorCode() }
                    else {
                        this.other = response.other
                        this.ac_closeOther()
                        setTimeout(() => {
                            this.showEvent(['Operación exitosa', 'El perfil fue actualizado'], '#0a813c')
                        }, 200)
                    }
                }).catch(error => {
                    this.processError(error)
                })
            } else {
                this.m_dataGeneral_frameTimeReset()
                setTimeout(() => {
                    this.showEvent(['Debe ingresar la descripción de su persona'], 'error')
                }, 200)
            }
        },
        //specialty
        ac_closeDialogSpecialty() {
            this.indexSpecialty = -1
            this.dataSpecialty = new Specialty()
            this.$refs.containerformspecilty.resetForm()
            this.dialogSpecialty = false
        },
        ac_showFormSpecialty(specialty) {
            this.indexSpecialty = this.listSpecialties.indexOf(specialty)
            this.dataSpecialty = Object.assign({}, specialty)
            this.dataSpecialty.route_file = null
            this.dialogSpecialty = true
        },
        addSpeciality() {
            this.m_dataGeneral_frameTimeReset()
            this.dataSpecialty.code = this.m_code_encript(this.dataCredentials.code)
            this.dataSpecialty.ci = this.dataCredentials.ci
            this.dataSpecialty.name_file = new Date().getTime()
            Specialty.addSpecialty(this.$objectToFD(this.dataSpecialty)).then(response => {
                this.dialogLoaderOperation = false
                if (response.exist !== 1) {
                    this.smsErrorCode()
                } else {
                    this.listSpecialties.unshift(response.specialty)
                    this.ac_closeDialogSpecialty()
                    setTimeout(() => {
                        this.showEvent(['Operación exitosa', 'La especialidad fue registrada'], '#0a813c')
                    }, 200)
                }
            }).catch(error => {
                this.processError(error)
            })
        },
        updateSpecialty() {
            this.m_dataGeneral_frameTimeReset()
            this.dataSpecialty.code = this.m_code_encript(this.dataCredentials.code)
            this.dataSpecialty.ci = this.dataCredentials.ci
            if (this.dataSpecialty.route_file !== null) this.dataSpecialty.name_file = new Date().getTime()
            this.dataSpecialty.change_img = this.dataSpecialty.route_file !== null ? 1 : 0
            Specialty.updateSpecialty(this.$objectToFD(this.dataSpecialty)).then(response => {
                this.dialogLoaderOperation = false
                if (response.exist !== 1) {
                    this.smsErrorCode()
                } else {
                    Object.assign(this.listSpecialties[this.indexSpecialty], response.specialty)
                    this.ac_closeDialogSpecialty()
                    setTimeout(() => {
                        this.showEvent(['Operación exitosa', 'La especialidad fue editada'], '#0a813c')
                    }, 200)
                }
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_confirmSpecialty(valid) {
            if (valid) {
                this.dialogSpecialty = false
                this.dialogLoaderOperation = true
                if (this.indexSpecialty === -1) this.addSpeciality()
                else this.updateSpecialty()
            } else {
                this.m_dataGeneral_frameTimeReset()
                setTimeout(() => {
                    this.showEvent(['Corrija los datos marcados con rojo porfavor'], 'error')
                }, 200)
            }
        },
        //profession
        ac_closeDialogProfession() {
            this.indexProfession = -1
            this.dataProfession = new Profession()
            this.$refs.contianerformprofession.resetForm()
            this.dialogProfession = false
        },
        ac_showFormProfession(profession) {
            this.indexProfession = this.listProfessions.indexOf(profession)
            this.dataProfession = Object.assign({}, profession)
            this.dataProfession.route_file = null
            this.dialogProfession = true
        },
        addProfession() {
            this.m_dataGeneral_frameTimeReset()
            this.dataProfession.code = this.m_code_encript(this.dataCredentials.code)
            this.dataProfession.ci = this.dataCredentials.ci
            this.dataProfession.name_file = new Date().getTime()
            Profession.addProfession(this.$objectToFD(this.dataProfession)).then(response => {
                this.dialogLoaderOperation = false
                if (response.exist !== 1) {
                    this.smsErrorCode()
                } else {
                    this.listProfessions.unshift(response.profession)
                    this.ac_closeDialogProfession()
                    setTimeout(() => {
                        this.showEvent(['Operación exitosa', 'La profesión fue registrada'], '#0a813c')
                    }, 200)
                }
            }).catch(error => {
                this.processError(error)
            })
        },
        updateProfession() {
            this.m_dataGeneral_frameTimeReset()
            this.dataProfession.code = this.m_code_encript(this.dataCredentials.code)
            this.dataProfession.ci = this.dataCredentials.ci
            if (this.dataProfession.route_file !== null) this.dataProfession.name_file = new Date().getTime()
            this.dataProfession.change_img = this.dataProfession.route_file !== null ? 1 : 0
            Profession.updateProfession(this.$objectToFD(this.dataProfession)).then(response => {
                this.dialogLoaderOperation = false
                if (response.exist !== 1) {
                    this.smsErrorCode()
                } else {
                    Object.assign(this.listProfessions[this.indexProfession], response.profession)
                    this.ac_closeDialogProfession()
                    setTimeout(() => {
                        this.showEvent(['Operación exitosa', 'La profesión fue editada'], '#0a813c')
                    }, 200)
                }
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_confirmProfession(valid) {
            if (valid) {
                this.dialogProfession = false
                this.dialogLoaderOperation = true
                if (this.indexProfession === -1) this.addProfession()
                else this.updateProfession()
            } else {
                this.m_dataGeneral_frameTimeReset()
                setTimeout(() => {
                    this.showEvent(['Corrija los datos marcados con rojo porfavor'], 'error')
                }, 200)
            }
        },
        //experience
        ac_openFormExperience() {
            this.dataExperience.beginning = this.m_date_systemDate(false)
            this.dataExperience.final = this.m_date_systemDate(false)
            this.dialogExperience = true
        },
        ac_closeDialogExperience() {
            this.indexExperience = -1
            this.dataExperience = new Experience()
            this.$refs.containerformexperience.resetForm()
            this.$refs.containerformexperience.initialDates()
            this.dialogExperience = false
        },
        ac_showFormExperience(experience) {
            this.dialogExperience = true
            this.indexExperience = this.listExperience.indexOf(experience)
            this.dataExperience = Object.assign({}, experience)
            this.dataExperience.route_file = null
            setTimeout(() => {
                this.$refs.containerformexperience.chargueValuesDate()
            }, 200)
        },
        addExperience() {
            this.m_dataGeneral_frameTimeReset()
            this.dataExperience.code = this.m_code_encript(this.dataCredentials.code)
            this.dataExperience.ci = this.dataCredentials.ci
            this.dataExperience.name_file = new Date().getTime()
            Experience.addExperience(this.$objectToFD(this.dataExperience)).then(response => {
                this.dialogLoaderOperation = false
                if (response.exist !== 1) {
                    this.smsErrorCode()
                } else {
                    this.listExperience.unshift(response.experience)
                    this.ac_closeDialogExperience()
                    setTimeout(() => {
                        this.showEvent(['Operación exitosa', 'Su experiencia laboral fue registrada'], '#0a813c')
                    }, 200)
                }
            }).catch(error => {
                this.processError(error)
            })
        },
        updateExperience() {
            this.m_dataGeneral_frameTimeReset()
            this.dataExperience.code = this.m_code_encript(this.dataCredentials.code)
            this.dataExperience.ci = this.dataCredentials.ci
            if (this.dataExperience.route_file !== null) this.dataExperience.name_file = new Date().getTime()
            this.dataExperience.change_img = this.dataExperience.route_file !== null ? 1 : 0
            Experience.updateExperience(this.$objectToFD(this.dataExperience)).then(response => {
                this.dialogLoaderOperation = false
                if (response.exist !== 1) {
                    this.smsErrorCode()
                } else {
                    Object.assign(this.listExperience[this.indexExperience], response.experience)
                    this.ac_closeDialogExperience()
                    setTimeout(() => {
                        this.showEvent(['Operación exitosa', 'Su experiencia laboral fue editada'], '#0a813c')
                    }, 200)
                }
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_confirmExperience(valid) {
            if (valid) {
                this.dialogExperience = false
                this.dialogLoaderOperation = true
                if (this.indexExperience === -1) this.addExperience()
                else this.updateExperience()
            } else {
                this.m_dataGeneral_frameTimeReset()
                setTimeout(() => {
                    this.showEvent(['Corrija los datos marcados con rojo porfavor',
                        'Revise que la fechas esten en un rango aceptable'], 'error')
                }, 200)
            }
        },
        //new metodhs
        ac_errorFilePdf() {
            this.m_dataGeneral_frameTimeReset()
            setTimeout(() => {
                this.showEvent(['El archivo PDF no debe',
                    'exceder los 2Mb'], 'error')
            }, 200)
        },
        ac_showPdf(data) {
            this.titlePdf = data.title
            if (this.mobile) {
                Person.showPdfMobile(data.pdf)
            } else {
                this.srcPdf = ''
                this.srcPdf = Person.showPdfPc(data.pdf)
                setTimeout(() => {
                    this.dialogPdfPreview = true
                }, 200)
            }
        },
        //publicacionts
        validListPdfs() {
            for(var i = 0; i < this.listProfessions.length; i++) {
                if (this.listProfessions[i].pdf === null) return false
            }
            for(var j = 0; j < this.listSpecialties.length; j++) {
                if (this.listSpecialties[j].pdf === null) return false
            }
            for(var k = 0; k < this.listExperience.length; k++) {
                if (this.listExperience[k].pdf === null) return false
            }
            return true
        },
        ac_confirmPublicationCv() {
            this.actionError = true
            if (this.listQualities.length > 0 && this.listProfessions.length > 0 &&
                this.other.profile !== null) {
                if (this.validListPdfs()) {
                    this.smsConfirm = ['¿Está seguro de solicitar la publicación de su CV?']
                    this.dialogConfirm = true
                } else {
                    this.smsError = ['Respalde con documento Pdf, todas los datos de Profesiones, Especialidades y Experiencia L.']
                    this.dialogError = true
                }
            } else {
                this.smsError = ['Las categorías obligatorias deben contener información']
                this.dialogError = true
            }
        },
        requestCvPublication(credentials) {
            ProcessCv.requestCvPublication(credentials).then(response => {
                if (response.exist !== 1) {
                    this.smsErrorCode()
                } else {
                    this.processCv = response.process_cv
                    this.isRequestCvPublication = true
                    this.dialogLoaderOperation = false
                    this.showEvent(['Operación exitosa',
                        'Se envió su CV'], 'success')
                }
            }).catch(error => {
                this.processError(error)
            })
        },
        sendObservations(credentials) {
            ProcessCv.sendObservations(credentials).then(response => {
                if (response.exist !== 1) {
                    this.smsErrorCode()
                } else {
                    this.processCv = response.process_cv
                    this.isCorrectionOfObservations = false
                    this.dialogLoaderOperation = false
                    this.showEvent(['Operación exitosa',
                        'Se envió sus correcciones'], 'success')
                }
            }).catch(error => {
                this.processError(error)
            })
        },
        changeStatePublication(credentials) {
            credentials.state = this.dataPerson.user_public === 0 ? 1 : 0
            ProcessCv.changeStateUser(credentials).then(response => {
                if (response.exist !== 1) {
                    this.smsErrorCode()
                } else {
                    const newState = this.dataPerson.user_public === 0 ? 1 : 0
                    this.dataPerson.user_public = newState
                    this.dialogLoaderOperation = false
                    this.isChangeState = false
                    this.showEvent(['Operación exitosa',
                            'Se cambió el estado de su CV'], 'success')
                }
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_perfomOperation() {
            this.dialogConfirm = false
            this.dialogLoaderOperation = true
            const credentials = {
                id: this.processCv.id != undefined ? this.processCv.id : 0,
                code: this.m_code_encript(this.dataCredentials.code),
                ci: this.dataCredentials.ci
            }
            if (this.tab === 3) {
                if (!this.firstProcess) this.updateProcessPayment()
                else this.addProcessPayment()
            } else {
                if (this.isChangeState) this.changeStatePublication(credentials)
                else {
                    if (this.isCorrectionOfObservations) this.sendObservations(credentials)
                    else this.requestCvPublication(credentials)
                }
            }
        },
        noRequestOfPublications() {
            if (this.dataPerson.admin_public === 1) return 0
            if (this.isRequestCvPublication) {
                if (this.processCv.received === 's') return 0
                return 1
            }
            return 2
        },
        ac_validateObservations() {
            if (this.dataPerson.admin_public === 1) return false
            if (!this.isRequestCvPublication) return false
            if (this.processCv.state_process === 'o' && this.processCv.received === 'r') return true
            return false
        },
        ac_closeDialogConfirm() {
            this.isChangeState = false
            this.dialogConfirm = false
            this.isCorrectionOfObservations = false
        },
        ac_confirmObservations() {
            this.isCorrectionOfObservations = true
            this.smsConfirm = ['¿Está seguro de enviar las correciones a su CV?']
            this.dialogConfirm = true
        },
        ac_changeStatePublications() {
            const newState = this.dataPerson.user_public === 0 ? 'publicar' : 'dejar de publicar'
            this.isChangeState = true
            this.smsConfirm = [`${this.dataPerson.full_name}, está seguro(a) de`,
                `${newState} su CV?`]
            this.dialogConfirm = true
        },
        seeStatementOfAccounts() {
            this.dialogAccount = true
        },
        ac_getTitleTab() {
            if (this.tab === 0) return 'INGRESAR'
            if (this.tab === 1) return 'INFORMACIÓN'
            if (this.tab === 2) return 'CURRICULUM VITAE'
            return 'FORMULARIO DE PAGO'
        },
        updateProcessPayment() {
            const parameters = {
                code: this.m_code_encript(this.dataCredentials.code),
                ci: this.dataCredentials.ci,
                name_file: new Date().getTime(),
                new_file: this.newRouteFile,
                id: this.dataProcessPayment.id,
                reference: this.dataProcessPayment.reference
            }
            ProcessPayments.sendCorrection(this.$objectToFD(parameters)).then(response => {
                if (response.exist !== 1) {
                    this.smsErrorCode()
                } else {
                    this.processPayment = response.process_payment
                    this.firstProcess = true
                    this.dialogLoaderOperation = false
                    this.showEvent(['Operación exitosa', 'se envió la correción'], 'success')
                    this.dataProcessPayment = new ProcessPayments()
                    this.showFormPayment = false
                }
            }).catch(error => {
                this.processError(error)
            })

        },
        addProcessPayment() {
            const parameters = {
                code: this.m_code_encript(this.dataCredentials.code),
                ci: this.dataCredentials.ci,
                name_file: new Date().getTime(),
                route_file: this.dataProcessPayment.route_file,
                reference: this.dataProcessPayment.reference
            }
            ProcessPayments.add(this.$objectToFD(parameters)).then(response => {
                if (response.exist !== 1) {
                    this.smsErrorCode()
                } else {
                    this.processPayment = response.process_payment
                    this.dialogLoaderOperation = false
                    this.showEvent(['Operación exitosa', 'se envió la solicitud'], 'success')
                    this.dataProcessPayment = new ProcessPayments()
                    this.showFormPayment = false
                }
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_showImgBif() {
            this.isServerImg = true
            this.srcImg = this.processPayment.route_file
            this.titleImg = 'Comprobante de pago'
            this.modalImg = true
        },
        ac_sendDeposit() {
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            setTimeout(() => {
                this.dialogLoaderOperation = true
                const parameters = {
                    code: this.m_code_encript(this.dataCredentials.code),
                    ci: this.dataCredentials.ci
                }
                ProcessPayments.getStateAccount(parameters).then(response => {
                    if (response.exist !== 1) {
                        this.smsErrorCode()
                    } else {
                        this.dialogLoaderOperation = false
                        this.processPayment = response.process_payment
                        this.tab = 3
                    }
                }).catch(error => {
                    this.processError(error)
                })
            }, velocity)
        },
        ac_showErrorFile(error) {
            this.m_dataGeneral_frameTimeReset()
            setTimeout(() => {
                this.showEvent(error, 'error')
            }, 200)
        },
        generateBigViewServer(route_file) {
            const dataRoute = route_file.split(".")
            if (dataRoute[1] != 'pdf') {
                this.isServerImg = true
                this.srcImg = route_file
                this.modalImg = true
            } else {
                if (this.mobile) {
                    Person.showPdfMobile(route_file)
                } else {
                    this.titlePdf = 'Comprobante de Pago'
                    this.srcPdf = ''
                    this.srcPdf = Person.showPdfPc(route_file)
                    setTimeout(() => {
                        this.dialogPdfPreview = true
                    }, 200)
                }
            }
        },
        generateBigViewLocal(new_file) {
            this.isServerImg = false
            var file = new_file
            this.titleImg = 'Comprobante de Pago'
            let reader = new FileReader()
            reader.onload = (file) =>{
                if (new_file.type == 'application/pdf') {
                    if (this.mobile) {
                        this.showEvent(['La vista previa del PDF, solo es', 'para computadora'], 'error')
                    } else {
                        this.titlePdf = 'Comprobante de Pago'
                        this.srcPdf = file.target.result
                        this.dialogPdfPreview = true
                    }
                } else {
                    this.srcImg = file.target.result
                    this.modalImg = true
                }
            }
            reader.readAsDataURL(file)
        },
        ac_showPaymentServer() {
            this.generateBigViewServer(this.processPayment.route_file)
        },
        ac_showBigFile() {
            if (this.firstProcess) {
                this.generateBigViewLocal(this.dataProcessPayment.route_file)
            } else {
                if (this.newRouteFile !== null)
                    this.generateBigViewLocal(this.newRouteFile)
                else this.generateBigViewServer(this.dataProcessPayment.route_file)
            }
        },
        ac_confirmSendPayement(valid) {
            if (!valid) {
                this.ac_showErrorFile(['Debe introducir el archivo de respaldo porfavor'])
            } else {
                this.smsConfirm = ['¿Está seguro de enviar el comprobandte de pago?']
                var velocity = this.m_scroll_velocity(window.scrollY)
                this.m_scroll_animation(velocity)
                setTimeout(() => {
                    this.dialogConfirm = true
                }, velocity)
            }
        },
        ac_validBtnActionForm() {
            if (this.processPayment.state_process != undefined) { return false }
            return true
        },
        ac_validShowPayment() {
            if (this.processPayment.state_process != undefined) return true
            return false
        },
        ac_backAffiliate() {
            this.firstProcess = true
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            setTimeout(() => {
                this.showFormPayment = false
                this.dataProcessPayment = new ProcessPayments()
                this.tab = 1
            }, velocity)
        },
        ac_showFormUpdate() {
            this.firstProcess = false
            this.dataProcessPayment = Object.assign({}, this.processPayment)
            this.showFormPayment = true
        },
        ac_chargueNewFile(file) {
            this.newRouteFile = file
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.mobile = true
    }
}
</script>