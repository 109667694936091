<template>
    <div>
        <div class="d-flex justify-center" v-if="publication.title!=undefined">
            <div class="publication__container" v-resize="onResize">
                <div v-if="publication.title!=undefined">
                    <h1 class="text-body-big publication__padding-left">{{publication.title.toUpperCase()}}</h1>
                    <v-divider></v-divider>
                    <div class="publication__text-subtitle publication__padding-left">
                        <h1 class="text-body-subtitle">Publicación: {{publication.alone_date}}</h1>
                        <div class="publication__text-subtitle-facebook">
                            <MBtnNormalComp
                                :depressed="true"
                                color="#38529A"
                                :dark="true"
                                @click="ac_shareFacebook()"
                            >
                            <v-icon small class="mr-1">mdi-facebook</v-icon>
                            COMPARTIR FACEBOOK</MBtnNormalComp>
                        </div>
                        <div class="publication__text-subtitle-facebook">
                            <MBtnNormalComp
                                :depressed="true"
                                color="#48C456"
                                :dark="true"
                                @click="ac_shareWhatsApp()"
                            >
                            <v-icon small class="mr-1">mdi-whatsapp</v-icon>
                            COMPARTIR WHATSAPP</MBtnNormalComp>
                        </div>
                    </div>
                    <div :style="ac_getDimensionsImg">
                        <v-img
                            :src="publication.route_img|imgNormal"
                            width="100%"
                        ></v-img>
                    </div>
                    <div class="just-line-break mt-3 publication__padding-left">
                        <h1 class="text-body-private-title">
                        {{publication.subtitle}}</h1>
                        <v-divider class="mt-1 mb-1"></v-divider>
                        <h1 class="text-body-private-subtitle">
                        {{publication.description}}</h1>
                    </div>
                </div>
                <div class="publication__social-media-mobile">
                    <MBtnNormalComp
                        :depressed="true"
                        color="#38529A"
                        class="mr-2"
                        :dark="true"
                        @click="ac_shareFacebook()"
                    >
                    <v-icon small class="mr-1">mdi-facebook</v-icon>
                    COMPARTIR F.</MBtnNormalComp>
                    <MBtnNormalComp
                        :depressed="true"
                        color="#48C456"
                        :dark="true"
                        @click="ac_shareWhatsApp()"
                    >
                    <v-icon small class="mr-1">mdi-whatsapp</v-icon>
                    COMPARTIR W.</MBtnNormalComp>
                </div>
                <div class="publication__padding-left">
                    <h1 class="text-body-supertitle space-top-two">OTRAS NOTICIAS</h1>
                    <div class="home__third-ornament-line mb-2 mt-2"></div>
                </div>
                <div class="publication__row-recommendation space-bottom">
                    <div
                        v-for="(publicity, index) in listRecommendation"
                        :key="index"
                        class="publication__recommendation"
                        @click="ac_changePublication(publicity)"
                    >
                        <div class="publication__recommendation-info" v-if="mobile">
                            <h1 class="text-body-private-title">{{publicity.title}}</h1>
                            <h1 class="text-body-private-subtitle mb-1">{{publicity.subtitle}}</h1>
                        </div>
                        <div class="publication__recommendation-img">
                            <v-img
                                :src="publicity.route_img|imgMiniature"
                                width="100%"
                                height="100%"
                            ></v-img>
                        </div>
                        <div class="publication__recommendation-info">
                            <h1 class="text-body-private-title mt-1" v-if="!mobile">{{publicity.title}}</h1>
                            <h1 class="text-body-private-subtitle" v-if="!mobile">{{publicity.subtitle}}</h1>
                            <v-divider v-if="!mobile"></v-divider>
                            <h1 class="text-body-private-subtitle mt-1">
                                {{ac_getDescription(publicity.description)}}</h1>
                            <MBtnNormalComp
                                v-if="mobile"
                                :depressed="true"
                                color="primary"
                                class="mt-1"
                                :dark="true"
                                :outlined="true"
                                @click="ac_changePublicationMobile(publicity)"
                            >
                            VER NOTICIA</MBtnNormalComp>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else style="min-height:600px"></div>
        <OLoaderOperationComp
            :dialog="dialogOperation"
            smsLoaderOperation="Obteniendo noticia"
        />
    </div>
</template>
<script>
import { MBtnNormalComp } from '../../components/molecules'
import { OLoaderOperationComp } from '../../components/organisms'
import { Publicity } from '../../models'
import { imgNormal, imgMiniature } from '../../filters'
import { scrollMixin } from '../../mixin'
export default {
    mixins: [ scrollMixin ],
    filters: { imgNormal, imgMiniature },
    components: {
        MBtnNormalComp,
        OLoaderOperationComp
    },
    metaInfo() {
        const title = this.titlePage
        return {
            title: title
        }
    },
    watch: { 
        '$route.params.id': {
            handler: function(id) {
                this.dialogOperation = true
                this.publication = {}
                this.listRecommendation = []
                this.isInitialWidth = true
                setTimeout(() => {
                    this.getRequest(id, 6)
                }, 150)
            },
            deep: true,
            immediate: true
        }
    },
    data: () => ({
        dialogOperation: false,
        title: 'ABIS Chuquisca',
        publication: {},
        existsParameters: false,
        listRecommendation: [],
        isInitialWidth: true,
        initialWidth: window.innerWidth,
        mobile: false
    }),
    computed: {
        titlePage: function() {
            if (this.publication.title === undefined) return this.title
            else return this.publication.title
        },
        ac_getDimensionsImg: function() {
            if (this.isInitialWidth) {
                if (window.innerWidth <= 1180) {
                    const newHeight = parseInt((window.innerWidth * this.publication.height) / this.publication.width)
                    return `width:100%; height:${newHeight}px;`
                } else {
                    const newHeight = parseInt((1180 * this.publication.height) / this.publication.width)
                    return `width:100%; height:${newHeight}px;`
                }
            } else return 'width:100%; height:auto;'
        }
    },
    methods: {
        onResize () {
            if (this.initialWidth !== window.innerWidth)
                this.isInitialWidth = false
        },
        getRequest(id, quantity) {
            const publication = Publicity.getPublication(id)
            const listRecommendations = Publicity.getRecommendations(id, quantity)
            Promise.all([publication, listRecommendations]).then(responses => {
                if (responses[0].exist === 0) this.$router.replace({name: 'home'})
                else {
                    this.titlaPage = responses[0].publication.title
                    this.publication = responses[0].publication
                }
                this.listRecommendation = responses[1]
                this.dialogOperation = false
            }).catch(error => {
                console.log(error)
            })
        },
        ac_shareFacebook() {
            //window.open(`http://ingbeimar.com/api/publicity/open-graps/${this.publication.id}`, '', "width=600,height=600")
            window.open(`https://abischuquisaca.com/api/publicity/send-open-graps?id=${this.publication.id}`, '', "width=600,height=600")
        },
        /*ac_shareFacebookDos() {
            window.open(`http://ingbeimar.com/api/publicity/send-open-graps?id=${this.publication.id}&active=1`, '', "width=600,height=600")
        },*/
        ac_shareWhatsApp() {
            const linkLocal = `https%3A%2F%2Fwww.abischuquisaca.com%2Fpublication%2F${this.publication.id}`
            var title = this.publication.title.toUpperCase()
            const titleFinal = title.replace(/\s+/g, "+")
            const smsWhatsApp = `https://api.whatsapp.com/send?text=${titleFinal}%0A${linkLocal}`
            window.open(smsWhatsApp)
        },
        ac_getDescription(description) {
            if (description === null) return ''
            else {
                if (description.length <= 70) return description
                else {
                    var newDescription = ''
                    for (var i=0; i<70; i++) {
                        newDescription += description[i]   
                    }
                    return newDescription+'...'
                }
            }
        },
        ac_changePublication(publication) {
            if (!this.mobile) {
                var velocity = this.m_scroll_velocity(window.scrollY)
                this.m_scroll_animation(velocity)
                setTimeout(() => {
                    this.$router.replace({name: 'publication', params: publication})
                }, velocity)
            }
        },
        ac_changePublicationMobile(publication) {
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            setTimeout(() => {
                this.$router.replace({name: 'publication', params: publication})
            }, velocity)
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.mobile = true
        if (this.$route.params.id === undefined) this.$router.replace({name: 'home'})
    }
}
</script>