<template>
    <v-dialog
        :value="dialog"
        :width="ac_getWidthImg()"
        persistent
        style="position:relative"
    >
        <div class="gallery__carrusel">
            <div class="gallery__carrusel__btn-close">
                <v-btn
                    x-small
                    color="white"
                    fab
                    depressed
                    :ripple="false"
                    @click="$emit('clickclose')"
                >
                    <v-icon color="black">mdi-close</v-icon>
                </v-btn>
            </div>
            <div style="height:100%;">
                <div class="gallery__title d-flex align-center">
                    <h1 class="text-body-private-title">{{title}}</h1>
                </div>
                <v-img
                    v-if="isServer"
                    :src="src|imgNormal"
                    height="90%"
                    contain
                ></v-img>
                <v-img
                    v-else
                    :src="src"
                    contain
                    height="90%"
                ></v-img>
            </div>
        </div>
    </v-dialog>
</template>
<script>
import { imgNormal } from '../../filters'
export default {
    filters: { imgNormal },
    model: {
        prop: 'dialog',
        event: 'click'
    },
    props: {
        src: String,
        dialog: Boolean,
        title: String,
        isServer: Boolean
    },
    methods: {
        ac_getWidthImg() {
            if (window.innerWidth <= 1400) return '1000'
            else return '1200'
        }
    }
}
</script>
