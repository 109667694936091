<template>
    <div class="pb-4">
        <div class="about__background">
            <v-img
                src="/imgs/public/home/fondo_2.jpg"
                gradient="to top right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.3)"
                width="100%"
                height="100%"
            >
                <OCardAnimationLogoComp />
            </v-img>
        </div>
        <div class="d-flex justify-center space-top-two">
            <div class="pt-2">
                <h1 class="text-body-big text-center">DOCUMENTOS</h1>
                <v-divider class="mb-1"></v-divider>
                <h1 style="font-size:14px" class="text-body-subtitle text-center">Trabajamos como una sola unidad de Ingenieros Chuquisaca</h1>
            </div>
        </div>
        <div class="d-flex justify-center mt-3">
            <div class="home__first">
                <OLoaderDataComp
                    class="mt-2"
                    v-if="dialogLoaderData"
                    smsLoaderData="Obteniendo Documentos"
                />
                <MNoDataComp
                    v-if="!dialogLoaderData && listDocuments.length==0"
                    smsNotData="SIN DOCUMENTOS"
                    class="ma-2"
                />
                <div class="d-flex justify-center flex-wrap">
                    <div
                        v-for="(document, index) in listDocuments"
                        :key="index"
                        class="documents__fill animation__card--opacity"
                    >
                        <div class="documents__fill-info">
                            <h1 class="text-body-private-title">{{document.title}}</h1>
                            <h1 class="text-body-private-subtitle">{{document.description}}</h1>
                        </div>
                        <v-spacer></v-spacer>
                        <div class="d-flex documents__fill-options" v-if="document.link != null">
                            <MBtnNormalComp
                                :outlined="true"
                                color="blue"
                                class="mr-2"
                                @click="ac_openPdfLink(document)"
                            >ABRIR</MBtnNormalComp>
                        </div>
                        <div class="d-flex documents__fill-options" v-else>
                            <MBtnNormalComp
                                :outlined="true"
                                color="blue"
                                class="mr-2"
                                @click="ac_showPdf(document)"
                            >VER</MBtnNormalComp>
                            <MBtnNormalComp
                                :outlined="true"
                                color="red"
                                @click="ac_downloadPdf(document)"
                            >DESCARGAR</MBtnNormalComp>
                        </div>
                    </div>
                </div>
                <SPaginationComp
                    class="mt-2"
                    v-if="!dialogLoaderData && listDocuments.length>0"
                    :condicionGo="page < last_page"
                    :condicionBack="page > 1"
                    :titlePagination="'Pag. '+page+' de '+last_page"
                    @clickback="ac_changePage(false)"
                    @clickgo="ac_changePage(true)"
                />
            </div>
        </div>
        <OModalPdfComp
            :dialog="dialogPdfPreview"
            :src="srcPdf"
            :title="titlePdf"
            @clickclose="dialogPdfPreview=false"
        />
    </div>
</template>
<script>
import { Document, Person } from '../../models'
import {
    MNoDataComp,
    MBtnNormalComp
} from '../../components/molecules'
import {
    OCardAnimationLogoComp,
    OLoaderDataComp,
    OModalPdfComp
} from '../../components/organisms'
import {
    SPaginationComp
} from '../../components/species'
import {
    scrollMixin
} from '../../mixin'
export default {
    metaInfo() {
        const title = `ABIS Ch. - Documentos`
        return {
            title: title
        }
    },
    mixins: [ scrollMixin ],
    components: {
        MNoDataComp,
        OCardAnimationLogoComp,
        MBtnNormalComp,
        OLoaderDataComp,
        OModalPdfComp,
        SPaginationComp
    },
    data: () => ({
        listDocuments: [],
        page: 0,
        last_page: 0,
        total: 0,
        dialogLoaderData: false,
        mobile: false,
        dialogPdfPreview: false,
        srcPdf: '',
        titlePdf: ''
    }),
    methods: {
        getDocuments(page) {
            this.dialogLoaderData = true
            Document.getListDocumentsPublic(page).then(response => {
                this.listDocuments = response.data
                this.page = response.page
                this.last_page = response.last_page
                this.total = response.total
                this.dialogLoaderData = false
            }).catch(error => {
                console.log(error)
            })
        },
        ac_showPdf(document) {
            this.titlePdf = document.title
            if (this.mobile) {
                Person.showPdfMobile(document.route_file)
            } else {
                this.srcPdf = ''
                this.srcPdf = Person.showPdfPc(document.route_file)
                setTimeout(() => {
                    this.dialogPdfPreview = true
                }, 200)
            }
        },
        ac_downloadPdf(document) {
            Person.downloadImage(document.route_file)
        },
        ac_changePage(state) {
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            if (state) { ++this.page }
            else { --this.page }
            setTimeout(() => {
                this.getDocuments(this.page)
            }, velocity)
        },
        ac_openPdfLink(document) {
            window.open(document.link)
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.mobile = true
        this.getDocuments(1)
    }
}
</script>