<template>
    <v-tooltip bottom color="#062242">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                style="border-radius:50px"
                height="32"
                width="32"
                max-width="32"
                min-width="32"
                v-bind="attrs"
                v-on="on"
                :ripple="false"
                x-small
                :color="color"
                depressed
                :disabled="disabled"
                @click="$emit('click')"
            >
                <slot></slot>
            </v-btn>
        </template>
        <div class="d-flex align-center">
            <v-icon class="mr-1" color="white">mdi-hand-pointing-right</v-icon>
            <h1 class="text-body-subtitle-inverse">{{smsTooltip}}</h1>
        </div>
    </v-tooltip>
</template>
<script>
export default {
    props: {
        smsTooltip: String,
        color: String,
        disabled: Boolean
    }
}
</script>