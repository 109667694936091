<template>
    <div class="o-pdf-input">
        <h1 class="text-body-private-small">{{titleFile}}</h1>
        <div
            :class="errorPdf ? 'o-pdf-input__file o-pdf-input__error' : 'o-pdf-input__file o-pdf-input__correct'"
            @click='$refs.inputpdf.click()'
        >
            <div v-if="validPdf" class="d-flex justify-center align-center">
                <h1 style="color:#60c560" class="text-body-private-title">ARCHIVO CARGADO</h1>
                <v-icon class="ml-1" color="#60c560" large>mdi-checkbox-marked-circle</v-icon>
            </div>
            <div v-else>
                <h1 class="text-body-private-title text-center">{{subtitleFile}}</h1>
                <h1 class="text-body-private-small text-center">(Máximo: 2Mb)</h1>
            </div>
            <input type="file"
                style="display: none"
                ref="inputpdf"
                accept="application/pdf"
                @change="ac_onFilePicked"
            >
        </div>
    </div>
</template>
<script>
export default {
    props: {
        titleFile: String,
        errorPdf: Boolean,
        subtitleFile: String,
        validPdf: Boolean
    },
    methods: {
        ac_onFilePicked(e) {
            const files = e.target.files
            if (files[0] !== undefined) {
                let file= e.target.files[0]
                if (file.size <= 2097152) {//2097152
                    this.$emit('onChangePdf', {'file': e.target.files[0], 'is_big': false})
                } else {
                    this.$emit('onChangePdf', {'file': null, 'is_big': true})
                }
            } else {
                this.$emit('onChangePdf', {'file': null, 'is_big': false})
            }
        }
    }
}
</script>