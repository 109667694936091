<template>
    <v-dialog
            :value="dialog"
            persistent
            width="360"
        >
            <v-card color="#FAFAFA">
                <div class="d-flex justify-center primary mb-1 pt-1 pb-1">
                    <h1 class="text-body-private-title-inverse">ABIS Chuquisaca</h1>
                </div>
                <div class="d-flex justify-center">
                    <IconOperationLoader />
                </div>
                <h1 class="text-body-subtitle text-center pb-2">
                    {{smsLoaderOperation}}
                </h1>
            </v-card>
    </v-dialog>
</template>
<script>
import { IconOperationLoader } from '../../iconsSvg'
export default {
    components: {
        IconOperationLoader
    },
    props: {
        dialog: {
            type: Boolean,
            default: false
        },
        smsLoaderOperation: String
    }
}
</script>
