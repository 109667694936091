<template>
    <div class="card-person card-person--big-size d-flex animation__card--opacity">
        <div class="mr-2">
            <div class="card-person__photo">
                <v-img
                    :src="person.route_face|imgMiniature"
                    width="100%"
                    height="100%"
                    style="border-radius:5px"
                    contain
                ></v-img>
            </div>
            <div
                class="d-flex justify-center flex-wrap mt-1"
                style="width:110px"
                v-if="person.admin_public==1"
            >
                <OBtnOptionToolTipComp
                    icon="mdi-clipboard-text-outline"
                    colorBtn="#6b99cac7"
                    style="margin:2px"
                    :smsTooltip="'Ver CV de: '+person.full_name"
                    @click="$emit('clickcv')"
                />
            </div>
        </div>
        <div>
            <div class="d-flex">
                <div>
                    <v-icon v-if="person.gender=='h'" color="blue" class="mr-1">mdi-face-man</v-icon>
                    <v-icon v-if="person.gender=='m'" color="pink" class="mr-1">mdi-face-woman</v-icon>
                    <v-icon v-if="person.gender=='o'" color="purple" class="mr-1">mdi-face-man-shimmer</v-icon>
                </div>
                <text-highlight
                    class="text-body-private-title"
                    :queries="searchPerson"
                >
                    {{person.full_name+''+person.last_name}}
                </text-highlight>
                <!--<h1 class="text-body-private-title">{{person.full_name}}</h1>-->
            </div>
            <v-divider></v-divider>
            <div class="d-flex">
                <h1 class="text-body-private-title mr-1">CI:</h1>
                <text-highlight
                    class="text-body-private-subtitle"
                    :queries="searchPerson"
                >
                    {{person.ci}}
                </text-highlight>
                <h1 class="text-body-private-subtitle ml-1">{{person.issued_in}}</h1>
            </div>
            <div class="d-flex">
                <h1 class="text-body-private-title mr-1">Categoría:</h1>
                <h1 class="text-body-private-subtitle" v-if="person.category=='A'">Activo</h1>
                <h1 class="text-body-private-subtitle" v-if="person.category=='B'">Transeunte</h1>
                <h1 class="text-body-private-subtitle" v-if="person.category=='C'">Adherente</h1>
                <h1 class="text-body-private-subtitle" v-if="person.category=='U'">Universitario</h1>
            </div>
            <div class="d-flex">
                <h1 class="text-body-private-title mr-1">N° Reg. ABIS Ch.:</h1>
                <h1 class="text-body-private-subtitle">{{person.identifier}}</h1>
            </div>
            <div class="d-flex align-center">
                <h1 class="text-body-private-title mr-1">Con CV:</h1>
                <h1 class="text-body-private-subtitle mr-1">
                    {{person.admin_public==1 ? 'SI' : 'NO'}}</h1>
                <div :class="ac_validateDate()"></div>
            </div>
        </div>
    </div>
</template>
<script>
import { imgMiniature } from '../../filters'
import TextHighlight from 'vue-text-highlight'
import {
    OBtnOptionToolTipComp
} from '../organisms'
export default {
    components: {
        OBtnOptionToolTipComp,
        TextHighlight
    },
    filters: {
        imgMiniature
    },
    props: {
        person: {},
        searchPerson: String
    },
    methods: {
        ac_validateDate() {
            if (this.person.admin_public === 1)
                return 'card-person__ornament card-person__ornament-correct'
            else return 'card-person__ornament card-person__ornament-error' 
        }
    }
}
</script>