import md5 from 'js-md5'
export default {
    methods: {
        m_code_encript(code) {
            var codeEncript = md5(code)
            var subcadena = codeEncript.substring(0,5);
            var leftoverLetters = codeEncript.substring(5, codeEncript.length)
            var codedLetters = `${subcadena[0]}${code[0]}${subcadena[1]}${code[1]}${subcadena[2]}${code[2]}${subcadena[3]}${code[3]}${subcadena[4]}${leftoverLetters}`
            return codedLetters
        }
    }
}
