<template>
    <div class="pb-2 animation-opacity">
        <div class="d-flex justify-center align-center">
            <h1 class="text-body-subtitle mr-2">{{titlePagination}}</h1>
            <div class="mr-1">
                <OBtnCircleComp
                        :disabled="!condicionBack"
                        color="primary"
                        smsTooltip="Página Anterior"
                        @click="$emit('clickback')">
                        <v-icon color="white" small>mdi-chevron-left</v-icon>
                </OBtnCircleComp>
            </div>
            <OBtnCircleComp
                    :disabled="!condicionGo"
                    color="primary"
                    smsTooltip="Página Siguiente"
                    @click="$emit('clickgo')">
                    <v-icon color="white" small>mdi-chevron-right</v-icon>
            </OBtnCircleComp>
        </div>
    </div>
</template>
<script>
import { OBtnCircleComp } from '../organisms'
export default {
    components: {
        OBtnCircleComp
    },
    props: {
        titlePagination: String,
        condicionBack: Boolean,
        condicionGo: Boolean
    }
}
</script>
