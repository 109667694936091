<template>
    <div class="pb-4">
        <div class="about__background">
            <v-img
                :src="srcBackground"
                gradient="to top right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)"
                width="100%"
                height="100%"
            >
                <OCardAnimationLogoComp />
            </v-img>
        </div>
        <div class="d-flex justify-center space-top-two">
            <div class="pt-2">
                <h1 class="text-body-big text-center">OPORTUNIDADES LABORALES</h1>
                <v-divider class="mb-1"></v-divider>
                <h1 style="font-size:14px" class="text-body-subtitle text-center">
                    ASOCIACIÓN BOLIVIANA DE INGENIERÍA SANITARIA Y AMBIENTAL - CHUQUISACA</h1>
            </div>
        </div>
        <div class="d-flex justify-center mt-3">
            <div class="home__first">
                <OLoaderDataComp
                    class="mt-2"
                    v-if="dialogLoaderData"
                    smsLoaderData="Obteniendo Noticias"
                />
                <div v-else class="pb-2">
                    <MNoDataComp
                        v-if="!dialogLoaderData && listPublicity.length==0"
                        smsNotData="SIN PUBLICACIONES"
                        class="ma-2"
                    />
                    <div class="d-flex justify-center">
                        <div class="home__first d-flex flex-wrap">
                            <OCardNewComp
                                v-for="(publicity, index) in listPublicity"
                                :key="index"
                                :newPublicity="publicity"
                                :mobile="mobile"
                                :contianImg="contianImg"
                                @clickimage="ac_goToPublication(publicity)"
                                @clickbtnimage="ac_goToBtnPublication(publicity)"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SPaginationComp
            v-if="!dialogLoaderData && listPublicity.length>0"
            :condicionGo="page < last_page"
            :condicionBack="page > 1"
            :titlePagination="'Pag. '+page+' de '+last_page"
            @clickback="ac_changePage(false)"
            @clickgo="ac_changePage(true)"
        />
    </div>
</template>
<script>
import { Publicity } from '../../models'
import {
    MNoDataComp
} from '../../components/molecules'
import {
    OCardAnimationLogoComp,
    OLoaderDataComp,
    OCardNewComp
} from '../../components/organisms'
import {
    SPaginationComp
} from '../../components/species'
import {
    scrollMixin,
} from '../../mixin'
export default {
    metaInfo() {
        const title = `ABIS Ch. - Oportunidades Laborales`
        return {
            title: title
        }
    },
    mixins: [ scrollMixin ],
    components: {
        MNoDataComp,
        OCardAnimationLogoComp,
        OLoaderDataComp,
        SPaginationComp,
        OCardNewComp
    },
    data: () => ({
        dialogLoaderData: false,
        listPublicity: [],
        page: 0,
        last_page: 0,
        mobile: false,
        srcBackground: '/imgs/public/news/fondo.jpg',
        contianImg: true
    }),
    methods: {
        getListPublicityPublic(page) {
            this.listPublicity = []
            this.dialogLoaderData = true
            Publicity.getListPublicityPublic(page, 'l').then(response => {
                this.listPublicity = response.data
                this.page = response.page
                this.last_page = response.last_page
                this.dialogLoaderData = false
            }).catch(error => {
                console.log(error)
            })
        },
        ac_goToPublication(publicity) {
            if (!this.mobile) {
                var velocity = this.m_scroll_velocity(window.scrollY)
                this.m_scroll_animation(velocity)
                setTimeout(() => {
                    this.$router.push({name: 'publication', params: publicity})
                }, velocity)
            }
        },
        ac_goToBtnPublication(publicity) {
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            setTimeout(() => {
                this.$router.push({name: 'publication', params: publicity})
            }, velocity)
        },
        ac_changePage(state) {
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            if (state) { ++this.page }
            else { --this.page }
            setTimeout(() => {
                this.getListPublicityPublic(this.page, 'l')
            }, velocity)
        }
    },
    mounted() {
        if (window.innerWidth <= 600) {
            this.mobile = true
            this.srcBackground = '/imgs/public/news/fondo_mobile.jpg'
        }
        if (window.innerWidth <= 440) this.contianImg = false
        this.getListPublicityPublic(1, 'l')
    }
}
</script>