import axios from "axios"
import { apiUrls } from '../plugins/apiUrls'
export default class Profession {
    constructor (
        profession = null,
        entity = null,
        location = null,
        route_file = null,
        name_file = null
    ) {
        this.profession = profession
        this.entity = entity
        this.location = location
        this.route_file = route_file
        this.name_file = name_file
    }
    static addProfession(parameters) {
        return axios.post(
            apiUrls.PROFESSION + '/add',
            parameters
        ).then(response => {
            return response.data
        })
    }
    static updateProfession(parameters) {
        return axios.post(
            apiUrls.PROFESSION + '/update',
            parameters
        ).then(response => {
            return response.data
        })
    }
}