<template>
    <div>
        <div class="about__background">
            <v-img
                :src="imgBackground"
                gradient="to top right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.3)"
                width="100%"
                height="100%"
            >
                <OCardAnimationLogoComp />
            </v-img>
        </div>
        <div class="d-flex justify-center space-top-two">
            <div class="pt-2">
                <h1 class="text-body-big text-center">DIRECTORIO</h1>
                <v-divider class="mb-1"></v-divider>
                <h1 style="font-size:14px" class="text-body-subtitle text-center">
                    ASOCIACIÓN BOLIVIANA DE INGENIERÍA SANITARIA Y AMBIENTAL - CHUQUISACA</h1>
            </div>
        </div>
        <div class="d-flex justify-center mt-3 space-bottom">
            <div class="home__first">
                <div class="mt-2 d-flex justify-center flex-wrap" v-if="!withResponse">
                    <div
                        class="gallery-public__image-load ma-2"
                        v-for="(number, index) in listNumbers"
                        :key="index"
                    >
                        <v-progress-linear
                            color="primary"
                            indeterminate
                            height="5"
                        ></v-progress-linear>
                    </div>
                </div>
                <div v-else class="pb-2 mt-2">
                    <MNoDataComp
                        v-if="!dialogLoaderData && listDirectory.length==0"
                        smsNotData="SIN DIRECTORIO"
                        class="ma-2 mt-0"
                    />
                    <div class="d-flex justify-center flex-wrap">
                        <div
                            v-for="(person, index) in listDirectory"
                            :key="index"
                            class="ma-2 gallery-public__container-img"
                        >
                            <div
                                class="gallery-public__image d-flex align-center"
                                :style="mobile ? 'opacity:1' : ''"
                                @click="ac_showImgBig(person)"
                            >
                                <v-img
                                    :src="person.route_face|imgNormal"
                                    contain
                                    width="100%"
                                ></v-img>
                            </div>
                            <v-divider></v-divider>
                            <div class="gallery-public__image-title">
                                <h1 class="text-body-private-small text-center">
                                {{person.full_name}}</h1>
                                <h1 class="text-body-private-small-title text-center">
                                {{person.position}}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <OModalGalleryComp
            :dialog="dialogImgBig"
            :listGallery="listDirectory"
            :positionCarrusel="positionCarrusel"
            @clickclose="dialogImgBig=false"
        />
        <v-dialog
            persistent
            v-model="dialogDescription"
            width="600"
        >
            <ODialogHeaderComp
                :title="person.title"
                @clickclose="dialogDescription=false"
            />
            <v-card class="ma-0 pa-2">
                <h1 class="text-body-private-subtitle">
                    {{person.description}}
                </h1>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { Directory } from '../../models'
import {
    MNoDataComp
} from '../../components/molecules'
import {
    OModalGalleryComp,
    OCardAnimationLogoComp,
    ODialogHeaderComp
} from '../../components/organisms'
import {
    imgNormal,
    imgMiniature
} from '../../filters'
export default {
    metaInfo() {
        const title = `ABIS Ch. - Directorio`
        return {
            title: title
        }
    },
    filters: {
        imgNormal,
        imgMiniature
    },
    components: {
        MNoDataComp,
        OModalGalleryComp,
        OCardAnimationLogoComp,
        ODialogHeaderComp
    },
    data: () => ({
        imgBackground: '/imgs/public/directory/img_1.jpg',
        withResponse: false,
        listNumbers: [1,2,3,4],
        listDirectory: [],
        dialogLoaderData: false,
        mobile: false,
        dialogImgBig: false,
        positionCarrusel: 0,
        person: {},
        dialogDescription: false
    }),
    methods: {
        getDirectory() {
            this.dialogLoaderData = true
            this.listDirectory = []
            Directory.getListDirectory().then(response => {
                response.forEach(element => {
                    element.title = element.full_name
                    element.route_img = element.route_face
                    this.listDirectory.push(element)
                });
                this.dialogLoaderData = false
                this.withResponse = true
            }).catch(error => {
                console.log(error)
            })
        },
        ac_showImgBig(directory) {
            if (this.mobile) {
                this.person = directory
                this.dialogDescription = true
            } else {
                this.positionCarrusel = this.listDirectory.indexOf(directory)
                setTimeout(() => {
                    this.dialogImgBig = true
                }, 150)
            }
        }
    },
    mounted() {
        if (window.innerWidth <= 600) {
            this.mobile = true
            this.imgBackground = '/imgs/public/directory/img_1_mobile.jpg'
        }
        this.getDirectory()
    }
}
</script>