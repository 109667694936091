import { mapMutations } from 'vuex'
export default {
    methods: {
        ...mapMutations('userStore', ['saveHeader']),
        m_user_saveToken() {
            if (JSON.parse(localStorage.getItem('abis'))) {
                var apitoken = JSON.parse(localStorage.getItem('abis')).token
                var header = {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + apitoken,
                }
                this.saveHeader(header)
                return true
            } else {
                return false
            }
        },
        m_user_nameCategory(category) {
            if (category === 0) return 'Administrador'
            if (category === 1) return 'G. Página Web'
            if (category === 2) return 'G. Afiliados'
            return 'Sin categoría'
        },
        m_user_stateOrnament(enabled) {
            if (enabled !== 0) return 'card-person__ornament card-person__ornament-correct'
            return 'card-person__ornament card-person__ornament-error'
        }
    }
}