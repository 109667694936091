<template>
    <div class="table-container">
        <table class="s-table-review-procedure animation__card--opacity" border="0">
            <thead>
                <th>N°</th>
                <th>Nombre</th>
                <th>Celular</th>
                <th>Cargo</th>
                <th>Acciones</th>
            </thead>
            <tbody>
                <tr
                    v-for="(phone, index) in listPhones"
                    :key="index"
                >
                    <td class="s-table-review-procedure__border-line td-center">{{(index+1)}}</td>
                    <td class="s-table-review-procedure__border-line">
                        <text-highlight
                            class="text-body-private-subtitle"
                            :queries="searchPhone"
                        >
                            {{phone.name}}
                        </text-highlight>
                    </td>
                    <td class="s-table-review-procedure__border-line">{{phone.number}}</td>
                    <td class="s-table-review-procedure__border-line">{{phone.profession}}</td>
                    <td>
                        <div class="d-flex justify-center align-center flex-wrap">
                            <OBtnOptionToolTipComp
                                smsTooltip="Editar Celular"
                                icon="mdi-pencil"
                                class="mr-1"
                                @click="$emit('clickedit', phone)"
                            />
                            <OBtnOptionToolTipComp
                                smsTooltip="Eliminar Celular"
                                icon="mdi-trash-can"
                                class="mr-1"
                                @click="$emit('clickdelete', phone)"
                            />
                        </div>
                    </td>
                </tr>
                <tr v-if="listPhones.length==0" class="text-center">
                    <td colspan="5">SIN CELULARES</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import { OBtnOptionToolTipComp } from '../organisms'
import TextHighlight from 'vue-text-highlight'
export default {
    components: {
        OBtnOptionToolTipComp,
        TextHighlight
    },
    props: {
        listPhones: Array,
        searchPhone: String
    }
}
</script>