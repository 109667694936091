<template>
    <div class="s-card-languajes">
        <div class="d-flex">
            <h1 class="text-body-title">CURSOS / ESPCIALIDADES DE FORMACIÓN MAS RELEVANTES</h1>
            <v-spacer></v-spacer>
            <OBtnHeaderToolTipComp
                v-if="listSpecialties.length <= 9 && action==2"
                smsTooltip="Añadir Especialidad"
                icon="mdi-plus"
                class="ml-1"
                colorBtn="#2196F3"
                @click="$emit('click')"
            />
        </div>
        <v-divider class="mt-2 mb-2" v-if="action==2"></v-divider>
        <div class="d-flex" v-if="action==2">
            <div class="membership__ornament-circle mr-2 mt-1"></div>
            <h2 class="text-body-subtitle">Solo se pueden introducir 10 cursos o especialidades.</h2>
        </div>
        <v-divider class="mt-2"></v-divider>
        <h1
            v-if="listSpecialties.length==0"
            class="mt-1 text-center text-body-private-title"
        >SIN CURSOS O ESPECIALIDADES</h1>
        <div
            v-for="(specialty, index) in listSpecialties"
            :key="index"
            class="d-flex align-center s-card-languajes__option"
        >
            <OBtnCircleComp
                v-if="ac_activateEdit(specialty)"
                smsTooltip="Editar especialidad"
                color="#2196F3"
                @click="$emit('clickupdate', specialty)"
            >
                <v-icon color="white" small>mdi-pencil</v-icon>
            </OBtnCircleComp>
            <div :class="mobile ? 'ml-1' : 'ml-3'">
                <h2 :style="ac_colorText(specialty)" class="text-body-private-title">{{specialty.specialty}}</h2>
                <h2 :style="ac_colorText(specialty)" class="text-body-private-subtitle">{{specialty.entity}}</h2>
                <h2 :style="ac_colorText(specialty)" class="text-body-private-subtitle">{{specialty.location}}</h2>
                <h2 :style="ac_colorText(specialty)" class="text-body-private-subtitle">{{'Acreditado el: '+m_date_formatDate(specialty.issuance_certificate)}}</h2>
                <MBtnNormalComp
                     v-if="specialty.pdf!=null"
                    color="blue"
                    class="mt-1"
                    :outlined="true"
                    :dark="true"
                    @click="$emit('clickpdf', ac_sendData(specialty))"
                >VER Docuemnto PDF</MBtnNormalComp>
            </div>
        </div>
    </div>
</template>
<script>
import {
    MBtnNormalComp
} from '../../components/molecules'
import {
    OBtnHeaderToolTipComp,
    OBtnCircleComp
} from '../../components/organisms'
import { datesMixin } from '../../mixin'
export default {
    mixins: [ datesMixin ],
    components: {
        OBtnHeaderToolTipComp,
        OBtnCircleComp,
        MBtnNormalComp
    },
    props: {
        listSpecialties: Array,
        mobile: Boolean,
        action: Number
    },
    methods: {
        ac_sendData(specialty) {
            const parameters = {
                'pdf': specialty.pdf,
                'title': specialty.specialty
            }
            return parameters
        },
        ac_activateEdit(specialty) {
            if (this.action === 2) return true
            if (this.action === 0) return false
            if (specialty.validate === 1) return false
            return true
        },
        ac_colorText(specialty) {
            if (this.action === 2) return ''
            if (this.action === 0) return ''
            if (specialty.validate === 1) return ''
            return 'color:red'
        }
    }
}
</script>