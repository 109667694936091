<template>
    <div
        :class="errorPhoto ? 'o-photo o-photo__error' : 'o-photo o-photo__correct'"
        @click='$refs.inputFile.click()'
        style="cursor:pointer"
    >
        <div class="o-photo__picture d-flex align-center">
            <div
                class="d-flex align-center"
                style="background:white; width:100%; height:100%;"
            >
                <div
                    v-if="imgPhotography!=''"
                    class="d-flex align-center"
                    style="background:white; width:100%; height:100%;"
                >
                    <v-img
                        src="/imgs/private/pdf_cargado.jpg"
                        width="100%"
                        height="100%"
                        contain
                    ></v-img>
                </div>
                <div v-else class="o-photo__picture d-flex align-center">
                    <div style="width:100%">
                        <h1 class="text-body-private-small-title text-center">SIN DOCUMENTO</h1>
                        <h1 class="text-body-private-small text-center">(Formato PDF)</h1>
                        <h1 class="text-body-private-small text-center">{{textSize}}</h1>
                    </div>
                </div>
            </div>
        </div>
        <input type="file"
            style="display: none"
            ref="inputFile"
            accept="application/pdf"
            @change="ac_onFilePicked"
        >
    </div>
</template>
<script>
import { imgMiniature } from '../../filters'
export default {
    filters: {
        imgMiniature
    },
    props: {
        errorPhoto: Boolean,
        sizePdf: {
            type: Number,
            default: 2097152
        },
        textSize: {
            type: String,
            default: '(Máximo: 2Mb)'
        }
    },
    data: () => ({
        imgPhotography: '',
    }),
    methods: {
        ac_onFilePicked(e) {
            const files = e.target.files
            if (files[0] !== undefined) {
                let file= e.target.files[0]
                if (file.size <= this.sizePdf) {//2097152
                    this.imgPhotography = 's'
                    this.$emit('onChangeFile', {'file': e.target.files[0], 'is_big': false})
                } else {
                    this.imgPhotography = ''
                    this.$emit('onChangeFile', {'file': null, 'is_big': true})
                }
            } else {
                this.imgPhotography = ''
                this.$emit('onChangeFile', {'file': null, 'is_big': false})
            }
        },
        resetData() {
            this.imgPhotography = ''
        }
    }
}
</script>