<template>
    <div>
        <div class="user__header pa-2">
            <div class="d-flex justify-center align-center">
                <v-app-bar-nav-icon class="mr-2 user__icon-menu" @click="m_menuComp_changeStateMenu()"></v-app-bar-nav-icon>
                <h1 class="text-body-private-title">TRÁMITES ESTANCADOS</h1>
            </div>
            <v-divider class="mb-2 mt-1 user__line-mobile"></v-divider>
            <v-spacer></v-spacer>
            <div class="d-flex align-center">
                <OSearchComp
                    :dense="true"
                    :hideDetails="true"
                    label="Buscar CI"
                    :singleLine="true"
                    v-model="search"
                    @search="ac_searchProcess()"
                />
                <OBtnHeaderToolTipComp
                    class="ml-1"
                    :smsTooltip="viewTable ? 'Cambiar a vista por cards' : 'Cambiar a vista por tabla'"
                    :icon="viewTable ? 'mdi-table-eye' : 'mdi-table-eye-off'"
                    @click="viewTable = !viewTable"
                />
            </div>
        </div>
        <MLineHeaderComp />
        <OLoaderDataComp
            class="mt-2"
            v-if="dialogLoaderData"
            smsLoaderData="Obteniendo Trámites"
        />
        <MNoDataComp
            v-if="!dialogLoaderData && listProcedureStoped.length==0 && !viewTable"
            smsNotData="SIN TRÁMITES ESTANCADOS"
            class="ma-2"
        />
        <div class="d-flex justify-center mt-3" v-if="viewTable">
            <STableReviewProcedureComp
                :isInfo="true"
                v-if="!dialogLoaderData"
                :listReviewProcedure="listProcedureStoped"
                :searchCi="searchCiFilter"
                @clickdelete="ac_confirmDeleteProcess"
            />
        </div>
        <div class="d-flex justify-center flex-wrap" v-else>
            <SCardReviewProcessComp
                class="ma-2"
                :isInfo="true"
                v-for="(process, index) in listProcedureStoped"
                :key="index"
                :process="process"
                :searchCi="searchCiFilter"
                @clickdelete="ac_confirmDeleteProcess(process)"
            />
        </div>
        <SPaginationComp
            class="mt-2"
            v-if="!dialogLoaderData && listProcedureStoped.length>0"
            :condicionGo="page < last_page"
            :condicionBack="page > 1"
            :titlePagination="'Pag. '+page+' de '+last_page"
            @clickback="ac_changePage(false)"
            @clickgo="ac_changePage(true)"
        />
        <OSmsConfirmComp
            :dialog="dialogConfirm"
            :smsConfirm="smsConfirm"
            @clickno="dialogConfirm=false"
            @clickyes="ac_perfomOperation()"
        />
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_closeSmsError()"
        />
        <OLoaderOperationComp
            :dialog="dialogLoaderOperation"
            smsLoaderOperation="Realizando Operación"
        />
        <OSmsActionComp
            :frameCorrect="frameCorrect"
            :smsAction="smsAction"
            :timeout="timeSucess"
            @click="m_dataGeneral_frameTimeReset()"
        />
    </div>
</template>
<script>
import {
    menuCompMixing,
    dataGeneralComponetsMixin,
    responseServerMixin,
    rulesMixin,
    userMixin,
    scrollMixin
} from '../../mixin'
import {
    MLineHeaderComp,
    MNoDataComp
} from '../../components/molecules'
import {
    OSearchComp,
    OSmsErrorComp,
    OSmsConfirmComp,
    OSmsActionComp,
    OLoaderOperationComp,
    OLoaderDataComp,
    OBtnHeaderToolTipComp
} from '../../components/organisms'
import {
    STableReviewProcedureComp,
    SCardReviewProcessComp,
    SPaginationComp
} from '../../components/species'
import {
    ProcessAffiliation
} from '../../models'
export default {
    mixins: [
        menuCompMixing,
        dataGeneralComponetsMixin,
        responseServerMixin,
        rulesMixin,
        userMixin,
        scrollMixin
    ],
    components : {
        OSearchComp,
        MLineHeaderComp,
        OSmsErrorComp,
        OSmsConfirmComp,
        OSmsActionComp,
        OLoaderOperationComp,
        OLoaderDataComp,
        OBtnHeaderToolTipComp,
        STableReviewProcedureComp,
        SCardReviewProcessComp,
        SPaginationComp,
        MNoDataComp
    },
    data: () => ({
        search: '',
        viewTable: true,
        listProcedureStoped: [],
        page: 0,
        last_page: 0,
        indexProcess: -1,
        idProcess: -1
    }),
    computed: {
        searchCiFilter: function() {
            if (this.m_rule_emptyField(this.search)) return ''
            else return this.search
        }
    },
    methods: {
        processError(error) {
            this.dialogLoaderOperation = false
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_action(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        ac_closeSmsError() {
            this.isDelete = false
            if (this.noAccess) {
                localStorage.removeItem('abis')
                this.$router.replace('/login')
            } else this.dialogError = false
        },
        getProceduresStoped(page, ci) {
            this.listProcedureStoped = []
            this.dialogLoaderData = true
            ProcessAffiliation.getListProcessAffiliation(page, ci, 'r').then(response => {
                this.listProcedureStoped = response.data
                this.page = response.page
                this.last_page = response.last_page
                this.dialogLoaderData = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_changePage(state) {
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            if (state) { ++this.page }
            else { --this.page }
            setTimeout(() => {
                this.getProceduresStoped(this.page, this.search)
            }, velocity)
        },
        ac_searchProcess() {
            if (this.m_rule_emptyField(this.search)) {
                this.search = ''
                this.getProceduresStoped(1, '')
            } else this.getProceduresStoped(1, this.search)
        },
        ac_confirmDeleteProcess(process) {
            this.idProcess = process.id
            this.indexProcess = this.listProcedureStoped.indexOf(process)
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            this.smsConfirm = ['¿Está seguro de eliminar el trámite?']
            setTimeout(() => {
                this.dialogConfirm = true
            }, velocity)
        },
        showEvent(message) {
            this.smsAction = message
            this.frameCorrect = true
            this.timeSucess = setTimeout(() => {
                this.frameCorrect = false
            }, 3000)
        },
        ac_perfomOperation() {
            this.dialogConfirm = false
            this.dialogLoaderOperation = true
            ProcessAffiliation.deleteProcess({'id_process': this.idProcess}).then(() => {
                this.listProcedureStoped.splice(this.indexProcess, 1)
                this.dialogLoaderOperation = false
                this.showEvent(['Eliminación exitosa',
                        `El trámite fue eliminado`])
            }).catch(error => {
                this.processError(error)
            })
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.viewTable = false
        if (this.m_user_saveToken()) {
            this.getProceduresStoped(1, '')
        } else this.$router.replace('/login')
    }
}
</script>