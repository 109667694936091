<template>
    <div>
        <div class="user__header pa-2">
            <div class="d-flex justify-center align-center">
                <v-app-bar-nav-icon class="mr-2 user__icon-menu" @click="m_menuComp_changeStateMenu()"></v-app-bar-nav-icon>
                <h1 class="text-body-private-title">SOLICITUDES ESTANCADAS</h1>
            </div>
            <v-divider class="mb-2 mt-1 user__line-mobile"></v-divider>
            <v-spacer></v-spacer>
            <div class="d-flex align-center">
                <OSearchComp
                    v-if="tab==0"
                    :dense="true"
                    :hideDetails="true"
                    label="Buscar"
                    :singleLine="true"
                    v-model="search"
                    @search="ac_searchProcess()"
                />
                <OBtnHeaderToolTipComp
                    v-if="tab==0"
                    class="ml-1"
                    :smsTooltip="viewTable ? 'Cambiar a vista por cards' : 'Cambiar a vista por tabla'"
                    :icon="viewTable ? 'mdi-table-eye' : 'mdi-table-eye-off'"
                    @click="viewTable = !viewTable"
                />
                <OBtnHeaderToolTipComp
                    :class="tab==0 ? 'ml-1' : 'mr-1 ml-1'"
                    smsTooltip="Refrescar la lista"
                    icon="mdi-refresh"
                    @click="ac_refreshList()"
                />
                <OBtnHeaderToolTipComp
                    v-if="tab==1"
                    smsTooltip="Volver a la lista"
                    icon="mdi-database"
                    @click="tab=0"
                />
            </div>
        </div>
        <MLineHeaderComp />
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <OLoaderDataComp
                    class="mt-2"
                    v-if="dialogLoaderData"
                    smsLoaderData="Obteniendo solicitudes de Pago"
                />
                <MNoDataComp
                    v-if="!dialogLoaderData && listProcessPayments.length==0 && !viewTable"
                    smsNotData="SIN SOLICITUDES"
                    class="ma-2"
                />
                <div class="d-flex justify-center mt-3" v-if="viewTable">
                    <STableProcessPaymentComp
                        v-if="!dialogLoaderData"
                        :listProcessPayments="listProcessPayments"
                        :search="searchFilter"
                        @clickadd="ac_showForm"
                        @clickdelete="ac_confirmDeleteProcess"
                    />
                </div>
                <div class="d-flex justify-center flex-wrap" v-else>
                    <SCardProcessPaymentComp
                        class="ma-2"
                        v-for="(process, index) in listProcessPayments"
                        :key="index"
                        :process="process"
                        :search="searchFilter"
                        @clickadd="ac_showForm(process)"
                        @clickdelete="ac_confirmDeleteProcess(process)"
                    />
                </div>
                <SPaginationComp
                    class="mt-2"
                    v-if="!dialogLoaderData && listProcessPayments.length>0"
                    :condicionGo="page < last_page"
                    :condicionBack="page > 1"
                    :titlePagination="'Pag. '+page+' de '+last_page"
                    @clickback="ac_changePage(false)"
                    @clickgo="ac_changePage(true)"
                />
            </v-tab-item>
            <v-tab-item>
                <div class="pa-2" v-if="process.person!=undefined">
                    <div class="d-flex">
                        <h1 class="text-body-private-title mr-1">Afiliado:</h1>
                        <h1 class="text-body-private-subtitle">
                            {{process.person.full_name+' '+process.person.last_name}}</h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="text-body-private-title mr-1">CI:</h1>
                        <h1 class="text-body-private-subtitle">{{process.person.ci}}</h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="text-body-private-title mr-1">Inicio de solicitud:</h1>
                        <h1 class="text-body-private-subtitle">{{process.date}}</h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="text-body-private-title mr-1">Observación:</h1>
                        <h1 class="text-body-private-subtitle">
                            {{process.observation!=null ? process.observation : 'Sin Observación'}}</h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="text-body-private-title mr-1">Referencia:</h1>
                        <h1 class="text-body-private-subtitle">
                            {{process.reference!=null ? process.reference : 'Sin Referencia'}}</h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="text-body-private-title mr-1">Estado de pagos:</h1>
                        <h1 class="text-body-private-subtitle">
                            {{ac_withValidity() ? 'Al día' : 'Adeudado'}}
                        </h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="text-body-private-title mr-1">Expiración de acreditación:</h1>
                        <h1 class="text-body-private-subtitle">{{m_date_formatDate(process.person.date_of_expiry)}}</h1>
                    </div>
                    <div class="d-flex" v-if="!ac_withValidity()">
                        <h1 class="text-body-private-title mr-1">N° de Cuotas atrasadas:</h1>
                        <h1 class="text-body-private-subtitle">
                            {{m_calculate_debt(process.person.dif_days)}}</h1>
                    </div>
                    <div class="d-flex" v-if="!ac_withValidity()">
                        <h1 class="text-body-private-title mr-1">Monto a Cancelar:</h1>
                        <h1 class="text-body-private-subtitle">
                            {{m_calculate_payment(process.person.dif_days, 30)+' Bs.'}}</h1>
                    </div>
                    <v-divider class="mt-1 mb-1"></v-divider>
                    <div class="d-flex flex-wrap">
                        <MBtnNormalComp
                            :outlined="true"
                            color="blue"
                            @click="ac_showFile()"
                            style="margin:2px"
                        >VER COMPROBANTE DE PAGO</MBtnNormalComp>
                    </div>
                </div>
            </v-tab-item>
        </v-tabs-items>
        <OModalImgServerComp
            :dialog="modalImg"
            :src="srcImg"
            :title="titlePDF"
            ref="modalimage"
            @clickclose="ac_closeModalImage()"
        />
        <OModalPdfComp
            :src="srcPDF"
            :dialog="dialogPDF"
            :title="titlePDF"
            @clickclose="dialogPDF=false"
        />

        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_closeSmsError()"
        />
        <OSmsConfirmComp
            :dialog="dialogConfirm"
            :smsConfirm="smsConfirm"
            @clickno="ac_closeSmsError()"
            @clickyes="ac_perfomOperation()"
        />
        <OSmsActionComp
            :frameCorrect="frameCorrect"
            :smsAction="smsAction"
            :timeout="timeSucess"
            @click="m_dataGeneral_frameTimeReset()"
        />
        <OLoaderOperationComp
            :dialog="dialogLoaderOperation"
            smsLoaderOperation="Realizando Operación"
        />
    </div>
</template>
<script>
import {
    MTextFieldComp,
    MLineHeaderComp,
    MBtnNormalComp,
    MNoDataComp
} from '../../components/molecules'
import {
    OSmsErrorComp,
    OSmsConfirmComp,
    OLoaderOperationComp,
    OLoaderDataComp,
    OSmsActionComp,
    OBtnHeaderToolTipComp,
    OModalImgServerComp,
    OModalPdfComp,
    ODialogHeaderComp,
    OSearchComp
} from '../../components/organisms'
import {
    SPaginationComp,
    STableProcessPaymentComp,
    SCardProcessPaymentComp
} from '../../components/species'
import {
    menuCompMixing,
    userMixin,
    responseServerMixin,
    rulesMixin,
    dataGeneralComponetsMixin,
    datesMixin,
    calculatePayementsMixin,
    scrollMixin
} from '../../mixin'
import { ProcessPayments, Person } from '../../models'
export default {
    mixins: [
        menuCompMixing,
        userMixin,
        responseServerMixin,
        rulesMixin,
        dataGeneralComponetsMixin,
        datesMixin,
        calculatePayementsMixin,
        scrollMixin
    ],
    components: {
        OSmsErrorComp,
        OSmsConfirmComp,
        OLoaderOperationComp,
        OLoaderDataComp,
        OSmsActionComp,
        MTextFieldComp,
        MLineHeaderComp,
        SPaginationComp,
        STableProcessPaymentComp,
        OBtnHeaderToolTipComp,
        MBtnNormalComp,
        OModalImgServerComp,
        OModalPdfComp,
        ODialogHeaderComp,
        OSearchComp,
        SCardProcessPaymentComp,
        MNoDataComp
    },
    data: () => ({
        search: '',
        listProcessPayments: [],
        page: 0,
        last_page: 0,
        total: 0,
        viewTable: true,
        indexProcess: -1,
        process: {},
        tab: 0,
        srcPDF: '',
        dialogPDF: false,
        titlePDF: '',
        modalImg: false,
        srcImg: '',
        idProcess: -1
    }),
    computed: {
        searchFilter: function() {
            if (this.search === null) return ''
            return this.search
        }
    },
    methods: {
        processError(error) {
            this.dialogLoaderOperation = false
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_action(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        getProcessPayments(page, search) {
            this.dialogLoaderData = true
            ProcessPayments.getListProcess(page, search, 'r').then(response => {
                this.listProcessPayments = response.data
                this.page = response.page
                this.last_page = response.last_page
                this.total = response.total
                this.dialogLoaderData = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_closeSmsError() {
            if (this.noAccess) {
                localStorage.removeItem('abis')
                this.$router.replace('/login')
            } else {
                this.dialogError = false
                this.dialogConfirm = false
            }
        },
        ac_showForm(process) {
            this.process = process
            this.indexProcess = this.listProcessPayments.indexOf(process)
            this.tab = 1
        },
        ac_closeModalImage() {
            this.$refs.modalimage.resetSize()
            this.modalImg = false
        },
        ac_showFile() {
            const dataRoute = this.process.route_file.split(".")
            this.titlePDF = 'Comprobante de Pago'
            if (dataRoute[1] != 'pdf') {
                this.srcImg = this.process.route_file
                this.modalImg = true
            } else {
                if (this.mobile) {
                    Person.showPdfMobile(this.process.route_file)
                } else {
                    this.srcPDF = ''
                    this.srcPDF = Person.showPdfPc(this.process.route_file)
                    setTimeout(() => {
                        this.dialogPDF = true
                    }, 200)
                }
            }
        },
        showEvent(message) {
            this.smsAction = message
            this.frameCorrect = true
            this.timeSucess = setTimeout(() => {
                this.frameCorrect = false
            }, 3000)
        },
        ac_perfomOperation() {
            this.dialogConfirm = false
            this.dialogLoaderOperation = true
            ProcessPayments.deleteProcess({id: this.idProcess}).then(() => {
                this.listProcessPayments.splice(this.indexProcess, 1)
                this.dialogLoaderOperation = false
                this.showEvent(['Operación exitosa', 'Se eliminó la solicitud'])
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_searchProcess() {
            if (this.search === null) this.getProcessPayments(1, '')
            else this.getProcessPayments(1, this.search)
        },
        ac_changePage(state) {
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            if (state) { ++this.page }
            else { --this.page }
            setTimeout(() => {
                this.getProcessPayments(this.page, this.search)
            }, velocity)
        },
        ac_refreshList() {
            this.search = ''
            if (this.tab != 0) this.tab = 0
            this.getProcessPayments(1, '')
        },
        ac_confirmDeleteProcess(process) {
            this.idProcess = process.id
            this.indexProcess = this.listProcessPayments.indexOf(process)
            this.smsConfirm = [`¿Está seguro de eliminar la solicitud de: 
                ${process.person.full_name} ${process.person.last_name}?`]
            this.dialogConfirm = true
        },
        ac_withValidity() {
            if (this.process.person !== undefined) {
                if (this.process.person.is_expired === 0) return true
                return false
            }
            return false
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.viewTable = false
        if (this.m_user_saveToken()) {
            this.getProcessPayments(1, '')
        } else this.$router.replace('/login')
    }
}
</script>