export default class ErrorsForm{
    constructor(
        full_name = null,
        last_name = null,
        ci = null,
        issued_in = null,
        gender = null,
        date_of_birth = null,
        phone = null,
        address = null,
        email = null,
        category = null,
        code_sib = null,
        route_face = null,
        route_payment = null,
        route_identity = null,
        route_identity_r = null,
        route_academy_a = null,
        route_academy_r = null,
        route_national_a = null,
        route_national_r = null
    ){
       this.full_name = full_name
       this.last_name = last_name
       this.ci = ci
       this.issued_in = issued_in
       this.gender = gender
       this.date_of_birth = date_of_birth
       this.phone = phone
       this.address = address
       this.email = email
       this.category = category
       this.code_sib = code_sib
       this.route_face = route_face
       this.route_payment = route_payment
       this.route_identity = route_identity
       this.route_identity_r = route_identity_r
       this.route_academy_a = route_academy_a
       this.route_academy_r = route_academy_r
       this.route_national_a = route_national_a
       this.route_national_r = route_national_r
    }
}