<template>
    <v-form
        v-on:submit.prevent="ac_confirmUpdateOther()"
        ref="formother"
        v-model="validOther"
        lazy-validation
    >
        <ODialogHeaderComp
            title="Actualizar Perfil"
            @clickclose="$emit('clickclose')"
        />
        <v-card class="ma-0 pa-2 pt-5">
            <v-textarea
                label="Descripción personal"
                v-model="dataOther.profile"
                height="100"
                :counter="200"
                outlined
                :rules="[v => !m_rule_emptyField(v) || 'El dato es requerido',
                    v => (v + '').length <= 200 || 'La descripción personal no puede exceder los 200 dígitos'
                ]"
            ></v-textarea>
            <MTextFieldComp
                label="Link Facebook"
                v-model="dataOther.facebook"
                :dense="true"
                :counter="100"
                :rules="[v => (v + '').length <= 100 || 'El link de facebook no puede exceder los 100 dígitos']"
            />
            <MTextFieldComp
                label="Link Linkedin"
                v-model="dataOther.linkedin"
                :dense="true"
                :counter="100"
                :rules="[v => (v + '').length <= 100 || 'El link de instagram no puede exceder los 100 dígitos']"
            />
            <div class="text-center mt-3">
                <MBtnNormalComp
                    :outlined="true"
                    color="primary"
                    @click="ac_confirmUpdateOther()"
                >GUARDAR</MBtnNormalComp>
            </div>
        </v-card>
    </v-form>
</template>
<script>
import {
    MBtnNormalComp,
    MTextFieldComp
} from '../../components/molecules'
import {
    ODialogHeaderComp
} from '../../components/organisms'
import {
    rulesMixin
} from '../../mixin'
export default {
    mixins: [ rulesMixin ],
    components: {
        MBtnNormalComp,
        ODialogHeaderComp,
        MTextFieldComp
    },
    props: {
        dataOther: Object
    },
    data: () => ({
        validOther: true
    }),
    methods: {
        validateOther() {
            if (!this.$refs.formother.validate()) return false
            return true
        },
        ac_confirmUpdateOther() {
            if (this.validateOther()) this.$emit('clickaction', true)
            else this.$emit('clickaction', false)
        },
        resetForm() {
            this.$refs.formother.resetValidation()
        }
    }
}
</script>