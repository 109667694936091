<template>
    <div class="s-card-languajes">
        <div class="d-flex">
            <h1 class="text-body-supertitle">PERFIL</h1>
            <v-spacer></v-spacer>
            <OBtnHeaderToolTipComp
                v-if="ac_activateEdit(other)"
                smsTooltip="Actualizar perfil"
                icon="mdi-pencil"
                colorBtn="#2196F3"
                class="ml-1"
                @click="$emit('click')"
            />
        </div>
        <v-divider class="mt-2 mb-2"></v-divider>
        <h1 :style="ac_colorText(other)" class="text-body-private-title">Descripción personal:</h1>
        <h1 class="text-body-private-subtitle">
            {{other.profile!=null ? other.profile : 'S/P'}}
        </h1>

        <h1 class="text-body-private-title">Link Facebook:</h1>
        <h1 class="text-body-private-subtitle">
            {{other.facebook!=null ? other.facebook : 'S/F'}}
        </h1>
        <h1 class="text-body-private-title">Link de LinkedIn:</h1>
        <h1 class="text-body-private-subtitle">
            {{other.linkedin!=null ? other.linkedin : 'S/I'}}
        </h1>
    </div>
</template>
<script>
import {
    OBtnHeaderToolTipComp
} from '../../components/organisms'
export default {
    components: {
        OBtnHeaderToolTipComp
    },
    props: {
        other: Object,
        action: Number
    },
    methods: {
        ac_activateEdit(other) {
            if (this.action === 2) return true
            if (this.action === 0) return false
            if (other.validate === 1) return false
            return true
        },
        ac_colorText(other) {
            if (this.action === 2) return ''
            if (this.action === 0) return ''
            if (other.validate === 1) return ''
            return 'color:red'
        }
    }
}
</script>