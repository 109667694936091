<template>
    <div>
        <div class="user__header pa-2">
            <div class="d-flex justify-center align-center">
                <v-app-bar-nav-icon class="mr-2 user__icon-menu" @click="m_menuComp_changeStateMenu()"></v-app-bar-nav-icon>
                <h1 class="text-body-private-title">RECURSOS AFILIADOS</h1>
            </div>
            <v-divider class="mb-2 mt-1 user__line-mobile"></v-divider>
            <v-spacer></v-spacer>
            <div class="d-flex align-center">
                <OSearchComp
                    :dense="true"
                    :hideDetails="true"
                    label="Buscar"
                    :singleLine="true"
                    v-model="search"
                    @search="ac_searchPerson()"
                />
                <OBtnHeaderToolTipComp
                    class="ml-1"
                    smsTooltip="Exportar Excel"
                    icon="mdi-microsoft-excel"
                    @click="ac_affiliateListExcel()"
                />
            </div>
        </div>
        <MLineHeaderComp />
        <div class="d-flex pa-2">
            <h1 class="text-body-private-title mr-1">N° de Afiliados:</h1>
            <h1 class="text-body-private-subtitle">{{total}}</h1>
        </div>
        <v-divider></v-divider>
        <OLoaderDataComp
            class="mt-2"
            v-if="dialogLoaderData"
            smsLoaderData="Obteniendo Afiliados"
        />
        <MNoDataComp
            v-if="!dialogLoaderData && listPerson.length==0"
            smsNotData="SIN AFILIADOS"
            class="ma-2"
        />
        <div class="d-flex justify-center flex-wrap">
            <div
                v-for="(affiliate, index) in listPerson"
                :key="index"
                class="ma-2"
                style="width:180px; border:1px solid rgba(0,0,0,0.3); padding:10px; border-radius:5px;"
            >
                <div class="o-photo">
                    <v-img
                        :src="affiliate.route_face|imgMiniature"
                        width="100%"
                        height="100%"
                        contain
                    ></v-img>
                </div>
                <h1 style="line-height:15px" class="text-body-private-small text-center mt-2">
                    {{affiliate.full_name+' '+affiliate.last_name}}</h1>
                <h1 style="line-height:15px" class="text-body-private-small text-center mt-2">
                    {{`CI: ${affiliate.ci}`}}
                </h1>
                <div class="d-flex justify-center mt-2">
                    <OBtnOptionToolTipComp
                        icon="mdi-file-download"
                        class="mr-1"
                        :smsTooltip="`Descargar información de: ${affiliate.full_name}`"
                        @click="ac_downloadInfo(affiliate)"
                    />
                    <OBtnOptionToolTipComp
                        icon="mdi-image-area-close"
                        class="mr-1"
                        @click="ac_downloadImage(affiliate)"
                        :smsTooltip="`Descargar fotografía de: ${affiliate.full_name}`"
                    />
                    <OBtnOptionToolTipComp
                        icon="mdi-qrcode"
                        :smsTooltip="`Descargar QR de: ${affiliate.full_name}`"
                        @click="ac_downloadQr(affiliate)"
                    />
                </div>
            </div>
        </div>
        <SPaginationComp
            class="mt-2"
            v-if="!dialogLoaderData && listPerson.length>0"
            :condicionGo="page < last_page"
            :condicionBack="page > 1"
            :titlePagination="'Pag. '+page+' de '+last_page"
            @clickback="ac_changePage(false)"
            @clickgo="ac_changePage(true)"
        />
        <div id="qrcode" class="d-none">
            <vue-qr :text="textQr" :size="200" :margin="0"></vue-qr>
        </div>
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_closeSmsError()"
        />
        <OLoaderOperationComp
            :dialog="dialogLoaderOperation"
            smsLoaderOperation="Obteniendo Información"
        />
    </div>
</template>
<script>
import {
    MLineHeaderComp,
    MNoDataComp
} from '../../components/molecules'
import {
    OSearchComp,
    OSmsErrorComp,
    OLoaderDataComp,
    OBtnOptionToolTipComp,
    OBtnHeaderToolTipComp,
    OLoaderOperationComp
} from '../../components/organisms'
import {
    SPaginationComp
} from '../../components/species'
import {
    menuCompMixing,
    responseServerMixin,
    userMixin,
    scrollMixin,
    datesMixin,
    utilitiesMixin
} from '../../mixin'
import { imgMiniature } from '../../filters'
import { Person } from '../../models'
import VueQr from 'vue-qr'
import XLSX from 'xlsx'
export default {
    filters: { imgMiniature },
    mixins: [
        menuCompMixing,
        responseServerMixin,
        userMixin,
        scrollMixin,
        datesMixin,
        utilitiesMixin
    ],
    components: {
        MLineHeaderComp,
        OSearchComp,
        OSmsErrorComp,
        OLoaderDataComp,
        MNoDataComp,
        SPaginationComp,
        OBtnOptionToolTipComp,
        VueQr,
        OBtnHeaderToolTipComp,
        OLoaderOperationComp
    },
    data: () => ({
        dialogLoaderOperation: false,
        noAccess: false,
        dialogError: false,
        smsError: [],
        actionError: true,
        dialogLoaderData: false,
        search: '',
        listPerson: [],
        last_page: 0,
        page: 0,
        total: 0,
        textQr: ''
    }),
    methods: {
        ac_closeSmsError() {
            if (this.noAccess) {
                localStorage.removeItem('abis')
                this.$router.replace('/login')
            } else this.dialogError = false
        },
        processError(error) {
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_action(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        getPersons(page, search) {
            this.listPerson = []
            this.dialogLoaderData = true
            Person.getPersons(page, search).then(response => {
                this.listPerson = response.data
                this.last_page = response.last_page
                this.page = response.page
                this.total = response.total
                this.dialogLoaderData = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_searchPerson() {
            if (this.search===null) {
                this.search = ''
                this.getPersons(1, '')
            } else this.getPersons(1, this.search)
        },
        ac_changePage(state) {
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            if (state) { ++this.page }
            else { --this.page }
            setTimeout(() => {
                this.getPersons(this.page, this.search)
            }, velocity)
        },
        downloadTxt(filename, text) {
            var element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
            element.setAttribute('download', filename);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        },
        ac_downloadInfo(affiliate) {
            const routeGroup = affiliate.route_face.split('/')
            const nameRouteFace = routeGroup[1]
            const category = affiliate.category==='A' ? 'Activo' : affiliate.category==='B' ? 'Transeúnte' : 'Adherente'
            const dateOfBirth = this.m_date_formatDate(affiliate.date_of_birth)
            const gender = affiliate.gender === 'm' ? 'Femenino' : affiliate.gender === 'h' ? 'Masculino' : 'Otro'
            const titleTxt = (affiliate.full_name+'_'+affiliate.dad_last_name+'_'+affiliate.mom_last_name).replace(/ /g, "_")
            const info = `Nombre: ${affiliate.full_name} ${affiliate.dad_last_name} ${affiliate.mom_last_name}\nCI: ${affiliate.ci} ${affiliate.issued_in}.\nGénero: ${gender}\nFecha de Nacimiento: ${dateOfBirth}\nCelular: ${affiliate.phone}\nDirección: ${affiliate.address}\nCategoría: ${category}\nN° de Registro SIB Ch.: ${affiliate.identifier}\nNombre archivo fotografía: ${nameRouteFace}`
            this.downloadTxt(`${titleTxt}.txt`, info)
        },
        ac_downloadImage(affiliate) {
            Person.downloadImage(affiliate.route_face)
        },
        ac_downloadQr(affiliate) {
            const title_qr = affiliate.full_name.replace(/ /g, "_")
            this.textQr = affiliate.route_face
            setTimeout(() => {
                var oQrcode = document.querySelector('#qrcode img')
                var url = oQrcode.src
                var a = document.createElement('a')  
                var event = new MouseEvent('click')  
                a.download = title_qr
                a.href = url
                a.dispatchEvent(event)
            }, 250)
        },
        generateExcel(listAffiliations) {
            var listFinal = []
            listAffiliations.forEach(person => {
                listFinal.push({
                    'N° ABIS CH.': person.identifier,
                    'Nombre Completo': person.full_name+' '+person.last_name,
                    'CI': person.ci+' '+person.issued_in,
                    'Dirección': person.address!=null ? person.address : 'S/D',
                    'Celular': person.phone!=null ? person.phone : 'S/N',
                    'Categoría': this.m_utilitie_category(person.category),
                    'Fecha de emisión': person.date_creation,
                    'Fecha de expiración': this.m_date_formatDate(person.date_of_expiry),
                    'Estado': person.enabled !== 0 ? 'Habilitado' : 'Inhabilitado'
                })
            })
            let data = XLSX.utils.json_to_sheet(listFinal)
            const workbook = XLSX.utils.book_new()
            const filename = 'afiliados'
            XLSX.utils.book_append_sheet(workbook, data, filename);
            XLSX.writeFile(workbook, `${filename}.xlsx`)
        },
        ac_affiliateListExcel() {
            this.dialogLoaderOperation = true
            Person.getListTotal().then(response => {
                this.generateExcel(response)
                this.dialogLoaderOperation = false
            }).catch(error => {
                this.processError(error)
            })
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.viewTable = false
        if (this.m_user_saveToken()) {
            this.getPersons(1, '')
        } else this.$router.replace('/login')
    }
}
</script>