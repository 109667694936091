<template>
    <div>
        <div class="user__header pa-2">
            <div class="d-flex justify-center align-center">
                <v-app-bar-nav-icon class="mr-2 user__icon-menu" @click="m_menuComp_changeStateMenu()"></v-app-bar-nav-icon>
                <h1 class="text-body-private-title">AFILIADOS INHABILITADOS</h1>
            </div>
            <v-divider class="mb-2 mt-1 user__line-mobile"></v-divider>
            <v-spacer></v-spacer>
            <div class="d-flex align-center">
                <OSearchComp
                    :dense="true"
                    :hideDetails="true"
                    label="Buscar"
                    :singleLine="true"
                    v-model="search"
                    @search="ac_searchPerson()"
                />
                <OBtnHeaderToolTipComp
                    smsTooltip="Deshabilitar a afiliado"
                    icon="mdi-plus"
                    class="ml-1"
                    @click="dialogPerson=true"
                />
                <OBtnHeaderToolTipComp
                    class="ml-1"
                    :smsTooltip="viewTable ? 'Cambiar a vista por cards' : 'Cambiar a vista por tabla'"
                    :icon=" viewTable ? 'mdi-table-eye' : 'mdi-table-eye-off'"
                    @click="viewTable = !viewTable"
                />
            </div>
        </div>
        <MLineHeaderComp />
        <div class="d-flex pa-2">
            <h1 class="text-body-private-title mr-1">N° de Afiliados Inhabilitados:</h1>
            <h1 class="text-body-private-subtitle">{{totalDisabled}}</h1>
        </div>
        <v-divider></v-divider>
        <OLoaderDataComp
            class="mt-2"
            v-if="dialogLoaderData"
            smsLoaderData="Obteniendo Afiliados"
        />
        <MNoDataComp
            v-if="!dialogLoaderData && listPerson.length==0 && !viewTable"
            smsNotData="SIN AFILIADOS INHABILITADOS"
            class="ma-2"
        />
        <div class="d-flex justify-center mt-3" v-if="viewTable">
            <STableAffiliationsComp
                v-if="!dialogLoaderData"
                :listPerson="listPerson"
                :isInfo="true"
                :isDisabled="true"
                :searchPerson="searchPersonFilter"
                @clickdetail="ac_showDetailPerson"
                @clickenabled="ac_confirmEnabled"
            />
        </div>
        <div class="d-flex justify-center flex-wrap" v-else>
            <SCardPersonComp
                v-for="(person, index) in listPerson"
                :key="index"
                class="ma-2"
                :person="person"
                :isInfo="true"
                :isDisabled="true"
                :searchPerson="searchPersonFilter"
                @clickdetail="ac_showDetailPerson(person)"
                @clickenabled="ac_confirmEnabled(person)"
            />
        </div>
        <SPaginationComp
            class="mt-2"
            v-if="!dialogLoaderData && listPerson.length>0"
            :condicionGo="page < last_page"
            :condicionBack="page > 1"
            :titlePagination="'Pag. '+page+' de '+last_page"
            @clickback="ac_changePage(false)"
            @clickgo="ac_changePage(true)"
        />
        <v-dialog
            persistent
            width="420px"
            v-model="dialogDetail"
        >
            <v-card class="ma-0 pa-0">
                <ODialogHeaderComp
                    :title="person.full_name+' '+person.last_name"
                    @clickclose="dialogDetail=false"
                />
                <OInfoAffiliateComp :person="person" />
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialogPerson"
            persistent
            width="450"
        >
            <ODialogHeaderComp
                title="Buscar Persona"
                @clickclose="ac_closeDialogPerson()"
            />
            <v-card class="ma-0 pa-2">  
                <OSearchComp
                    class="mb-2"
                    v-model="searchPeson"
                    label="Buscar Persona"
                    @search="ac_searchPersonDisabled()"
                />
                <v-divider></v-divider>
                <div class="works__list-clients mt-2">
                    <MNoDataComp
                        smsNotData="Sin Datos"
                        class="ma-1"
                        v-if="listPersonSearch.length==0 && !dialogLoaderDataPerson"
                    />
                    <OLoaderDataComp
                        v-if="dialogLoaderDataPerson"
                        class="mt-2"
                        smsLoaderData="Obteniendo Personas"
                    />
                    <div
                        v-for="(person, index) in listPersonSearch"
                        :key="index"
                        class="user__person-election animation__card--opacity ma-2 ml-0 mr-0"
                        @click="ac_electionPerson(person)"
                    >
                        <h1 class="text-body-subtitle">
                            {{person.full_name+' '+person.last_name}}</h1>
                    </div>
                    <SPaginationComp
                        class="mt-2"
                        v-if="!dialogLoaderDataPerson && listPersonSearch.length>0"
                        :condicionGo="page_person < last_page_person"
                        :condicionBack="page_person > 1"
                        :titlePagination="'Pag. '+page_person+' de '+last_page_person"
                        @clickback="ac_changePageDisabled(false)"
                        @clickgo="ac_changePageDisabled(true)"
                    />
                </div>
            </v-card>
        </v-dialog>

        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_closeSmsError()"
        />
        <OSmsActionComp
            :frameCorrect="frameCorrect"
            :smsAction="smsAction"
            :timeout="timeSucess"
            @click="m_dataGeneral_frameTimeReset()"
        />
        <OSmsConfirmComp
            :dialog="dialogConfirm"
            :smsConfirm="smsConfirm"
            @clickno="ac_closeSmsError()"
            @clickyes="ac_perfomOperation()"
        />
        <OLoaderOperationComp
            :dialog="dialogLoaderOperation"
            smsLoaderOperation="Realizando Operación"
        />
    </div>
</template>
<script>
import {
    MLineHeaderComp,
    MNoDataComp
} from '../../components/molecules'
import {
    OSearchComp,
    OBtnHeaderToolTipComp,
    OLoaderDataComp,
    OSmsErrorComp,
    OSmsActionComp,
    OSmsConfirmComp,
    OLoaderOperationComp,
    ODialogHeaderComp,
    OInfoAffiliateComp
} from '../../components/organisms'
import {
    STableAffiliationsComp,
    SCardPersonComp,
    SPaginationComp
} from '../../components/species'
import {
    dataGeneralComponetsMixin,
    userMixin,
    responseServerMixin,
    menuCompMixing,
    rulesMixin,
    scrollMixin
} from '../../mixin'
import { Person } from '../../models'
export default {
    mixins: [
        dataGeneralComponetsMixin,
        userMixin,
        responseServerMixin,
        menuCompMixing,
        rulesMixin,
        scrollMixin
    ],
    components: {
        OLoaderDataComp,
        OSmsErrorComp,
        OSmsActionComp,
        OSmsConfirmComp,
        OLoaderOperationComp,
        MNoDataComp,
        STableAffiliationsComp,
        SCardPersonComp,
        SPaginationComp,
        MLineHeaderComp,
        OSearchComp,
        OBtnHeaderToolTipComp,
        ODialogHeaderComp,
        OInfoAffiliateComp
    },
    data: () => ({
        search:'',
        viewTable: true,
        listPerson: [],
        page: 0,
        total: 0,
        last_page: 0,
        totalDisabled: 0,
        person: {},
        dialogDetail: false,
        indexPerson: -1,

        dialogPerson: false,
        searchPeson: '',
        listPersonSearch: [],
        dialogLoaderDataPerson: false,
        page_person: 0,
        last_page_person: 0
    }),
    computed: {
        searchPersonFilter: function() {
            if (this.m_rule_emptyField(this.search)) return ''
            else return this.search
        }
    },
    methods: {
        processError(error) {
            this.dialogLoaderOperation = false
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_action(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        getListDisabled(page, search) {
            this.listPerson = []
            this.dialogLoaderData = true
            Person.getListDisabled(page, search).then(response => {
                this.listPerson = response.data
                this.last_page = response.last_page
                this.page = response.page
                this.totalDisabled = response.total
                this.dialogLoaderData = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_showDetailPerson(person) {
            this.person = person
            this.dialogDetail = true
        },
        ac_closeSmsError() {
            if (this.noAccess) {
                localStorage.removeItem('abis')
                this.$router.replace('/login')
            } else {
                this.dialogError = false
                this.dialogConfirm = false
                if (this.indexPerson === -1) this.dialogPerson = true
                else this.indexPerson = -1
            }
        },
        ac_confirmEnabled(person) {
            this.indexPerson = this.listPerson.indexOf(person)
            this.person = person
            this.smsConfirm = ['¿Está seguro de habilitar a:', `${person.full_name}?`]
            this.dialogConfirm = true
        },
        ac_perfomOperation() {
            this.dialogConfirm = false
            this.dialogLoaderOperation = true
            if (this.indexPerson !== -1) this.enabledPerson()
            else this.disabledPerson()
        },
        showEvent(message) {
            this.smsAction = message
            this.frameCorrect = true
            this.timeSucess = setTimeout(() => {
                this.frameCorrect = false
            }, 3000)
        },
        enabledPerson() {
            Person.changeState({id: this.person.id}).then(() => {
                this.listPerson.splice(this.indexPerson, 1)
                this.indexPerson = -1
                this.dialogLoaderOperation = false
                this.showEvent(['Operación Exitosa',
                        `Se habilitó al afiliado`])
            }).catch(error => {
                this.processError(error)
            })
        },
        disabledPerson() {
            Person.changeState({id: this.person.id}).then(response => {
                this.listPerson.unshift(response)
                this.dialogLoaderOperation = false
                this.ac_closeDialogPerson()
                this.showEvent(['Operación Exitosa',
                        `Se inhabilitó al afiliado`])
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_searchPerson() {
            if (this.search===null) {
                this.search = ''
                this.getListDisabled(1, '')
            } else this.getListDisabled(1, this.search)
        },
        ac_changePage(state) {
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            if (state) { ++this.page }
            else { --this.page }
            setTimeout(() => {
                this.getListDisabled(this.page, this.search)
            }, velocity)
        },
        //person search
        getPersons(page, search) {
            this.dialogLoaderDataPerson = true
            this.listPersonSearch = []
            Person.getPersons(page, search).then(response => {
                this.listPersonSearch = response.data
                this.page_person = response.page
                this.last_page_person = response.last_page
                this.dialogLoaderDataPerson = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_searchPersonDisabled() {
            if (this.m_rule_emptyField(this.searchPeson))
                this.getPersons(1, '')
            else this.getPersons(1, this.searchPeson)
        },
        ac_changePageDisabled(state) {
            if (state) { ++this.page_person }
            else { --this.page_person }
            this.getPersons(this.page_person, this.searchPeson)
        },
        ac_closeDialogPerson() {
            this.dialogPerson = false
            setTimeout(() => {
                this.listPersonSearch = []
                this.searchPeson = ''
            }, 200)
        },
        ac_electionPerson(person) {
            this.person = person
            this.dialogPerson = false
            this.smsConfirm = ['¿Estás seguro de inhabilitar a:',
                `${person.full_name}?`]
            this.dialogConfirm = true
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.viewTable = false
        if (this.m_user_saveToken()) {
            this.getListDisabled(1, '')
        } else this.$router.replace('/login')
    }
}
</script>