<template>
    <div>
        <div class="d-flex justify-center space-bottom">
            <div class="home__first form__contianer">
                <div class="d-flex justify-center">
                    <div>
                        <h1 class="text-body-big text-center">AFILIADOS</h1>
                        <v-divider class="mb-1"></v-divider>
                        <h1 style="font-size:14px" class="text-body-subtitle text-center">ASOCIACIÓN BOLIVIANA DE INGENIERÍA SANITARIA Y AMBIENTAL - CHUQUISACA</h1>
                    </div>
                </div>
                <div class="d-flex justify-center mt-3">
                    <div class="home__first">
                        <div class="directory__message">
                            <h1 class="text-body-subtitle-inverse">
                                Puede seleccionar cualquiera de las tres opciones para encontrar a nuestros afiliados.
                            </h1>
                        </div>
                        <div class="d-flex">
                            
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-center">
                    <div class="abis-members__options">
                        <div class="abis-members__options-btns">
                            <MBtnNormalComp
                                v-for="(btn, index) in listBtn"
                                :key="index"
                                color="blue"
                                class="abis-members__options-btns--margin"
                                :small="mobile"
                                :dark="true"
                                :outlined="btn.action ? false : true"
                                :depressed="true"
                                @click="ac_changeBtn(btn, index)"
                            >{{btn.title}}</MBtnNormalComp>
                        </div>
                        <OSearchComp
                            :dense="true"
                            :hideDetails="true"
                            label="Buscar"
                            :singleLine="true"
                            v-model="search"
                            @search="ac_searchPerson()"
                        />
                    </div>
                </div>
                <OLoaderDataComp
                    class="mt-4"
                    v-if="dialogLoaderData"
                    smsLoaderData="Obteniendo Profesionales"
                />
                <div style="min-height:300px" v-if="!dialogLoaderData && affiliateList.length==0">
                    <MNoDataComp
                        smsNotData="SIN PROFESIONALES"
                        class="ma-2"
                    />
                </div>
                <div class="d-flex justify-center flex-wrap mt-3">
                    <SCardAbisMembersComp
                        v-for="(affiliate, index) in affiliateList"
                        :key="index"
                        :person="affiliate"
                        class="ma-2"
                        @clickcv="ac_showCv(affiliate)"
                        @clickdetail="ac_detailShow(affiliate)"
                        @clicknumber="ac_openWhatsapp(affiliate)"
                    />
                </div>
                <SPaginationComp
                    class="mt-2"
                    v-if="!dialogLoaderData && affiliateList.length>0"
                    :condicionGo="page < last_page"
                    :condicionBack="page > 1"
                    :titlePagination="'Pag. '+page+' de '+last_page"
                    @clickback="ac_changePage(false)"
                    @clickgo="ac_changePage(true)"
                />
            </div>
        </div>
        <v-dialog
            v-model="dialogCv"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card class="ma-0 pa-0" width="100%">
                <div class="d-flex pa-2" style="width:100%; background:#2196F3;">
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        depressed
                        x-small
                        color="white"
                        :ripple="false"
                        @click="dialogCv=false"
                    >
                        <v-icon color="blue">mdi-close</v-icon>
                    </v-btn>
                </div>
                <div class="d-flex justify-center">
                    <div class="home__first" v-if="person.full_name!=undefined">
                        <h1 class="text-center text-body-big space-top-two">
                            {{person.full_name+' '+person.last_name}}
                        </h1>
                        <div class="curriculum-vitae__first-group pt-4">
                            <div class="curriculum-vitae__f-photo">
                                <div class="curriculum-vitae__f-photo-img">
                                    <v-img
                                        :src="person.route_face|imgMiniature"
                                        width="100%"
                                        height="100%"
                                        contain
                                        style="background:white"
                                    ></v-img>
                                </div>
                            </div>
                            <div class="curriculum-vitae__f-info">
                                <div class="curriculum-vitae__f-info-text">
                                    <h1 class="text-body-subtitle-inverse">
                                       {{person.other[0].profile}}
                                    </h1>
                                    <v-divider dark class="mt-1 mb-1"></v-divider>
                                    <div class="d-flex">
                                        <h1 class="text-body-private-title-inverse mr-1">E-mail:</h1>
                                        <h1 class="text-body-private-subtitle-inverse">{{person.email}}</h1>
                                    </div>
                                    <div class="d-flex align-center">
                                        <h1 class="text-body-private-title-inverse mr-1">Celular:</h1>
                                        <h1 class="text-body-private-subtitle-inverse">{{person.phone}}</h1>
                                        <v-icon
                                            color="#E0DEDE"
                                            class="ml-2"
                                            small
                                        >mdi-whatsapp</v-icon>
                                    </div>
                                    <MBtnNormalComp
                                        v-if="person.other[0].facebook!=null"
                                        class="mt-1 mr-1"
                                        color="#2196F3"
                                        :depressed="true"
                                        :dark="true"
                                        @click="ac_openFacebook()"
                                    >FACEBOOK</MBtnNormalComp>
                                    <MBtnNormalComp
                                        v-if="person.other[0].linkedin!=null"
                                        class="mt-1"
                                        color="#0A63BC"
                                        :depressed="true"
                                        :dark="true"
                                        @click="ac_openLinkedIn()"
                                    >LINKEDIN</MBtnNormalComp>
                                </div>
                            </div>
                            <div class="curriculum-vitae__f-qualities">
                                <h1 class="text-body-title text-center">CUALIDADES</h1>
                                <div
                                    class="pl-2 pr-2 mb-2"
                                    v-for="(qualitie, index) in person.qualities"
                                    :key="index"
                                >
                                    <h1 class="curriculum-vitae--text-small">{{qualitie.qualitie}}</h1>
                                    <v-progress-linear
                                        :value="qualitie.progress"
                                        height="7"
                                        color="#2196F3"
                                    ></v-progress-linear>
                                </div>
                                
                                <h1 class="text-body-title text-center mt-3">IDIOMAS</h1>
                                <div
                                    class="pl-2 pr-2 mb-2"
                                    v-for="(languaje, index) in person.languajes"
                                    :key="index"
                                >
                                    <h1 class="curriculum-vitae--text-small">
                                        {{languaje.languaje+' ('+ac_getTextLevel(languaje.nivel)+')'}}</h1>
                                </div>
                            </div>
                        </div>
                    <!--second block-->
                        <div class="curriculum-vitae__second">
                            <div class="curriculum-vitae__s-profession">
                                <h1 class="mb-3 text-body-title text-center">TÍTULOS A NIVEL TÉCNICO, LICENCIATURA Y/O POSGRADO</h1>
                                <div
                                    class="mb-2"
                                    v-for="(profession, index) in person.professions"
                                    :key="index"
                                >
                                    <h1 style="font-weight:500" class="text-body-subtitle">{{profession.profession}}</h1>
                                    <h1 class="curriculum-vitae--text-small">{{profession.entity}}</h1>
                                    <h1 class="curriculum-vitae--text-small">{{profession.location}}</h1>
                                </div>
                            </div>
                            <div class="curriculum-vitae__s-speciality">
                                <h1 class="mb-3 text-body-title text-center">ESPECIALIDADES Y/O CURSOS</h1>
                                <div
                                    class="mb-2"
                                    v-for="(specialty, index) in person.specialties"
                                    :key="index"
                                >
                                    <h1 style="font-weight:500" class="text-body-subtitle">{{specialty.specialty}}</h1>
                                    <h1 class="curriculum-vitae--text-small">{{specialty.entity}}</h1>
                                    <h1 class="curriculum-vitae--text-small">{{specialty.location}}</h1>
                                </div>
                                <MNoDataComp
                                    v-if="person.specialties.length==0"
                                    smsNotData="Sin Especialidades y/o Cursos"
                                />
                            </div>
                        </div>
                    <!--third block-->
                        <div class="curriculum-vitae__third space-bottom">
                            <h1 class="mb-3 text-body-title">EXPERIENCIA LABORAL</h1>
                            <div
                                v-for="(experience, index) in person.experiences"
                                :key="index"
                            >
                                <div>
                                    <div class="d-flex">
                                        <h1 style="font-weight:500" class="text-body-subtitle">{{experience.position}}</h1>
                                        <v-spacer></v-spacer>
                                        <h1 class="curriculum-vitae--text-small">
                                            {{`Del ${m_date_formatDate(experience.beginning)} al ${m_date_formatDate(experience.final)}`}}
                                        </h1>
                                    </div>
                                    <h1 class="curriculum-vitae--text-small">{{experience.employer}}</h1>
                                    <h1 class="curriculum-vitae--text-small">{{experience.location}}</h1>
                                </div>
                                <v-divider class="mt-1 mb-1"></v-divider>
                            </div>
                            <MNoDataComp
                                v-if="person.experiences.length==0"
                                smsNotData="Sin Experiencia Laboral"
                            />
                        </div>
                    </div>
                </div>
            </v-card>
        </v-dialog>
        <v-dialog persistent width="400" v-model="dialogDetail">
            <ODialogHeaderComp
                title="Información"
                @clickclose="dialogDetail=false"
            />
            <v-card class="ma-0 pa-2" v-if="affiliate.full_name!=undefined">
                <div class="d-flex">
                    <h1 class="text-body-private-title mr-1">Afiliado:</h1>
                    <h1 class="text-body-private-subtitle">
                        {{affiliate.full_name+' '+affiliate.last_name}}
                    </h1>
                </div>
                <div class="d-flex">
                    <h1 class="text-body-private-title mr-1">Género:</h1>
                    <h1 class="text-body-private-subtitle" v-if="affiliate.gender=='h'">Masculino</h1>
                    <h1 class="text-body-private-subtitle" v-if="affiliate.gender=='m'">Femenino</h1>
                    <h1 class="text-body-private-subtitle" v-if="affiliate.gender=='o'">Otro</h1>
                </div>
                <div class="d-flex" v-if="affiliate.email!=null">
                    <h1 class="text-body-private-title mr-1">E-mail:</h1>
                    <h1 class="text-body-private-subtitle">
                        {{affiliate.email}}
                    </h1>
                </div>
                <div class="d-flex">
                    <h1 class="text-body-private-title mr-1">N° Reg. ABIS Ch.:</h1>
                    <h1 class="text-body-private-subtitle">
                        {{affiliate.identifier}}
                    </h1>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import {
    MBtnNormalComp,
    MNoDataComp
} from '../../components/molecules'
import {
    OLoaderDataComp,
    OSearchComp,
    ODialogHeaderComp
} from '../../components/organisms'
import { Person } from '../../models'
import { imgMiniature } from '../../filters'
import {
    datesMixin,
    scrollMixin,
    rulesMixin
} from '../../mixin'
import {
    SPaginationComp,
    SCardAbisMembersComp
} from '../../components/species'
export default {
    metaInfo() {
        const title = `ABIS Ch. - Profesionales`
        return {
            title: title
        }
    },
    mixins: [ datesMixin, scrollMixin, rulesMixin ],
    filters: { imgMiniature },
    components: {
        OLoaderDataComp,
        SPaginationComp,
        OSearchComp,
        MBtnNormalComp,
        SCardAbisMembersComp,
        ODialogHeaderComp,
        MNoDataComp
    },
    data:() => ({
        listBtn: [
            {
                title: 'Nombre/CI',
                option: 0,
                action: true
            },{
                title: 'Profesión',
                option: 1,
                action: false
            },{
                title: 'Especialidad',
                option: 2,
                action: false
            }
        ],
        order: '',
        type_order: 0,
        affiliateList: [],
        page: 0,
        total: 0,
        last_page: 0,
        dialogLoaderData: false,
        dialogCv: false,
        person: {},
        search: '',
        optionSearch: 0,
        mobile: false,
        affiliate: {},
        dialogDetail: false
    }),
    methods: {
        generateOrder() {
            var listOrder = ['asc', 'desc']
            this.order = listOrder[Math.floor(Math.random() * listOrder.length)]
            return this.order
        },
        generateTypeOrder() {
            var listTypeOrder = [1,2,3,4,5]
            return listTypeOrder[Math.floor(Math.random() * listTypeOrder.length)]
        },
        getAffiliateList(page, search, order, type_order, option_search) {
            this.affiliateList = []
            this.dialogLoaderData = true
            Person.getListAffiliates(page, search, order, type_order, option_search).then(response => {
                this.affiliateList = response.data
                this.page = response.page
                this.total = response.total
                this.last_page = response.last_page
                this.dialogLoaderData = false
            }).catch(error => {
                console.log(error)
            })
        },
        ac_changePage(state) {
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            if (state) { ++this.page }
            else { --this.page }
            setTimeout(() => {
                this.getAffiliateList(this.page, '', this.generateOrder(), this.type_order, this.optionSearch)
            }, velocity)
        },
        ac_showCv(affiliate) {
            this.person = affiliate
            this.dialogCv = true
        },
        ac_getTextLevel(nivel){
            if (nivel === 'b') return 'Básico'
            if (nivel === 'm') return 'Medio'
            return 'Avanzado'
        },
        ac_changeBtn(btn, index) {
            for(var i=0; i<this.listBtn.length; i++) {
                this.listBtn[i].action = false
            }
            this.listBtn[index].action = !this.listBtn[index].action
            this.optionSearch = btn.option
        },
        ac_searchPerson() {
            if (this.m_rule_emptyField(this.search)) this.search = ''
            this.getAffiliateList(1, this.search, this.generateOrder(), this.type_order, this.optionSearch)
        },
        ac_detailShow(affiliate) {
            this.affiliate = affiliate
            this.dialogDetail = true
        },
        ac_openWhatsapp(affiliate) {
            window.open('https://api.whatsapp.com/send?phone=591'+affiliate.phone)
        },
        ac_openFacebook() {
            const linkFace = this.person.other[0].facebook
            window.open(linkFace)
        },
        ac_openLinkedIn() {
            const linkFace = this.person.other[0].linkedin
            window.open(linkFace)
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.mobile = true
        this.type_order = this.generateTypeOrder()
        this.getAffiliateList(1, '', this.generateOrder(), this.type_order, 0)
    }
}
</script>