import axios from "axios"
import { apiUrls } from '../plugins/apiUrls'
export default class Qualitie {
    constructor (
        qualitie = null,
        progress = 0
    ) {
        this.qualitie = qualitie
        this.progress = progress
    }
    static addQualitie(parameters) {
        return axios.post(
            apiUrls.QUALITIE + '/add',
            parameters
        ).then(response => {
            return response.data
        })
    }
    static updateQualitie(parameters) {
        return axios.put(
            apiUrls.QUALITIE + '/update',
            parameters
        ).then(response => {
            return response.data
        })
    }
}