<template>
    <div>
        <div class="user__header pa-2">
            <div class="d-flex justify-center align-center">
                <v-app-bar-nav-icon class="mr-2 user__icon-menu" @click="m_menuComp_changeStateMenu()"></v-app-bar-nav-icon>
                <h1 class="text-body-private-title">USUARIOS</h1>
            </div>
            <v-divider class="mb-2 mt-1 user__line-mobile"></v-divider>
            <v-spacer></v-spacer>
            <div class="d-flex align-center">
                <MTextFieldComp
                    v-if="tab==0"
                    :dense="true"
                    :hideDetails="true"
                    label="Buscar"
                    :singleLine="true"
                    v-model="search"
                />
                <OBtnHeaderToolTipComp
                    v-if="tab==0"
                    smsTooltip="Nuevo usuario"
                    icon="mdi-plus"
                    class="ml-1 mr-1"
                    @click="tab=1"
                />
                <OBtnHeaderToolTipComp
                    v-if="tab==0"
                    :smsTooltip="viewTable ? 'Cambiar a vista por cards' : 'Cambiar a vista por tabla'"
                    :icon=" viewTable ? 'mdi-table-eye' : 'mdi-table-eye-off'"
                    @click="viewTable = !viewTable"
                />
                <OBtnHeaderToolTipComp
                    v-if="tab==1"
                    smsTooltip="Volver a la lista usuario"
                    icon="mdi-database"
                    @click="ac_backListUser()"
                />
            </div>
        </div>
        <MLineHeaderComp />
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <OLoaderDataComp
                    class="mt-2"
                    v-if="dialogLoaderData"
                    smsLoaderData="Obteniendo Trámites"
                />
                <MNoDataComp
                    v-if="!dialogLoaderData && listUserFilter.length==0 && !viewTable"
                    smsNotData="SIN USUARIOS"
                    class="ma-2"
                />
                <div class="d-flex justify-center mt-3" v-if="viewTable">
                    <STableUserComp
                        v-if="!dialogLoaderData"
                        :listUser="listUserFilter"
                        @clickcredentials="ac_showFormCredentials"
                        @clickstate="ac_confirmChangeState"
                        :searchUser="serchUserFilter"
                        @clickupdate="ac_showChangeCategory"
                    />
                </div>
                <div class="d-flex justify-center flex-wrap" v-else>
                    <SCardUserComp
                        class="ma-2"
                        v-for="(user, index) in listUserFilter"
                        :key="index"
                        :user="user"
                        :searchUser="serchUserFilter"
                        @clickcredentials="ac_showFormCredentials(user)"
                        @clickstate="ac_confirmChangeState(user)"
                        @clickupdate="ac_showChangeCategory(user)"
                    />
                </div>
            </v-tab-item>
            <v-tab-item>
                <div class="d-flex justify-center pa-2 pt-0 pb-3">
                    <v-form
                        v-on:submit.prevent="ac_confirmUser()"
                        ref="formuser"
                        v-model="valid"
                        lazy-validation
                        class="user--border user--radius pa-3 mt-3"
                        style="width:400px"
                    >
                        <h1 class="text-body-private-title text-center mb-2">FORMULARIO USUARIO</h1>
                        <div :class="errorPerson ? 'mb-4' : 'mb-6'">
                            <div class="d-flex align-center">
                                <div :class="ac_classElectionPerson()">
                                    <h1 class="text-body-private-subtitle">{{dataUser.name_person}}</h1>
                                    <h1
                                        class="user__person-title"
                                        :style="errorPerson ? 'color:#FF5252': 'color:#858484'"
                                    >Persona</h1>
                                </div>
                                <OBtnHeaderToolTipComp
                                    smsTooltip="Añadir Persona"
                                    icon="mdi-plus"
                                    @click="dialogPerson=true"
                                />
                            </div>
                            <p v-if="errorPerson" class="user__person-error">La elección es requerida</p>
                        </div>
                        <MTextFieldComp
                            class="mb-2"
                            v-model="dataUser.username"
                            :clearable="true"
                            label="Username"
                            :dense="true"
                            :counter="20"
                            @click="ac_confirmUser()"
                            :rules="[v => !this.m_rule_emptyField(v) || 'El dato es requerido',
                                v => !this.m_rule_withSpaces(v) || 'El username no debe contener espacios',
                                v => (v+'').length<=20 || 'El username no debe exeder los 20 dígitos',
                                v => (v+'').length>=8 || 'El username debe ser igual o mayor a 8 dígitos']"
                        />
                        <MTextFieldComp
                            class="mb-2"
                            v-model="dataUser.password"
                            :clearable="true"
                            label="Password"
                            :dense="true"
                            :counter="20"
                            :type="showPassword ? 'text' : 'password'"
                            :icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            @clicksee="showPassword = !showPassword"
                            @click="ac_confirmUser()"
                            :rules="[v => !this.m_rule_emptyField(v) || 'El dato es requerido',
                                v => !this.m_rule_withSpaces(v) || 'El password no debe contener espacios',
                                v => (v+'').length<=20 || 'El password no debe exeder los 20 dígitos',
                                v => (v+'').length>=5 || 'El password debe ser igual o mayor a 5 dígitos']"
                        />
                        <MTextFieldComp
                            class="mb-2"
                            v-model="dataUser.repitpassword"
                            :clearable="true"
                            label="Repita Password"
                            :dense="true"
                            :counter="20"
                            :type="showPassword ? 'text' : 'password'"
                            :icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            @clicksee="showPassword = !showPassword"
                            @click="ac_confirmUser()"
                            :rules="[v => !this.m_rule_emptyField(v) || 'El dato es requerido',
                                v => !this.m_rule_withSpaces(v) || 'El password no debe contener espacios',
                                v => (v+'').length<=20 || 'El password no debe exeder los 20 dígitos',
                                v => (v+'').length>=5 || 'El password debe ser igual o mayor a 5 dígitos',
                                v => !this.ac_passwordEquals(v) || 'Las contraseñas no coinciden']"
                        />
                        <MSelectComp
                            label="Categoría"
                            v-model="dataUser.category"
                            :items="listCategories"
                            itemText="title"
                            itemValue="value"
                            :rules="[v => !m_rule_emptyField(v) || 'La categoría es requerida']"
                        />
                        <div class="text-center">
                            <MBtnNormalComp
                                color="primary"
                                :outlined="true"
                                @click="ac_confirmCreateUser()"
                            >CREAR USUARIO</MBtnNormalComp>
                        </div>
                    </v-form>
                </div>
            </v-tab-item>
        </v-tabs-items>
        
        <v-dialog
            v-model="dialogPerson"
            persistent
            width="450"
        >
            <ODialogHeaderComp
                title="Buscar Persona"
                @clickclose="ac_closeDialogPerson()"
            />
            <v-card class="ma-0 pa-2">  
                <OSearchComp
                    class="mb-2"
                    v-model="searchPeson"
                    label="Buscar Persona"
                    @search="ac_searchPerson()"
                />
                <v-divider></v-divider>
                <div class="works__list-clients mt-2">
                    <MNoDataComp
                        smsNotData="Sin Datos"
                        class="ma-1"
                        v-if="listPerson.length==0 && !dialogLoaderDataPerson"
                    />
                    <OLoaderDataComp
                        v-if="dialogLoaderDataPerson"
                        class="mt-2"
                        smsLoaderData="Obteniendo Personas"
                    />
                    <div
                        v-for="(person, index) in listPerson"
                        :key="index"
                        class="user__person-election animation__card--opacity ma-2 ml-0 mr-0"
                        @click="ac_electionPerson(person)"
                    >
                        <h1 class="text-body-subtitle">
                            {{person.full_name+' '+person.last_name}}</h1>
                    </div>
                    <SPaginationComp
                        class="mt-2"
                        v-if="!dialogLoaderDataPerson && listPerson.length>0"
                        :condicionGo="page_person < last_page_person"
                        :condicionBack="page_person > 1"
                        :titlePagination="'Pag. '+page_person+' de '+last_page_person"
                        @clickback="ac_changePage(false)"
                        @clickgo="ac_changePage(true)"
                    />
                </div>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialogPassword"
            persistent
            width="450"
        >
            <ODialogHeaderComp
                title="Cambiar Contraseña"
                @clickclose="ac_closeFormNewPassword()"
            />
            <v-card class="ma-0 pa-2">
                <SFormPasswordComp
                    ref="containerformpass"
                    :dataUser="dataUserChangePass"
                    @clickchange="ac_confirmChangePass"
                />
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialogCategory"
            persistent
            width="400"
        >
            <ODialogHeaderComp
                title="Cambiar Categoría"
                @clickclose="ac_closeCategory()"
            />
            <v-card class="ma-0 pa-2">
                <div
                    v-for="(category, index) in listCategories"
                    :key="index"
                    class="mb-2 pa-2 user__person-election"
                    @click="ac_confirmChangeCategory(category)"
                >
                    <h1 class="text-body-private-title text-center">{{category.title}}</h1>
                </div>
            </v-card>
        </v-dialog>
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_closeSmsError()"
        />
        <OSmsConfirmComp
            :dialog="dialogConfirm"
            :smsConfirm="smsConfirm"
            @clickno="ac_closeSmsError()"
            @clickyes="ac_perfomOperation()"
        />
        <OSmsActionComp
            :frameCorrect="frameCorrect"
            :smsAction="smsAction"
            :timeout="timeSucess"
            @click="m_dataGeneral_frameTimeReset()"
        />
        <OLoaderOperationComp
            :dialog="dialogLoaderOperation"
            smsLoaderOperation="Realizando Operación"
        />
    </div>
</template>
<script>
import {
    MLineHeaderComp,
    MNoDataComp,
    MTextFieldComp,
    MSelectComp,
    MBtnNormalComp
} from '../../components/molecules'
import {
    OSmsErrorComp,
    OSmsConfirmComp,
    OLoaderOperationComp,
    OLoaderDataComp,
    OSmsActionComp,
    OBtnHeaderToolTipComp,
    OSearchComp,
    ODialogHeaderComp
} from '../../components/organisms'
import {
    SCardUserComp,
    SPaginationComp,
    SFormPasswordComp,
    STableUserComp
} from '../../components/species'
import {
    dataGeneralComponetsMixin,
    responseServerMixin,
    rulesMixin,
    userMixin,
    menuCompMixing
} from '../../mixin'
import md5 from 'js-md5'
import {
    User,
    Person
} from '../../models'
export default {
    mixins: [
        dataGeneralComponetsMixin,
        responseServerMixin,
        rulesMixin,
        userMixin,
        menuCompMixing
    ],
    components: {
        OSmsErrorComp,
        OSmsConfirmComp,
        OLoaderOperationComp,
        OLoaderDataComp,
        OSmsActionComp,
        MLineHeaderComp,
        MNoDataComp,
        OBtnHeaderToolTipComp,
        MTextFieldComp,
        SCardUserComp,
        MSelectComp,
        OSearchComp,
        ODialogHeaderComp,
        SPaginationComp,
        MBtnNormalComp,
        SFormPasswordComp,
        STableUserComp
    },
    data: () => ({
        tab: 0,
        listUser: [],
        viewTable: true,
        search: '',
        indexUser: -1,
        valid: true,
        dataUser: new User(),
        showPassword: false,
        listCategories: [
            {
                title: 'Administrador',
                value: 0
            },{
                title: 'G. Página Web',
                value: 1
            },{
                title: 'G. Afiliados',
                value: 2
            }
        ],
        errorPerson: false,
        dialogPerson: false,
        dialogLoaderDataPerson: false,
        listPerson: [],
        page_person: 0,
        last_page_person: 0,
        searchPeson: '',
        changePassword: false,
        dialogPassword: false,
        dataUserChangePass: new User(),
        user: {},
        isChangeCategory: false,
        dialogCategory: false,
        category: {}
    }),
    computed: {
        listUserFilter: function() {
            if (this.search === null) return this.listUser
            else {
                return this.listUser.filter(user => {
                    return user.name.toLowerCase()
                            .indexOf(this.search.toLowerCase()) !== -1
                })
            }
        },
        serchUserFilter: function() {
            if (this.search === null) return ''
            return this.search
        },
    },
    methods: {
        processError(error) {
            this.dialogLoaderOperation = false
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_action(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        ac_closeSmsError() {
            if (this.noAccess) {
                localStorage.removeItem('abis')
                this.$router.replace('/login')
            } else {
                this.dialogError = false
                this.dialogConfirm = false
                if (this.isChangeCategory) this.dialogCategory = true
                if (this.changePassword) this.dialogPassword = true
            }
        },
        getListUser(idUser) {
            this.dialogLoaderData = true
            User.getListUser(idUser).then(response => {
                this.listUser = response
                this.dialogLoaderData = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_passwordEquals(input) {
            if (input != this.dataUser.password) return true
            return false
        },
        ac_classElectionPerson() {
            if (this.errorPerson) return 'user__election-person user--error mr-1 d-flex align-center'
            return 'user__election-person user--check mr-1 d-flex align-center'
        },
        ac_closeDialogPerson() {
            if (this.dataUser.fkperson !== null) this.errorPerson = false
            else this.errorPerson = true
            this.dialogPerson = false
            setTimeout(() => {
                this.listPerson = []
                this.searchPeson = ''
            }, 200)
        },
        getPersons(page, search) {
            this.dialogLoaderDataPerson = true
            this.listPerson = []
            Person.getPersons(page, search).then(response => {
                this.listPerson = response.data
                this.page_person = response.page
                this.last_page_person = response.last_page
                this.dialogLoaderDataPerson = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_searchPerson() {
            if (this.m_rule_emptyField(this.searchPeson))
                this.getPersons(1, '')
            else this.getPersons(1, this.searchPeson)
        },
        ac_electionPerson(person) {
            this.dataUser.fkperson = person.id
            this.dataUser.name_person = person.full_name
            this.ac_closeDialogPerson()
        },
        ac_changePage(state) {
            if (state) { ++this.page_person }
            else { --this.page_person }
            this.getPersons(this.page_person, this.searchPeson)
        },
        validateDataUser() {
            if (!this.$refs.formuser.validate()) return false
            return true
        },
        ac_confirmCreateUser() {
            if (!this.validateDataUser() || this.dataUser.fkperson === null) {
                if (this.dataUser.fkperson === null) this.errorPerson = true
                this.smsError = [`Revise los datos marcados con rojo y corrija porfavor`]
                this.dialogError = true
                this.actionError = true
            } else {
                this.smsConfirm = [`¿Está seguro de crear un usuario para:`, `${this.dataUser.name_person}?`]
                this.dialogConfirm = true
            }
        },
        ac_backListUser() {
            this.dataUser = new User()
            this.$refs.formuser.resetValidation()
            this.listPerson = []
            this.searchPeson = ''
            this.errorPerson = false
            this.tab = 0
        },
        showEvent(message) {
            this.smsAction = message
            this.frameCorrect = true
            this.timeSucess = setTimeout(() => {
                this.frameCorrect = false
            }, 3000)
        },
        createUser() {
            const parameters = {
                username: this.dataUser.username,
                password: md5(this.dataUser.password),
                category: this.dataUser.category,
                fkperson: this.dataUser.fkperson
            }
            User.createUser(parameters).then(response => {
                this.dialogLoaderOperation = false
                this.listUser.unshift(response)
                this.ac_backListUser()
                this.showEvent(['Operación Exitosa',
                        `Se creó el usuario`])
            }).catch(error => {
                this.processError(error)
            })
        },
        changePasswordUser() {
            const parameters = {
                username: this.user.username,
                password: md5(this.dataUserChangePass.password),
                id: this.user.id
            }
            User.changeCredentials(parameters).then(() => {
                this.changePassword = false
                this.dialogLoaderOperation = false
                this.showEvent(['Operación Exitosa',
                        `La contraseña fue actualizada`])
                this.ac_closeFormNewPassword()
            }).catch(error => {
                this.processError(error)
            })
        },
        changeStateUser() {
            const smsState = this.user.enabled===0 ? 'Inhabilitado' : 'Habilitado'
            User.changeStateUser({id: this.user.id}).then(response => {
                this.listUser[this.indexUser].enabled = response
                this.indexUser = -1
                this.dialogLoaderOperation = false
                this.showEvent(['Operación Exitosa',
                        `El usuario fue ${smsState}`])
            }).catch(error => {
                this.processError(error)
            })
        },
        changeCategory() {
            const parameters = {
                'id': this.user.id,
                'category': this.category.value
            }
            User.changeCategory(parameters).then(response => {
                Object.assign(this.listUser[this.indexUser], response)
                this.dialogLoaderOperation = false
                this.indexUser = -1
                this.isChangeCategory = false
                this.showEvent(['Operación Exitosa',
                        `Se cambio la categoría`])
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_perfomOperation() {
            this.dialogConfirm = false
            this.dialogLoaderOperation = true
            if (this.isChangeCategory) this.changeCategory()
            else {
                if (this.indexUser !== -1)
                    this.changeStateUser()
                else {
                    if (this.changePassword) this.changePasswordUser()
                    else this.createUser()
                }
            }
        },
        ac_showFormCredentials(user) {
            this.changePassword = true
            this.dialogPassword = true
            this.user = user
        },
        ac_confirmChangePass(e) {
            this.dialogPassword = false
            if (e) {
                this.smsConfirm = ['¿Está seguro de cambiar la contraseña de:', `${this.user.name}?`]
                this.dialogConfirm = true
            } else {
                this.smsError = ['Corrija los datos marcados con rojo porfavor']
                this.dialogError = true
                this.actionError = true
            }
        },
        ac_closeFormNewPassword() {
            this.changePassword = false
            this.dataUserChangePass = new User()
            this.$refs.containerformpass.resetForm()
            this.dialogPassword = false
        },
        ac_confirmChangeState(user) {
            this.user = user
            this.indexUser = this.listUser.indexOf(user)
            const smsAction = user.enabled===0 ? 'habilitar' : 'inhabilitar'
            this.smsConfirm = [`¿Está seguro de ${smsAction} a:`, `${user.name}?`]
            this.dialogConfirm = true
        },
        ac_showChangeCategory(user) {
            this.indexUser = this.listUser.indexOf(user)
            this.user = user
            this.isChangeCategory = true
            this.dialogCategory = true
        },
        ac_closeCategory() {
            this.isChangeCategory = false
            this.dialogCategory = false
        },
        ac_confirmChangeCategory(category) {
            this.category = category
            this.dialogCategory = false
            this.smsConfirm = ['¿Está seguro de cambiar la categoría de:',
                `${this.user.name}`, `a: ${category.title}?`]
            this.dialogConfirm = true
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.viewTable = false
        if (this.m_user_saveToken()) {
            var idUser = JSON.parse(localStorage.getItem('abis')).user.id
            this.getListUser(idUser)
        } else this.$router.replace('/login')
    }
}
</script>