export default {
    data: () => ({
        dialogLoaderOperation: false,
        smsLoaderOperation: 'Realizando Operación',
        //sms loader data
        smsLoaderData: '',
        dialogLoaderData: false,
        //sms confirm
        dialogConfirm: false,
        smsConfirm: [],
        //sms error
        noAccess: false,
        dialogError: false,
        smsError: [],
        actionError: true,
        //sms correct
        smsAction: [],
        frameCorrect: false,
        colorMessage: '#0a813c',
        timeSucess: 3000
    }),
    methods: {
        m_dataGeneral_frameTimeReset() {
            clearTimeout(this.timeSucess)
            this.frameCorrect = false
        }
    }
}