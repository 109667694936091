<template>
    <div>
        <div class="gallery__carrusel__btn-close-fixed" v-if="dialog">
            <v-btn
                small
                color="white"
                fab
                depressed
                :ripple="false"
                @click="$emit('clickclose')"
            >
                <v-icon color="black">mdi-close</v-icon>
            </v-btn>
        </div>
        <v-dialog
            :value="dialog"
            :width="ac_getWidthImg()"
            persistent
            style="position:relative"
        >
            <div class="gallery__carrusel">
                <div style="height:100%">
                <div class="gallery__title d-flex align-center">
                    <h1 class="text-body-private-title">{{title}}</h1>
                </div>
                    <iframe
                        :src="src"
                        frameBorder="0"
                        scrolling="auto"
                        height="89%"
                        width="100%"
                    ></iframe>
                </div>
            </div>
        </v-dialog>
    </div>
</template>
<script>
export default {
    model: {
        prop: 'dialog',
        event: 'click'
    },
    props: {
        src: String,
        dialog: Boolean,
        title: String
    },
    methods: {
        ac_getWidthImg() {
            if (window.innerWidth <= 1400) return '1000'
            else return '1200'
        }
    }
}
</script>
