<template>
    <div class="pb-4">
        <div class="about__background">
            <v-img
                :src="srcBackground"
                gradient="to top right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)"
                width="100%"
                height="100%"
            >
                <OCardAnimationLogoComp />
            </v-img>
        </div>
        <div class="d-flex justify-center space-top-two">
            <div class="pt-2">
                <h1 class="text-body-big text-center">CONVENIOS</h1>
                <v-divider class="mb-1"></v-divider>
                <h1 style="font-size:14px" class="text-body-subtitle text-center">Trabajamos como una sola unidad de Ingenieros</h1>
            </div>
        </div>
        <div class="d-flex justify-center mt-3">
            <div class="home__first">
                <div class="d-flex justify-center flex-wrap" v-if="!withResponse">
                    <div
                        class="gallery-public__image-load ma-2"
                        v-for="(number, index) in listNumbers"
                        :key="index"
                    >
                        <v-progress-linear
                            color="primary"
                            indeterminate
                            height="5"
                        ></v-progress-linear>
                    </div>
                </div>
                <div v-else class="pb-2">
                    <MNoDataComp
                        v-if="!dialogLoaderData && listConventions.length==0"
                        smsNotData="SIN CONVENIOS"
                        class="ma-2"
                    />
                    <div class="d-flex justify-center flex-wrap">
                        <div
                            v-for="(image, index) in listConventions"
                            :key="index"
                            class="ma-2 gallery-public__container-img"
                        >
                            <div
                                class="gallery-public__image d-flex align-center"
                                :style="mobile ? 'opacity:1' : ''"
                                @click="ac_showImgBig(image)"
                            >
                                <v-img
                                    :src="image.route_img|imgMiniature"
                                    contain
                                    width="100%"
                                    height="100%"
                                ></v-img>
                            </div>
                            <v-divider></v-divider>
                            <div class="gallery-public__image-title">
                                <h1 class="text-body-private-subtitle text-center">
                                {{image.title}}</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <SPaginationComp
                    class="mt-2"
                    v-if="!dialogLoaderData && listConventions.length>0"
                    :condicionGo="page < last_page"
                    :condicionBack="page > 1"
                    :titlePagination="'Pag. '+page+' de '+last_page"
                    @clickback="ac_changePage(false)"
                    @clickgo="ac_changePage(true)"
                />
            </div>
        </div>
        <OModalGalleryComp
            :dialog="dialogImgBig"
            :listGallery="listConventions"
            :mobile="mobile"
            :positionCarrusel="positionCarrusel"
            @clickclose="dialogImgBig=false"
        />
    </div>
</template>
<script>
import { Convention } from '../../models'
import {
    MNoDataComp
} from '../../components/molecules'
import {
    OModalGalleryComp,
    OCardAnimationLogoComp
} from '../../components/organisms'
import { SPaginationComp } from '../../components/species'
import {
    scrollMixin
} from '../../mixin'
import {
    imgMiniature
} from '../../filters'
export default {
    metaInfo() {
        const title = `ABIS Ch. - Convenios`
        return {
            title: title
        }
    },
    mixins: [scrollMixin],
    filters: {
        imgMiniature
    },
    components: {
        MNoDataComp,
        OModalGalleryComp,
        OCardAnimationLogoComp,
        SPaginationComp
    },
    data: () => ({
        withResponse: false,
        listNumbers: [1,2,3,4],
        listConventions: [],
        page: 0,
        last_page: 0,
        dialogLoaderData: false,
        mobile: false,
        dialogImgBig: false,
        positionCarrusel: 0,
        srcBackground: '/imgs/public/news/fondo.jpg'
    }),
    methods: {
        getConventios(page) {
            this.dialogLoaderData = true
            Convention.getListConventionPublic(page).then(response => {
                this.listConventions = response.data
                this.page = response.page
                this.last_page = response.last_page
                this.withResponse = true
                this.dialogLoaderData = false
            }).catch(error => {
                console.log(error)
            })
        },
        ac_showImgBig(image) {
            this.positionCarrusel = this.listConventions.indexOf(image)
            setTimeout(() => {
                this.dialogImgBig = true
            }, 150)
        },
        ac_changePage(state) {
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            if (state) { ++this.page }
            else { --this.page }
            setTimeout(() => {
                this.getConventios(this.page)
            }, velocity)
        }
    },
    mounted() {
        if (window.innerWidth <= 600) {
            this.mobile = true
            this.srcBackground = '/imgs/public/news/fondo_mobile.jpg'
        }
        this.getConventios(1)
    }
}
</script>