export default {
    data: () => ({
        itemsDepartments: [
            {
                title: "Beni",
                value: "be"
            },{
                title: "Chuquisaca",
                value: "ch"
            },{
                title: "Cochabamba",
                value: "cb"
            },{
                title: "La Paz",
                value: "lp"
            },{
                title: "Oruro",
                value: "or"
            },{
                title: "Pando",
                value: "pd"
            },{
                title: "Potosí",
                value: "pt"
            },{
                title: "Santa Cruz",
                value: "sc"
            },{
                title: "Tarija",
                value: "tr"
            }
        ],
        itemsGender: [
            {
                title: 'Masculino',
                value: 'h'
            },{
                title: 'Femenino',
                value: 'm'
            },{
                title: 'Otro',
                value: 'o'
            }
        ],
        itemsCategory: [
            {
                title: 'Activos',
                value: 'A'
            },{
                title: 'Transeúntes',
                value: 'B'
            },{
                title: 'Adherentes',
                value: 'C'
            }
        ]
    })
}