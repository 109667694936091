<template>
    <div class="table-container">
        <table class="s-table-review-procedure animation__card--opacity" border="0">
            <thead>
                <th>Nombre</th>
                <th>CI</th>
                <th>Username</th>
                <th>Categoría</th>
                <th>Estado</th>
                <th>Fecha Registro</th>
                <th>Acciones</th>
            </thead>
            <tbody>
                <tr
                    v-for="(user, index) in listUser"
                    :key="index"
                >
                    <td class="s-table-review-procedure__border-line">
                        <text-highlight
                            class="text-body-private-subtitle"
                            :queries="searchUser"
                        >
                            {{user.name}}
                        </text-highlight>
                    </td>
                    <td class="s-table-review-procedure__border-line">{{`${user.ci} ${user.issued_in}`}}</td>
                    <td class="s-table-review-procedure__border-line">{{user.username}}</td>
                    <td class="s-table-review-procedure__border-line">
                        {{m_user_nameCategory(user.category)}}</td>
                    <td class="s-table-review-procedure__border-line">
                        <div class="d-flex align-center">
                            <h1 class="text-body-private-subtitle mr-1">
                                {{user.enabled==0 ? 'Inactivo' : 'Activo'}}</h1>
                            <div :class="m_user_stateOrnament(user.enabled)"></div>
                        </div>
                    </td>
                    <td class="s-table-review-procedure__border-line">{{user.date}}</td>
                    <td>
                        <div class="d-flex justify-center align-center flex-wrap">
                            <OBtnOptionToolTipComp
                                smsTooltip="Cambiar contraseña"
                                icon="mdi-account-lock-open"
                                style="margin:2px"
                                @click="$emit('clickcredentials' ,user)"
                            />
                            <OBtnOptionToolTipComp
                                :smsTooltip="user.enabled==0 ? 'Habilitar Usuario' : 'Inhabilitar Usuario'"
                                :icon="user.enabled==0 ? 'mdi-eye' : 'mdi-eye-off'"
                                @click="$emit('clickstate', user)"
                                style="margin:2px"
                            />
                            <OBtnOptionToolTipComp
                                smsTooltip="Cambiar categoría"
                                icon="mdi-pencil"
                                style="margin:2px"
                                @click="$emit('clickupdate', user)"
                            />
                        </div>
                    </td>
                </tr>
                <tr v-if="listUser.length==0" class="text-center">
                    <td colspan="7">SIN DATOS</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import { OBtnOptionToolTipComp } from '../organisms'
import TextHighlight from 'vue-text-highlight'
import { userMixin } from '../../mixin'
export default {
    mixins: [ userMixin ],
    props: {
        listUser: Array,
        searchUser: String
    },
    components: {
        OBtnOptionToolTipComp,
        TextHighlight,
    }
}
</script>