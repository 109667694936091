<template>
    <div class="o-background">
        <v-img
            :src="imgBackground"
            gradient="to top right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.3)"
            height="100%"
            width="100%"
        >
            <div class="o-background__group-info">
                <div class="o-background__info">
                    <div class="o-background__info-ornament-l"></div>
                    <div class="o-background__info-ornament-r"></div>
                    <div class="o-background__info-ornament-t"></div>
                    <div class="o-background__info-ornament-b"></div>
                    <div class="o-background__info-container">
                        <div style="width:100%">
                            <div class="d-flex justify-center">
                                <div class="o-background__logo">
                                    <v-img
                                        src="/imgs/logo/logo_little_inverse.png"
                                        width="100%"
                                    ></v-img>
                                </div>
                            </div>
                            <div class="d-flex justify-center mt-1">
                                <h1 class="text-fond-title text-fond--shadow mr-3"
                                    style="color:#329EF4"
                                >ABIS</h1>
                                <h1 class="text-fond-title text-fond--shadow"
                                    style="color:#f5f4f4cb"
                                >Chuquisaca</h1>
                            </div>
                            <div>
                                <div class="home__message">
                                    <div class="home__group-text">
                                        <div class="home__message-title d-flex justify-center align-center">
                                            <h1 class="text-fond-subtitle">Trabajo Cooperativo entre profesionales</h1>
                                        </div>
                                        <div class="home__message-title d-flex justify-center align-center">
                                            <h1 class="text-fond-subtitle">Actualización de profesionales</h1>
                                        </div>
                                        <div class="home__message-title d-flex justify-center align-center">
                                            <h1 class="text-fond-subtitle">Fortalecimiento de sus miembros</h1>
                                        </div>
                                        <div class="home__message-title d-flex justify-center align-center">
                                            <h1 class="text-fond-subtitle">Facilidad de oportunidades</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-center mt-4">
                                <MBtnNormalComp
                                    :outlined="true"
                                    color="rgba(255,255,255,0.8)"
                                    class="mr-2"
                                    :small="small"
                                    :to="{name:'info-affiliate'}"
                                >
                                    INGRESAR
                                </MBtnNormalComp>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </v-img>
    </div>
</template>
<script>
import { MBtnNormalComp } from '../molecules'
export default {
    components: {
        MBtnNormalComp
    },
    props: {
        imgBackground: String
    },
    data: () => ({
        small: false
    }),
    mounted() {
        if (window.innerWidth <= 600) {
            this.small = true
        }
    }
}
</script>