<template>
    <div class="d-flex">
        <v-app-bar
            fixed
            class="o-menu-mobile"
            height="52"
        >
            <div class="d-flex align-center" style="width:100%">
                <div>
                <v-img
                    src="/imgs/logo/logo_little_inverse.png"
                    height="40px"
                    width="100px"
                    contain
                ></v-img>
                </div>
                <v-spacer></v-spacer>
                <div class="d-flex align-center">
                    <v-menu
                        transition="slide-x-transition"
                        offset-y
                    >
                        <template v-slot:activator="{on, attrs}">
                            <v-btn
                                icon
                                color="white"
                                :ripple="false"
                                class="mr-3"
                                :retain-focus-on-click="false"
                                width="40"
                                min-width="40px"
                                height="40px"
                                min-height="40px"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon small>mdi-bell</v-icon>
                                <h1 class="o-menu__number-notices mb-2">
                                    {{quantityProcess+quantityProcessAcademic}}</h1>
                            </v-btn>
                        </template>
                        <v-card
                            width="300"
                            color="black"
                            class="pa-2"
                        >
                            <div @click="ac_goProcess(true)" class="o-menu-mobile__notification">
                                <h1 class="text-body-private-subtitle-inverse">
                                    {{ac_getTextNotify(true)}}</h1>
                            </div>
                            <div @click="ac_goProcess(false)" class="o-menu-mobile__notification mt-1">
                                <h1 class="text-body-private-subtitle-inverse">
                                    {{ac_getTextNotify(false)}}</h1>
                            </div>
                        </v-card>
                    </v-menu>
                    <v-app-bar-nav-icon style="color:white;" @click="drawer=true"></v-app-bar-nav-icon>
                </div>
            </div>
        </v-app-bar>
        <v-navigation-drawer
                v-model="drawer"
                fixed
                temporary
                width="360"
        >
            <v-toolbar class="o-menu-mobile__header">
                <div class="d-flex align-center">
                    <v-img
                        src="/imgs/logo/logo_little_inverse_2.png"
                        width="75px"
                    ></v-img>
                </div>
                <v-spacer></v-spacer>
                <v-icon @click="drawer=!drawer" color="white">
                    mdi-close-circle
                </v-icon>
            </v-toolbar>
            <v-list nav class="pb-0 pt-0 mt-2">
                <div
                    v-for="(option, index) in listMenu"
                    :key="index"
                ><!--v-model="item.active"-->
                    <v-list-group
                        v-if="option.submenu.length>0"
                        no-action
                        :ripple="false"
                        class="mb-1"
                    >
                        <template v-slot:activator>
                            <v-list-item-icon class="mr-1">
                                <v-icon small color="#0073B6">{{option.icon}}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                                <h1 class="text-menu">{{option.title}}</h1>
                            </v-list-item-title>
                        </template>
                        <v-list-item
                            v-for="(child, index) in option.submenu"
                            :key="index"
                            class="pl-8"
                            :to="{name: child.route}"
                        >
                            <v-list-item-icon class="mr-0">
                                <v-icon small color="#0073B6">{{child.icon}}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title class="d-flex align-center">
                                <div class="ornament-circle mr-2"></div>
                                <h1 class="text-menu">{{child.title}}</h1>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list-group>
                    <v-list-item-group
                        v-else
                        color="primary"
                    >
                        <v-list-item
                            class="mb-1"
                            :to="{name: option.route}"
                        >
                            <v-list-item-icon class="mr-1">
                                <v-icon small color="#0073B6">{{option.icon}}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                                <h1 class="text-menu">{{option.title}}</h1>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list-item-group>
                </div>
                <v-list-item
                    active-class="o-menu-private__btn-action"
                    :ripple="false"
                    @click="$emit('closesesion')"
                >
                    <v-list-item-icon class="mr-1">
                        <v-icon small color="#0073B6">mdi-account-lock</v-icon>
                    </v-list-item-icon>
                    <v-list-item-subtitle>
                        <h1 class="text-menu">Cerrar Sesión</h1>
                    </v-list-item-subtitle>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>
<script>
export default {
    props: {
        listMenu: Array,
        quantityProcess: Number,
        quantityProcessAcademic: Number
    },
    data: () => ({
        drawer: false
    }),
    methods: {
        ac_getTextNotify(isProfession) {
            if (isProfession) {
                if (this.quantityProcess === 0) return `No hay trámites profesionales de afiliación.`
                const smsProcess = this.quantityProcess > 1
                    ? `${this.quantityProcess} trámites profesionales de afiliación`
                    : `${this.quantityProcess} trámite profesionale de afiliación`
                return  smsProcess
            } else {
                if (this.quantityProcessAcademic === 0) return `No hay trámites universitarios de afiliación.`
                const smsProcess = this.quantityProcessAcademic > 1
                    ? `${this.quantityProcessAcademic} trámites universitarios de afiliación`
                    : `${this.quantityProcessAcademic} trámite universitario de afiliación`
                return  smsProcess
            }
        },
        ac_goProcess(isProfession) {
            if (isProfession) {
                if (this.$route.name !== 'review') this.$router.push({name: 'review'})
            } else {
                if (this.$route.name !== 'university-review') this.$router.push({name: 'university-review'})
            }
        }
    }
}
</script>
