import axios from 'axios'
import { apiUrls } from '../plugins/apiUrls'
import { userStore } from '../store/modules'

export default class Whatsapp {
    constructor (
        number = null,
        name = null,
        profession = null
    ) {
        this.number = number
        this.name = name
        this.profession = profession
    }
    static listPhones() {
        return axios.get(
            apiUrls.WHATSAPP + '/list',
        ).then(response => {
            return response.data
        })
    }
    static addPhone(parameters) {
        return axios.post(
            apiUrls.WHATSAPP + '/add',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static updatePhone(parameters) {
        return axios.put(
            apiUrls.WHATSAPP + '/update',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static deletePhone(parameters) {
        return axios.delete(
            apiUrls.WHATSAPP + '/delete',
            { headers: userStore.state.header, data: parameters } 
        ).then(response => {
            return response.data
        })
    }
}