<template>
    <div>
        <v-menu
            transition="slide-x-transition"
            offset-y
        >
            <template v-slot:activator="{on, attrs}">
                <div v-bind="attrs" v-on="on" v-if="!mobile">
                    <v-tooltip
                        bottom
                        color="#062242"
                        v-model="valueToolTip"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <div
                                v-bind="attrs"
                                v-on="on"
                                :style="!isPhoto ? 'height:40px' : ''"
                                @click="ac_outToolTipAndEmit()"
                            >
                                <v-icon :color="colorbtn">{{icon}}</v-icon>
                            </div>
                        </template>
                        <div class="d-flex align-center">
                            <v-icon class="mr-1" color="white">mdi-hand-pointing-right</v-icon>
                            <h1 class="text-body-subtitle-inverse">{{smsTooltip}}</h1>
                        </div>
                    </v-tooltip>
                </div>
                <div
                    v-else
                    v-bind="attrs"
                    v-on="on"
                    style="height:40px"
                    @click="$emit('click')"
                >
                    <v-icon :color="colorbtn">{{icon}}</v-icon>
                </div>
            </template>
            <div class="pa-2 o-sms-observation__message">
                <h1 class="text-body-private-subtitle-inverse text-center">{{smsObservation}}</h1>
            </div>
        </v-menu>
    </div>
</template>
<script>
export default {
    props: {
        smsObservation: String,
        smsTooltip: String,
        isPhoto: Boolean,
        colorbtn: {
            type: String,
            default: 'red'
        },
        icon: {
            type: String,
            default: 'mdi-information'
        }
    },
    data: () => ({
        valueToolTip: false,
        mobile: false
    }),
    methods: {
        ac_outToolTipAndEmit() {
            this.valueToolTip = false
            this.$emit('click')
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.mobile = true
    }
}
</script>