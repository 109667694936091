var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-menu',{attrs:{"transition":"slide-x-transition","bottom":"","right":"","offset-y":"","close-on-content-click":_vm.closeOnContentClick},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.mobile)?_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-tooltip',{attrs:{"bottom":"","color":"#062242"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","ripple":false,"depressed":"","small":""},on:{"click":function($event){return _vm.$emit('clickopen')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.icon))])],1)]}}],null,true)},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"white"}},[_vm._v("mdi-hand-pointing-right")]),_c('h1',{staticClass:"text-body-subtitle-inverse"},[_vm._v(_vm._s(_vm.smsTooltip))])],1)])],1):_c('div',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$emit('clickopen')}}},'div',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.icon))])],1)]}}])},[_c('div',{staticClass:"pa-3 pb-2",style:(_vm.mobile ? 'background:white;' : 'background:white; width:300px;')},[_c('div',{staticClass:"d-flex align-center mb-1"},[_c('h1',{staticClass:"text-body-private-title"},[_vm._v(_vm._s(_vm.title))]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","depressed":"","ripple":false,"fab":"","x-small":""},on:{"click":function($event){return _vm.$emit('clickclose')}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-close")])],1)],1),_c('MTextAreaComp',{staticStyle:{"width":"300px"},attrs:{"label":"Observación","singleLine":true,"counter":100,"height":"120","value":_vm.value,"rules":[function (v) { return (v + '').length <= 100; }]},on:{"input":function (e){ return (this$1.$emit('input', e)); }}}),_c('div',{staticClass:"text-center"},[_c('MBtnNormalComp',{attrs:{"outlined":true,"color":"primary"},on:{"click":function($event){return _vm.$emit('clicksave')}}},[_vm._v("GUARDAR")])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }