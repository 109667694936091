import axios from 'axios'
import { apiUrls } from '../plugins/apiUrls'
import { userStore } from '../store/modules'
export default class ProcessCv{
    static requestCvPublication(parameters) {
        return axios.post(apiUrls.PROCESS_CV + '/request-cv',
            parameters).then(response => {
            return response.data
        })
    }
    static getListProcessCv(page, search) {
        return axios.get(
            apiUrls.PROCESS_CV + '/list-process?page='+page+'&search='+search,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static getCvPerson(id) {
        return axios.get(
            apiUrls.PROCESS_CV + '/cv-person?id='+id,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static approveCv(parameters) {
        return axios.post(
            apiUrls.PROCESS_CV + '/approve-cv',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static observeProcess(parameters) {
        return axios.post(
            apiUrls.PROCESS_CV + '/observe-cv',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
    static sendObservations(parameters) {
        return axios.put(
            apiUrls.PROCESS_CV + '/send-observations',
            parameters
        ).then(response => {
            return response.data
        })
    }
    static changeStateUser(parameters) {
        return axios.put(
            apiUrls.PROCESS_CV + '/change-state-user',
            parameters
        ).then(response => {
            return response.data
        })
    }
    static deleteCv(parameters) {
        return axios.post(
            apiUrls.PROCESS_CV + '/delete-cv',
            parameters,
            { headers: userStore.state.header }
        ).then(response => {
            return response.data
        })
    }
}