<template>
    <div>
        <v-tooltip bottom color="#062242" v-if="!mobile">
            <template v-slot:activator="{ on, attrs }">
                <div
                    v-bind="attrs"
                    v-on="on"
                    @click="$emit('click')"
                    style="cursor:pointer"
                >
                    <v-icon :color="color">{{icon}}</v-icon>
                </div>
            </template>
            <div class="d-flex align-center">
                <v-icon class="mr-1" color="white">mdi-hand-pointing-right</v-icon>
                <h1 class="text-body-subtitle-inverse">{{smsTooltip}}</h1>
            </div>
        </v-tooltip>
        <div
            v-else
            @click="$emit('click')"
            style="cursor:pointer"
        >
            <v-icon :color="color">{{icon}}</v-icon>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        icon: String,
        smsTooltip: String,
        color: String,
        mobile: Boolean
    }
}
</script>
