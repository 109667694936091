<template>
    <div class="table-container">
        <table class="s-table-review-procedure animation__card--opacity" border="0">
            <thead>
                <th>Interesado</th>
                <th>CI</th>
                <th>Inicio de solicitud</th>
                <th>Estado</th>
                <th>Acciones</th>
            </thead>
            <tbody>
                <tr
                    v-for="(process, index) in listReviewProcedure"
                    :key="index"
                >
                    <td class="s-table-review-procedure__border-line">
                        {{process.list_interested[0].full_name+' '+process.list_interested[0].last_name}}</td>
                    <td class="s-table-review-procedure__border-line">
                        <text-highlight
                            class="text-body-private-subtitle"
                            :queries="searchCi"
                        >
                            {{process.list_interested[0].ci}}
                        </text-highlight>
                    </td>
                    <td class="s-table-review-procedure__border-line">
                        {{process.hours+' '+process.date}}</td>
                    <td class="s-table-review-procedure__border-line">
                        {{process.state_process=='c' ? 'Enviado' : 'Observado'}}
                    </td>
                    <td>
                        <div class="d-flex justify-center">
                            <OBtnOptionToolTipComp
                                smsTooltip="Eliminar trámite"
                                icon="mdi-trash-can"
                                class="ma-1"
                                @click="$emit('clickdelete', process)"
                            />
                            <OBtnOptionToolTipComp
                                v-if="!isInfo"
                                smsTooltip="Ver el proceso del trámite"
                                icon="mdi-file-send"
                                class="ma-1"
                                @click="$emit('clickform', process)"
                            />
                        </div>
                    </td>
                </tr>
                <tr v-if="listReviewProcedure.length==0" class="text-center">
                    <td colspan="5">SIN DATOS</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import { OBtnOptionToolTipComp } from '../organisms'
import TextHighlight from 'vue-text-highlight'
export default {
    props: {
        listReviewProcedure: Array,
        searchCi: String,
        isInfo: Boolean
    },
    components: {
        OBtnOptionToolTipComp,
        TextHighlight
    }
}
</script>