<template>
    <div>
        <v-tooltip bottom color="#062242">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    :color="colorBtn"
                    width="40"
                    max-width="40"
                    min-width="40"
                    depressed
                    v-bind="attrs"
                    v-on="on"
                    :ripple="false"
                    @click="$emit('click')"
                >
                    <v-icon color="white">{{icon}}</v-icon>
                </v-btn>
            </template>
            <div class="d-flex align-center">
                <v-icon class="mr-1" color="white">mdi-hand-pointing-right</v-icon>
                <h1 class="text-body-subtitle-inverse">{{smsTooltip}}</h1>
            </div>
        </v-tooltip>
    </div>
</template>
<script>
export default {
    props: {
        icon: String,
        smsTooltip: String,
        colorBtn: {
            type: String,
            default: 'primary'
        }
    }
}
</script>
