export default {
    methods: {
        m_calculate_debt(days = 0) {
            var totalDebt = parseInt(days / 30) + 1
            return totalDebt
        },
        m_calculate_payment(days = 0, price = 0) {
            return this.m_calculate_debt(days) * price
        }
    }
}