<template>
    <div class="o-menu">
        <div class="o-menu__options d-flex align-center">
            <div class="mr-2">
                <v-img
                    src="/imgs/logo/logo_little_inverse.png"
                    width="70px"
                    contain
                    @click="ac_goHome()"
                    style="cursor:pointer"
                ></v-img>
            </div>
            <v-spacer></v-spacer>
            <div
                v-for="(option, index) in listMenu"
                :key="index"
            >
                <div v-if="option.submenu.length>0" class="mr-1">
                    <v-menu
                        transition="slide-x-transition"
                        offset-y
                        open-on-hover
                    >
                        <template v-slot:activator="{on, attrs}">
                            <v-btn
                                text
                                class="mr-1"
                                color="#ffffffef"
                                :ripple="false"
                                v-bind="attrs"
                                v-on="on"
                                active-class="o-menu__btn"
                            >
                                <v-icon small color="#ffffffef" class="mr-1">{{option.icon}}</v-icon>
                                {{option.title}}
                            </v-btn>
                        </template>
                        <v-list class="pa-0 ma-0 pb-1 pt-1 menu-computer__list">
                            <v-list-item-group>
                                    <div
                                        v-for="(suboption, index) in option.submenu"
                                        :key="index"
                                        @click="ac_goRouter(suboption.route)"
                                        class="ma-1 o-menu__submenu-item d-flex align-center"
                                        :style="styleBackground(suboption.route)"
                                    >
                                        <v-icon :class="getClassIcon(suboption.route)" small>{{suboption.icon}}</v-icon>
                                        <h1 :class="getClassTitle(suboption.route)">{{suboption.title}}</h1>
                                    </div>
                            </v-list-item-group>
                        </v-list>
                    </v-menu>
                </div>
                <!-- direct routes -->
                <div v-else class="mr-1">
                    <v-btn
                        :ripple="false"
                        text
                        class="mr-1"
                        color="#ffffffef"
                        active-class="o-menu__btn"
                        @click="ac_goRouter(option.route)"
                    >
                        <v-icon color="#ffffffef" small class="mr-1">{{option.icon}}</v-icon>
                        {{option.title}}
                    </v-btn>
                </div>
            </div>
            <v-btn
                icon
                :ripple="false"
                class="mr-1"
                :retain-focus-on-click="false"
                @click="$emit('clickface')"
            >
                <v-icon color="#2196F3">mdi-facebook</v-icon>
            </v-btn>
            <v-btn
                icon
                color="#a6f1ac"
                :ripple="false"
                class="mr-3"
                :retain-focus-on-click="false"
                style="position:relative"
                @click="$emit('clicknews')"
            >
                <v-icon small>mdi-bell</v-icon>
                <h1 class="o-menu__number-notices mb-2">{{numberNews}}</h1>
            </v-btn>
        </div>
    </div>
</template>
<script>
import { MBtnCircleComp } from '../molecules'
import { scrollMixin } from '../../mixin'
export default {
    mixins: [scrollMixin],
    components: {
        MBtnCircleComp
    },
    props: {
        listMenu: Array,
        numberNews: Number
    },
    data: () => ({
        listNotices: [
            "Cursos de postgrado",
            "Capacidad de la norma #125"
        ]
    }),
    methods: {
        ac_goHome() {
            if (this.$route.name != 'home') {
                var velocity = this.m_scroll_velocity(window.scrollY)
                this.m_scroll_animation(velocity)
                setTimeout(() => {
                    this.$router.push({name: 'home'})
                }, velocity)
            }
        },
        ac_goRouter(name_route) {
            if (this.$route.name != name_route) {
                var velocity = this.m_scroll_velocity(window.scrollY)
                this.m_scroll_animation(velocity)
                setTimeout(() => {
                    this.$router.push({name: name_route})
                }, velocity)
            }
        },
        styleBackground(name_route) {
            if (this.$route.name != name_route) return 'background:white !important'
            return 'background:#2196F3 !important'
        },
        getClassIcon(name_route) {
            if (this.$route.name != name_route) return 'o-menu__icon mr-2'
            return 'o-menu__icon-dark mr-2'
        },
        getClassTitle(name_route) {
            if (this.$route.name != name_route) return 'o-menu__title'
            return 'o-menu__title-dark'
        }
    }
}
</script>