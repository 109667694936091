<template>
    <div>
        <div class="user__header pa-2">
            <div class="d-flex justify-center align-center">
                <v-app-bar-nav-icon class="mr-2 user__icon-menu" @click="m_menuComp_changeStateMenu()"></v-app-bar-nav-icon>
                <h1 class="text-body-private-title">REVISIÓN DE CV</h1>
            </div>
            <v-divider class="mb-2 mt-1 user__line-mobile"></v-divider>
            <v-spacer></v-spacer>
            <div class="d-flex align-center">
                <OSearchComp
                    v-if="tab==0"
                    :dense="true"
                    :hideDetails="true"
                    label="Buscar"
                    :singleLine="true"
                    v-model="search"
                    @search="ac_searchProcess()"
                />
                <OBtnHeaderToolTipComp
                    v-if="tab==0"
                    class="ml-1"
                    :smsTooltip="viewTable ? 'Cambiar a vista por cards' : 'Cambiar a vista por tabla'"
                    :icon="viewTable ? 'mdi-table-eye' : 'mdi-table-eye-off'"
                    @click="viewTable = !viewTable"
                />
                <OBtnHeaderToolTipComp
                    :class="tab==0 ? 'ml-1' : 'mr-1 ml-1'"
                    smsTooltip="Refrescar la lista"
                    icon="mdi-refresh"
                    @click="ac_refreshList()"
                />
                <OBtnHeaderToolTipComp
                    v-if="tab==1"
                    smsTooltip="Volver a la lista"
                    icon="mdi-database"
                    @click="tab=0"
                />
            </div>
        </div>
        <MLineHeaderComp />
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <OLoaderDataComp
                    class="mt-2"
                    v-if="dialogLoaderData"
                    smsLoaderData="Obteniendo Solicitudes CV"
                />
                <MNoDataComp
                    v-if="!dialogLoaderData && listProcessCv.length==0 && !viewTable"
                    smsNotData="SIN SOLICITUDES"
                    class="ma-2"
                />
                <div class="d-flex justify-center mt-3" v-if="viewTable">
                    <STableReviewCvComp
                        v-if="!dialogLoaderData"
                        :listProcessCv="listProcessCv"
                        :search="searchFilter"
                        @clickviewcv="ac_showFormCv"
                        @clickdelete="ac_confirmDelete"
                    />
                </div>
                <div class="d-flex justify-center flex-wrap" v-else>
                    <SCardReviewCvComp
                        class="ma-2"
                        v-for="(process, index) in listProcessCv"
                        :key="index"
                        :process="process"
                        :search="searchFilter"
                        @clickviewcv="ac_showFormCv(process)"
                        @clickdelete="ac_confirmDelete(process)"
                    />
                </div>
                <SPaginationComp
                    class="mt-2"
                    v-if="!dialogLoaderData && listProcessCv.length>0"
                    :condicionGo="page < last_page"
                    :condicionBack="page > 1"
                    :titlePagination="'Pag. '+page+' de '+last_page"
                    @clickback="ac_changePage(false)"
                    @clickgo="ac_changePage(true)"
                />
            </v-tab-item>
            <v-tab-item>
                <div class="pa-2" v-if="processCv.person!=undefined">
                    <div class="d-flex">
                        <h1 class="text-body-private-title mr-1">Afiliado:</h1>
                        <h1 class="text-body-private-subtitle">
                            {{processCv.person.full_name+' '+processCv.person.last_name}}</h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="text-body-private-title mr-1">CI:</h1>
                        <h1 class="text-body-private-subtitle">{{processCv.person.ci}}</h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="text-body-private-title mr-1">Inicio de solicitud:</h1>
                        <h1 class="text-body-private-subtitle">{{processCv.date}}</h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="text-body-private-title mr-1">Observación:</h1>
                        <h1 class="text-body-private-subtitle">
                            {{processCv.observation!=null ? processCv.observation : 'Sin Observación'}}</h1>
                    </div>
                </div>
                <v-divider></v-divider>
                <h1 class="pl-2 pt-2 text-body-private-title mb-1">PERFIL</h1>
                <div class="d-flex align-center pa-2" v-if="profile.validate!=undefined">
                    <div class="mr-1">
                        <div>
                            <MBtnCircleComp
                                color="primary"
                                :xsmall="true"
                                class="mb-1"
                                @click="ac_changeStateProfile(1)"
                            >
                                <v-icon>mdi-check</v-icon>
                            </MBtnCircleComp>
                        </div>
                        <MBtnCircleComp
                            color="primary"
                            @click="ac_changeStateProfile(0)"
                        >
                            <v-icon>mdi-close</v-icon>
                        </MBtnCircleComp>
                    </div>
                    <div :class="ac_getClassDesaignOption(profile)">
                        <h1 class="text-body-private-subtitle">{{profile.profile}}</h1>
                        <div class="d-flex align-center">
                            <h1 class="text-body-private-subtitle">
                                {{profile.facebook!= null ? profile.facebook : 'Sin Facebook'}}</h1>
                            <MBtnNormalComp
                                v-if="profile.facebook!= null"
                                :dense="true"
                                :depressed="true"
                                color="blue"
                                class="ml-1"
                                :dark="true"
                                @click="ac_openLink(profile.facebook)"
                            >IR</MBtnNormalComp>
                        </div>
                        <div class="d-flex align-center mt-1">
                            <h1 class="text-body-private-subtitle">
                                {{profile.linkedin!= null ? profile.linkedin : 'Sin LinkedIn'}}</h1>
                            <MBtnNormalComp
                                v-if="profile.linkedin!= null"
                                :dense="true"
                                :depressed="true"
                                :dark="true"
                                color="blue"
                                class="ml-1"
                                @click="ac_openLink(profile.facebook)"
                            >IR</MBtnNormalComp>
                        </div>
                    </div>
                </div>
                <v-divider class="mt-3 mb-2"></v-divider>
                <h1 class="pl-2 text-body-private-title mb-1">IDIOMAS</h1>
                <div class="d-flex flex-wrap">
                    <div v-for="(languaje, index) in listLanguaje"
                        :key="index"
                        class="d-flex align-center ma-2 review-cv__group-info"
                    >
                        <div class="mr-1">
                            <div>
                                <MBtnCircleComp
                                    color="primary"
                                    :xsmall="true"
                                    class="mb-1"
                                    @click="ac_changeStateLanguaje(1, index)"
                                >
                                    <v-icon>mdi-check</v-icon>
                                </MBtnCircleComp>
                            </div>
                            <MBtnCircleComp
                                color="primary"
                                @click="ac_changeStateLanguaje(0, index)"
                            >
                                <v-icon>mdi-close</v-icon>
                            </MBtnCircleComp>
                        </div>
                        <div :class="ac_getClassDesaignOption(languaje)">
                            <h1 class="text-body-private-subtitle">{{languaje.languaje}}</h1>
                        </div>
                    </div>
                </div>
                <v-divider class="mt-3 mb-2"></v-divider>
                <h1 class="pl-2 text-body-private-title mb-1">CUALIDADES</h1>
                <div class="d-flex flex-wrap">
                    <div v-for="(qualitie, index) in listQualities"
                        :key="qualitie.qualitie"
                        class="d-flex align-center ma-2 review-cv__group-info"
                    >
                        <div class="mr-1">
                            <div>
                                <MBtnCircleComp
                                    color="primary"
                                    :xsmall="true"
                                    class="mb-1"
                                    @click="ac_changeStateQualitie(1, index)"
                                >
                                    <v-icon>mdi-check</v-icon>
                                </MBtnCircleComp>
                            </div>
                            <MBtnCircleComp
                                color="primary"
                                @click="ac_changeStateQualitie(0, index)"
                            >
                                <v-icon>mdi-close</v-icon>
                            </MBtnCircleComp>
                        </div>
                        <div :class="ac_getClassDesaignOption(qualitie)">
                            <h1 class="text-body-private-subtitle">{{qualitie.qualitie}}</h1>
                        </div>
                    </div>
                </div>
                <v-divider class="mt-3 mb-2"></v-divider>
                <h1 class="pl-2 text-body-private-title mb-1">PROFESIONES</h1>
                <div class="d-flex flex-wrap">
                    <div v-for="(profession, index) in listProfession"
                        :key="profession.profession"
                        class="d-flex align-center ma-2 review-cv__group-info"
                    >
                        <div class="mr-1">
                            <div>
                                <MBtnCircleComp
                                    color="primary"
                                    :xsmall="true"
                                    class="mb-1"
                                    @click="ac_changeStateProfession(1, index)"
                                >
                                    <v-icon>mdi-check</v-icon>
                                </MBtnCircleComp>
                            </div>
                            <MBtnCircleComp
                                color="primary"
                                @click="ac_changeStateProfession(0, index)"
                            >
                                <v-icon>mdi-close</v-icon>
                            </MBtnCircleComp>
                        </div>
                        <div :class="ac_getClassDesaignOption(profession)">
                            <h1 class="text-body-private-title">{{profession.profession}}</h1>
                            <h1 class="text-body-private-subtitle">{{profession.entity}}</h1>
                            <h1 class="text-body-private-subtitle">{{profession.location}}</h1>
                            <MBtnNormalComp
                                color="primary"
                                class="mt-1"
                                :outlined="true"
                                @click="ac_showPdf({pdf: profession.pdf, title: profession.profession})"
                            >RESPALDO PDF</MBtnNormalComp>
                        </div>
                    </div>
                </div>
                <v-divider class="mt-3 mb-2"></v-divider>
                <h1 class="pl-2 text-body-private-title mb-1">CURSOS O ESPECIALIDADES</h1>
                <div class="d-flex flex-wrap">
                    <div v-for="(specialty, index) in listSpecialities"
                        :key="specialty.specialty"
                        class="d-flex align-center ma-2 review-cv__group-info"
                    >
                        <div class="mr-1">
                            <div>
                                <MBtnCircleComp
                                    color="primary"
                                    :xsmall="true"
                                    class="mb-1"
                                    @click="ac_changeStateSpecialty(1, index)"
                                >
                                    <v-icon>mdi-check</v-icon>
                                </MBtnCircleComp>
                            </div>
                            <MBtnCircleComp
                                color="primary"
                                @click="ac_changeStateSpecialty(0, index)"
                            >
                                <v-icon>mdi-close</v-icon>
                            </MBtnCircleComp>
                        </div>
                        <div :class="ac_getClassDesaignOption(specialty)">
                            <h1 class="text-body-private-title">{{specialty.specialty}}</h1>
                            <h1 class="text-body-private-subtitle">{{specialty.entity}}</h1>
                            <h1 class="text-body-private-subtitle">{{specialty.location}}</h1>
                            <h1 class="text-body-private-subtitle">
                                {{'Acreditado el: '+m_date_formatDate(specialty.issuance_certificate)}}</h1>
                            <MBtnNormalComp
                                color="primary"
                                class="mt-1"
                                :outlined="true"
                                @click="ac_showPdf({pdf: specialty.pdf, title: specialty.specialty})"
                            >RESPALDO PDF</MBtnNormalComp>
                        </div>
                    </div>
                </div>
                <v-divider class="mt-3 mb-2"></v-divider>
                <h1 class="pl-2 text-body-private-title mb-1">EXPERIENCIA LABORAL</h1>
                <div class="d-flex flex-wrap">
                    <div v-for="(experience, index) in listExperience"
                        :key="experience.employer"
                        class="d-flex align-center ma-2 review-cv__group-info"
                    >
                        <div class="mr-1">
                            <div>
                                <MBtnCircleComp
                                    color="primary"
                                    :xsmall="true"
                                    class="mb-1"
                                    @click="ac_changeStateExperience(1, index)"
                                >
                                    <v-icon>mdi-check</v-icon>
                                </MBtnCircleComp>
                            </div>
                            <MBtnCircleComp
                                color="primary"
                                @click="ac_changeStateExperience(0, index)"
                            >
                                <v-icon>mdi-close</v-icon>
                            </MBtnCircleComp>
                        </div>
                        <div :class="ac_getClassDesaignOption(experience)">
                            <h1 class="text-body-private-title">{{experience.employer}}</h1>
                            <h1 class="text-body-private-subtitle">{{experience.position}}</h1>
                            <h1 class="text-body-private-subtitle">{{experience.location}}</h1>
                            <h1 class="text-body-private-small">
                                {{'Del '+m_date_formatDate(experience.beginning)+' al '+m_date_formatDate(experience.final)}}</h1>
                            <MBtnNormalComp
                                color="primary"
                                class="mt-1"
                                :outlined="true"
                                @click="ac_showPdf({pdf: experience.pdf, title: experience.position})"
                            >RESPALDO PDF</MBtnNormalComp>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-center mt-2 mb-3">
                    <MBtnNormalComp
                        color="primary"
                        :outlined="true"
                        @click="ac_showFormObservation()"
                    >OBSERVADO</MBtnNormalComp>
                    <MBtnNormalComp
                        color="primary"
                        class="ml-1"
                        :outlined="true"
                        @click="ac_confirmPublicationCv()"
                    >APROBAR CV</MBtnNormalComp>
                </div>
            </v-tab-item>
        </v-tabs-items>
        <v-dialog persistent width="400" v-model="dialogObservation">
            <ODialogHeaderComp
                title="Observar CV"
                @clickclose="ac_closeDialogObservation()"
            />
            <v-card class="ma-0 pa-2">
                <div class="d-flex">
                    <h1 class="text-body-private-title mr-1">AFILIADO:</h1>
                    <h1 class="text-body-private-subtitle" v-if="processCv.person!=undefined">
                        {{processCv.person.full_name+' '+processCv.person.last_name}}
                    </h1>
                </div>
                <div class="d-flex pb-2">
                    <div class="membership__ornament-circle mr-2 mt-1"></div>
                    <h2 class="text-body-subtitle">Todos los datos marcados con rojo serán observados.</h2>
                </div>
                <v-form
                    v-on:submit.prevent="ac_saveObservation()"
                    ref="formobservation"
                    v-model="valid"
                    lazy-validation
                >
                    <MTextFieldComp
                        class="mt-3"
                        v-model="observation"
                        :clearable="true"
                        label="Observación"
                        :dense="true"
                        :counter="100"
                        :rules="[v => (v+'').length<=100 || 'La observación no debe exeder los 100 dígitos']"
                    />
                </v-form>
                <div class="text-center">
                    <MBtnNormalComp
                        color="primary"
                        :outlined="true"
                        @click="ac_saveObservation()"
                    >GUARDAR OBSERVACIÓN</MBtnNormalComp>
                </div>
            </v-card>
        </v-dialog>

        <OModalPdfComp
            :dialog="dialogPdfPreview"
            :src="srcPdf"
            :title="titlePdf"
            @clickclose="dialogPdfPreview=false"
        />
        <OSmsConfirmComp
            :dialog="dialogConfirm"
            :smsConfirm="smsConfirm"
            @clickno="ac_closeOperationCv()"
            @clickyes="ac_perfomOperation()"
        />
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_closeSmsError()"
        />
        <OLoaderOperationComp
            :dialog="dialogLoaderOperation"
            smsLoaderOperation="Realizando Operación"
        />
        <OSmsActionComp
            :frameCorrect="frameCorrect"
            :smsAction="smsAction"
            :timeout="timeSucess"
            @click="m_dataGeneral_frameTimeReset()"
        />
    </div>
</template>
<script>
import {
    MLineHeaderComp,
    MBtnNormalComp,
    MBtnCircleComp,
    MTextFieldComp,
    MNoDataComp
} from '../../components/molecules'
import {
    OSearchComp,
    OSmsConfirmComp,
    OSmsErrorComp,
    OLoaderOperationComp,
    OSmsActionComp,
    OLoaderDataComp,
    OBtnHeaderToolTipComp,
    OModalPdfComp,
    ODialogHeaderComp
} from '../../components/organisms'
import {
    SPaginationComp,
    STableReviewCvComp,
    SCardReviewCvComp
} from '../../components/species'
import {
    ProcessCv,
    Person
} from '../../models'
import {
    dataGeneralComponetsMixin,
    rulesMixin,
    responseServerMixin,
    userMixin,
    menuCompMixing,
    scrollMixin,
    datesMixin
} from '../../mixin'
export default {
    mixins: [
        dataGeneralComponetsMixin,
        rulesMixin,
        responseServerMixin,
        userMixin,
        menuCompMixing,
        scrollMixin,
        datesMixin
    ],
    components : {
        OSearchComp,
        MLineHeaderComp,
        OSmsConfirmComp,
        OSmsErrorComp,
        OLoaderOperationComp,
        OSmsActionComp,
        OLoaderDataComp,
        SPaginationComp,
        OBtnHeaderToolTipComp,
        STableReviewCvComp,
        MBtnNormalComp,
        MBtnCircleComp,
        OModalPdfComp,
        ODialogHeaderComp,
        MTextFieldComp,
        SCardReviewCvComp,
        MNoDataComp
    },
    data: () => ({
        tab: 0,
        viewTable: true,
        mobile: false,
        page: 0,
        total: 0,
        last_page: 0,
        listProcessCv: [],
        search: '',
        processCv: {},
        listLanguaje: [],
        listQualities: [],
        listSpecialities: [],
        listProfession: [],
        listExperience: [],
        profile: {},
        dialogPdfPreview: false,
        srcPdf: '',
        titlePdf: '',
        isPublicCv: false,
        dialogObservation: false,
        observation: null,
        valid: true,
        isDelete: false
    }),
    computed: {
        searchFilter: function() {
            if (this.search !== null) return this.search
            return ''
        }
    },
    methods: {
        processError(error) {
            this.dialogLoaderOperation = false
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_action(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        ac_closeSmsError() {
            if (this.noAccess) {
                localStorage.removeItem('abis')
                this.$router.replace('/login')
            } else this.dialogError = false
        },
        ac_closeOperationCv() {
            this.dialogConfirm = false
            this.isPublicCv = false
            this.isDelete = false
        },
        getListProcessCv(page, search) {
            this.listProcessCv = []
            this.dialogLoaderData = true
            ProcessCv.getListProcessCv(page, search).then(response => {
                this.listProcessCv = response.data
                this.page = response.page,
                this.last_page = response.last_page,
                this.total = response.total
                this.dialogLoaderData = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_searchProcess() {
            if (this.search === null) this.getListProcessCv(1, '')
            else this.getListProcessCv(1, this.search)
        },
        getDataPersonCv(id) {
            this.dialogLoaderOperation = true
            ProcessCv.getCvPerson(id).then(response => {
                this.listLanguaje = response.list_languajes
                this.listQualities = response.list_qualities
                this.listSpecialities = response.list_specialties
                this.listProfession = response.list_professions
                this.listExperience = response.list_experience
                this.profile = response.other.length > 0 ? response.other[0] : {}
                this.dialogLoaderOperation = false
                this.tab = 1
            }).catch(error => {
                this.processCv(error)
            })
        },
        ac_getClassDesaignOption(option) {
            //if (option.select === 2) return 'review-cv__option review-cv--border-normal'
            if (option.validate === 1) return 'review-cv__option review-cv--border-correct'
            return 'review-cv__option review-cv--border-error'
        },
        ac_showFormCv(process) {
            this.processCv = process
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            setTimeout(() => {
                this.getDataPersonCv(process.person.id)
            }, velocity)
        },
        ac_changeStateLanguaje(value, index) {
            this.listLanguaje[index].validate = value
        },
        ac_changeStateQualitie(value, index) {
            this.listQualities[index].validate = value
        },
        ac_changeStateSpecialty(value, index) {
            this.listSpecialities[index].validate = value
        },
        ac_changeStateProfession(value, index) {
            this.listProfession[index].validate = value
        },
        ac_changeStateExperience(value, index) {
            this.listExperience[index].validate = value
        },
        ac_changeStateProfile(value) {
            this.profile.validate = value
        },
        ac_showPdf(parameters) {
            this.titlePdf = parameters.title
            if (this.mobile) {
                Person.showPdfMobile(parameters.pdf)
            } else {
                this.srcPdf = ''
                this.srcPdf = Person.showPdfPc(parameters.pdf)
                setTimeout(() => {
                    this.dialogPdfPreview = true
                }, 200)
            }
        },
        ac_confirmPublicationCv() {
            this.isPublicCv = true
            this.smsConfirm = ['¿Está seguro de aprobar el CV de:',
                `${this.processCv.person.full_name}?`]
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            setTimeout(() => {
                this.dialogConfirm = true
            }, velocity)
        },
        approveCv() {
            ProcessCv.approveCv({id: this.processCv.person.id}).then(() => {
                const index = this.listProcessCv.indexOf(this.processCv)
                this.listProcessCv.splice(index, 1)
                this.dialogLoaderOperation = false
                this.tab = 0
                this.showEvent(['Operación exitosa', 'Se aprobó el CV'])
            }).catch(error => {
                this.processError(error)
            })
        },
        showEvent(message) {
            this.smsAction = message
            this.frameCorrect = true
            this.timeSucess = setTimeout(() => {
                this.frameCorrect = false
            }, 3000)
        },
        deleteProcess() {
            const parameters = {
                id_person: this.processCv.person.id,
                id_process: this.processCv.id
            }
            ProcessCv.deleteCv(parameters).then(() => {
                const indexProcess = this.listProcessCv.indexOf(this.processCv)
                this.listProcessCv.splice(indexProcess, 1)
                this.showEvent(['Operación exitosa', 'Se eliminó el CV'])
                this.dialogLoaderOperation = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_perfomOperation() {
            this.dialogConfirm = false
            this.dialogLoaderOperation = true
            if (this.isDelete) this.deleteProcess()
            else {
                if (this.isPublicCv) this.approveCv()
                else this.cvObserver()
            }
        },
        ac_showFormObservation() {
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            setTimeout(() => {
                this.dialogObservation = true
            }, velocity)
        },
        ac_closeDialogObservation() {
            this.dialogObservation = false
            this.observation = null
            this.$refs.formobservation.resetValidation()
        },
        ac_saveObservation() {
            this.dialogObservation = false
            this.dialogLoaderOperation = true
            const parameters = {
                profile: this.profile,
                languajes: this.listLanguaje,
                qualities: this.listQualities,
                professions: this.listProfession,
                specialties: this.listSpecialities,
                experiences: this.listExperience,
                id_process: this.processCv.id,
                observation: this.m_rule_emptyField(this.observation) ? null : this.observation
            }
            ProcessCv.observeProcess(parameters).then(response => {
                const index = this.listProcessCv.indexOf(this.processCv)
                this.listProcessCv.splice(index, 1)
                this.dialogLoaderOperation = false
                this.tab = 0
                this.showEvent(['Operación exitosa', 'Se observó el CV'])
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_confirmDelete(process) {
            this.processCv = process
            this.isDelete = true
            this.smsConfirm = ['¿Está seguro de eliminar el CV de:',
                `${process.person.full_name}?`]
            this.dialogConfirm = true
        },
        ac_refreshList() {
            this.search = ''
            if (this.tab != 0) this.tab = 0
            this.getListProcessCv(1, '')
        },
        ac_changePage(state) {
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            if (state) { ++this.page }
            else { --this.page }
            setTimeout(() => {
                this.getListProcessCv(this.page, this.search)
            }, velocity)
        },
        ac_openLink(link) {
            window.open(link)
        }
    },
    mounted() {
        if (window.innerWidth <= 600) {
            this.mobile = true
            this.viewTable = false
        }
        if (this.m_user_saveToken()) {
            this.getListProcessCv(1, '')
        } else this.$router.replace('/login')
    }
}
</script>