<template>
    <div>
        <div class="user__header pa-2">
            <div class="d-flex justify-center align-center">
                <v-app-bar-nav-icon class="mr-2 user__icon-menu" @click="m_menuComp_changeStateMenu()"></v-app-bar-nav-icon>
                <h1 class="text-body-private-title">GALERÍA</h1>
            </div>
            <v-divider class="mb-2 mt-1 user__line-mobile"></v-divider>
            <v-spacer></v-spacer>
            <div class="d-flex align-center">
                <MSelectComp
                    v-if="tab==0"
                    v-model="searchOption"
                    :items="itemsOptionsPublic"
                    style="width:220px"
                    itemValue="value"
                    itemText="title"
                    :hideDetails="true"
                    @change="ac_changeStateGallery"
                />
                <OBtnHeaderToolTipComp
                    v-if="tab==0"
                    smsTooltip="Nueva publicación"
                    icon="mdi-plus"
                    class="ml-1"
                    @click="tab=1"
                />
                <OBtnHeaderToolTipComp
                    v-if="tab==1"
                    class="ml-1"
                    smsTooltip="Volver a la lista"
                    icon="mdi-database"
                    @click="ac_backListGallery()"
                />
            </div>
        </div>
        <MLineHeaderComp />
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <div class="d-flex pa-2">
                    <h1 class="text-body-private-title mr-1">Total Fotografías:</h1>
                    <h1 class="text-body-private-subtitle">{{total}}</h1>
                </div>
                <v-divider></v-divider>
                <OLoaderDataComp
                    class="mt-2"
                    v-if="dialogLoaderData"
                    smsLoaderData="Obteniendo Fotos"
                />
                <MNoDataComp
                    v-if="!dialogLoaderData && listGallery.length==0"
                    smsNotData="SIN FOTOGRAFÍAS"
                    class="ma-2"
                />
                <div class="d-flex justify-center flex-wrap">
                    <SCardGalleryComp
                        class="ma-2"
                        v-for="(gallery, index) in listGallery"
                        :key="index"
                        :gallery="gallery"
                        @clickedit="ac_showFormEditPhoto(gallery)"
                        @clickdelete="ac_confirmDeletePhoto(gallery)"
                        @clickpublic="ac_confirmChangeState(gallery)"
                        @clickbig="ac_showImgBig(gallery)"
                        @clickdetail="ac_showDetailPhoto(gallery)"
                    />
                </div>
                <SPaginationComp
                    class="mt-2"
                    v-if="!dialogLoaderData && listGallery.length>0"
                    :condicionGo="page < last_page"
                    :condicionBack="page > 1"
                    :titlePagination="'Pag. '+page+' de '+last_page"
                    @clickback="ac_changePage(false)"
                    @clickgo="ac_changePage(true)"
                />
            </v-tab-item>
            <v-tab-item>
                <div class="d-flex justify-center pa-2 pt-0 pb-3">
                    <v-form
                        v-on:submit.prevent="ac_confirmAction()"
                        ref="formphoto"
                        v-model="valid"
                        lazy-validation
                        class="user--border user--radius pa-3 mt-3"
                        style="width:460px"
                    >
                        <h1 class="text-body-private-title text-center mb-2">
                            {{indexGallery==-1 ? 'NUEVA FOTOGRAFÍA' : 'EDITAR FOTOGRAFÍA'}}</h1>
                        <div class="d-flex justify-center">
                            <OPhotoPublicComp
                                ref="photopublic"
                                :errorPhoto="errorPhoto"
                                :haveImage="haveImage"
                                :srcPreviewImage="dataGallery.route_img!=undefined ? dataGallery.route_img : ''"
                                @onChangePhoto="ac_changePhoto"
                            />
                        </div>
                        <MTextFieldComp
                            class="mt-3"
                            v-model="dataGallery.title"
                            :clearable="true"
                            label="Título"
                            :dense="true"
                            :counter="200"
                            :rules="[v => !this.m_rule_emptyField(v) || 'El dato es requerido',
                                v => (v+'').length<=200 || 'El título no debe exeder los 200 dígitos']"
                        />
                        <MTextAreaComp
                            class="mt-3"
                            v-model="dataGallery.description"
                            :clearable="true"
                            label="Descripción"
                            :dense="true"
                            :counter="1000"
                            height="120"
                            :rules="[v => (v+'').length<=1000 || 'La descripción no debe exeder los 1000 dígitos']"
                        />
                        <div class="text-center">
                            <MBtnNormalComp
                                color="primary"
                                :outlined="true"
                                @click="ac_confirmAction()"
                            >GUARDAR</MBtnNormalComp>
                        </div>
                    </v-form>
                </div>
            </v-tab-item>
        </v-tabs-items>
        <v-dialog persistent width="550" v-model="dialogDetailPhoto">
            <ODialogHeaderComp
                title="Información Completa"
                @clickclose="dialogDetailPhoto=false"
            />
            <v-card class="ma-0 pa-0">
                <div class="pa-2" v-if="infoGallery.title!=undefined">
                    <h1 class="text-body-private-title">TÍTULO</h1>
                    <h1 class="text-body-private-subtitle">{{infoGallery.title}}</h1>
                    <v-divider class="mt-1 mb-1"></v-divider>
                    <h1 class="text-body-private-title">DESCRIPCIÓN</h1>
                    <h1 class="text-body-private-subtitle">{{infoGallery.description}}</h1>
                </div>
            </v-card>
        </v-dialog>
        <OBigImageComp
            :dialog="dialogImgBig"
            :listGallery="listGallery"
            :mobile="mobile"
            :positionCarrusel="positionCarrusel"
            @clickclose="dialogImgBig=false"
        />
        <OSmsErrorComp
            :dialog="dialogError"
            :smsError="smsError"
            :actionError="actionError"
            @click="ac_closeSmsError()"
        />
        <OSmsConfirmComp
            :dialog="dialogConfirm"
            :smsConfirm="smsConfirm"
            @clickno="ac_closeSmsError()"
            @clickyes="ac_perfomOperation()"
        />
        <OSmsActionComp
            :frameCorrect="frameCorrect"
            :smsAction="smsAction"
            :timeout="timeSucess"
            :colorMessage="colorMessage"
            @click="m_dataGeneral_frameTimeReset()"
        />
        <OLoaderOperationComp
            :dialog="dialogLoaderOperation"
            smsLoaderOperation="Realizando Operación"
        />
    </div>
</template>
<script>
import {
    MTextFieldComp,
    MLineHeaderComp,
    MNoDataComp,
    MBtnNormalComp,
    MTextAreaComp,
    MSelectComp
} from '../../components/molecules'
import {
    OBtnHeaderToolTipComp,
    OSmsErrorComp,
    OSmsConfirmComp,
    OSmsActionComp,
    OLoaderOperationComp,
    OLoaderDataComp,
    OPhotoPublicComp,
    OBigImageComp,
    ODialogHeaderComp
} from '../../components/organisms'
import {
    SCardDirectoryComp,
    SCardGalleryComp,
    SPaginationComp
} from '../../components/species'
import {
    dataGeneralComponetsMixin,
    responseServerMixin,
    rulesMixin,
    userMixin,
    menuCompMixing,
    scrollMixin
} from '../../mixin'
import {
    Gallery
} from '../../models'
export default {
    mixins: [
        dataGeneralComponetsMixin,
        responseServerMixin,
        rulesMixin,
        userMixin,
        menuCompMixing,
        scrollMixin
    ],
    components: {
        MTextFieldComp,
        OBtnHeaderToolTipComp,
        MLineHeaderComp,
        SCardDirectoryComp,
        OSmsErrorComp,
        OSmsConfirmComp,
        OSmsActionComp,
        OLoaderOperationComp,
        OLoaderDataComp,
        MNoDataComp,
        MBtnNormalComp,
        MTextAreaComp,
        MSelectComp,
        OPhotoPublicComp,
        SCardGalleryComp,
        SPaginationComp,
        OBigImageComp,
        ODialogHeaderComp
    },
    data: () => ({
        valid: true,
        viewTable: true,
        searchOption: 2,
        dataGallery: new Gallery(),
        indexGallery: -1,
        idGalerry: 0,
        listGallery: [],
        page: 0,
        total: 0,
        last_page: 0,
        isDelete: false,
        tab: 0,
        errorPhoto: false,
        haveImage: false,
        itemsOptionsPublic: [
            { title: 'Todos', value: 2 },
            { title: 'Publicados', value: 1 },
            { title: 'No Publicados', value: 0 }
        ],
        isChangeState: false,
        dialogImgBig: false,
        mobile: false,
        positionCarrusel: 0,
        infoGallery: {},
        dialogDetailPhoto: false
    }),
    methods: {
        processError(error) {
            this.dialogLoaderOperation = false
            this.smsError = this.m_response_smsError(error)
            this.actionError = this.m_response_action(error)
            this.noAccess = this.m_response_autorization(error)
            this.dialogError = true
        },
        ac_closeSmsError() {
            if (this.noAccess) {
                localStorage.removeItem('abis')
                this.$router.replace('/login')
            } else {
                this.isChangeState = false
                this.indexGallery = -1
                this.dialogError = false
                this.dialogConfirm = false
                this.isDelete = false
            }
        },
        getListGallery(page, option_public) {
            this.listGallery = []
            this.dialogLoaderData = true
            Gallery.getListGallery(page, option_public).then(response => {
                this.listGallery = response.data
                this.page = response.page
                this.last_page = response.last_page
                this.total = response.total
                this.dialogLoaderData = false
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_changePhoto(data) {
            if (data.is_big) {
                this.dataGallery.route_file = null
                this.errorPhoto = true
                this.dataGallery.new_img = 0
                this.showEvent(['Error de imagen',
                    'El tamaño no debe exceder los 2MB'], 'error')
            } else {
                this.dataGallery.route_file = data.file
                if (data.file !== null) {
                    this.errorPhoto = false
                    this.dataGallery.new_img = 1
                } else {
                    this.dataGallery.new_img = 0
                    if (this.indexGallery === -1) this.errorPhoto = true
                }
            }
        },
        validatePhoto() {
            if (!this.$refs.formphoto.validate()) return false
            return true
        },
        smsErrorForm() {
            this.smsError = [`Revise los datos marcados con rojo y corrija porfavor`]
            this.dialogError = true
            this.actionError = true
        },
        ac_confirmAction() {
            if (!this.validatePhoto()) {
                if (this.indexGallery === -1 && this.dataGallery.route_file === null) this.errorPhoto = true
                this.smsErrorForm()
            } else {
                if (this.indexGallery === -1 && this.dataGallery.route_file === null) {
                    this.errorPhoto = true
                    this.smsErrorForm()
                } else {
                    var velocity = this.m_scroll_velocity(window.scrollY)
                    this.m_scroll_animation(velocity)
                    setTimeout(() => {
                        if (this.indexGallery !== -1) this.smsConfirm = ['¿Está seguro de editar la fotografía?']
                        else this.smsConfirm = ['¿Está seguro de registrar la fotografía?']
                        this.dialogConfirm = true
                    }, velocity)
                }
            }
        },
        showEvent(message, colorSms) {
            this.colorMessage = colorSms
            this.smsAction = message
            this.frameCorrect = true
            this.timeSucess = setTimeout(() => {
                this.frameCorrect = false
            }, 3000)
        },
        deletePhoto() {
            Gallery.deleteGallery({id: this.idGalerry}).then(() => {
                this.listGallery.splice(this.indexGallery, 1)
                this.indexGallery = -1
                this.idGalerry = 0
                this.isDelete = false
                this.dialogLoaderOperation = false
                this.showEvent(['Operación exitosa',
                    'se eliminó la fotografía'], 'success')
            }).catch(error => {
                this.processError(error)
            })
        },
        addPhoto() {
            this.dataGallery.name_file = new Date().getTime()
            Gallery.addGallery(this.$objectToFD(this.dataGallery)).then(response => {
                this.listGallery.unshift(response)
                this.ac_backListGallery()
                this.dialogLoaderOperation = false
                this.showEvent(['Operación exitosa',
                    'se registró la fotografía'], 'success')
            }).catch(error => {
                this.processError(error)
            })
        },
        updatePhoto() {
            this.dataGallery.name_file = new Date().getTime()
            Gallery.updateGallery(this.$objectToFD(this.dataGallery)).then(response => {
                Object.assign(this.listGallery[this.indexGallery], response)
                this.ac_backListGallery()
                this.dialogLoaderOperation = false
                this.showEvent(['Operación exitosa',
                    'se editó la fotografía'], 'success')
            }).catch(error => {
                this.processError(error)
            })
        },
        changeStatePhoto() {
            Gallery.changeState({id: this.idGalerry}).then(response => {
                this.listGallery[this.indexGallery].public = response
                this.isChangeState = false
                this.indexGallery = -1
                this.dialogLoaderOperation = false
                this.showEvent(['Operación exitosa',
                    'se cambio el estado de la foto'], 'success')
            }).catch(error => {
                this.processError(error)
            })
        },
        ac_perfomOperation() {
            this.dialogConfirm = false
            this.dialogLoaderOperation = true
            if (this.isChangeState) {
                this.changeStatePhoto()
            } else {
                if (this.isDelete) this.deletePhoto()
                else {
                    if (this.indexGallery !== -1) this.updatePhoto()
                    else this.addPhoto()
                }
            }
        },
        ac_backListGallery() {
            this.tab = 0
            setTimeout(() => {
                this.haveImage = false
                this.$refs.photopublic.resetMiniature()
                this.indexGallery = -1
                this.dataGallery = new Gallery()
                this.$refs.formphoto.resetValidation()
                this.errorPhoto = false
            }, 200)  
        },
        ac_showFormEditPhoto(photo) {
            this.haveImage = true
            this.indexGallery = this.listGallery.indexOf(photo)
            this.dataGallery = Object.assign({}, photo)
            this.dataGallery.new_img = 0
            this.dataGallery.route_file = null
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            setTimeout(() => {
                this.tab = 1
            }, velocity)
        },
        ac_confirmDeletePhoto(photo) {
            this.isDelete = true
            this.indexGallery = this.listGallery.indexOf(photo)
            this.idGalerry = photo.id
            this.smsConfirm = ['¿Está seguro de eliminar la fotografía?']
            this.dialogConfirm = true
        },
        ac_changePage(state) {
            var velocity = this.m_scroll_velocity(window.scrollY)
            this.m_scroll_animation(velocity)
            if (state) { ++this.page }
            else { --this.page }
            setTimeout(() => {
                this.getListGallery(this.page, this.searchOption)
            }, velocity)
        },
        ac_changeStateGallery(e) {
            this.getListGallery(1, e)
        },
        ac_confirmChangeState(gallery) {
            this.idGalerry = gallery.id
            this.indexGallery = this.listGallery.indexOf(gallery)
            this.isChangeState = true
            const smsState = gallery.public == 0 ? 'PUBLICAR' : 'QUITAR LA PUBLICACIÓN de'
            this.smsConfirm = [`¿Está seguro de ${smsState} la fotografía?`]
            this.dialogConfirm = true
        },
        ac_showImgBig(gallery) {
            this.positionCarrusel = this.listGallery.indexOf(gallery)
            setTimeout(() => {
                this.dialogImgBig = true
            }, 150)
        },
        ac_showDetailPhoto(gallery) {
            this.infoGallery = gallery
            this.dialogDetailPhoto = true
        }
    },
    mounted() {
        if (window.innerWidth <= 600) {
            this.viewTable = false
            this.mobile = true
        }
        if (this.m_user_saveToken()) {
            this.getListGallery(1, 2)
        } else this.$router.replace('/login')
    }
}
</script>