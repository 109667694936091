<template>
    <v-btn
        :ripple="false"
        :color="color"
        :small="small"
        :dark="dark"
        :disabled="disabled"
        :outlined="outlined"
        :width="width"
        :depressed="depressed"
        :loading="loading"
        :to="to"
        @click="$emit('click')"
    >
        <slot></slot>
    </v-btn>
</template>
<script>
export default {
    props: {
        color: String,
        dark: Boolean,
        outlined: Boolean,
        disabled: Boolean,
        small: {
            default: true,
            type: Boolean
        },
        depressed: Boolean,
        to: [String, Object],
        width: String,
        loading: Boolean
    }
}
</script>