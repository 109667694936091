<template>
    <div
        :class="errorPhoto ? 'o-photo o-photo__error' : 'o-photo o-photo__correct'"
        @click="ac_activateChargue()"
        :style="ac_styleCursorImg()"
        @drop.prevent="addFile"
        @dragover.prevent
    >
        <div class="o-photo__picture d-flex align-center">
            <div v-if="haveImage"
                class="d-flex align-center"
                style="background:white; width:100%; height:100%;"
            >
                <div v-if="!selectOtherImg" style="width:100%; height:100%;">
                    <v-img
                        v-if="ac_isFileImg()"
                        :src="srcPreviewImage|imgMiniature"
                        width="100%"
                        height="100%"
                        contain
                    ></v-img>
                    <v-img
                        v-else
                        src="/imgs/private/pdf_cargado.jpg"
                        width="100%"
                        height="100%"
                        contain
                    ></v-img>
                </div>
                <v-img
                    v-else
                    :src="imgPhotography"
                    width="100%"
                    height="100%"
                    contain
                ></v-img>
            </div>
            <div
                v-else
                class="d-flex align-center"
                style="background:white; width:100%; height:100%;"
            >
                <div
                    v-if="imgPhotography!=''"
                    class="d-flex align-center"
                    style="background:white; width:100%; height:100%;"
                >
                    <v-img
                        :src="imgPhotography"
                        width="100%"
                        height="100%"
                        contain
                    ></v-img>
                </div>
                <div v-else class="o-photo__picture d-flex align-center">
                    <div style="width:100%">
                        <h1 class="text-body-private-small-title text-center">{{firstTitle}}</h1>
                        <h1 class="text-body-private-small-title text-center">{{secondTitle}}</h1>
                        <h1 class="text-body-private-small-title text-center" v-if="!justTwoLine">{{thirdTitle}}</h1>
                        <h1 class="text-body-private-small text-center">{{textFormat}}</h1>
                        <h1 class="text-body-private-small text-center">(Máximo: 2Mb)</h1>
                    </div>
                </div>
            </div>
        </div>
        <input
            style="display: none"
            type="file"
            :ref="reference"
            :accept="acceptFile"
            @change="ac_onFilePicked"
        >
    </div>
</template>
<script>
import { imgMiniature } from '../../filters'
export default {
    filters: {
        imgMiniature
    },
    props: {
        justTwoLine: {
            type: Boolean,
            default: false
        },
        firstTitle: String,
        secondTitle: String,
        thirdTitle: String,
        reference: String,
        errorPhoto: Boolean,
        haveImage: Boolean,
        srcPreviewImage: String,
        observationImg: Boolean,
        acceptFile: String,
        textFormat: String
    },
    data: () => ({
        imgPhotography: '',
        selectOtherImg: false
    }),
    methods: {
        loadImg(file, isImg) {
            let reader = new FileReader()
            reader.onload = (e) =>{
                if (isImg) this.imgPhotography = e.target.result
                else this.imgPhotography = '/imgs/private/pdf_cargado.jpg'
            }
            reader.readAsDataURL(file)
        },
        addFile(e) {
            var actionDropFile = true
            if (this.haveImage && !this.observationImg) actionDropFile = false
            if (actionDropFile) {
                const files = e.dataTransfer.files
                if (files[0] !== undefined) {
                    let file = e.dataTransfer.files[0]
                    if (file.size <= 2097152) {//2097152
                        if (file.type != 'application/pdf' && file.type != 'image/jpeg') {
                            this.imgPhotography = ''
                            this.selectOtherImg = false
                            this.$emit('onChangePhoto', {'file': null, 'election_photo': false, 'is_big': false, 'is_another_file': true})
                        } else {
                            if (file.type == 'application/pdf') { this.loadImg(file, false) }
                            else { this.loadImg(file, true) }
                            this.selectOtherImg = true
                            this.$emit('onChangePhoto', {'file': e.dataTransfer.files[0], 'election_photo': true, 'is_big': false, 'is_another_file': false})
                        }
                    } else {
                        this.imgPhotography = ''
                        this.selectOtherImg = false
                        this.$emit('onChangePhoto', {'file': null, 'election_photo': false, 'is_big': true, 'is_another_file': false})
                    }
                } else {
                    this.selectOtherImg = false
                    this.imgPhotography = ''
                    this.$emit('onChangePhoto', {'file': null, 'election_photo': false, 'is_big': false, 'is_another_file': false})
                }
            }
        },
        ac_onFilePicked(e) {
            const files = e.target.files
            if (files[0] !== undefined) {
                let file= e.target.files[0]
                if (file.size <= 2097152) {
                    if (file.type == 'application/pdf') {
                        this.loadImg(file, false)
                    } else {
                        this.loadImg(file, true)
                    }
                    this.selectOtherImg = true
                    this.$emit('onChangePhoto', {'file': e.target.files[0], 'election_photo': true, 'is_big': false})
                } else {
                    this.imgPhotography = ''
                    this.selectOtherImg = false
                    this.$emit('onChangePhoto', {'file': null, 'election_photo': false, 'is_big': true})
                }
            } else {
                this.selectOtherImg = false
                this.imgPhotography = ''
                this.$emit('onChangePhoto', {'file': null, 'election_photo': false, 'is_big': false})
            }
        },
        ac_activateChargue() {
            if (this.haveImage){
                if (this.observationImg) {
                    this.$refs[this.reference].click()
                }
            } else this.$refs[this.reference].click()
        },
        ac_styleCursorImg() {
            if (this.haveImage) {
                if (this.observationImg) {
                    return 'cursor:pointer'
                } else return ''
            } return 'cursor:pointer'
        },
        ac_isFileImg() {
            const typeFile = this.srcPreviewImage.split(".")
            if(typeFile[1] != 'pdf') return true
            return false
        }
    }
}
</script>