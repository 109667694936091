export default {
    methods: {
        m_response_action(error) {
            var accionResponse = false
            if( error.response !==  undefined ) {
                switch (error.response.status) {
                    case 422:
                        accionResponse = true
                        break
                    case 500:
                        accionResponse = false
                        break
                    case 401:
                        accionResponse = true
                        break
                    case 402:
                        accionResponse = true
                        break
                    default:
                        accionResponse = false
                }
            } else {
                accionResponse = false
            }
            return accionResponse
        },
        m_response_smsError(error) {
            var smsResponse = []
            if( error.response !==  undefined ) {
                switch (error.response.status) {
                    case 422:
                        smsResponse = error.response.data
                        break
                    case 500:
                        smsResponse = ['Error en el proceso de almacenado en la B.D.',
                            'Revise su concexión de internet y refresque la página (F5)']
                        break
                    case 401:
                            smsResponse = ['OPERACIONES CANCELADAS',
                                'Su usuario ha iniciado sesión en otro dispositivo,',
                                ' por seguridad será redirigido al login para volver a iniciar sesión']
                            break
                    case 402:
                        smsResponse = ['OPERACIONES CANCELADAS',
                            'Usted fue inhabilitado del sistema,',
                            ' porfavor comuniquese con su administrador']
                        break
                    default:
                        smsResponse = ['Error en el servidor', 'Revise su conexión de internet y refresque la página (F5)']
                }
            } else {
                smsResponse = ['Error de conexión de internet',
                    'Revise su concexión de internet y refresque la página (F5)']
            }
            return smsResponse
        },
        m_response_autorization(error) {
            if( error.response !==  undefined ) {
                if (error.response.status === 401 || error.response.status === 402) {
                    return true
                }
            }
            return false
        },
        m_response_disabledUser(error) {
            if( error.response !==  undefined ) {
                if (error.response.status === 402) {
                    return true
                }
            }
            return false
        }
    }
}